import React, { createRef } from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonInput, IonLabel, IonLoading, IonPage, IonToggle, IonToolbar } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { TopNavbar } from '../common/header/topbar';
import { connect } from 'react-redux';
import { apiService } from '../../services/apiService';
import { addGroup, resetValues } from '../../redux/actions/dashboard';
import { chevronDownOutline, chevronForwardOutline } from 'ionicons/icons';
import { ContactsList } from './components/ContactsList';
import { locale } from '../../locales/local';
import EnumService from '../../services/enumService';
import { info, sortByAlias } from '../../helpers/common';

const ContactImg = './assets/img/blank-user-profile.png';
const CloseIcon = './assets/icon/close-icon-outline.svg';
const ArrowRightIcon = './assets/icon/arrow-right.svg';
const CloseCircleIcon = './assets/icon/close-circle.svg';
const PlusLightIcon = './assets/icon/plus-light-icon.svg';
const ClearIcon = './assets/icon/close-icon.svg';

interface iProps extends RouteComponentProps<{ name: string }> {
	//addGroup: Function;
	resetValues: Function;
	location: any;
	dashboard: any;
}
interface iState {
	loggedInUser?: any;
	contacts: any;
	isTagNameEdit: boolean;
	isTagNameFocus: boolean;
	isTagContactsEdit: boolean;
	tagName: string;
	tagsList: any;
	isDeleteMode: boolean;
	isAddNew: boolean;
}

class TagInfo extends React.Component<iProps, iState> {
	tagNameInputRef: any = createRef();
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);

		const state: any = props.history.location.state;

		let tagsList = [];
		if (state?.isAddNew) {
			tagsList = [
				{ section_name: 'Groups', type: 'groups', members: [] },
				{ section_name: 'Members', type: 'friends', members: [] },
			];
		} else {
			tagsList = [
				{ section_name: 'Groups', type: 'groups', members: [{ name: 'Grp Name' }] },
				{ section_name: 'Members', type: 'friends', members: [{ name: 'Username' }, { name: 'Username' }, { name: 'Username' }, { name: 'Username' }, { name: 'Username long' }, { name: 'Username' }, { name: 'Username long' }, { name: 'Username' }] },
			];
		}
		this.state = {
			isAddNew: state?.isAddNew,
			loggedInUser: undefined,
			contacts: undefined,
			isDeleteMode: false,
			tagName: '',
			isTagNameEdit: state?.isAddNew,
			isTagNameFocus: false,
			isTagContactsEdit: false,
			tagsList: tagsList,
		};

		this.props.resetValues();
	}

	getData = async () => {
		let user = await apiService.me(),
			contacts: any = user.contacts.filter((_contact: any) => _contact.status === 'confirmed').sort(sortByAlias);
			/*contacts: any = await apiService.getContacts(false, 'confirmed').then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});*/

		return [user, contacts];
	};

	setPageData = async () => {
		let [user, contacts] = await this.getData();

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			contacts: contacts,
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	setSearchText() {}
	searchAllGroups(searchValue: string, props: any) {
		props.history.replace('/contacts/search', searchValue);
	}

	_onContactSelect = (cont: any) => {};

	_renderSwitchItem = (title: any, value: any, onChange: any) => {
		return (
			<div className={'swtich-item'} slot="end">
				<IonLabel>{title}</IonLabel>
				<IonToggle slot="end" className="toggle-customised" mode="ios" value={value} onChange={onChange} />
			</div>
		);
	};

	render() {
		const { tagsList, tagName, isAddNew } = this.state;

		let isDoneBtnActive = false;

		if (tagName) {
			isDoneBtnActive = true;
		}

		return (
			<>
				<IonPage className="taginfo-page">
					<TopNavbar
						{...this.props}
						isHideRightButton={false}
						showBack={false}
						hideSearchBar={true}
						leftButtonIcon={CloseIcon}
						pageTitle={isAddNew ? 'New Tag' : 'Tag info'}
						rightButtonText="Done"
						rightButtonDisabled={!this.state.isDeleteMode || (this.state.isTagNameEdit && !isDoneBtnActive)}
						onLeftButtonPress={() => {
							this.props.history.goBack();
						}}
						onRightButtonPress={() => {
							this.props.history.replace('/contacts', {
								actionType: EnumService.ContactsPageActionType.ChooseContactForTag,
							});
						}}
					/>

					<IonContent className="main-content-profile has-bottom-navbar has-topbar">
						<IonList lines="none">
							{/* <IonItem className="tag-field-item">
								<IonLabel className={isTagContactsEdit ? 'active' : ''}>Members: 5</IonLabel>
								<IonButton slot="end" fill="clear" onClick={() => this.setState({ isTagContactsEdit: !this.state.isTagContactsEdit })}>
									<IonIcon slot="icon-only" icon={this.state.isTagContactsEdit ? chevronDownOutline : chevronForwardOutline} />
								</IonButton>
							</IonItem> */}

							<IonItem className="tagname-title-item">Tag Name</IonItem>
							<IonItem
								className="tag-field-item"
								lines="none"
								onClick={() => {
									if (!this.state.isTagNameEdit) {
										const tagNameInput: any = document.getElementById('tagnameinputfield');
										if (tagNameInput && tagNameInput.setFocus) {
											tagNameInput.setFocus();
										}
									}
									this.setState({ isTagNameEdit: true });
								}}
							>
								<IonInput
									value={tagName}
									id="tagnameinputfield"
									placeholder="Set Tag name"
									onIonChange={(event) => {
										this.setState({ tagName: String(event.detail.value) });
									}}
									ref={this.tagNameInputRef}
									clearInput={false}
									onFocus={() => {
										this.setState({ isTagNameFocus: true });
									}}
									onBlur={() => {
										this.setState({ isTagNameFocus: false });
									}}
								/>

								{!this.state.isTagNameEdit && (
									<IonButton slot="end" fill="clear" className="arrow-icon">
										<IonIcon slot="icon-only" src={ArrowRightIcon} />
									</IonButton>
								)}
								{this.state.isTagNameEdit && tagName && (
									<IonButton
										slot="end"
										fill="clear"
										className="clear-icon"
										onClick={() => {
											this.setState({ tagName: '' });
											const tagNameInput: any = document.getElementById('tagnameinputfield');
											if (tagNameInput && tagNameInput.setFocus) {
												tagNameInput.setFocus();
											}
										}}
									>
										<IonIcon slot="icon-only" src={ClearIcon} />
									</IonButton>
								)}
							</IonItem>
						</IonList>

						{tagsList.map((item: any, key: any) => (
							<React.Fragment key={key}>
								<div className="list-devider">
									{item.section_name} ({item.members.length > 0 ? item.members.length : ''})
								</div>

								<div className="contacts-item-container">
									{item.members.length > 0 ? (
										item.members.map((contact: any, cKey: any) => {
											return (
												<div className="contact-item" key={cKey}>
													<img className="contact-img" src={ContactImg} alt="profilePhoto"/>
													<span className="contact-name">{contact.name}</span>

													{this.state.isDeleteMode && (
														<IonButton className="remove-icon" fill="clear">
															<IonIcon src={CloseCircleIcon} />
														</IonButton>
													)}
												</div>
											);
										})
									) : (
										<div className="new-item">
											<IonButton
												fill="clear"
												className="add-item"
												onClick={() => {
													this.props.history.push('contacts/add-members', {
														actionType: EnumService.AddMembersPageActionType.ChooseContactForTag,
														addContactType: item.type,
													});
												}}
											>
												<img src={PlusLightIcon} alt="icon"/>
											</IonButton>
											<span className="title">{item.type === 'groups' ? 'Add Group' : 'Add User'}</span>
										</div>
									)}

									{/* <div className="contact-item empty"></div>
									<div className="contact-item empty"></div>
									<div className="contact-item"></div>
									<div className="contact-item"></div>
									<div className="contact-item"></div> */}
								</div>
							</React.Fragment>
						))}

						{/* <ContactsList
							contacts={this.state.contacts}
							props={this.props}
							contactListMode={isTagContactsEdit ? EnumService.ContactsListMode.RemoveContactListMode : ''}
							onContactRemove={() => {
								info('On contact remove');
							}}
						/> */}
						{/* {this.state.isTagNameEdit && (
							<div className="save-cancel-btns-container">
								<IonToolbar className="save-cancel-btns">
									<IonButtons slot="start">
										<IonButton
											slot="end"
											className="cancel-btn"
											onClick={() => {
												this.setState({ isTagNameEdit: false });
											}}
										>
											{locale.global.cancel}
										</IonButton>
									</IonButtons>

									<IonButtons slot="end">
										<IonButton
											disabled={!isDoneBtnActive}
											className={'done-btn  ' + (isDoneBtnActive ? 'active' : '')}
											slot="end"
											onClick={() => {
												this.setState({ isTagNameEdit: false });
											}}
										>
											{locale.global.done}
										</IonButton>
									</IonButtons>
								</IonToolbar>
							</div>
						)} */}
					</IonContent>

					{tagsList.length >= 2 && tagsList[0].members.length > 0 && tagsList[1].members.length > 0 && (
						<div className="bottom-buttons-view">
							<IonButton className="remove-btn" fill="clear" onClick={() => this.setState({ isDeleteMode: true })}>
								Remove
							</IonButton>
							<div style={{ flex: 1 }}></div>
							<IonButton
								className="add-btn"
								fill="clear"
								onClick={() => {
									this.props.history.push('contacts/add-members', {
										actionType: EnumService.AddMembersPageActionType.ChooseContactForTag,
									});
								}}
							>
								Add
							</IonButton>
						</div>
					)}
				</IonPage>

				<IonLoading isOpen={this.props.dashboard.isLoading} message={this.props.dashboard.loaderMessage} />
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	//addGroup: (payload: any) => dispatch(addGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagInfo);
