import React from 'react';
import { IonItemOptions, IonItemOption, IonImg } from '@ionic/react';
import './../style.scss';
const ReplyArrowIcon = './assets/icon/chat-reply.png';

const ThreadItemOptions: React.FC<any> = ({ ionItemSlideRef }) => {
	return (
		<IonItemOptions className="thread-options" side="start">
			<IonItemOption
				className={'reply-btn'}
				onClick={() => {
					ionItemSlideRef?.current?.close();
				}}
			>
				<IonImg src={ReplyArrowIcon} />
			</IonItemOption>
		</IonItemOptions>
	);
};

export default ThreadItemOptions;
