import React, { MouseEventHandler } from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar, IonNote } from '@ionic/react';
import './style.scss';
import { checkmark, chevronDown } from 'ionicons/icons';

import { RouteComponentProps } from 'react-router';

interface iProps extends RouteComponentProps<{ name: string }> {}
interface iState {
	shareWith: String;
}

export default class ShareWith extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);

		this.state = {
			shareWith: 'all',
		};
	}

	componentDidMount() {}

	_renderItem = (title: String, description: String, isSelected = false, onClick?: MouseEventHandler<HTMLIonItemElement>, isExpandable = false, onExpandClick?: MouseEventHandler<HTMLIonButtonElement>) => {
		return (
			<IonItem onClick={onClick}>
				<div style={{ width: 36 }}>
					{isSelected && (
						<IonButton fill="clear" color="danger" slot="start">
							<IonIcon icon={checkmark} slot="icon-only" />
						</IonButton>
					)}
				</div>
				<IonLabel className="ion-text-wrap">
					<h3>{title}</h3>
					<IonNote>{description}</IonNote>
				</IonLabel>
				{isExpandable && (
					<IonButton fill="clear" color="dark" slot="end" onClick={onExpandClick}>
						<IonIcon icon={chevronDown} slot="end" />
					</IonButton>
				)}
			</IonItem>
		);
	};

	render() {
		const { shareWith } = this.state;

		return (
			<IonPage className="share-with-page">
				<IonToolbar className="post-header" mode="ios">
					<IonButtons slot="start">
						<IonButton style={{ fontSize: '16px' }} color={'dark'} onClick={(e) => this.props.history.goBack()}>
							Cancel
						</IonButton>
					</IonButtons>

					<IonTitle style={{ color: '#000', fontSize: '16px' }}>Share With</IonTitle>
					<IonButtons slot="end">
						<IonButton className="post-btn" fill="solid" color={'danger'} onClick={(e) => this.props.history.goBack()}>
							Post
						</IonButton>
					</IonButtons>
				</IonToolbar>

				<IonContent>
					<IonList mode="ios" lines="full">
						{this._renderItem('All', 'All contacts', shareWith === 'all', () => {
							this.setState({ shareWith: 'all' });
						})}
						{this._renderItem('Private', 'Just Me', shareWith === 'private', () => {
							this.setState({ shareWith: 'private' });
						})}
						{this._renderItem(
							'Share With',
							'Selected Freinds',
							shareWith === 'friends',
							() => {
								this.props.history.replace('contacts');
								this.setState({ shareWith: 'friends' });
							},
							true,
							() => {}
						)}
						{this._renderItem(
							'Don’t Share',
							'Excluded selected contacts',
							shareWith === 'excludefriends',
							() => {
								this.props.history.replace('contacts');
								this.setState({ shareWith: 'excludefriends' });
							},
							true,
							() => {}
						)}
					</IonList>
				</IonContent>
			</IonPage>
		);
	}
}
