import { createClient } from '../stanza';
import { Config } from '../config/config';
import { info, getInternalStorage, setInternalStorage, logError, isBlank, isJsonString, prefixMedia, isConversationOpen, isDashboardOpen, getActiveConversation, compressSelectedFile, logoutUser, sleep } from '../helpers/common';
import { apiService } from './apiService';
import store from '../redux/store';
import { controlMessageService } from './controlMessageService';
import { createNotification } from '../components/Notifications/notifications';
import md5 from 'md5';
import _ from 'lodash';
import { DASHBOARD_SHOW_LOADER, DASHBOARD_HIDE_LOADER } from '../redux/constants/dashboard';
import { AppManager } from './appManager';
import { Parser } from 'xml2js';
import { Constants } from '../constants';
import { v4 as uuidv4 } from 'uuid';
import EnumService from '../services/enumService';

let cookies: any = getInternalStorage();
export const xmpp = {
	client: null as any,
	queue: [] as any[],
	isReady: false as Boolean,
	isSessionStarting: false as Boolean,
	isSessionStarted: false as Boolean,
	isConnecting: false as Boolean,
	isConnected: false as Boolean,
	isResumed: false as Boolean,
	isReloading: false as Boolean,
	isReconnecting: false as Boolean,
	isDisconnecting: false as Boolean,
	isDisconnected: true as Boolean,
	isClosed: true as Boolean,
	isLoggingOut: false as Boolean,
	isHardReload: false as Boolean,
	isRecoveringFromDisconnect: false as Boolean,
	isProcessingMessage: false as Boolean,
	availabilityInterval: undefined as any,
	connectTimeout: undefined as any,
	sentTimeout: undefined as any,
	messageIsSent: false as Boolean,
	recentControlIds: [] as any,
	messagesLoaded: false as Boolean,
	mediaProcessed: false as Boolean,
	initialize: async () => {
		let user = await apiService.me();

		if (window.navigator.onLine) {
			if (xmpp?.client && xmpp?.isSessionStarted && xmpp?.client?.sessionStarted) {
				info('xmpp::initialize: Already connected.');
			} else if (!user?.jid) {
				logoutUser();
			} else {
				info('xmpp::initialize: Initializing ...');
				try {
					xmpp.client = null;

					const xmppOptions = {
						jid: user.jid,
						password: user.password || getInternalStorage().up,
						server: Config.xmppServer,
						resource: cookies.uuid,
						transports: {
							websocket: `wss://${Config.xmppServer}/ws`,
							bosh: `https://${Config.xmppServer}/bosh`,
						},
					};

					info('xmpp::initialize::xmppOptions:', xmppOptions);
					xmpp.client = createClient(xmppOptions);
				} catch (error) {
					logError('xmpp::initialize::Error:', error);
				}
			}
		} else {
			logError(`xmpp::initialize: network is offline.`);
			await xmpp.xmppManager();
		}
	},

	reset: async () => {
		info('xmpp::reset');

		/*if (xmpp.isProcessingMessage) {
			info('xmpp::reset: waiting for messages to complete processing');

			while (xmpp.isProcessingMessage) {
				await sleep(1);
			}
		}*/

		xmpp.stopListeners();
		delete xmpp.client;

		xmpp.isClosed = true;
		xmpp.isDisconnected = true;

		xmpp.isReady = false;
		xmpp.isConnecting = false;
		xmpp.isConnected = false;
		xmpp.isReconnecting = false;
		xmpp.isReloading = false;
		xmpp.isHardReload = false;
		xmpp.isDisconnecting = false;
		xmpp.isSessionStarted = false;
		xmpp.isSessionStarting = false;
	},

	connect: async () => {
		if (!xmpp.client) {
			await xmpp.initialize();
		}

		if (xmpp.client && !xmpp.isConnected && !xmpp.isConnecting) {
			store.dispatch({ type: DASHBOARD_SHOW_LOADER, payload: { loader: true, loaderMessage: 'Connecting ...' } });
			cookies = getInternalStorage();
			const cachedSM = getInternalStorage().sm;

			try {
				xmpp.isConnecting = true;

				await xmpp.startXmppListeners([
					{ event: Constants.STANZA_EVENT_TYPES['connected'], fn: xmpp.handleConnected },
					{ event: Constants.STANZA_EVENT_TYPES['session:started'], fn: xmpp.handleSessionStarted },
					{ event: Constants.STANZA_EVENT_TYPES['stream:management:resumed'], fn: xmpp.handleResumed },
				]);

				info('xmpp::connect: Connecting ...');
				store.dispatch({ type: DASHBOARD_SHOW_LOADER, payload: { loader: true, loaderMessage: 'Connecting ...' } });

				if (!isBlank(cachedSM)) {
					info('xmpp::connect: Will attempt to resume previous session...');
					await xmpp.client.sm.load(cachedSM);
				}

				xmpp.client.sm.cache(async (state: any) => setInternalStorage('sm', state));

				await xmpp.client
					.connect()
					.catch((error: any) => {
						logError('xmpp::connect.connect::Error:', error);
						xmpp.isConnecting = false;
					})
					.then(async () => {
						info('xmpp::connect.connect: request completed');

						if (!xmpp.connectTimeout) {
							xmpp.connectTimeout = await setTimeout(async () => await xmpp.handleNotConnected(), 15000);
						}
					});
			} catch (error) {
				logError('xmpp::connect::Error:', error);
			}
		} else {
			info('xmpp::connect: xmpp.isConnecting is', xmpp.isConnecting);
		}
	},

	handleNotConnected: async () => {
		info('xmpp::connect::handleNotConnected: xmpp connection timeout.  Resetting and restarting xmppManager');
		clearTimeout(xmpp.connectTimeout);
		xmpp.connectTimeout = null;
		xmpp.reset();
		await xmpp.xmppManager();
	},

	handleConnected: (data: any) => {
		info('xmpp::handleConnected: xmpp is connected');
		xmpp.isDisconnected = false;
		xmpp.isClosed = false;
		xmpp.isConnecting = false;
		xmpp.isConnected = true;
		xmpp.isReconnecting = false;
	},

	handleSessionStarted: async (data: any) => {
		info('xmpp::handleSessionStarted: xmpp session is started.  Calling xmpp.startXmpp');
		xmpp.isSessionStarting = false;
		xmpp.isSessionStarted = true;
		await xmpp.startXmpp();
	},

	handleKeepAlive: async (data: any) => {
		info('xmpp::handleKeepAlive: enabled:', data);
		await xmpp.client.updateCaps();
		info('xmpp::handleKeepAlive: Sending presence for capabilities ...');
		await xmpp.client.sendPresence({ legacyCapabilities: await xmpp.client.disco.getCaps() });
		await xmpp.sendPresence('available');

		if (!xmpp.isResumed) {
			info('xmpp::handleKeepAlive: Enabling carbons ...');
			try {
				await xmpp.client
					.enableCarbons()
					.then(async () => {
						info('xmpp::handleKeepAlive::enableCarbons: Carbons enabled.');
					})
					.catch(async (error: any) => {
						logError('xmpp::handleKeepAlive::client.enableCarbons::Error', error);
						xmpp.reset();
						await xmpp.xmppManager();
					});
			} catch (err) {
				info('xmpp::handleKeepAlive::enableCarbons: Server does not support carbons.');
				xmpp.reset();
			}
		} else if (xmpp.isResumed) {
			xmpp.isDisconnected = false;
			xmpp.isClosed = false;
			xmpp.isResumed = true;
			xmpp.isSessionStarted = true;
			xmpp.isSessionStarting = false;
			xmpp.isConnected = true;
			xmpp.isConnecting = false;
			xmpp.isReady = true;
			info('xmpp::handleKeepAlive: xmpp isResumed and isReady');
		}
	},

	handleResumed: async (message: any) => {
		cookies = getInternalStorage();

		if (message?.type === 'resumed') {
			info(`xmpp::handleResumed: xmpp connection obtained from resumed session.  Calling xmpp.startXmpp`);
			xmpp.isDisconnected = false;
			xmpp.isClosed = false;
			xmpp.isConnected = true;
			xmpp.isResumed = true;
			xmpp.isConnecting = false;
			xmpp.isReconnecting = false;
			xmpp.isSessionStarting = false;
			xmpp.isSessionStarted = true;
			xmpp.isReady = true;
			//await xmpp.client.sm.load(getInternalStorage().sm);
			await xmpp.startXmpp();
			await xmpp.sendPresence('available');
		} else {
			info(`xmpp::handleResumed::message`, message);
		}
	},

	startXmpp: async (enableKeepAlive: Boolean = true) => {
		info(
			`xmpp::startXmpp`,
			_.omitBy(xmpp, (_state) => typeof _state !== 'boolean')
		);

		if (xmpp.client && xmpp.isConnected /*&& xmpp.isSessionStarted*/) {
			info(`xmpp::startXmpp: client connected xmpp.isSessionStarted is ${xmpp.isSessionStarted}.`);

			if (xmpp.connectTimeout) {
				clearTimeout(xmpp.connectTimeout);
				xmpp.connectTimeout = undefined;
			}

			if (xmpp.availabilityInterval) {
				clearInterval(xmpp.availabilityInterval);
				xmpp.availabilityInterval = undefined;
			}

			await xmpp.startXmppListeners();

			if (enableKeepAlive) {
				info('xmpp::startXmpp: Enabling keepAlive ...');
				await xmpp.client.enableKeepAlive({ interval: 30, timeout: 15 });
			}

			xmpp.isReady = true;
			info('xmpp::startXmpp: xmpp isReady');
			store.dispatch({ type: DASHBOARD_HIDE_LOADER });
		} else if (!xmpp.isConnected) {
			xmpp.isConnected = false;
			await xmpp.connect();
		} else {
			xmpp.isSessionStarted = false;
			await sleep(50);
			info(`xmpp::startXmpp: calling xmpp.startXmpp recursively (likely not a good idea).`);
			await xmpp.startXmpp();
		}
	},

	xmppManager: async () => {
		if (xmpp.availabilityInterval) {
			clearInterval(xmpp.availabilityInterval);
			xmpp.availabilityInterval = null;
		}

		if ((!xmpp.client || !xmpp.isReady) && getInternalStorage().uuid) {
			if (window.navigator.onLine) {
				info(`xmpp::xmppManager: calling startXmpp ...`);
				await xmpp.startXmpp(true);
			} else {
				info(`xmpp::xmppManager: network is offline. Setting xmppManager interval.`);

				if (!xmpp.availabilityInterval) {
					xmpp.availabilityInterval = setInterval(async () => await xmpp.xmppManager(), 5000);
				}
			}
		} else {
			xmpp.availabilityInterval = setInterval(async () => await xmpp.xmppManager(), 5000);
		}
	},

	disconnect: async () => {
		info(`xmpp::disconnect: Disconnect requested.`);

		if (xmpp.client) {
			xmpp.isDisconnecting = true;
			xmpp.client.disconnect();
		}

		xmpp.isReady = false;
	},

	handleDisconnected: async (error: any) => {
		xmpp.isDisconnected = true;

		if (!xmpp.isLoggingOut) {
			info(`xmpp::xmppManager::handleDisconnected: reconnecting ...`);
			await xmpp.xmppManager();
		}
	},

	sendPresence: async (status: any = undefined) => {
		if (window.navigator.onLine) {
			info(`xmpp::sendPresence::sending`);
			let user = await apiService.me();
			await xmpp.client?.sendPresence({ jid: user.jid, status: status });
		} else {
			logError(`xmpp::sendPresence: network is offline.`);
			await xmpp.xmppManager();
		}
	},

	handlePresence: async (message: any) => {
		info(`xmpp::handlePresence::message:`, message);

		// when ready, add logic to identify other device for this client
		// and then add a popup to indicate to the client that they are logged in elsewhere
		// and allow them to have other clients logged out
	},

	sendMessage: async (receiverJid: any, messageBody: any = '', messageState: string = EnumService.ChatMessageState.ORIGINAL, messageType: string = EnumService.ChatMessageType.TEXT, relatedMessageId: any = undefined, taggedMembers: any[] = []) => {
		const fetchLinkPreview: Function = async (url: string) => {
				info('url:', url);

				// sanitize the provided url/domain
				if (!url.startsWith('http://') && !url.startsWith('https://')) {
					url = `https://${url}`;
				} else if (url.startsWith('http://')) {
					url = `https://${url.split('http://')[1]}`;
				}

				return await apiService.fetchLinkPreview({ url: url }).then(async (_response: any) => _response);
			},
			handleNotSent = async () => {
				if (!abortMediaWait) {
					info('xmpp::sendMessage::handleNotSent: timeout sending message');
					clearTimeout(xmpp.sentTimeout);
					xmpp.sentTimeout = null;
				}

				xmpp.reset();
				await xmpp.xmppManager();
			},
			user: any = await apiService.me(),
			// this is the LOCAL conversationHash for this user's conversation with the other party,
			// NOT the other party's conversation with this user
			// it CANNOT be attached to the message
			// When the recipients (including the sender) receive their first instance of the message
			// from ejabberd, the messageHandler will attach the appropriate conversationHash to the message
			conversationHash: string = md5(`${user.jid}_${user.jid !== receiverJid ? receiverJid : user.notepadJid}`),
			conversation: any = user.conversations.find((_conversation: any) => _conversation.conversationHash === conversationHash),
			isText: Boolean = messageType === EnumService.ChatMessageType.TEXT,
			isMedia: Boolean = messageType === EnumService.ChatMessageType.MEDIA,
			isContact: Boolean = messageType === EnumService.ChatMessageType.CONTACT,
			isResend: Boolean = messageState === EnumService.ChatMessageState.RESEND,
			isReply: Boolean = messageState === EnumService.ChatMessageState.REPLY,
			isReplacement: Boolean = !isBlank(relatedMessageId),
			messageStatus: string = 'PendingSent',
			messageKey: string = isResend ? relatedMessageId : uuidv4(),
			isLinkPreview: Boolean = isText && messageBody.match(/[a-zA-Z\d]+:?(\/\/(\w+:\w+@))?([a-zA-Z\d.-]+\.[A-Za-z]{2,4})(:\d+)?(.*)?/g),
			linkPreview: any = isLinkPreview ? await fetchLinkPreview(messageBody.match(/[a-zA-Z\d]+:?(\/\/(\w+:\w+@))?([a-zA-Z\d.-]+\.[A-Za-z]{2,4})(:\d+)?(.*)?/g)[0]) : undefined,
			originalMessage: any = isResend && (await apiService.getFromData({ messageKey: relatedMessageId })),
			mediaType: string = isLinkPreview ? 'url' : isMedia ? messageBody.mediaTypes[0] : undefined;

		xmpp.messageIsSent = false;

		let stanza: any = undefined,
			preSendStanza: any = undefined,
			mediaFiles: any = [],
			mediaUrls: any = [],
			mediaThumbnails: any = [],
			mediaTypes: any = [],
			originalMediaType: string = messageType,
			body: any = isResend ? originalMessage : undefined,
			preSendBody: any,
			abortMediaUploadTimeout: any,
			abortMediaWait: Boolean = false;

		if (receiverJid === user.jid) {
			receiverJid = user.notepadJid;
		}

		if (isResend && originalMessage) {
			stanza = originalMessage.stanza;
			mediaFiles = originalMessage.mediaFiles;
			mediaThumbnails = originalMessage.mediaThumbnails;
			mediaTypes = originalMessage.mediaTypes;
		} else {
			// sanitize the outgoing messageBody
			if (isMedia) {
				if (!mediaTypes || mediaTypes.length === 0) {
					mediaTypes = messageBody.mediaTypes;
				}

				// expect an array
				mediaFiles = isMedia ? messageBody?.mediaFiles : [];

				for (let mediaIndex in mediaFiles) {
					let mediaFile = mediaFiles[mediaIndex],
						mediaType = mediaTypes[mediaIndex];

					mediaUrls[mediaIndex] = mediaFile;

					switch (mediaType) {
						case EnumService.ChatMediaType.IMAGE:
							mediaThumbnails[mediaIndex] = await compressSelectedFile(mediaFile, { x: 164, y: 123, fit: 'contain', upscale: false });
							// override originalMediaType for images as this becomes either 'Full Image' or 'Reduced Image' after processing
							originalMediaType = messageBody.mediaResolutions[mediaIndex];
							break;

						case EnumService.ChatMediaType.AUDIO:
							mediaThumbnails[mediaIndex] = '/audio-icon.svg';
							break;

						case EnumService.ChatMediaType.VIDEO:
							// TODO extract first non-blank frame from video to use as thumbnail
							mediaThumbnails[mediaIndex] = '/video-icon.svg';
							break;

						case EnumService.ChatMediaType.DOCUMENT:
							mediaThumbnails[mediaIndex] = '/message-file-bg.svg';
							break;
					}
				}

				messageBody = messageBody.message;
			}

			//if (user.encryptMessages) {
			//	let privateKey = getInternalStorage().pk;
			//}

			// conversationHash is intentionally not included as it will be different
			// for each receiver

			body = {
				read: false,
				replaced: false,
				recalled: false,
				replaces: isReplacement && relatedMessageId,
				inReplyTo: isReply && relatedMessageId,
				replacedBy: undefined,
				deleted: false,
				translated: false,
				originalTimestamp: new Date().toISOString(),
				body: _.trimEnd(messageBody.replace(/"/g, '&quot;').replace(/'/g, '&apos;').replace('<div><br></div>', ''), ' \n'),
				messageType: messageType,
				tagged: taggedMembers,
				mediaType: mediaType,
				mediaUrl: mediaUrls,
				mediaThumbnail: mediaThumbnails,
				linkPreview: linkPreview,
				status: messageStatus,
				messageKey: messageKey,
			};

			preSendBody = {
				...body,
				conversationHash: conversationHash,
				status: Constants.MESSAGE_STATUS.PreSend,
			};

			stanza = {
				body: JSON.stringify(body),
				from: user.jid,
				to: receiverJid,
				requestReceipt: true,
				timestamp: body.originalTimestamp,
				type: (conversation.type === 'chat' && conversation.type) || 'groupchat',
			};

			preSendStanza = {
				...stanza,
				id: messageKey,
				body: JSON.stringify(preSendBody),
			};

			apiService.saveToData({
				messageKey: messageKey,
				type: 'original',
				stanza: stanza,
				originalMedia: mediaFiles,
				mediaThumbnail: mediaThumbnails,
				mediaTypes: mediaTypes,
				originalMediaType: originalMediaType,
			});
		}

		if (window.navigator.onLine) {
			info('xmpp::sendMessage: sending PreSend message', preSendStanza);

			// this will force the message to be displayed in the conversation
			await xmpp.handleMessageSent(preSendStanza, false);

			if (!xmpp.isReady) {
				info(
					`xmpp::sendMessage: waiting for xmpp to be ready`,
					_.omitBy(xmpp, (_state) => typeof _state !== 'boolean')
				);

				await xmpp.xmppManager();

				while (!xmpp.isReady) {
					await sleep(10);
				}

				info(`xmpp::sendMessage: xmpp says that it is ready`);
			}

			// send the thumbnail now so that it's there when it gets added to the message
			if (isMedia) {
				info(`xmpp::sendMessage: uploading media thumbnail`);
				xmpp.setMediaProcessed(false);

				// start an abort timer for 5 seconds per media item

				abortMediaUploadTimeout = setTimeout(() => {
					apiService.cancelUpload();
					abortMediaWait = true;
				}, 5000 * mediaFiles.length);

				let uploadedMedia: any = [],
					mediaIndex;

				for (mediaIndex in mediaFiles) {
					uploadedMedia[mediaIndex] = await apiService.uploadMedia({
						messageKey: messageKey,
						media: mediaThumbnails[mediaIndex],
						mediaType: EnumService.ChatMediaType.THUMBNAIL,
					});
				}

				clearTimeout(abortMediaUploadTimeout);

				// non-blocking delay here to wait until the media has been processed on the server
				// before actually sending the message to prevent recipients from displaying a broken image.
				// server will send a mediaChanged message when it is finished, which will set
				// xmpp.mediaProcessed.

				if (uploadedMedia.Error) {
					logError(`xmpp::sendMessage: uploading media failed:`, uploadedMedia.Error.error);
					abortMediaWait = true;
				} else if (uploadedMedia.length > 0) {
					for (mediaIndex in uploadedMedia) {
						body.mediaThumbnail[mediaIndex] = uploadedMedia[mediaIndex].mediaId;
						body.mediaUrl[mediaIndex] = uploadedMedia[mediaIndex].mediaId;
						let prefixedMedia = await prefixMedia(uploadedMedia[mediaIndex].mediaId, false);

						user.gallery.push({
							type: EnumService.ChatMediaType.THUMBNAIL,
							url: prefixedMedia,
							messageKey: messageKey,
							original: prefixedMedia, // this will get replaced when the actual media is uploaded
						});

						stanza.body = JSON.stringify(body);
					}

					await apiService.updateUser(
						{
							_id: user._id,
							gallery: user.gallery,
						},
						false
					);
				}

				while (!xmpp.mediaProcessed && !abortMediaWait) {
					await sleep(10);
				}
			}

			if (isMedia && !xmpp.mediaProcessed) {
				await handleNotSent();
			} else if (!isMedia || (isMedia && xmpp.mediaProcessed)) {
				info('xmpp::sendMessage: sending', stanza);
				await xmpp.client.sendMessage(stanza);
				xmpp.sentTimeout = setTimeout(async () => await handleNotSent(), 5000);

				// send original now that thumbnail and message have been processed;
				if (isMedia) {
					info(`xmpp::sendMessage: uploading media original`);
					xmpp.setMediaProcessed(false);

					abortMediaUploadTimeout = setTimeout(() => {
						apiService.cancelUpload();
						abortMediaWait = true;
					}, 10000 * mediaFiles.length);

					let uploadedMedia: any = [],
						mediaIndex;

					for (mediaIndex in mediaFiles) {
						uploadedMedia[mediaIndex] = await apiService.uploadMedia({
							messageKey: messageKey,
							media: mediaFiles[mediaIndex],
							mediaType: mediaTypes[mediaIndex],
						});
					}

					clearTimeout(abortMediaUploadTimeout);

					if (uploadedMedia.Error) {
						logError(`xmpp::sendMessage: uploading media failed:`, uploadedMedia.Error.error);
						abortMediaWait = true;
					} else if (uploadedMedia.length > 0) {
						for (mediaIndex in uploadedMedia) {
							let prefixedMedia = await prefixMedia(uploadedMedia[mediaIndex].mediaId, false),
								galleryIndex = user.gallery.findIndex((_item: any) => _item.messageKey === messageKey && _item.type === EnumService.ChatMediaType.THUMBNAIL);

							user.gallery[galleryIndex] = {
								...user.gallery[galleryIndex],
								original: prefixedMedia,
							};

							user.gallery.push({
								type: mediaTypes[mediaIndex],
								url: prefixedMedia,
								messageKey: messageKey,
							});
						}

						await apiService.updateUser(
							{
								_id: user._id,
								gallery: user.gallery,
							},
							false
						);
					}
				}

				while (!xmpp.mediaProcessed && !abortMediaWait) {
					await sleep(10);
				}

				if (isMedia && !xmpp.mediaProcessed) {
					await handleNotSent();
				}
			}
		} else {
			logError('xmpp::sendMessage: unable to send message.  No connection to internet.');
			// simulate the reception of an actual sent message so that it gets processed and displayed in the conversation
			xmpp.handleMessageSent(
				{
					...preSendStanza,
					body: JSON.stringify({
						...preSendBody,
						status: Constants.MESSAGE_STATUS.SentFailed,
					}),
				},
				false
			);

			xmpp.reset();
			await xmpp.xmppManager();
		}
	},

	messageHandler: async () => {
		let user: any = await apiService.me(),
			queued: any,
			message: any,
			messageBody: any,
			source: any,
			controlData: any,
			newMessage: any,
			isCarbon: Boolean,
			update: Boolean = false,
			notify: Boolean,
			conversationIndex: number,
			conversation: any,
			from: any[],
			conversationIsOpen: Boolean = isConversationOpen(),
			dashBoardIsOpen: Boolean = isDashboardOpen(),
			activeConversation: any = getActiveConversation(user),
			timestamp: string,
			existingMessage: any,
			duplicate: Boolean,
			duplicateIsActual: Boolean,
			duplicateIsAck: Boolean,
			isMediaUpload: Boolean,
			isControl: Boolean;

		const findConversation = (existingMessage: any) => {
				let conversationIndex: number = user.conversations.findIndex((_conversation: any) => {
						let response: Boolean;

						if (existingMessage) {
							response = _conversation.conversationHash === existingMessage.conversationHash;
						} else if (messageBody.conversationHash) {
							response = _conversation.conversationHash === messageBody.conversationHash;
						} else {
							if (message.from.split('/')[0] !== user.jid) {
								response = _conversation.conversationHash === md5(`${user.jid}_${message.from.split('/')[0]}`);
							} else {
								response = _conversation.conversationHash === md5(`${user.jid}_${message.to.split('/')[0]}`);
							}
						}

						return response;
					}),
					conversation = user.conversations[conversationIndex];

				return [conversationIndex, conversation];
			},
			findExistingMessage = (messageKey: string) => {
				let messages: any = _.flatten(user.conversations.map((_conversation: any) => _conversation.messages)),
					message: any = messages && messages.find((_message: any) => _message.messageKey === messageKey);
				return message;
			},
			convertMedia = async () => {
				if (!isBlank(messageBody.mediaUrl) && _.isArray(messageBody.mediaUrl)) {
					for (let mediaUrlIndex in messageBody.mediaUrl) {
						messageBody.mediaUrl[mediaUrlIndex] = await prefixMedia(messageBody.mediaUrl[mediaUrlIndex]);
					}
				}

				if (!isBlank(messageBody.mediaThumbnail) && _.isArray(messageBody.mediaThumbnail)) {
					for (let mediaThumbnailIndex in messageBody.mediaThumbnail) {
						messageBody.mediaThumbnail[mediaThumbnailIndex] = await prefixMedia(messageBody.mediaThumbnail[mediaThumbnailIndex]);
					}
				}
			},
			processMessage: Function = async () => {
				info(`xmpp::messageHandler::${source}:`, newMessage);

				if (messageBody.messageType === EnumService.ChatMessageType.MEDIA) {
					await convertMedia();
				}

				newMessage = {
					conversationHash: conversation.conversationHash,
					id: messageBody.messageKey,
					language: messageBody?.lang || navigator.language.split('-')[0],
					body: messageBody.hasOwnProperty('body') ? messageBody.body.replace(/&quot;/g, '"') : '',
					translated: messageBody?.translated ? 1 : 0,
					translation: messageBody.translated ? messageBody.translation.replace(/&quot;/g, '"') : messageBody.translation,
					messageType: messageBody.messageType,
					messageKey: messageBody.messageKey,
					tags: messageBody.tags || [],
					recalled: messageBody?.recalled ? 1 : 0,
					replaced: messageBody?.replaced ? 1 : 0,
					replaces: messageBody?.replaces,
					replacedBy: messageBody?.replacedBy,
					inReplyTo: messageBody?.inReplyTo,
					forwardedFrom: messageBody?.forwardedFrom,
					deleted: 0,
					tagged: messageBody?.tagged ? (messageBody.tagged.some((_tagged: any) => _tagged === user.userId) ? 1 : 0) : 0,
					mediaType: messageBody?.mediaType,
					mediaUrl: !isBlank(messageBody.mediaUrl) ? messageBody.mediaUrl : undefined,
					mediaThumbnail: !isBlank(messageBody.mediaThumbnail) ? messageBody.mediaThumbnail : undefined,
					linkPreview: messageBody?.linkPreview,
					...newMessage,
				};

				if (!duplicate || duplicateIsActual) {
					if (!duplicateIsAck) {
						info(`xmpp::messageHandler: Message is ${duplicate ? `${isCarbon ? `a carbon and is` : ``}${!duplicate ? `not ` : ``}a duplicate${duplicateIsAck ? ` and is a sent acknowledgement.` : duplicateIsActual ? ` and is the actual sent message.` : ``}` : `a new message.`} Updating local database.`);

						if (!isCarbon && _.includes([Constants.MESSAGE_STATUS.PendingSent, Constants.MESSAGE_STATUS.PendingAck, Constants.MESSAGE_STATUS.PendingUpload, Constants.MESSAGE_STATUS.Reconciled], newMessage.status)) {
							newMessage = { ...newMessage, status: Constants.MESSAGE_STATUS.Reconciled };
							info(`xmpp::messageHandler::${source}: message ${newMessage.messageKey} replaced.`);
						} else if (isCarbon) {
							newMessage = { ...newMessage, status: isMediaUpload ? newMessage.status : Constants.MESSAGE_STATUS.Reconciled, read: 1 };
							info(`xmpp::messageHandler::${source}: carbon message saved with status ${newMessage.status}`, newMessage);
						} else {
							newMessage = {
								...newMessage,
								status: source === Constants.STANZA_EVENT_TYPES['groupchat:sent'] || source === Constants.STANZA_EVENT_TYPES['groupchat:received'] ? Constants.MESSAGE_STATUS.Reconciled : newMessage.status,
							};
							info(`xmpp::messageHandler::${source}: message saved with status ${newMessage.status}`, newMessage);
						}

						user = await apiService.saveMessage(newMessage, user);

						if (!duplicateIsActual) {
							info(`xmpp::messageHandler::${source}: updating dashboard with this message as lastMessage:`, conversation.lastMessage);
							await apiService.updateConversation(user, conversationIndex);
						}

						if (_.includes([Constants.STANZA_EVENT_TYPES['chat:sent:viaCarbon'], Constants.STANZA_EVENT_TYPES['chat:sent:acked'], Constants.STANZA_EVENT_TYPES['groupchat:sent']], source) && newMessage.read === 1) {
							info(`xmpp::messageHandler::${source}: conversation is open. Updating read status of this message:`, newMessage);
							await apiService.updateReadStatus({ conversationHash: conversation.conversationHash, messageKeys: [newMessage.messageKey] });
						}
					} else if (!update) {
						if (conversation.status === 'confirmed') {
							info(`xmpp::messageHandler::${source}: message is ${duplicate ? '' : 'not'} a duplicate and not duplicateIsAck and not an update. Updating dashboard with this message as lastMessage:`, conversation.lastMessage);
							await apiService.updateConversation(user, conversationIndex);

							if (notify && !duplicateIsAck) {
								createNotification(
									newMessage.messageKey !== existingMessage?.messageKey && newMessage.sender !== 'Me' && cookies.desktopNotifications && newMessage.read === 0 && (!cookies.active || (!dashBoardIsOpen && (!conversationIsOpen || (conversationIsOpen && activeConversation !== newMessage.sender))))
										? newMessage
										: undefined,
									true
								);
							}

							if (newMessage.replaces) {
								// get the message being replaced and annotate it with the id of the replacement.

								let toBeReplacedIndex = conversation.messages.findIndex((_message: any) => _message.messageKey === newMessage.replaces),
									toBeReplaced = conversation.messages[toBeReplacedIndex];

								toBeReplaced.isReplaced = true;
								toBeReplaced.replacedBy = newMessage.messageKey;

								conversation.messages[toBeReplacedIndex] = toBeReplaced;
								user.conversations[conversationIndex] = conversation;
								await apiService.updateUser(user);
							}
						}
					}
				} else {
					if (update || duplicateIsAck) {
						info(`xmpp::messageHandler: Message is an update and ${duplicateIsAck ? `a sent acknowledgement` : duplicateIsActual ? `the actual sent message` : `a duplicate`}.  Not updating local database.`);

						if (_.includes([Constants.STANZA_EVENT_TYPES['chat:sent:viaCarbon'], Constants.STANZA_EVENT_TYPES['chat:sent:acked'], Constants.STANZA_EVENT_TYPES['groupchat:received']], source) && newMessage.read === 1) {
							info(`xmpp::messageHandler: Sending updateReadStatus for ${source}.  Dashboard update only.`);
							await apiService.updateReadStatus({ conversationHash: conversation.conversationHash, messageKeys: [newMessage.messageKey] });
						}
					} else if (!update && !duplicateIsAck) {
						info(`xmpp::messageHandler: Message is already displayed.  Dashboard update only.`);
						await apiService.updateConversation(user, conversationIndex);
					}
				}
			};

		xmpp.isProcessingMessage = true;

		while (xmpp.queue.length > 0) {
			cookies = getInternalStorage();
			queued = xmpp.queue[0];
			message = queued.message;
			messageBody = queued.messageBody;
			source = queued.source;
			isControl = source === Constants.STANZA_EVENT_TYPES['controlMessage'];
			controlData = isControl && queued.controlData;
			newMessage = {};
			isCarbon = source === Constants.STANZA_EVENT_TYPES['chat:sent:viaCarbon'];
			update = false;
			notify = false;
			existingMessage = messageBody.status !== Constants.MESSAGE_STATUS.PreSend && !isControl ? findExistingMessage(messageBody.messageKey) : undefined;
			[conversationIndex, conversation] = !isControl ? findConversation(existingMessage) : [-1, undefined];
			from = [];
			timestamp = message?.timestamp ? new Date(message.timestamp).toISOString() : message?.originalTimestamp ? new Date(message.originalTimestamp).toISOString() : new Date().toISOString();
			duplicate = false;
			duplicateIsActual = false;
			duplicateIsAck = false;
			duplicate = !isBlank(messageBody.messageKey) && messageBody.messageKey === existingMessage?.messageKey;
			duplicateIsActual = duplicate && source === Constants.STANZA_EVENT_TYPES['chat:sent'] && messageBody.status === Constants.MESSAGE_STATUS.PendingSent;
			duplicateIsAck = duplicate && _.includes([Constants.STANZA_EVENT_TYPES['chat:sent:acked'], Constants.STANZA_EVENT_TYPES['chat:sent:viaCarbon']], source);
			isMediaUpload = !duplicate && messageBody.status === Constants.MESSAGE_STATUS.PendingUpload;

			if (source === Constants.STANZA_EVENT_TYPES['chat:sent'] && message.type === 'groupchat') {
				if (!xmpp.isReconnecting) {
					info(`xmpp.messageHandler::pre-check: converting chat:sent to groupchat:sent on message.type groupchat`);
					source = Constants.STANZA_EVENT_TYPES['groupchat:sent'];
				} else {
					info(`xmpp.messageHandler::pre-check: converting chat:sent.  xmpp is reconnecting.  Discarding this message as it will be resent.`);
					source = undefined;
					xmpp.isRecoveringFromDisconnect = true;
				}
			} else if (source === Constants.STANZA_EVENT_TYPES['message:failed']) {
				// capture the details of the message, since it needs to be resent.
				// we don't know if this was a p2p or group chat message at this stage
				// it will be followed by a message sent - but no ack - so we need to preserve this state
				// beyond a potential AppManager.reload of the page
			} else if (source === Constants.STANZA_EVENT_TYPES['chat:received'] && messageBody.isControl) {
				source = Constants.STANZA_EVENT_TYPES['controlMessage'];
				message = messageBody;
			}

			switch (source) {
				case undefined:
					break;

				case Constants.STANZA_EVENT_TYPES['error']:
					info(`xmpp::messageHandler::error`, message);
					// the following condition has been seen to randomly occur
					// specifically in the case of sending a message while know to be online
					// the action here should be to re-establish the session and re-send the message
					// this condition occurs AFTER receiving the initial chat:sent or groupchat:sent message - which must be removed locally
					// this condition is followed by the reception of a chat:sent:acked message - which must be ignored

					/*if (_.includes(Object.keys(message.error[0]), 'service-unavailable') && message.error[0].text[0]._ === 'User session not found') {
						from = message.from.split('/');
						to = message.to.split('@')[0];
						setInternalStorage('toResend', JSON.stringify(message));
						xmpp.reset();
						AppManager.reload();
					}*/

					break;

				//failed to send a message that is displayed
				case Constants.STANZA_EVENT_TYPES['message:failed']:
					from = message.from.split('/');
					newMessage.from = from[0];
					newMessage.to = !message.to ? user.jid : message.to;
					newMessage.sender = 'Me';
					newMessage.read = conversationIsOpen && (newMessage.from.startsWith(activeConversation) || newMessage.to.startsWith(activeConversation)) ? (message?.read ? 1 : 0) : 0;
					newMessage.originalTimestamp = timestamp;
					newMessage.type = existingMessage?.type || 'chat';
					newMessage.status = Constants.MESSAGE_STATUS.SentFailed;
					break;

				//groupchat or notepad
				case Constants.STANZA_EVENT_TYPES['groupchat:received']:
				case Constants.STANZA_EVENT_TYPES['groupchat:received:resent']:
					if (!duplicate && !existingMessage?.read) {
						from = message.from.split('/');
						newMessage.from = from[0];
						newMessage.to = message.to;

						if (newMessage.from === user.notepadJid || from[1] === user.userId) {
							newMessage.sender = 'Me';
							notify = false;
						} else {
							const messageGroup: any = from[1],
								group = await apiService.getGroupByJid(newMessage.from),
								groupMember = group?.members?.find((_member: any) => _member.userId === messageGroup);

							newMessage.sender = groupMember?.userId === user.userId ? 'Me' : group?.members?.find((member: any) => member?.userId === messageGroup).alias;
							notify = newMessage.sender !== 'Me' && !message.delay;
						}

						newMessage.read = newMessage.sender === 'Me' ? 1 : messageBody.status;
						newMessage.originalTimestamp = timestamp;
						newMessage.type = 'groupchat';
						newMessage.status = Constants.MESSAGE_STATUS.Reconciled;
					}
					break;

				// p2p chat
				case Constants.STANZA_EVENT_TYPES['chat:received']:
					from = message.from.split('/');
					newMessage.from = from[0];
					newMessage.to = message.to;
					newMessage.sender = newMessage.from.split('@')[0];
					newMessage.read = isBlank(existingMessage) ? (conversationIsOpen && activeConversation === newMessage.sender ? 1 : 0) : message?.read ? 1 : 0;
					newMessage.originalTimestamp = timestamp;
					newMessage.type = 'chat';
					newMessage.status = Constants.MESSAGE_STATUS.Reconciled;
					notify = !message.delay;
					break;

				// sent from groupchat or chatpad
				// this must represent the ack, so change status accordingly
				case Constants.STANZA_EVENT_TYPES['groupchat:sent']:
					from = message.from.split('/');
					newMessage.from = from[0];
					newMessage.to = message.to;
					newMessage.sender = newMessage.from === user.jid ? 'Me' : (await apiService.getContactByJid(newMessage.from)).alias; // should this lookup group alias?
					newMessage.read = newMessage.sender === 'Me' ? 1 : 0;
					newMessage.originalTimestamp = timestamp;
					newMessage.type = 'groupchat';
					newMessage.status = Constants.MESSAGE_STATUS.Reconciled;
					break;

				// sent from chat - but not acked yet
				case Constants.STANZA_EVENT_TYPES['chat:sent']:
					info(`xmpp::messageHandler::sent message:`, message);
					from = message.from.split('/');
					newMessage.from = from[0];
					newMessage.to = !message.to ? user.jid : message.to;
					newMessage.sender = 'Me';
					newMessage.read = newMessage.sender === 'Me' && existingMessage?.type === 'groupchat' ? 1 : 0;
					newMessage.originalTimestamp = timestamp;
					newMessage.type = existingMessage?.type || 'chat';
					newMessage.status = messageBody.status;
					break;

				// sent from chat viaCarbon (from a groupchat or chat on other device) or acked from chat
				case Constants.STANZA_EVENT_TYPES['chat:sent:viaCarbon']:
				case Constants.STANZA_EVENT_TYPES['chat:sent:acked']:
					from = message.from.split('/');
					newMessage.from = from[0];
					newMessage.to = message.to;
					//conversation = message.to === user.notepadJid ? user : source === 'chat:sent:viaCarbon' ? await getConversation(from[0], newMessage.to) : findConversation(message.to); // specifically set this way
					newMessage.sender = 'Me';
					newMessage.read = message?.read ? 1 : 0;
					newMessage.originalTimestamp = timestamp;
					newMessage.type = existingMessage?.type || 'chat';
					newMessage.status = Constants.MESSAGE_STATUS.Reconciled;
					break;

				// control message
				case Constants.STANZA_EVENT_TYPES['controlMessage']:
					if (message.action) {
						info(`xmpp::xmppManager::messageHandler::control: Received ${message.action} (${message.controlMessageKey})${message.data.include ? ` include: ${JSON.stringify(message.data.include)}` : message.data.exclude ? ` exclude: ${JSON.stringify(message.data.exclude)}` : ''}`);

						let controlMessageKey: any;

						if (controlData.message.archived) {
							controlMessageKey = message.controlMessageKey;
						} else {
							// this is a forwarded message/carbon copy
							// determine if we need it
							// we need to process carbons for chat:sent and groupchat:sent

							if (_.includes([Constants.CONTROL.selfUpdate, Constants.CONTROL.messageUpdated, Constants.CONTROL.messageRead, Constants.CONTROL.mediaUpdated, Constants.CONTROL.conversations, Constants.CONTROL.messageTranslated], message.action)) {
								controlMessageKey = message.controlMessageKey;
							}
						}

						if (!isBlank(controlMessageKey)) {
							if (!_.includes(xmpp.recentControlIds, controlMessageKey)) {
								if (!(message.data.exclude || message.data.include) || (message.data.exclude && message.data.exclude !== cookies.uuid) || (message.data.include && message.data.include === cookies.uuid)) {
									info(`xmpp::xmppManager::messageHandler::control: ControlMessageService on `, controlData.message, message);
									await controlMessageService.handler(message, controlMessageKey);
									xmpp.recentControlIds.push(controlMessageKey);

									if (xmpp.recentControlIds.length > 25) {
										xmpp.recentControlIds.pop();
									}
								} else {
									if (message.data.exclude === cookies.uuid) {
										info(`xmpp::xmppManager::messageHandler::control: Ignoring because this device is specifically excluded.`, message);
									} else {
										info(`xmpp::xmppManager::messageHandler::control: Ignoring because this device is does not have the correct uuid (${cookies.uuid})for this message.`, message);
									}
									await controlMessageService.handler({ action: 'delete' }, controlMessageKey);
								}
							} else {
								info(`xmpp::xmppManager::messageHandler::control: Discarding duplicate control messageKey`, controlMessageKey);
								await controlMessageService.handler({ action: 'delete' }, controlMessageKey);
							}
						} else {
							info(`xmpp::xmppManager::messageHandler::control: Discarding`, message);
						}
					} else {
						info(`xmpp::xmppManager::messageHandler::control: Control Message has no action.`);
					}
					notify = false;
					break;

				default:
					break;
			}

			if (!isBlank(newMessage)) {
				await processMessage();
			}

			xmpp.queue.shift();
		}

		xmpp.isProcessingMessage = false;
		info(`xmpp::messageHandler: message processing is complete.`);
	},

	handleMessageError: (error: any) => {
		info(`xmpp::handleMessageError:`, error);
	},

	handleErrorConditions: async (source: string, error: any) => {
		if (xmpp.isReady) {
			let doReset = true;

			info(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}: condition: ${(error && error.condition) || 'none specifed'} - ${(error && error.text) || ''}`);

			if (!xmpp.isHardReload && !_.includes(Constants.STANZA_ERROR_CONDITIONS, error && error.condition) && source !== Constants.STANZA_EVENT_TYPES['message:failed']) {
				logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}:${error.condition}: reloading ...`);
				AppManager.reload();
			} else if (source === Constants.STANZA_EVENT_TYPES['message:failed']) {
				logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}: we may have become disconnected from the internet.`, error);
				xmpp.isReconnecting = true;
			} else if ((error && error.condition) || false) {
				switch (error.condition) {
					case Constants.STANZA_ERROR_CONDITIONS['invalid-xml']:
						logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}: logged.`);
						doReset = false;
						break;

					case Constants.STANZA_ERROR_CONDITIONS['system-shutdown']:
						// wait 1 minute for the server to come back up, then reconnect
						logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}: Setting xmpp.isReady to false.`);
						break;

					case Constants.STANZA_ERROR_CONDITIONS['policy-violation']:
					case Constants.STANZA_ERROR_CONDITIONS['conflict']:
					case Constants.STANZA_ERROR_CONDITIONS['connection-timeout']:
					case Constants.STANZA_ERROR_CONDITIONS['not-authorized']:
						logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}:`, error);
						xmpp.isReconnecting = true;
						break;

					case Constants.STANZA_ERROR_CONDITIONS['streamError']:
						logError(`xmpp::xmppManager::xmpp.handleErrorConditions::${source}:`, error);
						doReset = false;
						break;

					default:
						break;
				}
			}

			if (doReset) {
				if (xmpp.isReconnecting) {
					info(`xmpp::handleErrorConditions:: calling xmpp.disconnect`);
					await xmpp.disconnect();
				} else {
					await xmpp.xmppManager();
				}
			} else if (xmpp.isReconnecting || !xmpp.isReady || error.condition === Constants.STANZA_ERROR_CONDITIONS['system-shutdown']) {
				await xmpp.xmppManager();
			}
		}
	},

	handleAuthFailed: async (error: any) => await xmpp.handleErrorConditions(Constants.STANZA_ERROR_CONDITIONS['auth:failed'], error),

	handleIqGetPing: async () => await AppManager.checkVersion(),

	handleStream_Error: async (error: any) => await xmpp.handleErrorConditions(Constants.STANZA_ERROR_CONDITIONS['stream:error'], error),

	handleStreamError: async (error: any) => await xmpp.handleErrorConditions(Constants.STANZA_EVENT_TYPES['streamError'], error),

	handleMessageFailed: async (error: any) => await xmpp.handleErrorConditions(Constants.STANZA_EVENT_TYPES['message:failed'], error),

	handleMessageHibernated: (message: any) => info(`xmpp::xmppManager::hibernated::message`, message),

	handleStreamManagementAck: async (message: any) => await AppManager.checkVersion(),

	handleRawIncoming: (data: any) => {
		let parser = new Parser();

		parser.parseString(data, async (_err: any, result: any) => {
			/** possible result top level properties:
					message
					open
					stream:features
					challenge
					success
					failed
					iq
					enabled
					presence
					r
					a
					close
					not-authorized
					error
				*/

			if (result.body) {
				result.message = result.body;
			}

			if (result.close) {
				xmpp.isClosed = true;
			} else if (result.failed) {
				info(`xmpp::handleRawIncoming::result::failed:`, result);
			} else if (result.message) {
				info(`xmpp::handleRawIncoming::result:`, result);
				let message: any,
					event: any,
					item: any,
					source: any,
					unhandled: any = undefined,
					delay: any = undefined,
					type: any = undefined,
					controlData: any = false,
					handleThis: Boolean = true;

				if (result.message?.$ && result.message?.body && _.isArray(result.message?.body) && result.message?.error) {
					message = { ...result.message.$, body: result.message.body[0], error: result.message.error };
					source = 'error';
				} else if (result.message?.$ && result.message?.body && _.isArray(result.message?.body) && JSON.parse(result.message.body[0]).type === 'control') {
					if (result.message?.body) {
						message = JSON.parse(result.message.body[0]);
					}

					source = Constants.STANZA_EVENT_TYPES['controlMessage'];
					controlData = result;
				} else if (
					result.message?.sent &&
					_.isArray(result.message.sent) &&
					result.message.sent[0].forwarded &&
					_.isArray(result.message.sent[0].forwarded) &&
					result.message.sent[0].forwarded[0].message &&
					_.isArray(result.message.sent[0].forwarded[0].message) &&
					result.message.sent[0].forwarded[0].message[0]?.body &&
					_.isArray(result.message.sent[0].forwarded[0].message[0].body) &&
					JSON.parse(result.message.sent[0].forwarded[0].message[0].body).type === 'control'
				) {
					//info(`xmpp::handleRawIncoming: received:`, JSON.stringify(result.message));
					handleThis = false;
					unhandled = true;
				} else if (result.message?.$ && result.message?.body && _.isArray(result.message?.body) && (!cookies.active || !result.message.$.xmlns)) {
					message = { ...result.message.$, body: result.message.body[0] };
					source = Constants.STANZA_EVENT_TYPES['chat:received'];
				} else if (result.message?.sent && _.isArray(result.message?.sent) && result.message.sent[0].forwarded && _.isArray(result.message.sent[0].forwarded)) {
					// do not process control messages sent to contacts on our behalf
					// but we need to remove the control message from the server
					if (isJsonString(result?.message?.body) && JSON.parse(result.message.body)?.type === 'control') {
						source = Constants.STANZA_EVENT_TYPES['extraneous'];
						handleThis = false;
					} else {
						// message sent by user from another device while offline on current device and now being received
						message = { ...result.message.sent[0].forwarded[0].message[0].$, body: result.message.sent[0].forwarded[0].message[0].body };
						source = Constants.STANZA_EVENT_TYPES['chat:sent'];
					}
				} else if ((result.message?.archived && _.isArray(result.message.archived) && result.message?.body && _.isArray(result.message.body)) || (result.message?.event && _.isArray(result.message.event) && _.isArray(result.message.event[0]?.items) && _.isArray(result.message.event[0].items[0]?.item))) {
					if (result.message?.event) {
						event = result.message?.event[0];
						item = event.items[0].item[0];
					} else if (result.message?.body) {
						item = result.message?.archived
							? {
									message: [
										{
											body: result.message.body,
											$: result.message.$,
										},
									],
									unsubscribe: result.message?.unsubscribe,
									subscribe: result.message?.subscribe,
							  }
							: {};
					}

					if (_.isArray(item?.message) && _.isArray(item.message[0]?.body) && !isBlank(item.message[0]?.$)) {
						if (_.isArray(item?.unsubscribe) && item.unsubscribe[0]?.$) {
							// received when a member is unsubscribed from a group
							unhandled = item.unsubscribe[0].$.jid;
							handleThis = false;
							// usage TBD
							// assume look up group, find member, remove member.
							// need to verify if this message is received by every member or just the owner
							// attempt to modify group on server
						} else if (_.isArray(item?.subscribe) && item.subscribe[0]?.$) {
							// received when a member is subscribed to a group
							unhandled = item.subscribe[0].$.jid;
							handleThis = false;
							// usage TBD
							// assume look up group, find member, add member.
							// need to verify if this message is received by every member or just the owner
							// attempt to modify group on server
						} else {
							// received when user has sent a message to a groupchat ... we care if it came from another device
							// and check in the message handler
							message = { ...item.message[0].$, body: item.message[0].body[0] };

							// check for a delayed message
							// we don't really want this as it would have been received after a reconnect
							// and we will get this message automatically with the refreshMessages
							delay = result.message?.delay && _.isArray(result.message.delay) && result.message.delay[0]?._ ? result.message.delay[0]._ : undefined;

							if (delay) {
								info(`xmpp::handleRawIncoming::message::delay:: Not processing`, delay);
								unhandled = delay;
								handleThis = false;
							} else {
								type = item.message[0].$?.type;

								if (type === 'groupchat') {
									source = Constants.STANZA_EVENT_TYPES[`groupchat:received${delay === 'Resent' ? ':resent' : ''}`];
								} else if (type === 'chat') {
									source = Constants.STANZA_EVENT_TYPES[`chat:received${delay === 'Resent' ? ':resent' : ''}`];
								}
							}
						}
					} else if (item.$ && _.isArray(item.$)) {
						unhandled = item.$[0]?.node;
						info(`xmpp::handleRawIncoming:: Not processing ${unhandled}`);
						handleThis = false;
					}
				} else {
					info(`xmpp::handleRawIncoming: Not processing:`, result);
					handleThis = false;
				}

				if (handleThis) {
					let messageBody: any = message;

					if (_.isArray(message?.body)) {
						messageBody = message.body[0];
					} else if (message.body) {
						messageBody = message.body;
					}

					if (isJsonString(messageBody)) {
						messageBody = JSON.parse(message.body);
					}

					// ensure that control messages are at the beginning of the queue
					if (xmpp.queue.length > 0 && source === Constants.STANZA_EVENT_TYPES['controlMessage']) {
						let sources = xmpp.queue.map((_queued: any) => _queued.source);
						xmpp.queue.splice(sources.lastIndexOf(Constants.STANZA_EVENT_TYPES['controlMessage']) + 1, 0, { message: message, messageBody: messageBody, source: source, controlData: controlData });
					} else {
						xmpp.queue.push({ message: message, messageBody: messageBody, source: source, controlData: controlData });
					}

					info(`xmpp::handleRawIncoming: queued:`, source, message);

					if (!xmpp.isProcessingMessage) {
						await xmpp.messageHandler();
					}
				} else {
					if (!unhandled) {
						info(`xmpp::handleRawIncoming: Not processing and discarding not unhandled message:`, result);
					} else {
						info(`xmpp::handleRawIncoming: Not processing and discarding unhandled message:`, result);
					}
				}
			} else if (result.resumed) {
				info(`xmpp::handleRawIncoming::result.resumed:`, result);
			} else if (result.enabled) {
				await xmpp.handleKeepAlive();
			} else if (!result.a && !result.iq && !result.r && !result.presence && !result.resumed) {
				info(`xmpp::handleRawIncoming::result:`, Object.keys(result)[0], result);
			}
		});
	},

	handleChat: async (message: any) => {
		if (message.type && message.type === 'groupchat') {
			clearTimeout(xmpp.sentTimeout);
			xmpp.messageIsSent = true;
			info(`xmpp::handleChat::queuing:`, Constants.STANZA_EVENT_TYPES['groupchat:sent'], message);
			xmpp.queue.push({ message: message, messageBody: JSON.parse(message.body), source: Constants.STANZA_EVENT_TYPES['groupchat:sent'], controlData: false });

			if (!xmpp.isProcessingMessage) {
				await xmpp.messageHandler();
			}
		}
	},

	/*handleMessage: async (message: any) => {
		info('xmpp::handleMessage: received (logged only)', JSON.stringify(message));
		if (!message.carbon && (!message.type || message.type === 'chat')) {

			try {
				info(`xmpp::handleMessage::queuing:`, Constants.STANZA_EVENT_TYPES['chat:received'], message);
				xmpp.queue.push({ message: message, messageBody: JSON.parse(message.body), source: Constants.STANZA_EVENT_TYPES['chat:received'], controlData: false });
			} catch (err) {
				debugger;
			}
			if (!xmpp.isProcessingMessage) {
				await xmpp.messageHandler();
			}
		} else {
			info('xmpp::handleMessage:: received', message);
		}
	},*/

	handleMessageSent: async (message: any, viaCarbon: Boolean) => {
		if (message.type && message.type.includes('chat')) {
			clearTimeout(xmpp.sentTimeout);
			xmpp.messageIsSent = true;
			info(`xmpp::handleMessageSent::queuing:`, Constants.STANZA_EVENT_TYPES[`chat:sent${viaCarbon ? ':viaCarbon' : ''}`], message);
			xmpp.queue.push({ message: message, messageBody: JSON.parse(message.body), source: Constants.STANZA_EVENT_TYPES[`chat:sent${viaCarbon ? ':viaCarbon' : ''}`], controlData: false });

			if (!xmpp.isProcessingMessage) {
				await xmpp.messageHandler();
			}
		}
	},

	handleMessageAcked: async (message: any) => {
		//info('xmpp::handleMessageAcked:', message);
		if (message.type && message.type === 'chat') {
			info(`xmpp::handleMessageAcked::queuing:`, Constants.STANZA_EVENT_TYPES['chat:sent:acked'], message);
			xmpp.queue.push({ message: message, messageBody: JSON.parse(message.body), source: Constants.STANZA_EVENT_TYPES['chat:sent:acked'], controlData: false });

			if (!xmpp.isProcessingMessage) {
				await xmpp.messageHandler();
			}
		}
	},

	handleGroupChat: (message: any) => info('xmpp::xmppManager::groupchat::message:', message),

	handleTransportDisconnected: async () => {
		if (!xmpp.isLoggingOut && !xmpp.isDisconnected) {
			logError('xmpp::handleTransportDisconnected: re-establishing connection ...');
			xmpp.reset();
			let timeout: number = 250;
			setTimeout(await xmpp.xmppManager, Math.min(250, (timeout *= 2)));
		} else if (!xmpp.isReady) {
			logError('xmpp::handleTransportDisconnected:: thrown again but already trying to reconnect');
		}
	},

	handleAll: async (event: any, result: any) => {
		if (
			!xmpp.isHardReload &&
			!event.startsWith(Constants.STANZA_EVENT_TYPES['iq']) &&
			!event.startsWith(Constants.STANZA_EVENT_TYPES['presence:id:']) &&
			!event.startsWith(Constants.STANZA_EVENT_TYPES['message:id:']) &&
			!event.startsWith(Constants.STANZA_EVENT_TYPES['sm:id:']) &&
			!_.includes(Constants.STANZA_EVENT_TYPES, event)
		) {
			info('xmpp::handleAll:', event, result ? result : '');
		}
	},

	setMessagesLoaded: (state: Boolean) => (xmpp.messagesLoaded = state),

	setMediaProcessed: (state: Boolean) => (xmpp.mediaProcessed = state),

	startXmppListeners: async (action: any = undefined) => {
		info('xmpp::startXmppListeners');

		if (xmpp.client) {
			cookies = getInternalStorage();

			if (!cookies.listeners) {
				cookies.listeners = [];
			} else {
				await xmpp.stopListeners(true);
			}

			if (!action) {
				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['session:started'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['session:started'], fn: xmpp.handleSessionStarted });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['connected'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['connected'], fn: xmpp.handleConnected });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['stream:management:resumed'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['stream:management:resumed'], fn: xmpp.handleResumed });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['auth:failed'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['auth:failed'], fn: xmpp.handleAuthFailed });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['iq:get:ping'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['iq:get:ping'], fn: xmpp.handleIqGetPing });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['stream:error'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['stream:error'], fn: xmpp.handleStream_Error });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['streamError'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['streamError'], fn: xmpp.handleStreamError });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message:failed'])) {
					// interesting event.  occurs when sending and the websocket has become disconnected, and usually followed by a valid chat:sent indicator,
					// with a message id, however the message is not acutally in the database and has not been sent to recipient
					// we need to reconnect, and then resend the message
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message:failed'], fn: xmpp.handleMessageFailed });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['stream:management:ack'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['stream:management:ack'], fn: xmpp.handleStreamManagementAck });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['disconnected'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['disconnected'], fn: xmpp.handleDisconnected });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message:error'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message:error'], fn: xmpp.handleMessageError });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message:hibernated'])) {
					// this occurs when an attempt to send a message while not connected occurs
					// the hibernated messages should get sent when the connection is re-established
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message:hibernated'], fn: xmpp.handleMessageHibernated });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['presence'])) {
					// we get this in response to sending a presence message, or when we log into a different device
					// with the same credentials.  the 'resource' portion of the full jid is the uuid of the different device
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['presence'], fn: xmpp.handlePresence });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['raw:incoming'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['raw:incoming'], fn: xmpp.handleRawIncoming });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['chat'])) {
					// this is where we receive incoming group chat messages from ejabbered
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['chat'], fn: xmpp.handleChat });
				}

				/*if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message'])) {
					// this is where we receive incoming chat messages from ejabbered
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message'], fn: xmpp.handleMessage });
				}*/

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message:sent'])) {
					// this is generated when a message is sent to ejabberd - it does not necessarily mean that it has been delivered
					// if the xmpp.user session has ended, the message will have to be resent
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message:sent'], fn: xmpp.handleMessageSent });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['message:acked'])) {
					// acknowledgement that ejabbered has received and processed an outgoing P2P message
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['message:acked'], fn: xmpp.handleMessageAcked });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['groupchat'])) {
					// acknowledgement that ejabbered has received and processed an outgoing MUC message
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['groupchat'], fn: xmpp.handleGroupChat });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['--transport-disconnected'])) {
					// this is thrown when the websocket throws an error
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['--transport-disconnected'], fn: xmpp.handleTransportDisconnected });
				}

				if (!cookies.listeners.find((_listener: any) => _listener.event === Constants.STANZA_EVENT_TYPES['*'])) {
					cookies.listeners.push({ element: 'xmpp', event: Constants.STANZA_EVENT_TYPES['*'], fn: xmpp.handleAll });
				}
			} else {
				for (let _listener of action) {
					cookies.listeners.push({ element: 'xmpp', event: _listener.event, fn: _listener.fn });
				}
			}

			for (let listener of cookies.listeners.filter((_listener: any) => _listener.element === 'xmpp' && !_listener.started)) {
				xmpp.client.on(listener.event, listener.fn);
				listener.started = true;
			}

			setInternalStorage('listeners', cookies.listeners);
		}
	},

	stopListeners: async (xmppOnly: Boolean = true) => {
		cookies = getInternalStorage();

		if (cookies.listeners && cookies.listeners.constructor === Array && cookies.listeners.length > 0) {
			//info(`xmpp::stopListeners: stopping ${xmppOnly ? cookies.listeners.filter((_listener: any) => _listener?.element === 'xmpp').length : cookies.listeners.length}`);

			while (cookies.listeners.length > 0) {
				try {
					let listener = cookies.listeners.pop();
					//info(`stopping ${listener.event}`);

					if (!xmppOnly && listener?.element === 'window') {
						window.removeEventListener(listener.event, listener.fn);
					} else if (!xmppOnly && listener?.element === 'document') {
						document.removeEventListener(listener.event, listener.fn);
					} else if (xmpp.client && listener?.element === 'xmpp') {
						xmpp.client.off(listener.event, listener.fn);
					}
				} catch (error) {
					logError(error);
				}
			}
		}

		setInternalStorage('listeners', []);
	},
} as any;
