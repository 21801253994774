/* eslint-disable array-callback-return */
import React from 'react';
import { IonToolbar, IonTitle, IonContent, IonApp, IonButtons, IonButton, IonIcon, IonLabel, IonGrid, IonRow, IonPage, IonNote, IonAlert } from '@ionic/react';
import { Redirect, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import './style.scss';
import './thread.scss';
import ThreadComponent from './thread';
import { searchContacts } from '../../redux/actions/dashboard';
import store from '../../redux/store';
import { CHAT_CLEAR } from '../../redux/constants/chat';
import { reloadChat } from '../../redux/actions/chat';
import { blankProfilePic, info, isBlank, compressSelectedFile, getActiveConversation, isPersonalNotepad } from '../../helpers/common';
import { apiService } from '../../services/apiService';
import ChatInputBox from './components/ChatBottomComponents/ChatInputBox';
import moment from 'moment';
import EnumService from '../../services/enumService';
import { sharedService } from '../../services/sharedService';
import { ChatSelectView } from './components/ChatBottomComponents/ChatSelectView';
import DropdownPopover from '../../components/DropdownPopover';
import FavoriteSearchModal from '../../modals/favoriteSearchModal';
import _ from 'lodash';
import { PhotoPreviewModal } from '../../modals/PhotoPreviewModal';
import { publishData, resetPublishData } from '../../redux/actions/pubsub';
import { locale } from '../../locales/local';
import UnreadMsgAccessBtnsView from './components/ChatBottomComponents/UnreadMsgAccessBtnsView';
import { Virtuoso, ListRange, LogLevel } from 'react-virtuoso';
import CustomSpinner from '../../components/CustomSpinner';
import { xmpp } from '../../services/xmpp';
import { CHAT_INIT } from '../../redux/constants/chat';
import { VideoPreviewModal } from '../../modals/VideoPreviewModal';
import MultipleImageEditor, { OpenEditorType } from '../../modals/ImageEditor/MultipleImageEditor';

require('default-passive-events');

class Chat extends React.Component<iProps, iState> {
	groupHeaderMenuOption = [
		{ title: locale.dashboard.chat.group_info, svgIcon: 'info.svg', type: 'groupInfo' },
		{ title: locale.dashboard.chat.favorite, svgIcon: 'heart.svg', type: 'favorite' },
		{ title: locale.dashboard.chat.todo, svgIcon: 'todos.svg', type: 'todos' },
		{ title: locale.dashboard.chat.tagged, svgIcon: 'tagged.svg', type: 'tagged' },
		{ title: locale.dashboard.chat.group_settings, svgIcon: 'settings.svg', type: 'groupSettings' },
	];

	chatHeaderMenuOption = [
		{ title: locale.dashboard.chat.create_group, svgIcon: 'group-create-icon.svg', width: '28px', height: '20px', type: 'createGroup' },
		{ title: locale.dashboard.chat.manage_contact, svgIcon: 'manage-icon.svg', width: '20.66px', height: '20.66px', type: 'manageContact' },
		{ title: locale.dashboard.chat.search, svgIcon: 'search-icon.svg', width: '20px', height: '20px', type: 'search' },
		{ title: locale.dashboard.chat.share, svgIcon: 'share-icon.svg', width: '22px', height: '22px', type: 'share' },
	];

	maxMediaSelectLimit: number = 9;

	constructor(props: iProps) {
		super(props);

		this.state = {
			...this.props,
			dynamicClass: false,
			showFavoriteSearchModal: false,
			showReload: false,
			selectedVideo: null,
			selectedVideoMessageKey: '',
			openVideoPreview: false,
			receiver: {},
			//isInitialDataLoadCompleted: false,
			selectingMessages: false,
			showPhotoEditor: false,
			message: '',
			selectedMessages: [],
			captionMessage: '',
			openPreview: false,
			selectedImage: undefined,
			medias: null,
			selectedMediaMessageKey: '',
			popoverEvent: undefined,
			showPopover: false,
			unreadMessagesIds: [],
			children: null,
			chatBoxHeight: 0,
			shouldShowQuickAccessBtn: false,
			isChatSyncing: false,
			isNetworkConnectionOnline: window.navigator.onLine,
			showAlert: false,
			alertMessage: '',
		};
	}

	receiverProfilePhoto: any;
	receiverNameToDisplay: any;
	senderId: any;
	previousMessageTime: any;
	shouldShowTime: Boolean = false;
	conversationFetching: Boolean = false;
	componentHasMounted: Boolean = true;
	hasMounted: Boolean = false;
	componentIsUnMounted: Boolean = false;
	componentIsUpdating: Boolean = false;
	activeConversation: any = undefined;
	personalNotepad: Boolean = isPersonalNotepad();
	conversations: any[] = [];
	isAllUnreadMessageUpdatingInProgress: Boolean = false;
	isManualScrolling: boolean = false;
	isNewMessageSent: boolean = false;
	unreadThreadlist: any = [];
	isUnreadThreadsAppended: boolean = false;
	isAtBottom: boolean = false;
	virtuosoRef: any = React.createRef();
	chatInputBoxRef: any = React.createRef();
	unreadRequestSentIds: any = []; // It is usefull when send unread update request send
	readMessageStore: any = [];
	virtualScrollOffsetHeight: number = 0;
	virtualScrollContentHeight: number = 0;
	virtualScrollOffsetTop: number = 0;
	chatSyncListenerTimer: any = null;
	showQuickScrollComponent: Boolean = false;
	isRendered: Boolean = false;
	bodyHeight: any = document?.querySelector('body')?.offsetHeight;
	conversationHeight: number = this.bodyHeight - 84 - 58;
	maxConversationItems: number = Math.trunc(this.conversationHeight / 112);
	range: ListRange = { startIndex: 0, endIndex: 0 };
	uploadController: any;
	isRebuilding: Boolean = false;
	firstItem: any = 0;
	isInitialDataLoadCompleted: Boolean = false;

	virtuosoComponents: any = {
		Header: () => <div id="virtualsoHeader"></div>,
		Footer: () => <div id="virtualsoFooter" style={{ height: '48px' }}></div>,
	};

	toggleClass() {
		if (this.componentHasMounted) {
			this.setState({ dynamicClass: !this.state.dynamicClass });
		}
	}

	scrollTo(index: number = -1, duration = 0, scrollFrom = '') {
		if (sharedService.isChatScrolling) {
			return;
		}
		info('Log_Scroll_to_call ', scrollFrom);
		_.defer(async () => {
			//info('isManualScrolling', true);
			this.isManualScrolling = true;
			// let ionContent: any = document.getElementById('chat-ion-content');
			// if (ionContent) {
			// 	const elem = await ionContent.getScrollElement();
			// 	if (offsetTop) {
			// 		elem?.scrollTo(0, offsetTop);
			// 	} else {
			// 		ionContent?.scrollToBottom(duration);
			// 	}
			// } else {
			// 	info('scrollTo-call-from', 'scrollTo');
			// 	this.scrollTo(0);
			// }
			const scrollVirtualListTo = (indexNumber: Number) => {
				this.virtuosoRef?.current?.scrollToIndex({
					index: indexNumber,
					align: 'top',
					behavior: duration ? 'smooth' : 'auto',
				});
			};
			if (index >= 0) {
				scrollVirtualListTo(index);
			} else {
				scrollVirtualListTo(this.props?.chat?.history?.length - 1);
			}
			_.defer(() => {
				this.isManualScrolling = false;
			}, 1000);
			return '';
		});
	}

	scrollToIndex(index: number = -1, duration = 0, scrollFrom = '') {
		if (sharedService.isChatScrolling) {
			return;
		}
		_.defer(async () => {
			this.isManualScrolling = true;

			const scrollVirtualListTo = (indexNumber: Number) => {
				this.virtuosoRef?.current?.scrollToIndex({
					index: indexNumber,
					align: 'top',
					behavior: duration ? 'smooth' : 'auto',
				});
			};
			if (index >= 0) {
				scrollVirtualListTo(index);
			} else {
				scrollVirtualListTo(this.props?.chat?.history?.length - 1);
			}
			_.defer(() => {
				this.isManualScrolling = false;
			}, 1000);
			return '';
		});
	}

	setImagePreview() {
		if (this.componentHasMounted) {
			this.setState({ openPreview: false });
		}
	}

	previewActionHandler = (image: any, message: any) => {
		if (this.componentHasMounted) {
			if (message.messageType === EnumService.ChatMessageType.MEDIA) {
				if (message.mediaType === EnumService.ChatMediaType.VIDEO) {
					this.setState({ openVideoPreview: true, selectedVideo: image, selectedVideoMessageKey: message.messageKey });
				} else {
					this.setState({ openPreview: true, selectedImage: image, selectedMediaMessageKey: message.messageKey });
				}
			}
		}
	};
	async sendMedia(mediaFiles: any = [], mediaResolutions: any = []) {
		for (let index in mediaFiles) {
			const files: any = [],
				mediaTypes: any = [],
				_mediaResolutions: any = [];

			const media = mediaFiles[index],
				mediaResolution = parseInt(index) === 0 ? mediaResolutions[index] : 0; // Remove this condition when image editor impletmented or multiple images

			// Remove this condition when image editor impletmented or multiple images
			if (parseInt(index) === 0) {
				_mediaResolutions.push(mediaResolution === 0 ? 'full image' : 'reduced image');
			} else {
				// No need this after image editor implemented for multiple images
				_mediaResolutions.push('full image');
			}

			if (media.indexOf('image') !== -1) {
				mediaTypes.push(EnumService.ChatMediaType.IMAGE);
			} else if (media.indexOf('video') !== -1) {
				mediaTypes.push(EnumService.ChatMediaType.VIDEO);
			}

			if (mediaResolution !== 0) {
				const compressedFile = await compressSelectedFile(media, { x: mediaResolution, y: mediaResolution, fit: 'contain', upscale: false });
				if (!compressedFile?.hasError) {
					files.push(compressedFile);
				}
			} else {
				files.push(media);
			}

			const messageBody: any = {
				mediaFiles: files,
				mediaResolutions: _mediaResolutions,
				mediaTypes: mediaTypes,
				message: this.state.captionMessage,
			};

			let messageType = EnumService.ChatMessageType.MEDIA,
				messageState = EnumService.ChatMessageState.ORIGINAL,
				relatedMessageId = '';

			if (this.chatInputBoxRef.current?.isReplyViewOpen) {
				messageType = EnumService.ChatMessageState.REPLY;
				relatedMessageId = this.chatInputBoxRef.current.replyForMessageId;
				this.chatInputBoxRef.current.closeReplyView();
			}

			console.log('sendMessage::', messageBody);
			this.uploadController = xmpp.sendMessage(this.props.chat?.receiver.jid, messageBody, messageState, messageType, relatedMessageId);
		}

		if (this.componentHasMounted) {
			this.setState({
				openPreview: false,
				message: '',
				captionMessage: '',
				medias: null,
				selectedMediaMessageKey: '',
			});
		}
		//this.scrollTo(-1, 0, 'sendMedia');
	}

	async cancelUpload() {
		await apiService.cancelUpload();
		if (this.componentHasMounted) {
			this.setState({ openPreview: false, medias: null });
		}
	}

	pushToSelectedMessages(message: any) {
		const selectedMessages: any = this.state.selectedMessages || [];

		let selectedIndex = -1;
		selectedMessages.some((item: any, key: number) => {
			if (item.messageKey === message.messageKey) {
				selectedIndex = key;
				return true;
			}
		});

		if (selectedIndex >= 0) {
			selectedMessages.splice(selectedIndex, 1);
		} else {
			selectedMessages.push(message);
		}

		if (this.componentHasMounted) {
			this.setState({ selectedMessages });
		}
	}

	onOnline = () => {
		console.log('isNetworkConnectionOnline', true);
		if (!this.componentIsUnMounted) {
			if (!this.state.isNetworkConnectionOnline) {
				(async () => await xmpp.xmppManager())();
			}

			if (this.componentHasMounted) {
				this.setState({ isNetworkConnectionOnline: true });
			}
		}
	};

	onOffline = () => {
		console.log('isNetworkConnectionOnline', false);
		if (!this.componentHasMounted) {
			if (this.componentHasMounted) {
				this.setState({ isNetworkConnectionOnline: false });
			}
			xmpp.reset();
		}
	};

	componentWillUnmount() {
		this.componentIsUnMounted = true;
		store.dispatch({ type: CHAT_CLEAR });
		window.removeEventListener('online', this.onOnline, true);
		window.removeEventListener('offline', this.onOffline, true);
		this.chatSyncListenerTimer && clearInterval(this.chatSyncListenerTimer);
		this.uploadController = null;
	}

	async setPageData() {
		if (!xmpp.isReady) {
			await xmpp.xmppManager({ noReset: true });
		}

		let user: any = this.props.chat.loggedInUser,
			userUpdated: Boolean = false;

		if (isBlank(user)) {
			user = await apiService.me();
			user.updated = true;
		}

		this.activeConversation = getActiveConversation(user);
		this.personalNotepad = isPersonalNotepad();
		//info(`Chat::setPageData: user: ${user.userId}, activeConversation: ${this.activeConversation}, personalNotepad: ${this.personalNotepad}`);

		let receiver: any,
			receiverUpdated: Boolean = false,
			history: any,
			historyUpdated: Boolean = false,
			conversation: any;

		if (isBlank(this.props.chat.receiver?.conversationHash)) {
			receiver = user.contacts.find((_conversation: any) => _.includes([_conversation.userId, _conversation.room], this.activeConversation));
			receiverUpdated = true;
		} else {
			receiver = this.props.chat.receiver;
		}

		if (isBlank(this.props.chat.history) && !this.isRebuilding) {
			this.isRebuilding = true;
			info('Chat::setPageData: this.isRebuilding set');

			let theDate = new Date();
			theDate.setDate(theDate.getDate() - 7);

			let conversationIndex: number = user.conversations.findIndex((_conversation: any) => _conversation.conversationHash === receiver?.conversationHash);
			conversation = user.conversations[conversationIndex];

			if (conversation.messages.length <= 1 || conversation.lastMessage.timestamp < theDate) {
				user = await apiService.refreshMessages([conversation], false);
			}

			history = user?.conversations[conversationIndex]?.messages;
			historyUpdated = true;
		} else if (!this.isRebuilding) {
			history = this.props.chat.history;
		}

		if (isBlank(receiver)) {
			this.props.history.goBack();
		} else if (userUpdated || receiverUpdated || historyUpdated) {
			store.dispatch({ type: CHAT_INIT, payload: { loggedInUser: user, conversation: conversation, history: history, receiver: receiver } });
		} else if (!this.isRebuilding) {
			xmpp.setMessagesLoaded(true);
		}
	}

	componentWillUmount() {
		this.componentHasMounted = false;
		this.hasMounted = false;
		window.removeEventListener('online', this.onOnline);
		window.removeEventListener('offline', this.onOffline);
	}

	async componentDidMount() {
		this.componentHasMounted = true;
		info('Chat::componentDidMount:start');
		window.addEventListener('online', this.onOnline, true);
		window.addEventListener('offline', this.onOffline, true);
		await this.setPageData();

		if (this.isRebuilding) {
			this.isRebuilding = false;
			info('Chat::componentDidMount: this.isRebuilding reset');
		}

		this.isRendered = true;
		this.hasMounted = true;

		if (this.componentHasMounted) {
			//	this.setState({ isInitialDataLoadCompleted: true });
			this.isInitialDataLoadCompleted = true;
		}

		info('Chat::componentDidMount:end');
	}

	shouldComponentUpdate(nextProps: any, nextState: any) {
		let shouldUpdate = false;
		const nextPropChatHistoryHasLength = nextProps.chat.history.length > 0,
			isOnlineOfflineChanged: boolean = this.state.isNetworkConnectionOnline !== nextState.isNetworkConnectionOnline,
			isChatHistoryUpdated: boolean = nextPropChatHistoryHasLength && this.props.chat.history?.length !== nextProps.chat.history.length,
			isNewImage: boolean = nextState.medias && nextState.medias.length > 0,
			isDataPublished: boolean = nextProps.pubsub?.publishType === EnumService.PubSubEventType.QuickAccessBtnVisibility && this.props.pubsub?.publishedData?.show !== nextProps.pubsub?.publishedData?.show,
			isSyncingStateChanged = this.state.isChatSyncing !== nextState.isChatSyncing,
			isRead: Boolean = nextPropChatHistoryHasLength && nextProps.chat.history.slice(-1)[0]?.read === 1,
			isTranslated: Boolean = nextProps.chat.history.filter((_message: any) => _message?.translated).filter((_include: any) => this.props.chat.history.filter((_message: any) => _message?.translated).indexOf(_include) === -1).length > 0,
			isUnreadMessagesChanged = this.state.unreadMessagesIds.length !== nextState.unreadMessagesIds.length,
			showPhotoEditor = this.state.showPhotoEditor !== nextState.showPhotoEditor || this.state.openVideoPreview !== nextState.openVideoPreview || this.state.openPreview !== nextState.openPreview;

		if (showPhotoEditor || (nextPropChatHistoryHasLength && (!this.hasMounted || isOnlineOfflineChanged || isChatHistoryUpdated || isDataPublished || isSyncingStateChanged || isUnreadMessagesChanged || isRead || isTranslated || isNewImage || this.isRebuilding))) {
			!this.hasMounted && info('Chat::shouldComponentUpdate: because !this.componentHasMounted');
			isOnlineOfflineChanged && info('Chat::shouldComponentUpdate: because isOnlineOfflineChanged');
			isChatHistoryUpdated && info('Chat::shouldComponentUpdate: because isChatHistoryUpdated');
			isNewImage && info('Chat::shouldComponentUpdate: because isNewImage');
			isDataPublished && info('Chat::shouldComponentUpdate: because isDataPublished');
			isSyncingStateChanged && info('Chat::shouldComponentUpdate: because isSyncingStateChanged');
			//isRead && info('Chat::shouldComponentUpdate: because isRead');
			isTranslated && info('Chat::shouldComponentUpdate: because isTranslated');
			isUnreadMessagesChanged && info('Chat::shouldComponentUpdate: because isUnreadMessagesChanged');
			showPhotoEditor && info('Chat::shouldComponentUpdate: because showPhotoEditor');
			this.isRebuilding && info('Chat::shouldComponentUpdate: because this.isRebuilding');
			shouldUpdate = true;

			if (isNewImage) {
				(async () => await this.setPageData())();
			}

			this.bodyHeight = document.querySelector('body')?.offsetHeight;

			if (this.bodyHeight > 0) {
				this.conversationHeight = this.bodyHeight - 84 - 58;
				this.maxConversationItems = Math.trunc(this.conversationHeight / 112);
				this.firstItem = this.props.chat.history.length > this.maxConversationItems ? this.props.chat.history.length - this.maxConversationItems + 1 : 0;
			} else {
				this.firstItem = 0;
			}

			//info('Chat::shouldComponentUpdate: this.firstItem', this.firstItem);
		} else if (nextProps.chat.history.length === 0) {
			//info('Chat::shouldComponentUpdate: false because this.props.chat.history.length === 0');
		} else {
			//info('Chat::shouldComponentUpdate: false because no conditions met');
		}

		return shouldUpdate;
	}

	async componentDidUpdate(prevProps: any) {
		//info('this.props.chat.receiver.unreadMessages.length', this.props.chat?.receiver?.unreadMessages?.length);
		//info('this.state.unreadMessagesIds.length', this.state.unreadMessagesIds.length);

		if (!this.componentIsUpdating) {
			//info('Chat::componentDidUpdate:start');
			this.componentIsUpdating = true;
			await this.setPageData();

			this.virtuosoRef?.current?.scrollToIndex({
				index: this.firstItem,
				align: 'end',
				behavior: 'auto',
			});

			/*if (this.props.chat.receiver.unreadCount > 0 && !this.isAllUnreadMessageUpdatingInProgress) {
				this.isAllUnreadMessageUpdatingInProgress = true;
				info(`Chat::componentDidUpdate: setting ${this.props.chat.receiver.unreadCount} unread messages read.`);
				apiService.updateReadStatus(this.props.chat.receiver.unreadCount > 1 ? { conversation: this.props.chat?.receiver } : { messageKeys: this.props.chat?.receiver.unreadMessages });
			}*/

			if (this.isRebuilding) {
				this.isRebuilding = false;
				info('Chat::componentDidUpdate: this.isRebuilding reset');
			}

			this.componentIsUpdating = false;
			//info('Chat::componentDidUpdate:end');
		} else {
			//info('Chat::componentDidUpdate:ignored');
		}
	}

	async setAllMessagesRead() {
		if (!this.isAllUnreadMessageUpdatingInProgress && !this.personalNotepad && this.state.unreadMessagesIds && this.state.unreadMessagesIds.length > 0) {
			let isMsgNotSentAlreadyForReadStatus = false;
			const unreadMsgIds: any = [];
			this.state.unreadMessagesIds.forEach((item: any) => {
				if (!_.includes(this.unreadRequestSentIds, item)) {
					isMsgNotSentAlreadyForReadStatus = true;
					unreadMsgIds.push(item);
				}
			});

			if (isMsgNotSentAlreadyForReadStatus) {
				this.unreadRequestSentIds = this.unreadRequestSentIds.concat(unreadMsgIds);
				this.isAllUnreadMessageUpdatingInProgress = true;
				//await apiService.updateReadStatus({ conversation: this.props.chat?.receiver });
				this.state.unreadMessagesIds.splice(0, this.state.unreadMessagesIds.length);
				console.log(this.state.unreadMessagesIds);

				if (this.componentHasMounted) {
					this.setState({ unreadMessagesIds: [] });
				}
				this.isAllUnreadMessageUpdatingInProgress = false;
			}
		}
	}

	async setReadMessagesByIds(unreadMessageIds: any) {
		if (!this.isAllUnreadMessageUpdatingInProgress && unreadMessageIds?.length > 0) {
			const messageKeys: any = [];

			unreadMessageIds.forEach((messageKey: any) => {
				if (!_.includes(this.unreadRequestSentIds, messageKey)) {
					messageKeys.push(messageKey);
				}
			});

			this.unreadRequestSentIds = this.unreadRequestSentIds.concat(messageKeys);

			/*if (messageKeys && messageKeys.length > 0) {
				await apiService.updateReadStatus({ messageKeys: messageKeys });
			}*/
		}
	}

	async readNextUnreadMessage(nextIndex: number) {
		await this.setReadMessagesByIds([nextIndex]);
	}

	async readNextUnreadMessage2(nextIndex: number) {
		if (!this.props.chat?.receiver?.notepadJid) {
			const unreadCount = this.props.chat?.receiver?.unreadCount ? this.props.chat?.receiver?.unreadCount : this.state.unreadMessagesIds ? this.state.unreadMessagesIds.length : 0;

			if (unreadCount > 0) {
				const firstUnreadMsgId: string = nextIndex < unreadCount && nextIndex >= 0 ? this.state.unreadMessagesIds[nextIndex] : this.state.unreadMessagesIds[0];
				const element: any = document.getElementById(firstUnreadMsgId);

				if (this.state.unreadMessagesIds?.length === 1) {
					//info('scrollTo-call-from', 'readNextUnreadMessage 1');
					this.scrollTo(-1, 0, 'readNextUnreadMessage->unreadMessagesIds.length is 1');
					await this.setReadMessagesByIds([firstUnreadMsgId]);
				} else {
					const chatInputBox: any = document.getElementById('chatInputBox');

					if (chatInputBox) {
						const minY = element ? element.offsetTop - (this.virtualScrollOffsetHeight || 0) / 2 : 0,
							maxY = minY + this.virtualScrollOffsetHeight - chatInputBox.offsetHeight,
							readThisMsgIds: any = [firstUnreadMsgId];

						this.state.unreadMessagesIds.map((unreadMessagesId: any) => {
							const msgElement = document.getElementById(unreadMessagesId);

							if (msgElement) {
								const offsetTop = msgElement.offsetTop,
									offsetBottom = offsetTop + msgElement.offsetHeight;

								if (offsetBottom <= maxY) {
									if (!_.includes(readThisMsgIds, unreadMessagesId)) {
										readThisMsgIds.push(unreadMessagesId);
									}
								}
							}
						});
						//info('scrollTo-call-from', 'readNextUnreadMessage 2');

						// this.scrollTo(element.offsetTop - (this.virtualScrollOffsetHeight || 0) / 2);
						const scrollToIndex = this.props?.chat?.history
							.map(function (o: any) {
								return o.messageKey;
							})
							.indexOf(firstUnreadMsgId);

						this.scrollTo(scrollToIndex - 1, 0, 'readNextUnreadMessage->scrollToIndex');

						if (readThisMsgIds.length > 0) {
							await this.setReadMessagesByIds(readThisMsgIds);
						}
					}
				}
			}
		}
	}
	async readNextUnreadTaggedMessage(nextIndex: number) {
		if (!this.personalNotepad) {
			const taggedMessages = this.props.chat?.receiver?.taggedMessages;

			if (taggedMessages && taggedMessages.length > 0) {
				const firstUnreadTaggedMsg: any = nextIndex < taggedMessages.length && nextIndex >= 0 ? taggedMessages[nextIndex] : taggedMessages[0],
					firstUnreadMsgId: string = firstUnreadTaggedMsg.messageKey,
					element: any = document.getElementById(firstUnreadMsgId),
					chatInputBox: any = document.getElementById('chatInputBox');

				if (element && chatInputBox) {
					const minY = element.offsetTop - (this.virtualScrollOffsetHeight || 0) / 2,
						maxY = minY + this.virtualScrollOffsetHeight - chatInputBox.offsetHeight,
						readThisMsgIds: any = [];

					this.state.unreadMessagesIds.map((unreadMessagesId: any) => {
						const msgElement = document.getElementById(unreadMessagesId);

						if (msgElement) {
							const offsetTop = msgElement.offsetTop,
								offsetBottom = offsetTop + msgElement.offsetHeight;

							if (offsetBottom <= maxY) {
								readThisMsgIds.push(unreadMessagesId);
							}
						}
					});

					taggedMessages.map((msg: any) => {
						const unreadMessagesId = msg.messageKey,
							msgElement = document.getElementById(unreadMessagesId);

						if (msgElement) {
							const offsetTop = msgElement.offsetTop,
								offsetBottom = offsetTop + msgElement.offsetHeight;

							if (offsetBottom <= maxY) {
								readThisMsgIds.push(unreadMessagesId);
							}
						}
					});

					//info('scrollTo-call-from', 'readNextUnreadTaggedMessage');
					const scrollToIndex = this.props?.chat?.history
						.map(function (o: any) {
							return o.messageKey;
						})
						.indexOf(firstUnreadMsgId);
					this.scrollTo(scrollToIndex - 1, 0, 'readNextUnreadTaggedMessage');

					if (readThisMsgIds.length > 0) {
						await this.setReadMessagesByIds(readThisMsgIds);
					}
				}
			}
		}
	}
	_openFriendProfile(profileType: string, userId?: any) {
		if (profileType === 'chat') {
			this.props.history.replace('/profile-friend', {
				data: this.props.chat?.receiver,
				friendProfileActionType: EnumService.ProfileFriendActionTypes.ChatProfileView,
			});
		} else if (profileType === 'me') {
			this.props.history.replace('/profile', {
				shouldBack: true,
			});
		} else if (profileType === 'group' && userId) {
			let userProfile: any;
			this.props.chat.conversations.some((user: any) => {
				if (user?.userId?.toLowerCase() === userId?.toLowerCase()) {
					userProfile = user;
					return true;
				}
			});

			this.props.history.replace('/profile-friend', {
				data: userProfile,
				friendProfileActionType: EnumService.ProfileFriendActionTypes.ChatProfileView,
			});
		}
	}

	startSelectingMessages() {
		if (this.componentHasMounted) {
			this.setState({ selectingMessages: true });
		}
	}

	_selectAllMessages() {
		if (this.componentHasMounted && this.state.selectedMessages?.length !== this.props.chat?.history?.length) {
			this.setState({ selectedMessages: JSON.parse(JSON.stringify(this.props.chat?.history)) });
		}
	}

	_startForwardingMessages(message: any) {
		this.props.history.replace('/contacts/chat', {
			actionType: EnumService.ChatContactsPageActionType.ForwardMessage,
			chatDetail: message,
		});
	}

	checkBtnVisibility(scrollTop: number, scrollHeight: number, offsetHeight: number) {
		// const leaveSpaceFromBottomForVisible = (offsetHeight * 380) / 100;
		const leaveSpaceFromBottomForVisible = (offsetHeight * 100) / 100;
		const isChatContentScrollable = scrollHeight - 20 > offsetHeight;
		if (isChatContentScrollable && scrollTop + offsetHeight <= scrollHeight - leaveSpaceFromBottomForVisible) {
			if (!sharedService.isQuickChatAccessBtnVisible) {
				this.props.publishData({
					type: EnumService.PubSubEventType.QuickAccessBtnVisibility,
					data: { show: true },
				});
			}
		} else if (sharedService.isQuickChatAccessBtnVisible) {
			this.props.publishData({
				type: EnumService.PubSubEventType.QuickAccessBtnVisibility,
				data: { show: false },
			});
		}
	}

	handleFollowOutput(isAtBottom: Boolean) {
		if (isAtBottom) {
			return 'smooth';
		} else {
			return false;
		}
	}

	onPinturaEditingDone = (files: any, fullSizes: any) => {
		// encode the file using the FileReader API

		let resolutions: any = fullSizes.map((isFullSize: boolean) => (isFullSize ? 0 : 1024));

		this.setState({ showPhotoEditor: false }, () => {
			this.sendMedia(files, resolutions);
		});
	};

	handleItemsRendered = (e: any) => {
		//info(`Chat::virtuoso-ItemsRendered: this.firstItem is ${this.firstItem}, itemsRendered`, e);

		if (e.slice(-1)[0]?.index > this.firstItem) {
			this.firstItem = e.slice(-1)[0].index;
			this.virtuosoRef?.current?.scrollToIndex({
				index: this.firstItem,
				align: 'end',
				behavior: 'auto',
				offset: e.slice(-1)[0].offset,
			});
			//info(`Chat::handleItemsRendered: this.firstItem updated to ${this.firstItem}`);
		}
	};

	render() {
		// this is required in order to handle the user refreshing their screen when the chat is open,
		// or when the chat is directly referenced via a url
		if (!this.props.chat.receiver.jid || !this.props.chat.history) {
			if (!this.props.chat.receiver.jid) {
				this.props.chat.receiver = apiService.getConversations().then((_conversations: any) => _conversations.find((_conversation: any) => _.includes([_conversation.userId, _conversation.room], this.activeConversation)));
			} else {
				info(`Chat::render: should not be here.  history length is ${this.props.chat?.history?.length}`);
			}
		}

		this.activeConversation = getActiveConversation(this.props.chat.loggedInUser);

		return (
			<IonApp>
				{!this.props.chat?.receiver && this.hasMounted ? (
					<Redirect to="/auth" />
				) : (
					<IonPage className="chat-page">
						<div className="chat-top-header">
							<IonToolbar className="header-toolbar">
								<IonTitle className="ct-toolbar-title" onClick={() => this._openFriendProfile(this.props.chat?.type)}>
									{this.state.isChatSyncing || this.props.chat?.showLoading || !this.state.isNetworkConnectionOnline ? (
										<>
											<div className="synch-loader">
												<CustomSpinner />
												<IonLabel>{this.props?.chat?.loaderMessage || locale.global.loading}</IonLabel>
											</div>
										</>
									) : (
										<>
											<div className="main-title">{this.props.chat?.type === 'chat' || this.personalNotepad ? this.props.chat?.receiver?.alias || this.props.chat?.receiver?.name || this.props.chat?.receiver?.username || this.props.chat?.receiver?.userId : this.props.chat?.receiver.groupname}</div>
											{(this.props.chat?.type === 'chat' || this.personalNotepad) && <div className="profession">{this.props.chat?.receiver?.profession}</div>}
											{this.props.chat?.type === 'groupchat' && !this.personalNotepad && (
												<div className="profession">
													{this.props.chat?.receiver?.members?.length + ' '}
													{this.props.chat?.receiver?.members?.length > 1 ? 'members' : 'member'}
												</div>
											)}
										</>
									)}
								</IonTitle>

								<IonButtons slot="start">
									{this.state.selectingMessages ? (
										<IonButton className="select-all-btn" onClick={this._selectAllMessages}>
											{locale.dashboard.chat.select_all}
										</IonButton>
									) : (
										<IonButton
											className="ct-btn-back"
											onClick={(e) => {
												this.props.history.replace('/auth');
											}}
										>
											<IonIcon className="ct-icon-back" slot="icon-only" src={'./assets/icon/back.svg'} />
										</IonButton>
									)}
								</IonButtons>
								<IonButtons slot="end">
									{this.state.selectingMessages ? (
										<IonButton
											className="selection-cancel-btn"
											fill="clear"
											onClick={(event: any) => {
												event.persist();
												if (this.componentHasMounted) {
													this.setState({ selectingMessages: false, selectedMessages: [] });
												}
											}}
										>
											{locale.global.cancel}
										</IonButton>
									) : (
										<IonButton
											className="ct-btn-option"
											onClick={(event: any) => {
												if (this.personalNotepad) {
													this.props.history.replace('/profile', {
														shouldBack: true,
													});
												} else {
													event.persist();
													if (this.componentHasMounted) {
														this.setState({ showPopover: true, popoverEvent: event });
													}
												}
											}}
										>
											<IonIcon className="ct-icon-option" slot="icon-only" src={'./assets/icon/3doticon.svg'} />
										</IonButton>
									)}
								</IonButtons>
							</IonToolbar>
						</div>
						<IonContent id="chat-ion-content" scrollEvents={true}>
							{this.props.chat.receiver.jid && this.props.chat.history && this.props.chat.history.length > 0 ? (
								<>
									<Virtuoso
										/*logLevel={LogLevel.DEBUG}*/
										ref={this.virtuosoRef}
										className="virtualso"
										components={this.virtuosoComponents}
										followOutput={'smooth'}
										data={this.props.chat.history}
										atBottomStateChange={(bottom) => {
											if (!bottom) {
												//info('Chat::render: bottom = ', bottom);
											}
										}}
										initialTopMostItemIndex={this.firstItem}
										initialItemCount={this.props.chat.history.length}
										itemsRendered={this.handleItemsRendered}
										isScrolling={(status) => {
											// sharedService.isChatScrolling = status;
										}}
										onScroll={(event: any) => {
											/*if (event.target?.scrollTop === 0) {
													this.isAtBottom = true;
												} else {
													this.isAtBottom = false;
												}
												console.log(event);
												sharedService.isChatScrolling = true;
												if (timerRef !== null) {
													clearTimeout(timerRef);
												}
												timerRef = setTimeout(function () {
													sharedService.isChatScrolling = false;
												}, 500);

												const scrollTop = event.target?.scrollTop;
												const scrollHeight = event.target?.scrollHeight;
												const offsetHeight = event.target?.offsetHeight;

												this.virtualScrollOffsetHeight = offsetHeight;
												this.virtualScrollContentHeight = scrollHeight;
												this.virtualScrollOffsetTop = scrollTop;

												// info('Event scrollTop ', scrollTop);
												// info('Event scrollHeight ', scrollHeight);
												// info('Event offsetHeight', offsetHeight);

												if (this.state.unreadMessagesIds && this.state.unreadMessagesIds.length > 1) {
													this.state.unreadMessagesIds.forEach((unreadMessagesId: any) => {
														const unreadMessageElem = document.getElementById(unreadMessagesId);
														if (unreadMessageElem) {
															const unreadMessageElemTop = unreadMessageElem?.offsetTop + unreadMessageElem?.offsetHeight - offsetHeight;
															if (scrollTop >= unreadMessageElemTop) {
																if (!_.includes(this.readMessageStore, unreadMessagesId)) {
																	this.readMessageStore.push(unreadMessagesId);
																	if (this.hasMounted && !this.isManualScrolling) {
																		this.setReadMessagesByIds([unreadMessagesId]);
																	}
																}
															}
														}
													});
												}
												this.checkBtnVisibility(scrollTop, scrollHeight, offsetHeight);*/
										}}
										itemContent={(index) => {
											const message = this.props.chat.history[index];
											if (index === 0) {
												this.previousMessageTime = undefined;
											}
											//if (message && (message.body || message.messageBody)) {
											const sameElseDateTimeFormat: string = '(MMM D, YYYY) h:mm A',
												messageSentTime: string = message?.originalTimestamp
													? moment(message.originalTimestamp).calendar(null, {
															sameDay: 'h:mm A',
															lastDay: `[(${locale.dashboard.chat.yesterday}]) h:mm A`,
															lastWeek: '(dddd) h:mm A',
															sameElse: sameElseDateTimeFormat,
													  })
													: locale.dashboard.chat.recently,
												shouldShowTime: Boolean = this.previousMessageTime !== messageSentTime;

											this.previousMessageTime = messageSentTime;

											if (message.sender === 'Me') {
												this.receiverProfilePhoto = this.props.chat.loggedInUser?.profileThumb || this.props.chat.loggedInUser?.profilePhoto || blankProfilePic;
												this.senderId = this.props.chat.loggedInUser?.userId;
												this.receiverNameToDisplay = message.sender;
											} else if (this.props.chat?.type === 'groupchat') {
												let receiverProfileDetail: any = this.props.chat.conversations?.find((_conversation: any) => _conversation?.userId === this.props.chat?.receiver?.members?.find((_member: any) => _member.alias === message.sender)?.userId);
												this.receiverProfilePhoto = receiverProfileDetail?.profileThumb || receiverProfileDetail?.profilePhoto || blankProfilePic;
												this.receiverNameToDisplay = message.sender;
												this.senderId = receiverProfileDetail?.userId;
											} else {
												this.receiverProfilePhoto = this.props.chat?.receiver?.profileThumb || this.props.chat?.receiver?.profilePhoto || blankProfilePic;
												this.receiverNameToDisplay = this.props.chat?.receiver?.alias || this.props.chat?.receiver?.name || this.props.chat?.receiver?.username || this.props.chat?.receiver?.userId;
												this.senderId = this.props.chat?.receiver.userId;
											}

											if (!isBlank(message.linkPreview) && message.linkPreview !== false) {
												message.body = `<div class="preview-link-view"><a href="${message.linkPreview.url}" target="_blank"><img src="${message.linkPreview.image || message.linkPreview.logo}" /></a><div class="title">${message.linkPreview.title}</div><div class="url">${
													message.linkPreview.url.replace(/http?s:\/\//, '').split('/')[0]
												}</div></div>`;
											}

											return (
												<div key={message.messageKey} className="chat-item-container" id={message.messageKey}>
													{shouldShowTime && (
														<IonRow className="timestamp-row">
															<IonNote color="danger">
																<span dangerouslySetInnerHTML={{ __html: messageSentTime.replace('(', '<span class="date-day">').replace(')', '</span>') }}></span>
															</IonNote>
														</IonRow>
													)}

													<ThreadComponent
														self={this}
														type={this.props.chat?.type}
														myProfilePhoto={this.props.chat.loggedInUser?.profileThumb || this.props.chat.loggedInUser?.profilePhoto || blankProfilePic}
														receiverProfilePhoto={this.receiverProfilePhoto}
														selectingMessages={this.state.selectingMessages}
														selectedMessages={this.state.selectedMessages as []}
														pushToSelectedMessages={() => this.pushToSelectedMessages(message)}
														startSelectingMessages={this.startSelectingMessages}
														startForwardingMessages={() => this._startForwardingMessages(message)}
														actionHandler={(image: any, message: any) => this.previewActionHandler(image, message)}
														senderUserId={this.props.chat.loggedInUser?.userId}
														senderProfileName={this.receiverNameToDisplay}
														align={!this.personalNotepad && message.sender === 'Me' ? 'right' : 'left'}
														message={message}
														lastMessage={index > 0 ? this.props.chat.history[index - 1] : {}}
														onProfilePhotoPress={() => {
															this._openFriendProfile(message.sender === 'Me' ? 'me' : this.props.chat?.type, message?.sender);
														}}
														onReplyItemSelect={(repliedItemIndex: number) => {
															if (repliedItemIndex > 0) {
																this.scrollToIndex(repliedItemIndex - 1);
															} else {
																this.scrollToIndex(repliedItemIndex);
															}
														}}
													/>
												</div>
											);
											//}
											return <React.Fragment key={Math.random()}></React.Fragment>;
										}}
									/>

									{this.showQuickScrollComponent && this.isInitialDataLoadCompleted && !this.personalNotepad && this.props.chat.receiver.unreadMessages.length > 0 /*this.unreadThreadlist.length > 0*/ && (
										<UnreadMsgAccessBtnsView
											props={this.props}
											isNewMessageSent={this.isNewMessageSent}
											chatBoxHeight={this.state.chatBoxHeight}
											unreadMessagesIds={this.props.chat.receiver.unreadMessages} /*{this.unreadThreadlist}*/
											readNextUnreadMessage={async () => {
												await this.readNextUnreadMessage(0);
												this.props.chat.receiver.unreadMessages.splice(0, this.props.chat.receiver.unreadMessages.length - 1);
												//this.unreadThreadlist.splice(0, this.unreadThreadlist.length);
											}}
											readNextUnreadTaggedMessage={async () => {
												if (this.hasMounted && !this.isManualScrolling) {
													await this.readNextUnreadTaggedMessage(0);
												}
											}}
											// readUnreadMessage={async (messageId: any) => {
											// 	if (this.hasMounted && !this.isManualScrolling) {
											// 		await this.setReadMessagesByIds([messageId]);
											// 	}
											// }}
											readAllUnreadMessages={async () => {
												await this.setAllMessagesRead();
												this.props.chat.receiver.unreadMessages.splice(0, this.props.chat.receiver.unreadMessages.length - 1);
												//this.unreadThreadlist.splice(0, this.unreadThreadlist.length);

												// if (this.hasMounted) {
												// 	this.scrollTo(-1, 300, 'readAllUnreadMessages');
												// 	await this.setAllMessagesRead();
												//     //info('scrollTo-call-from', 'readAllUnreadMessages');
												// }
											}}
										/>
									)}
								</>
							) : !this.props.chat?.showLoading ? (
								<>
									<IonGrid className="chat-db-grid">
										<IonRow className="chat-db-row">
											<IonLabel color="light" style={{ textAlign: 'center' }}>
												<h1>{this.personalNotepad ? locale.chat.personal_chatpad : !this.hasMounted || this.conversationFetching ? locale.reducers.chat.no_message : this.props.chat?.emptyMessage}</h1>
												{this.state.showReload ? (
													<IonButton color="#000000" onClick={() => {}}>
														{locale.global.reload}{' '}
													</IonButton>
												) : null}
											</IonLabel>
										</IonRow>
									</IonGrid>
								</>
							) : null}
						</IonContent>

						{this.state.selectingMessages ? (
							<ChatSelectView
								count={this.state.selectedMessages?.length}
								onDelete={() => {
									if (this.componentHasMounted) {
										this.setState({ selectingMessages: false });
									}
								}}
								onForward={() => {
									if (this.componentHasMounted) {
										this.setState({ selectingMessages: false });
									}
								}}
							/>
						) : (
							<ChatInputBox
								myRef={(ref: any) => (this.chatInputBoxRef = ref)}
								props={this.props}
								self={this}
								allContacts={this.props.chat.conversations}
								loggedInUser={this.props.chat.loggedInUser}
								onMediaUpload={async (medias: any) => {
									if (this.componentHasMounted) {
										if (Array.isArray(medias)) {
											if (medias.length > this.maxMediaSelectLimit) {
												this.setState({ showAlert: true, alertMessage: `Select a Maximum of ${this.maxMediaSelectLimit} photos` });
											} else {
												this.setState({ showPhotoEditor: true, medias: medias });
											}
										} else {
											this.setState({ showPhotoEditor: true, medias: [medias] });
										}
									}
								}}
								onPasteImageFromClipboard={async (image: any) => {
									if (this.componentHasMounted) {
										this.setState({ showPhotoEditor: true, medias: [image] });
									}
								}}
								onProfilePhotoPress={(userId: any) => {
									this._openFriendProfile('group', userId);
								}}
								onChatBoxHeightChange={(chatBoxHeight: number) => {
									const virtualsoFooter = document.getElementById('virtualsoFooter');
									virtualsoFooter?.style.setProperty('height', chatBoxHeight + 8 + 'px');
									if (this.componentHasMounted) {
										this.setState({ chatBoxHeight: chatBoxHeight });
									}
								}}
								onMessageSent={() => {
									this.isNewMessageSent = true;
								}}
							/>
						)}
					</IonPage>
				)}

				<DropdownPopover
					showPopover={this.state.showPopover}
					list={this.props.chat?.type === 'chat' ? this.chatHeaderMenuOption : this.groupHeaderMenuOption}
					popoverEvent={this.state.popoverEvent}
					onItemSelect={(item: any) => {
						if (this.componentHasMounted) {
							this.setState({ showPopover: false, popoverEvent: undefined });
							switch (item.type) {
								case 'createGroup':
									this.props.history.push('/contacts/add-members', {
										actionType: EnumService.AddMembersPageActionType.ChooseContactForGroup,
										defaultGroupMember: this.props.chat?.receiver,
									});
									break;
								case 'groupInfo':
									this.props.history.replace('/group-info', {
										groupDetail: this.props.chat?.receiver,
									});
									break;
								case 'favorite':
									this.setState({ showFavoriteSearchModal: true });
									break;
								case 'todos':
									break;
								case 'tagged':
									break;
								case 'groupSettings':
									this.props.history.replace('/group-setting', {
										groupDetail: this.props.chat?.receiver,
									});
									break;

								default:
									break;
							}
						}
					}}
				/>

				<FavoriteSearchModal
					show={this.state.showFavoriteSearchModal}
					onClose={() => {
						if (this.componentHasMounted) {
							this.setState({ showFavoriteSearchModal: false });
						}
					}}
				/>

				<PhotoPreviewModal
					onViewAllMediaClick={() => {
						if (this.componentHasMounted) {
							this.setState({ openPreview: false, selectedImage: null, selectedMediaMessageKey: '' });
							this.props.history.push('/chat-medias');
						}
					}}
					selectedMediaMessageKey={this.state.selectedMediaMessageKey}
					props={this.props}
					show={this.state.openPreview}
					image={this.state.selectedImage}
					onClose={() => this.setImagePreview()}
					// onCancel={() => this.cancelUpload()}
				/>

				<VideoPreviewModal
					onViewAllMediaClick={() => {
						if (this.componentHasMounted) {
							this.setState({ openVideoPreview: false, selectedVideo: null, selectedVideoMessageKey: '' });
							this.props.history.push('/chat-medias');
						}
					}}
					selectedMediaMessageKey={this.state.selectedVideoMessageKey}
					props={this.props}
					show={this.state.openVideoPreview}
					image={this.state.selectedVideo}
					onClose={() => {
						if (this.componentHasMounted) {
							this.setState({ openVideoPreview: false, selectedVideoMessageKey: '', selectedVideo: null });
						}
					}}
					// onCancel={() => this.cancelUpload()}
				/>

				{/* <IonLoading
					isOpen={this.props.chat?.showLoading}
					onDidDismiss={() => store.dispatch({ type: CHAT_LOADING, payload: { showLoading: false, loaderMessage: 'Please wait...' } })}
					message={this.props?.chat?.loaderMessage}
				/> */}

				<MultipleImageEditor
					openEditorType={OpenEditorType.FOR_SEND_IMAGE}
					medias={this.state.medias}
					show={this.state.showPhotoEditor}
					onClose={() => {
						if (this.componentHasMounted) {
							this.setState({ showPhotoEditor: false, medias: null });
						}
					}}
					onSave={this.onPinturaEditingDone}
				/>

				<IonAlert
					mode="ios"
					isOpen={this.state.showAlert}
					onDidDismiss={() => this.setState({ showAlert: false, alertMessage: '' })}
					cssClass="alert-controller-class"
					message={this.state.alertMessage}
					buttons={[
						{
							text: 'OK',
							role: 'destructive',
						},
					]}
				/>
			</IonApp>
		);
	}
}

interface iProps extends RouteComponentProps<{ name: string }> {
	pubsub: any;
	resetPublishData: any;
	publishData: any;
	reloadChat: any;
	receiver: any;
	type: String;
	chat: any;
	history: any;
	loggedInUser: any;
	emptyMessage: string;
	errorMessage: string;
}

interface iState {
	selectedVideo: any;
	selectedVideoMessageKey: any;
	openVideoPreview: boolean;
	unreadMessagesIds: any;
	receiver: any;
	openPreview: boolean;
	showPhotoEditor: boolean;
	//: boolean;
	showPopover: boolean;
	message: String;
	dynamicClass: boolean;
	showFavoriteSearchModal: boolean;
	captionMessage: String;
	selectedImage: any;
	medias: any;
	selectedMediaMessageKey: any;
	popoverEvent: any;
	selectingMessages: boolean;
	showReload: boolean;
	selectedMessages: any[];
	children: any;
	chatBoxHeight: number;
	shouldShowQuickAccessBtn: Boolean;
	isChatSyncing: Boolean;
	isNetworkConnectionOnline: Boolean;
	showAlert: boolean;
	alertMessage: string;
}

const mapStateToProps = (state: any) => {
	//info('mapStateToProps---', state.chat);
	return {
		pubsub: state.pubsub,
		chat: state?.chat,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	searchContacts: (payload: String) => dispatch(searchContacts(payload)),
	publishData: (payload: String) => dispatch(publishData(payload)),
	reloadChat: (payload: any) => dispatch(reloadChat(payload)),
	resetPublishData: () => dispatch(resetPublishData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
