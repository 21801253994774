import React, { useCallback, useMemo } from 'react';
import './style.scss';
import { locale } from '../../../../locales/local';
import { info } from '../../../../helpers/common';
import ChatIconTextButton from '../../../../components/ChatIconTextButton';

interface iProps {
	props: any;
	onTriggerFileUpload: any;
	onTriggerPhotoCapture?: any;
}

export const ChatOtherOptions: React.FC<iProps> = ({ props, onTriggerFileUpload, onTriggerPhotoCapture }) => {
	const options = useMemo(
		() => [
			{ title: locale.chat.other.file, icon: 'files-icon.svg', type: 'file', width: '27.62px', height: '24px' },
			{ title: locale.chat.other.call, icon: 'video-icon.svg', type: 'call', width: '32.5px', height: '20px' },
			{ title: locale.chat.other.location, icon: 'location-icon.svg', type: 'location', width: '20.8px', height: '26px' },
			{ title: locale.chat.other.camera, icon: 'chat-camera-icon.svg', type: 'camera', width: '27px', height: '24px' },
			{ title: locale.chat.other.contact, icon: 'contact-icon.svg', type: 'contact', width: '23.36px', height: '24px' },
			{ title: locale.chat.other.transfer, icon: 'transfer-icon.svg', type: 'transfer', width: '30.55px', height: '24px' },
			{ title: locale.chat.other.voiceinput, icon: 'chat-mic-icon.svg', type: 'voiceinput', width: '15.97px', height: '26px' },
			{ title: locale.chat.other.favorites, icon: 'favorite-icon.svg', type: 'favorite', width: '27px', height: '24px' },
		],
		[]
	);

	const onOptionSelect = useCallback(
		(selectionType) => {
			info('selectionType', selectionType);
			switch (selectionType) {
				case 'contact':
					break;
				case 'call':
					break;
				case 'location':
					break;
				case 'file':
					onTriggerFileUpload && onTriggerFileUpload();
					break;
				case 'voiceinput':
					break;
				case 'camera':
					onTriggerPhotoCapture && onTriggerPhotoCapture();
					break;
				case 'transfer':
					break;
				case 'favorite':
					break;

				default:
					break;
			}
		},
		[onTriggerFileUpload, onTriggerPhotoCapture]
	);

	return (
		<div className="chat-other-options" onClick={(event) => event.stopPropagation()}>
			{options.map((option, key) => {
				return (
					<ChatIconTextButton
						key={key}
						icon={option.icon}
						title={option.title}
						onClick={() => onOptionSelect(option.type)}
						containerStyle={{ width: '25%', margin: '0 !important', marginTop: '0px', marginBottom: '10px' }}
						iconStyle={{ width: option.width || '24px', height: option.height || '24px' }}
						labelStyle={{ marginTop: 0 }}
					/>
				);
			})}
		</div>
	);
};
