import React from 'react';
import { IonButton, IonContent, IonList, IonIcon, IonInput, IonItemDivider, IonLabel, IonItem, IonToolbar, IonThumbnail, IonImg, IonPage, IonFooter, IonButtons, IonToggle, IonNote, IonLoading, IonActionSheet } from '@ionic/react';
import './GroupManager.scss';
import { sortByAlias } from '../../../helpers/common';
import { Config } from '../../../config/config';
import { connect } from 'react-redux';
import { apiService } from '../../../services/apiService';
import { addGroup } from '../../../redux/actions/dashboard';
import { resetValues } from '../../../redux/actions/auth';
import store from '../../../redux/store';
import { initChat } from '../../../redux/actions/chat';
import { DASHBOARD_LAST_MESSAGE_CLEAR } from '../../../redux/constants/dashboard';
import { SelectedContacts } from '../../contacts/components/SelectedContacts';
import { TopNavbar } from '../../common/header/topbar';
import ImageEditor, { OpenEditorType } from '../../../modals/ImageEditor/ImageEditor';
import EnumService from '../../../services/enumService';
import { locale } from '../../../locales/local';
import GroupAboutModal from '../../../modals/GroupAboutModal/GroupAboutModal';
import MyAliasInGroupPopup from '../../../modals/MyAliasInGroupPopup/MyAliasInGroupPopup';
import CameraService from '../../../services/cameraService';
import ContactItem from '../../../components/ContactItems/ContactItem';
import AddContactItem from '../../../components/ContactItems/AddContactItem';
import RemoveContactItem from '../../../components/ContactItems/RemoveContactItem';
import { sharedService } from '../../../services/sharedService';

const BlankUserProfile = './assets/img/blank-user-profile.png';
const ArrowRightIcon = './assets/icon/arrow-right.svg';

interface iState {
	aliasInGroup: string;
	groupDescription: string;
	selectedGroupPhoto: any;
	myAliasGroupPhoto: any;
	hasError: boolean;
	allContacts: any;
	errorMessage: string;
	selectedContacts: any;
	selectedContactIds?: any;
	contacts: any;
	loggedInUser?: any;
	groupName: string;
	selectedImage?: any;
	showPinturaEditor: boolean;
	showPinturaEditorFor: any;
	oldLayout: boolean;
	canDisplayQRCode: boolean;
	showMoreMembersModal: boolean;
	adminApproval: boolean;
	canInvite: boolean;
	showGroupAboutModal: boolean;
	aboutGroupValue?: string;
	showMyAliasInGroupPopup: boolean;
	myAliasInGroupValue?: string;
	openActionSheetFor?: string;
}

interface iProps {
	self: any;
	loggedInUser: any;
	history: any;
	contacts: any;
	actionHandler: Function;
	closeHandler: Function;
	addGroup: Function;
	resetValues: Function;
	initChat: Function;
	location: any;
	dashboard: any;
}

class GroupManager extends React.Component<iProps, iState> {
	limitShowContactOnThisPage = 8;

	cameraService = new CameraService();

	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);
		const locationState = props.location.state,
			selectedContacts = locationState?.selectedContacts || [],
			selectedContactIds = locationState?.selectedContactIds || [];

		/*let user: any, contacts: any;

		apiService.me().then((_user: any) => (user = _user));
		apiService.getContacts(false, 'confirmed').then((_contacts: any) => (contacts = _contacts));*/

		this.state = {
			loggedInUser: {},//user,
			showPinturaEditor: false,
			showPinturaEditorFor: '',
			showMoreMembersModal: false,
			showGroupAboutModal: false,
			canDisplayQRCode: false,
			adminApproval: false,
			canInvite: false,
			showMyAliasInGroupPopup: false,
			myAliasInGroupValue: '',
			aliasInGroup: '',
			groupName: '',
			aboutGroupValue: '',
			groupDescription: '',
			selectedGroupPhoto: '',
			myAliasGroupPhoto: '',
			hasError: false,
			oldLayout: false,
			allContacts: [],
			contacts: [],//contacts,
			errorMessage: '',
			selectedContacts: selectedContacts,
			selectedContactIds: selectedContactIds,
			openActionSheetFor: '',
		};

		this.props.resetValues();
	}

	async setPageData() {
		let user: any = await apiService.me(),
			contacts: any = user.contacts.filter((_contact: any) => _contact.status === 'confirmed').sort(sortByAlias);/*
			contacts: any = await apiService.getContacts(false, 'confirmed').then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});*/

		let selectedContactIds = this.state.selectedContactIds,
			selectedContacts = this.state.selectedContacts;

		if (sharedService.callBackData.groupManager) {
			if (sharedService.callBackData.groupManager.groupAllContacts) {
				selectedContacts = sharedService.callBackData.groupManager.groupAllContacts;
			}
			if (sharedService.callBackData.groupManager.groupAllContactIds) {
				selectedContactIds = sharedService.callBackData.groupManager.groupAllContactIds;
			}
		}

		this.setState({
			loggedInUser: user,
			contacts: contacts,
			allContacts: contacts,
			aliasInGroup: user.alias,
			myAliasInGroupValue: user?.username,
			selectedContacts,
			selectedContactIds,
		});
	}

	async componentDidMount() {
		await this.setPageData();
		this.componentIsMounted = true;
	}

	componentWillUnmount() {
		sharedService.callBackData.groupManager = null;
	}

	async componentDidUpdate() {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
			this.componentIsMounted = true;
		}
	}

	openGroupChat(data: any) {
		data.handlerText = locale.global.start_chat;
		this.props.initChat(data);
		store.dispatch({ type: DASHBOARD_LAST_MESSAGE_CLEAR, payload: data });
		this.props.history.replace(data.room ? `/chat/${data.room}` : data.userId ? `/chat/${data.room}` : '/');
	}

	_createGroup = () => {
		if (this.state.selectedContacts && this.state.selectedContacts?.length > 0) {
			const payload = {
				groupname: this.state.groupName,
				groupDescription: this.state.groupDescription,
				groupPhoto: this.state.selectedGroupPhoto,
				members: this.state.selectedContacts,
				canDisplayQRCode: this.state.canDisplayQRCode,
				canInvite: this.state.canInvite,
				history: this.props.history,
			};
			this.props.addGroup(payload);
		}
	};

	toggleSelectedContact = (index: any) => {
		let allContacts = this.state.allContacts,
			selectedContacts = this.state.selectedContacts;

		allContacts[index].isSelected = !this.state.allContacts[index].isSelected;

		if (selectedContacts.hasOwnProperty(this.state.contacts[index]._id)) {
			delete selectedContacts[this.state.contacts[index]._id];
		} else {
			selectedContacts[this.state.contacts[index]._id] = {
				_id: this.state.contacts[index]._id,
				userId: this.state.contacts[index].userId,
				username: this.state.contacts[index].username,
				jid: `${this.state.contacts[index].userId}@${Config.xmppServer}`,
				admin: false,
			};
		}
		this.setState({
			allContacts: [...allContacts],
			selectedContacts: selectedContacts,
		});
	};

	_onPinturaEditingDone = (file: any) => {
		// encode the file using the FileReader API
		const reader = new FileReader();
		reader.onloadend = async () => {
			// use a regex to remove data url part
			const base64String = reader.result as string;
			base64String.replace('data:', '').replace(/^.+,/, '');
			// log to console

			if (this.state.showPinturaEditorFor === 'group-photo') {
				this.setState({
					showPinturaEditor: false,
					showPinturaEditorFor: '',
					selectedGroupPhoto: base64String,
				});
			} else if (this.state.showPinturaEditorFor === 'alias-photo') {
				this.setState({
					showPinturaEditor: false,
					showPinturaEditorFor: '',
					myAliasGroupPhoto: base64String,
				});
			}
		};

		reader.readAsDataURL(file);
	};

	_renderSwitchItem = (title: any, value: any, onChange: any) => {
		return (
			<IonItem button lines="none" className={'swtich-item'}>
				<IonLabel slot="start">
					<p>{title}</p>
				</IonLabel>
				<IonToggle slot="end" className="toggle-customised" mode="ios" value={value} onChange={onChange} />
			</IonItem>
		);
	};

	extractContactName = (contact: any) => {
		return contact?.alias || contact?.username || contact?.userId || '';
	};

	_getActionsheetOptions = () => {
		const { openActionSheetFor } = this.state;

		const onPhotoAddedCallback = (photo: any) => {
			this.setState({
				showPinturaEditor: true,
				showPinturaEditorFor: openActionSheetFor,
				selectedImage: photo,
			});
		};
		const takePhoto = {
			text: locale.profile.upload_photo.take_photo,
			handler: () => {
				this.cameraService.capturePhotoFromCamera(onPhotoAddedCallback);
			},
		};

		const chooseFromAlbum = {
			text: locale.profile.upload_photo.choose_album,
			handler: () => {
				this.cameraService.choosePhotoFromAlbum(onPhotoAddedCallback);
			},
		};

		const cancelPhoto = {
			text: locale.global.cancel,
			handler: () => {
				this.setState({ openActionSheetFor: '' });
			},
		};

		if (openActionSheetFor === 'group-photo' || openActionSheetFor === 'alias-photo') {
			return [takePhoto, chooseFromAlbum, cancelPhoto];
		}
		return [];
	};

	render() {
		if (this.componentIsMounted) {
			const { selectedContacts, groupName, selectedGroupPhoto } = this.state;

			return (
				<IonPage className="group-manager-page">
					<TopNavbar
						{...this.props}
						rightButtonDisabled={!this.state.groupName}
						rightButtonText="Create"
						onRightButtonPress={() => {
							// this.setState({
							// 	showSearchContactsModal: true,
							// });
						}}
						pageTitle={locale.groups.new_group}
						pageTitleDescription={this.state.selectedContacts?.length > 0 ? this.state.selectedContacts?.length + ' members' : '# of members'}
						showBack={true}
						hideSearchBar={true}
					/>

					<IonContent className="groupCustomCard has-topbar">
						<div className="fields-list">
							<IonItem lines="none" className="groupname-item">
								<IonThumbnail
									slot="start"
									onClick={() => {
										this.setState({ openActionSheetFor: 'group-photo' });
									}}
								>
									{selectedGroupPhoto ? <IonImg src={selectedGroupPhoto} /> : <IonImg className="placeholder" src={'./assets/icon/camera.svg'} />}
								</IonThumbnail>

								<IonInput value={groupName} onIonChange={(e) => this.setState({ groupName: e.detail.value! })} placeholder="Please specify Group Name" />
							</IonItem>

							<div className="group-field-item">
								<div className="row-first">
									<div className="title">About</div>
									{!this.state.aboutGroupValue && (
										<IonButton className="value" fill="clear" onClick={() => this.setState({ showGroupAboutModal: true })}>
											<span className="placeholder">{locale.groups.not_set}</span>
											<IonIcon src={ArrowRightIcon} />
										</IonButton>
									)}
								</div>
								{this.state.aboutGroupValue && (
									<div className="row-second" style={{ marginBottom: '14px' }}>
										<div className="title" style={{ maxWidth: 'unset' }}>
											{this.state.aboutGroupValue}
										</div>
										<div className="arrow-adjust-view">
											<IonButton fill="clear" style={{ height: '24px' }} className="value" onClick={() => this.setState({ showGroupAboutModal: true })}>
												<IonIcon src={ArrowRightIcon} />
											</IonButton>
										</div>
									</div>
								)}
							</div>

							<div className="group-field-item">
								<div className="row-first">
									<div className="title">My Alias Photo</div>
									<IonButton className="value" fill="clear" onClick={() => this.setState({ openActionSheetFor: 'alias-photo' })}>
										<img src={this.state.myAliasGroupPhoto ? this.state.myAliasGroupPhoto : BlankUserProfile} alt="profilePhoto" />
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
							</div>

							<div className="group-field-item">
								<div className="row-first">
									<div className="title">My Alias</div>
									<IonButton className="value" fill="clear" onClick={() => this.setState({ showMyAliasInGroupPopup: true })}>
										{this.state.myAliasInGroupValue ? <span className="data">{this.state.myAliasInGroupValue}</span> : <span className="placeholder">{locale.groups.not_set}</span>}
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
							</div>

							<div className="group-field-item" style={{ minHeight: '67px' }}>
								<div className="row-first">
									<div className="title">Set Tags</div>
									<IonButton
										className="value"
										fill="clear"
										onClick={() => {
											this.props.history.push('/tags', { shouldSelectTag: true });
										}}
									>
										<span className="placeholder">{locale.groups.not_set}</span>
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
								<div className="row-second">
									<div className="title">Classify Groups by Tags</div>
								</div>
							</div>

							<div className="group-field-item" style={{ minHeight: '67px' }}>
								<div className="row-first">
									<div className="title">Guidelines</div>
									<IonButton className="value" fill="clear">
										<span className="placeholder">{locale.groups.not_set}</span>
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
								<div className="row-second">
									<div className="title">Rules, Agreements, Password protections, and more</div>
								</div>
							</div>

							<div className="group-field-item" style={{ minHeight: '99px' }}>
								<div className="row-first">
									<div className="title">Admin Approval</div>
									<div className="value">
										<IonToggle
											slot="end"
											className="toggle-customised"
											mode="ios"
											checked={this.state.adminApproval}
											onChange={() => {
												this.setState({ adminApproval: !this.state.adminApproval });
											}}
										/>
									</div>
								</div>
								<div className="row-second">
									<div className="title">Group members need group owner or admin approval before they can invite friends. Option to scan QR codes to join the group will be disabled.</div>
								</div>
							</div>
						</div>

						<IonItemDivider className="group-members-devider">
							<IonLabel>{locale.groups.members}</IonLabel>
							{this.state.selectedContacts?.length > this.limitShowContactOnThisPage && (
								<div
									slot="end"
									className="view-more-contact-btn-view"
									onClick={() => {
										this.props.history.push('/group-members', {
											groupAllContacts: this.state.selectedContacts,
											groupAllContactIds: this.state.selectedContactIds,
										});
									}}
								>
									<div className="title">View More</div>
									<IonIcon src={ArrowRightIcon}></IonIcon>
								</div>
							)}
						</IonItemDivider>

						<div className="selected-contacts-container">
							{this.state.selectedContacts &&
								this.state.selectedContacts.map((_contact: any, key: any) => {
									if (key >= this.limitShowContactOnThisPage) {
										return null;
									}
									return <ContactItem key={key} contact={_contact} onClick={() => {}} />;
								})}

							<AddContactItem
								onClick={() => {
									this.props.history.push('/contacts/add-members', {
										actionType: EnumService.AddMembersPageActionType.ChooseContactForCurrentGroup,
										previousSelectedContactsIds: this.state.selectedContactIds,
										previousSelectedContacts: this.state.selectedContacts,
										fromPageKey: 'groupManager',
									});
								}}
							/>
							<RemoveContactItem
								onClick={() => {
									this.props.history.push('/group-members', {
										isDeleteMode: true,
										groupAllContacts: this.state.selectedContacts,
										groupAllContactIds: this.state.selectedContactIds,
									});
								}}
							/>
						</div>
					</IonContent>

					<div className="encryption-view">
						<h3>Encryption</h3>
						<p>All communication within this group chat is end-to-end encrypted</p>
					</div>

					<IonLoading isOpen={this.props.dashboard.isLoading} message={this.props.dashboard.loaderMessage} />

					<ImageEditor
						openEditorType={OpenEditorType.FOR_PROFILE_GROUP_PHOTO}
						selectedImage={this.state.selectedImage}
						show={this.state.showPinturaEditor}
						onClose={() => {
							this.setState({ showPinturaEditor: false, showPinturaEditorFor: '' });
						}}
						onSave={this._onPinturaEditingDone}
					/>

					<GroupAboutModal
						props={this.props}
						initialValue={this.state.aboutGroupValue}
						show={this.state.showGroupAboutModal}
						onCloseCallBack={() => this.setState({ showGroupAboutModal: false })}
						onConfirm={(value: string) => {
							this.setState({ aboutGroupValue: value, showGroupAboutModal: false });
						}}
					/>

					<MyAliasInGroupPopup
						initialValue={this.state.myAliasInGroupValue}
						show={this.state.showMyAliasInGroupPopup}
						onCloseCallBack={() => this.setState({ showMyAliasInGroupPopup: false })}
						onConfirm={(value: string) => {
							this.setState({ showMyAliasInGroupPopup: false, myAliasInGroupValue: value });
						}}
					/>

					<IonActionSheet
						header={this.state.openActionSheetFor === 'group-photo' ? 'Add Group Photo' : 'Add Alias Photo'}
						mode="ios"
						isOpen={this.state.openActionSheetFor !== ''}
						onDidDismiss={() => this.setState({ openActionSheetFor: '' })}
						cssClass="group-manager-action-sheet"
						buttons={this._getActionsheetOptions()}
					></IonActionSheet>
				</IonPage>
			);
		} else {
			return <></>;
		}
	}
}
const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	addGroup: (payload: any) => dispatch(addGroup(payload)),
	initChat: (payload: any) => dispatch(initChat(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupManager);
