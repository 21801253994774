import { IonAlert, IonButton, IonIcon, IonPage } from '@ionic/react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { PhotoPreviewModal } from '../../../modals/PhotoPreviewModal';
import EnumService from '../../../services/enumService';
import { sharedService } from '../../../services/sharedService';
import { TopNavbar } from '../../common/header/topbar';
import './Chatmedias.scss';

const CheckboxChecked = './assets/img/checkbox-checked.svg';
const CheckboxUnChecked = './assets/img/checkbox-unchecked.svg';
const NotepadLight = './assets/icon/notepad-light.svg';
const TrashLight = './assets/icon/trash-light.svg';
const BookmarkLight = './assets/icon/bookmark-light.svg';
const ForwardLight = './assets/icon/forward-light.svg';
const DownloadLight = './assets/icon/download-light.svg';

interface iProps {
	history: any;
	chat: any;
}

interface iState {
	openVideoPreview: boolean;
	selectedVideo: any;
	selectedVideoMessageKey: any;
	isSelectMode: boolean;
	selectedMediaIds: any;
	medias: any;
	showAlert: boolean;
	selectedMediaMessageKey: any;
	openPreview: boolean;
	selectedImage: any;
}

class ChatMedias extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);

		//Filter
		const list = props.chat.history.filter((item: any) => item?.messageType === EnumService.ChatMessageType.MEDIA && (item?.mediaType === EnumService.ChatMediaType.IMAGE || item?.mediaType === EnumService.ChatMediaType.VIDEO || item?.mediaType === EnumService.ChatMediaType.THUMBNAIL));

		const thisWeekStartDate = moment().startOf('week');
		const thisMonthStartDate = moment().startOf('month');
		const medias: any = {};
		const thisWeekLabel = 'This Week';
		const thisMonthLabel = 'This Month';

		list.forEach((message: any) => {
			const mediaItemDate = moment(message.timestamp);
			let monthDeviderLabel = '';
			if (mediaItemDate.diff(thisWeekStartDate, 'day') > 0) {
				monthDeviderLabel = thisWeekLabel;
			} else if (mediaItemDate.format('MM-YYYY') === thisMonthStartDate.format('MM-YYYY')) {
				monthDeviderLabel = thisMonthLabel;
			} else {
				if (mediaItemDate.format('YYYY') === moment().format('YYYY')) {
					monthDeviderLabel = mediaItemDate.format('MMMM');
				} else {
					monthDeviderLabel = mediaItemDate.format('MMMM YYYY');
				}
			}

			if (!medias[monthDeviderLabel]) {
				medias[monthDeviderLabel] = [];
			}
			medias[monthDeviderLabel].push(message);
		});

		this.state = {
			openVideoPreview: false,
			selectedVideo: null,
			selectedVideoMessageKey: '',
			isSelectMode: false,
			selectedMediaIds: [],
			showAlert: false,
			medias: medias,
			selectedMediaMessageKey: '',
			openPreview: false,
			selectedImage: null,
		};
	}

	render() {
		const { isSelectMode, selectedMediaIds, medias } = this.state;

		return (
			<IonPage className="chat-medias-page">
				<TopNavbar
					{...this.props}
					pageTitle={isSelectMode ? `Select Media${selectedMediaIds.length > 0 ? ` (${selectedMediaIds.length})` : ''}` : 'Media'}
					onRightButtonPress={() => {
						if (isSelectMode) {
							this.setState({ isSelectMode: false, selectedMediaIds: [] });
						} else {
							this.setState({ isSelectMode: true });
						}
					}}
					hideSearchBar={true}
					rightButtonText={isSelectMode ? 'Cancel' : 'Select'}
					showBack={true}
					onLeftButtonPress={() => {
						this.props.history.goBack();
					}}
					searchTypeHandler={() => {}}
				/>
				<div className="container has-topbar" style={{ paddingBottom: isSelectMode ? '94px' : '10px' }}>
					{Object.keys(medias).map((dateGroupName: any, key: any) => {
						const list = medias[dateGroupName];
						return (
							<React.Fragment key={key}>
								<div className="date-devider">{dateGroupName}</div>
								<div className="media-grid">
									{list.map((message: any, key1: any) => {
										let media: any = null;
										if (Array.isArray(message.mediaUrl) && message.mediaUrl.length > 0) {
											media = message.mediaUrl[0];
										} else if (Array.isArray(message.mediaThumbnail) && message.mediaThumbnail.length > 0) {
											media = message.mediaThumbnail[0];
										}

										return (
											<div
												className="grid-item"
												key={key1}
												onClick={() => {
													if (isSelectMode) {
														if (selectedMediaIds.indexOf(message.messageKey) !== -1) {
															selectedMediaIds.splice(selectedMediaIds.indexOf(message.messageKey), 1);
														} else {
															if (selectedMediaIds.length >= sharedService.maxMediaSelectLimit) {
																this.setState({ showAlert: true });
															} else {
																selectedMediaIds.push(message.messageKey);
															}
														}
														this.setState({ selectedMediaIds });
													} else {
														if (message.messageType === EnumService.ChatMediaType.VIDEO) {
															this.setState({ openVideoPreview: true, selectedVideo: media, selectedVideoMessageKey: message.messageKey });
														} else {
															this.setState({ openPreview: true, selectedImage: media, selectedMediaMessageKey: message.messageKey });
														}
													}
												}}
											>
												<img src={media} alt="media" />
												{isSelectMode && (
													<IonButton fill="clear" className="select-checkbox" slot="icon-only">
														<IonIcon src={selectedMediaIds.indexOf(message.messageKey) !== -1 ? CheckboxChecked : CheckboxUnChecked} />
													</IonButton>
												)}
											</div>
										);
									})}
								</div>
							</React.Fragment>
						);
					})}
				</div>

				{isSelectMode && (
					<div className="footer-view">
						<div className="btns">
							<IonButton fill="clear" slot="icon-only">
								<IonIcon style={{ width: '16.17px', height: '19px' }} src={NotepadLight}></IonIcon>
							</IonButton>
							<IonButton fill="clear" slot="icon-only">
								<IonIcon style={{ width: '18.24px', height: '19px' }} src={TrashLight}></IonIcon>
							</IonButton>
							<IonButton fill="clear" slot="icon-only">
								<IonIcon style={{ width: '14.77px', height: '19px' }} src={BookmarkLight}></IonIcon>
							</IonButton>
							<IonButton fill="clear" slot="icon-only">
								<IonIcon style={{ width: '20.88px', height: '19px' }} src={ForwardLight}></IonIcon>
							</IonButton>
							<IonButton fill="clear" slot="icon-only">
								<IonIcon style={{ width: '19px', height: '23.96px' }} src={DownloadLight}></IonIcon>
							</IonButton>
						</div>
					</div>
				)}

				<IonAlert
					mode="ios"
					isOpen={this.state.showAlert}
					onDidDismiss={() => this.setState({ showAlert: false })}
					cssClass="alert-controller-class"
					message={`Select a Maximum of ${sharedService.maxMediaSelectLimit} photos`}
					buttons={[
						{
							text: 'OK',
							role: 'destructive',
						},
					]}
				/>

				<PhotoPreviewModal
					onViewAllMediaClick={() => {
						this.setState({ openPreview: false, selectedImage: null, selectedMediaMessageKey: '' });
						this.props.history.replace('/chat-medias');
					}}
					selectedMediaMessageKey={this.state.selectedMediaMessageKey}
					props={this.props}
					show={this.state.openPreview}
					image={this.state.selectedImage}
					onClose={() => {
						this.setState({ selectedMediaMessageKey: '', openPreview: false, selectedImage: null });
					}}
				/>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		chat: state?.chat,
	};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChatMedias);
