import React, { createRef } from 'react';
import { IonContent, IonList, IonItem, IonApp, IonButton, IonLabel, IonGrid, IonRow, IonModal, IonToast, IonRefresher, IonRefresherContent } from '@ionic/react';
import { Redirect, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { addGroup, pageLoading, resetValues, searchContacts } from '../../redux/actions/dashboard';
import { initChat } from '../../redux/actions/chat';
import { addContact } from '../../redux/actions/addContact';
import store from '../../redux/store';
import { CHAT_RESET, CHAT_INIT } from '../../redux/constants/chat';
import './style.scss';
import { fetchProfilePic, validateEmail, validatePhone, info, getInternalStorage, isBlank } from '../../helpers/common';
import { TopNavbar } from '../common/header/topbar';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { apiService } from '../../services/apiService';
import ConversationItem from './components/ConversationItem';
import ConversationProfilePhoto from './components/ConversationProfilePhoto';
import { locale } from '../../locales/local';
import { xmpp } from '../../services/xmpp';
import { DASHBOARD_INIT, DASHBOARD_SEARCH_END, DASHBOARD_SEARCH_REQUESTED, DASHBOARD_SEARCH_SUCCESS, DASHBOARD_SEARCH_SUCCESS_WITH_LOADING, DASHBOARD_SHOW_GROUP_MODAL, DASHBOARD_SHOW_MODAL, DASHBOARD_SHOW_TOAST } from '../../redux/constants/dashboard';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline } from 'ionicons/icons';

const BasketIcon = './assets/icon/notepad-icon.svg';

class Dashboard extends React.Component<iProps, iState> {
	cookies = getInternalStorage();
	ionSlidesRef: any = createRef();
	conversations: any = createRef();
	componentIsMounted: Boolean = false;
	componentIsUpdating: Boolean = true;
	messagesRefreshing: Boolean = false;
	loggedInUser: any = {};

	constructor(props: iProps) {
		super(props);

		if (!this.cookies.uuid) {
			this.props.dashboard.history.goBack();
		}

		this.state = {
			...this.props.dashboard,
			searchText: '',
			showPopover: false,
			modal: {},
			actionHandler: () => {},
			chatTags: [{ name: 'All' }, { name: 'Family' }, { name: 'Creatives' }, { name: 'Design Hijack' }],
			selectedChatTag: 'All',
		};
	}

	async setPageData() {
		let user = await apiService.me();

		if (xmpp.messagesLoaded && !xmpp.isReady) {
			info(`Dashboard::setPageData: Calling xmpp.xmppManager because xmpp.isReady is ${xmpp.isReady}`);
			await xmpp.xmppManager();
		}

		if (!xmpp.messagesLoaded) {
			user = await apiService.refreshMessages();
		}

		if (isBlank(this.props.dashboard?.conversations)) {
			store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });
		}

		if (!this.messagesRefreshing && !xmpp.messagesLoaded) {
			this.messagesRefreshing = true;
			info(`Dashboard::setPageData: Calling apiService.refreshMessages because xmpp.messagesLoaded is false`);
			user = await apiService.refreshMessages();
			store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });
			this.messagesRefreshing = false;
		}

		this.componentIsMounted = true;
	}

	async componentDidMount() {
		//info('Dashboard::componentDidMount:start');
		if (!this.componentIsMounted) {
			await this.setPageData();
			this.componentIsMounted = true;
			//info('Dashboard::componentDidMount:end');
		}
	}

	async componentDidUpdate(prevProps: any) {
		if (this.componentIsMounted && !this.componentIsUpdating) {
			//info('Dashboard::componentDidUpdate:start');
			this.componentIsUpdating = true;

			if (!xmpp.isReady) {
				await xmpp.xmppManager();
			}

			await this.setPageData();
			this.componentIsUpdating = false;
			//info('Dashboard::componentDidUpdate:end');
		} else {
			//info('Dashboard::componentDidUpdate:ignored');
		}
	}

	/*denyContact(contact: any) {
		this.props.denyContact({ contactId: contact._id });
	}*/

	addContact(contact: any) {
		this.props.addContact({ contactId: contact._id });
	}

	async chatClickHandler(data: any) {
		store.dispatch({
			type: CHAT_INIT,
			payload: { receiver: { ...data, handlerText: locale.dashboard.start_chat }, history: (await apiService.getConversationByHash(data.conversationHash)).messages, type: data.type, conversations: this.props.dashboard.conversations, loggedInUser: this.props.dashboard.loggedInUser },
		});
		this.props.history.replace(data.room ? `/chat/${data.room}` : `/chat/${data.userId}`);
	}

	searchAllGroups(value: String, props: any) {
		store.dispatch({ type: DASHBOARD_SEARCH_REQUESTED, payload: { loader: true, loaderMessage: locale.global.searching } });
		if (value.length >= 3) {
			let payload: any;
			if (validateEmail(value.toLowerCase())) {
				payload = { email: value.toLowerCase() };
			} else if (validatePhone(value.toLowerCase())) {
				payload = { phone: value.toLowerCase() };
			} else {
				payload = { userId: value.toLowerCase() };
			}
			props.searchContacts(payload);
			props.pageLoading({ loader: true, loaderMessage: locale.global.searching });
		} else {
			info('----reseting values----', this.props);
			props.resetValues();
		}
	}

	inviteUser() {
		info('inviting user ---', this.state.searchText);
		if (validateEmail(this.state.searchText)) {
			let br = '%0D%0A';
			window.open(`mailto:${this.state.searchText}?subject=Join bChat!&body=Hey there!${br}${br}Join%20bChat!${br}${br}It's great app!`);
		} else {
			store.dispatch({ type: DASHBOARD_SHOW_TOAST, payload: { toastMessasge: locale.dashboard.error.default_trigger } });
		}
	}

	searchClickHandler(data: any) {
		data.imgSrc = fetchProfilePic(data.userId, 'contact');
		data.handlerText = locale.dashboard.send_req;
		this.setState({ modal: data, actionHandler: this.addContact });
		store.dispatch({ type: DASHBOARD_SHOW_MODAL, payload: true });
	}

	createGroupHandler() {
		this.setState({ modal: {}, actionHandler: this.addContact });
		store.dispatch({ type: DASHBOARD_SHOW_GROUP_MODAL, payload: true });
	}

	setShowModal(showModal: boolean) {
		store.dispatch({ type: DASHBOARD_SHOW_MODAL, payload: showModal });
		this.setState({ modal: {} });
	}

	performSearchText(searchText: String) {
		if (searchText.length >= 3) {
			this.props.searchContacts({ userId: searchText });
			this.props.pageLoading({ loader: true, loaderMessage: locale.searching });
		} else {
			info('----reseting values----', this.props);
			this.props.resetValues();
		}
	}

	setSearchText(searchText: String, props: any) {
		if (searchText.length >= 3) {
			let fetchedResults = props.dashboard.conversations.filter((contact: any) => searchText.toLowerCase() === (contact.type === 'chat' ? contact.userId.substring(0, searchText.length) : contact.room.substring(0, searchText.length)));
			if (fetchedResults.length > 0) {
				store.dispatch({ type: DASHBOARD_SEARCH_SUCCESS, payload: fetchedResults });
			} else {
				store.dispatch({ type: DASHBOARD_SEARCH_SUCCESS_WITH_LOADING, showInvite: false, payload: fetchedResults });
			}
		} else {
			store.dispatch({ type: DASHBOARD_SEARCH_END, payload: {} });
		}
	}

	createGroupSubmitHandler(payload: any, self: any) {
		self.props.addGroup(payload);
	}

	_buildChatList() {
		let isConversations = false;
		return (
			<>
				<IonContent className="full-background has-bottom-navbar has-topbar-with-searchbar">
					<IonRefresher slot="fixed" onIonRefresh={this.doRefresh}>
						<IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText="" refreshingSpinner="circles" refreshingText=""></IonRefresherContent>
					</IonRefresher>

					{this._renderTags()}

					{this.props.dashboard.conversations && this.props.dashboard.conversations.length > 0 ? (
						<>
							<IonList className="conversation-list">
								{this.props.dashboard.conversations
									.filter((_conversation: any) => _conversation.status === 'confirmed')
									.map(
										// eslint-disable-next-line array-callback-return
										(_conversation: any, index: any) => {
											isConversations = true;
											return <ConversationItem key={index} index={index} conversation={_conversation} chatClickHandler={() => this.chatClickHandler(_conversation)} />;
										}
									)}
							</IonList>
						</>
					) : (
						<>
							{(!this.props.dashboard.isLoading || this.componentIsMounted) && (
								<div className="no-conversations-view">
									<div className="title">{locale.dashboard.welcome}</div>
									<div className="description">{locale.dashboard.search_conversation}</div>
								</div>
							)}
						</>
					)}

					{!isConversations && (!this.props.dashboard.isLoading || this.componentIsMounted) && (
						<div className="no-conversations-view">
							<div className="title">{locale.dashboard.welcome}</div>
							<div className="description">{locale.dashboard.search_conversation}</div>
						</div>
					)}
				</IonContent>
			</>
		);
	}

	_buildSearch() {
		return (
			<>
				<IonContent className="has-bottom-navbar has-topbar-with-searchbar">
					{this._renderTags()}
					{this.props.dashboard.searchResults.length > 0 ? (
						<>
							<IonList className="conversation-list">
								{this.props.dashboard.searchResults.map((conversation: any, i: any) => (
									<IonItem className="searched-list-item" lines="none" detail={false} key={i}>
										<ConversationProfilePhoto user={conversation} />

										{this.props.dashboard.conversations.find((e: any) => e._id === conversation._id) || false ? null : (
											<IonButton slot="end" color="light" disabled={false} onClick={() => this.addContact(conversation)}>
												Send
											</IonButton>
										)}
										<IonLabel onClick={() => this.searchClickHandler(conversation)}>
											<h3>{conversation && conversation?.userId ? conversation.userId : conversation.groupname}</h3>
										</IonLabel>
									</IonItem>
								))}
							</IonList>
						</>
					) : (
						<>
							{!this.props.dashboard.isLoading && this.props.dashboard.showInvite ? (
								<IonGrid className="dbGrid">
									<IonRow className="dbRow">
										<IonButton color="light" disabled={false} onClick={() => this.inviteUser()}>
											Invite user
										</IonButton>
									</IonRow>
								</IonGrid>
							) : null}
						</>
					)}
				</IonContent>
			</>
		);
	}

	doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
		this.props.pageLoading({ loader: true, loaderMessage: locale.global.searching });
		event.detail.complete();
		await apiService.refreshMessages(this.props.dashboard?.conversations);
		this.props.pageLoading({ loader: false, loaderMessage: '' });
	};

	_renderTags() {
		const { chatTags, selectedChatTag } = this.state;

		return (
			<div className="tag-tabs">
				{chatTags.map((item: any, key: any) => {
					return (
						<div
							className={'tab-item ' + (selectedChatTag === item.name ? 'active' : '')}
							key={key}
							onClick={() => {
								this.setState({ selectedChatTag: item.name });
							}}
						>
							{item.name}
						</div>
					);
				})}
			</div>
		);
	}

	render() {
		return (
			<>
				<IonApp className="dashboard-page">
					<>
						<TopNavbar
							{...this.props}
							leftButtonIcon={BasketIcon}
							showUnViewDotOnLeftIcon={true}
							onLeftButtonPress={() => {
								const data: any = {
									...this.props.dashboard.loggedInUser,
									type: 'groupchat',
								};

								data.handlerText = locale.dashboard.start_chat;
								store.dispatch({ type: CHAT_RESET, payload: {} });
								store.dispatch({
									type: CHAT_INIT,
									payload: {
										history: this.props.dashboard.loggedInUser.conversations.find((_conversation: any) => _conversation.userId === this.props.dashboard.loggedInUser.userId).messages,
										receiver: this.props.dashboard.loggedInUser,
										loggedInUser: this.props.dashboard.loggedInUser,
										type: 'groupchat',
									},
								});
								this.props.history.replace('/personal-notepad', {
									conversation: this.props.dashboard.loggedInUser.notepadJid,
								});
							}}
							pageTitle={locale.global.app_name}
							searchTypeHandler={this.setSearchText}
						/>

						{/* ---------------------Main Page area starts here--------------------- */}
						{this.props.dashboard.isSearching ? this._buildSearch() : this._buildChatList()}
					</>
					{/* ---------------------Main Page Ends here--------------------- */}
					<BottomNavbar unreadCount={0} {...this.props} />
					<IonToast
						isOpen={this.props.dashboard.showToast}
						onDidDismiss={() => {
							this.setState({ searchText: '' });
							this.props.resetValues();
						}}
						message={this.props.dashboard.errorMessage}
						position="bottom"
						buttons={[
							{
								text: locale.global.close,
								role: 'cancel',
								handler: () => {
									info('Cancel clicked');
								},
							},
						]}
					/>
				</IonApp>
				<IonModal isOpen={this.props.dashboard.showGroupModal} onDidDismiss={() => store.dispatch({ type: DASHBOARD_SHOW_GROUP_MODAL, payload: false })} swipeToClose={true}>
					<div key={Math.random()}></div>
				</IonModal>
			</>
		);
	}
}

interface iProps extends RouteComponentProps<{ name: string }> {
	dashboard: any;
	loggedInUser: any;
	searchContacts: Function;
	pageLoading: Function;
	startLoading: Function;
	resetValues: Function;
	isLoggedIn: boolean;
	addContact: Function;
	//confirmContact: Function;
	//denyContact: Function;
	addGroup: Function;
	initChat: Function;
	historyCatchup: Function;
}

interface iState {
	searchText: String;
	showModal: boolean;
	showPopover: boolean;
	popoverEvent: any;
	chatTags: any;
	selectedChatTag: any;
	modal: any;
	actionHandler: Function;
	cookies: any;
}

const mapStateToProps = (state: any) => {
	state = store.getState();

	return {
		dashboard: state.dashboard,
		loggedInUser: state.auth.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	searchContacts: (payload: String) => dispatch(searchContacts(payload)),
	addContact: (payload: any) => dispatch(addContact(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
	resetValues: () => dispatch(resetValues()),
	addGroup: (payload: any) => dispatch(addGroup(payload)),
	initChat: (payload: any) => dispatch(initChat(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
