import { IonPage, IonContent } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import IconTextButton from '../../components/IconTextButton';
import BottomNavbar from '../common/navbar/bottomNavbar';
import ProjectsComponent from './ProjectsComponent';
import QrCodeComponent from './QrCodeComponent';
import './style.scss';
import { locale } from '../../locales/local';
import QrCodeScanModal from './QrCodeScanModal';
import { apiService } from '../../services/apiService';

interface iProps extends RouteComponentProps<{ name: string }> {
	location: any;
}
interface iState {
	selectedHotKey: string;
	previousPath: string;
	currentUser: any;
	showMyQrCodeOnly: boolean;
	showQrScanModal: boolean;
}

class QuickAccessPage extends React.Component<iProps, iState> {
	hotKeyOptions = [
		{ icon: 'transfer.svg', selectedIcon: 'transfer-selected.svg', title: locale.quickaccess.transfer, type: 'transfer' },
		{ icon: 'qrcode.svg', selectedIcon: 'qrcode-selected.svg', title: locale.quickaccess.qr_code, type: 'qrcode' },
		{ icon: 'todos.svg', selectedIcon: 'todos-selected.svg', title: locale.quickaccess.todo, type: 'todo' },
		{ icon: 'scan.svg', selectedIcon: 'scan-selected.svg', title: locale.quickaccess.scan, type: 'scan' },
	];

	constructor(props: iProps) {
		super(props);

		const locationState = props.location.state;

		let user: any;

		apiService.me().then((_user:any) => user = _user);

		this.state = {
			showQrScanModal: false,
			selectedHotKey: '',
			currentUser: user,
			showMyQrCodeOnly: locationState?.showMyQrCodeOnly || false,
			previousPath: locationState?.previousPath || '',
		};
	}

	onCloseCallBack = (event: any) => {
		this.setState({ selectedHotKey: '' });
		if (this.state.previousPath) {
			this.props.history.replace(this.state.previousPath);
		}
	};

	render() {
		const { selectedHotKey, showMyQrCodeOnly, currentUser } = this.state;
		return (
			<IonPage className="quick-access-page">
				<IonContent className="has-bottom-navbar">
					<div className="hotkey-container" onClick={this.onCloseCallBack}>
						<div className="hotkey-content-container">
							{(selectedHotKey === 'qrcode' || showMyQrCodeOnly) && <QrCodeComponent loggedInUser={currentUser} onClose={() => this.setState({ selectedHotKey: '' })} />}
							{selectedHotKey === 'todo' && <ProjectsComponent onClose={() => this.setState({ selectedHotKey: '' })} />}
						</div>
						{!showMyQrCodeOnly && (
							<div
								className="hotkey-card"
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								{this.hotKeyOptions.map((item, key) => {
									return (
										<IconTextButton
											key={key}
											selected={item.type === selectedHotKey}
											icon={item.icon}
											selectedIcon={item.selectedIcon}
											title={item.title}
											onClick={() => {
												if (selectedHotKey === item.type) {
													this.setState({ selectedHotKey: '' });
												} else {
													if (item.type === 'scan') {
														this.setState({ showQrScanModal: true });
													} else {
														this.setState({ selectedHotKey: item.type });
													}
												}
											}}
										/>
									);
								})}
							</div>
						)}
					</div>
				</IonContent>
				<BottomNavbar unreadCount={0} {...this.props} />

				<QrCodeScanModal
					show={this.state.showQrScanModal}
					onClose={() => {
						this.setState({ showQrScanModal: false });
					}}
				/>
			</IonPage>
		);
	}
}

export default QuickAccessPage;
