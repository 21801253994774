/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

/* Theme variables */
import './theme/variables.css';
import { Provider } from 'react-redux';
import Login from './pages/authenticate/Login';

// Test Report page
import TestReport from './pages/testReport';

/* Pages screens */
import Register from './pages/authenticate/Register';
import AuthGuard from './pages/AuthGuard';

import store from './redux/store';
import NoMatch from './pages/common/404/NoMatch';

import { deleteItemFromInternalStorage, getInternalStorage, setInternalStorage, clearInternalStorage, info } from './helpers/common';
import _ from 'lodash';
import { startBridge } from './helpers/pubsub';
import { AppManager } from './services/appManager';

const App: React.FC = () => {
	let cookies: any = getInternalStorage() || {};

	if (!cookies.uuid) {
		clearInternalStorage();
	}

	const _navigator: any = window.navigator,
		isWebBrowser: Boolean = 'serviceWorker' in _navigator;

	if (!isWebBrowser) {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		async () => await StatusBar.hide();

		SplashScreen.show({
			showDuration: 0,
			autoHide: true,
		});
	} else {
		if (_navigator.vendor === 'Apple Computer, Inc.') {
			info('serviceWorker::register::registerValidSW: This is Safari');
			setInternalStorage('Safari', true);
		}
	}

	if (!cookies.uuid) {
		for (let cookie of Object.keys(cookies)) {
			if (!_.includes(['desktopNotifications', 'personalDevice', 'sm', 'db', 'dbVersion', 'pk', 'version'], cookie)) {
				deleteItemFromInternalStorage(cookie);
			}
		}
	}

	if (!cookies.listeners) {
		cookies.listeners = [];
	}

	if (isWebBrowser) {
		cookies.mobileDevice = /Mobi|Android/i.test(navigator.userAgent);
		cookies.displayMode = ('standalone' in _navigator && _navigator?.standalone === true) || window.matchMedia('(display-mode: standalone)').matches ? 'standalone' : 'browser';
	} else {
		cookies.mobileDevice = true;
		cookies.displayMode = 'standalone';
	}

	cookies.network = 'online';

	cookies.isApp = !isWebBrowser;
	setInternalStorage('displayMode', cookies.displayMode);
	setInternalStorage('mobileDevice', cookies.mobileDevice);
	setInternalStorage('isApp', cookies.isApp);
	setInternalStorage('listeners', cookies.listeners);
	setInternalStorage('desktopNotifications', !isWebBrowser ? false : cookies.desktopNotifications);
	setInternalStorage('network', window.navigator.onLine ? 'online' : 'offline');
	startBridge();

	return (
		<Provider store={store}>
			<Router>
				<Switch>
					<Route path="/" component={Login} exact={true} />
					<Route path="/testscripts" component={TestReport} exact={true} />

					{/*<!-- <Route path="/vv" component={() => <VV />} exact={true} /> -->*/}
					{/*<!-- <Route path="/Receivervv" component={() => <ReceiverVV />} exact={true} /> -->*/}
					{/*<!-- <Route path="/call" component={() => <Call />} exact={true} /> -->*/}
					<Route path="/login" component={Login} exact={true} />
					<Route path="/signup" component={Register} exact={true} />
					<Route path="/home" component={Login} exact={true} />
					<Route path="/auth" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/moments" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/create-post" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/add-location" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/share-with" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/chat" render={(props: any) => <AuthGuard {...props} />} exact={true} />
					<Route path="/personal-notepad" render={(props: any) => <AuthGuard {...props} />} exact={true} />
					<Route path="/chat/:userId" render={(props: any) => <AuthGuard {...props} />} />
					<Route path="/profile/edit-profile" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/profile/upload-photo" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/contacts" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/discover" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/camera" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/profile" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/profile-friend" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/settings" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/privacy" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/my-qrcode" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/new-group" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/group-manager" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/group-setting" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/group-info" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/group-members" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/contacts" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/contacts/chat" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/contacts/search" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/contacts/view" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/contacts/add" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/contacts/recent" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/contacts/manage" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/contacts/add-members" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/tags" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/taginfo" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/quick-access" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/quick-access-qrcode" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="/chat-medias" render={(props) => <AuthGuard {...props} />} exact={true} />
					<Route path="*" component={NoMatch} />
				</Switch>
			</Router>
		</Provider>
	);
};

// function switchTheme(shouldAdd: boolean) {
//   document.body.classList.toggle('dark', shouldAdd)
// }

export default App;
