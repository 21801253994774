import React from 'react';
import { IonImg, IonThumbnail } from '@ionic/react';
import './style.scss';
import NotificationBadge from '../../../components/NotificationBadge';
import { isBlank } from '../../../helpers/common';

const ConversationProfilePhoto: React.FC<any> = ({ conversation }) => {
	return (
		<div className="conversation-profile-pic-container">
			<IonThumbnail className="thumb-user" slot="start" onClick={() => {}}>
				<IonImg src={(conversation.userId && !isBlank(conversation.profilePhoto) && conversation.profilePhoto) || (conversation.groupname && !isBlank(conversation.groupPhoto) && conversation.groupPhoto) || (conversation.userId && 'assets/img/blank-user-profile.png') || (conversation.groupname && 'assets/img/blank-group-profile.png')}></IonImg>
			</IonThumbnail>
			<NotificationBadge unreadCount={conversation.unreadCount} />
		</div>
	);
};
export default ConversationProfilePhoto;
