import React, { useState } from 'react';
import { locale } from '../../locales/local';
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react';
import { IonButton, IonIcon } from '@ionic/react';
import './Emoji.scss';
import { add } from 'ionicons/icons';
import EmojiStickerSearchModal from '../../modals/EmojiStickerSearchModal';

const SearchIcon = './assets/icon/search.svg';
const EmojiIcon = './assets/icon/emoji-icon.svg';
const EmojiActiveIcon = './assets/icon/emoji-icon-active.svg';
const StickerIcon = './assets/icon/stickericon.svg';
const StickerActiveIcon = './assets/icon/stickericon-active.svg';
const CloseIcon = './assets/icon/close-arrow.svg';
const Sticker1 = './assets/stickers/sticker1.svg';
const Sticker2 = './assets/stickers/sticker2.svg';

interface iProps {
	message?: string;
	onCancel?: () => void;
	onEmojiChoose?: (event: any) => void;
}

const Emoji: React.FC<iProps> = ({ message, onEmojiChoose, onCancel }) => {
	const [optionType, setOptionType] = useState<string>('emoji');
	const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

	const onEmojiClick = (event: any, emojiObject: any) => {
		onEmojiChoose && onEmojiChoose(emojiObject);
	};
	const onCancelClick = () => {
		onCancel && onCancel();
	};

	return (
		<>
			<div className="emoji-sticker-choose-view">
				<IonButton fill="clear" onClick={() => setIsSearchOpen(true)}>
					<IonIcon src={SearchIcon}></IonIcon>
				</IonButton>
				<IonButton fill="clear" onClick={() => setOptionType('emoji')}>
					<IonIcon src={optionType === 'emoji' ? EmojiActiveIcon : EmojiIcon}></IonIcon>
				</IonButton>
				<IonButton fill="clear" onClick={() => setOptionType('sticker')}>
					<IonIcon src={optionType === 'sticker' ? StickerActiveIcon : StickerIcon}></IonIcon>
				</IonButton>
			</div>
			{optionType === 'emoji' && (
				<>
					<Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} skinTone={SKIN_TONE_NEUTRAL} disableSkinTonePicker={true} groupNames={locale.chat.emoji_labels} groupVisibility={{ flags: false }} native={true} preload={true} />

					{message && (
						<div className="send-cancel-btns">
							<IonButton className="clear-btn" fill="clear" onClick={onCancelClick}>
								<IonIcon src={CloseIcon}></IonIcon>
							</IonButton>
							{/* <IonButton className="send-btn" fill="clear">
							Send
						</IonButton> */}
						</div>
					)}
				</>
			)}

			{optionType === 'sticker' && (
				<div className="stickers-view-container">
					<div className="title">Custom Stickers</div>
					<div className="stickers">
						<IonButton className="add-sticker-btn" fill="clear">
							<IonIcon icon={add}></IonIcon>
						</IonButton>
						{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, key) => {
							return (
								<React.Fragment key={key}>
									<IonButton className="sticker-btn" fill="clear">
										<IonIcon src={Sticker1}></IonIcon>
									</IonButton>
									<IonButton className="sticker-btn" fill="clear">
										<IonIcon src={Sticker2}></IonIcon>
									</IonButton>
								</React.Fragment>
							);
						})}
					</div>
				</div>
			)}

			<EmojiStickerSearchModal
				show={isSearchOpen}
				onCancel={() => {
					setIsSearchOpen(false);
				}}
			/>
		</>
	);
};

export default Emoji;
