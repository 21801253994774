import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonThumbnail, IonSpinner, IonActionSheet, IonButton, IonText, IonCheckbox, IonItemSliding, IonItem, IonImg } from '@ionic/react';
import { useLongPress, LongPressDetectEvents } from 'use-long-press';
import './style.scss';
import './thread.scss';
import { checkmarkOutline } from 'ionicons/icons';
import { apiService } from '../../services/apiService';
import { EmojiPopup } from './emoji-popup';
import SharedService, { sharedService } from '../../services/sharedService';
import moment from 'moment';
import ToastStylish from '../../components/ToastStylish';
import { publishData } from '../../redux/actions/pubsub';
import { connect } from 'react-redux';
import EnumService from '../../services/enumService';
import { locale } from '../../locales/local';
import { info } from '../../helpers/common';
import { xmpp } from '../../services/xmpp';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import RecallPopupModal from '../../modals/RecallPopupModal';
import ThreadItemOptions from './components/ThreadItemOptions';
import ThreadRepliedForView from './components/ThreadRepliedForView';
import CustomSpinner from '../../components/CustomSpinner';
import ResendMessageModal from '../../modals/ResendMessageModal';
import { createPubsub } from '../../helpers/pubsub';

require('default-passive-events');

const Trash = './assets/icon/trash.svg';
const Copy = './assets/icon/copy.svg';
const Dislike = './assets/icon/dislike.svg';
const Forward = './assets/icon/forward.svg';
const Heart = './assets/icon/heart.svg';
const Help = './assets/icon/help.svg';
const Like = './assets/icon/like.svg';
const Love = './assets/icon/love.svg';
const Recall = './assets/icon/recall.svg';
const Remind = './assets/icon/remind.svg';
const Report = './assets/icon/report.svg';
const Tick = './assets/icon/tick.svg';
const Translate = './assets/icon/translate.svg';
const PLUS = './assets/icon/plus.svg';
const EMOJICRY = './assets/icon/emoji-cry.svg';
const EMOJIFIRE = './assets/icon/emoji-fire.svg';
const EMOJILOVE = './assets/icon/emoji-love.svg';
const EMOJISCREAMING = './assets/icon/emoji-screaming.svg';
const EMOJITHINK = './assets/icon/emoji-think.svg';
const CopyDark = './assets/icon/copy-dark.svg';
const ExpandIcon = './assets/icon/expand.svg';
const ReplyIcon = './assets/icon/reply.svg';
const NotepadIcon = './assets/icon/notepad.svg';
const ReplyArrowIcon = './assets/icon/chat-reply.png';
const FailedIcon = './assets/icon/error.svg';
const VideoMessageIcon = './assets/icon/msg-video-icon.svg';
const VideoPlayIcon = './assets/icon/video-play-icon.svg';

const recallMaxTimeInMins = 5;

type CardProps = {
	onPublishData: Function;

	type: string;
	myProfilePhoto: any;
	receiverProfilePhoto: any;
	align: string;
	message: any;
	lastMessage: any;
	self: any;
	selectedMessages: [];
	selectingMessages: boolean;
	senderUserId: string;
	senderProfileName: string;
	actionHandler: Function;
	onReplyItemSelect?: Function;
	startSelectingMessages?: Function;
	startForwardingMessages?: Function;
	pushToSelectedMessages?: Function;
	onProfilePhotoPress?: any;
};

const pubsub = createPubsub('com.be-society');

const ThreadComponent = ({
	onPublishData,
	myProfilePhoto,
	receiverProfilePhoto,
	align,
	type,
	selectingMessages,
	message,
	lastMessage,
	selectedMessages,
	senderUserId,
	senderProfileName,
	self,
	startSelectingMessages = () => {},
	startForwardingMessages = () => {},
	pushToSelectedMessages = () => {},
	actionHandler = () => {},
	onReplyItemSelect = () => {},
	onProfilePhotoPress,
}: CardProps) => {
	const recallTimerIntervalRef = useRef();
	const ionItemSlideRef = useRef(null);

	//console.log('message::', message);

	let isGroup: boolean = type === 'groupchat',
		count: number = 0,
		snippet: any,
		isEmojiStyleApplied: boolean = false,
		attachmentData: any,
		canTranslate: boolean = message.hasOwnProperty('body') ? !message.body.translated : false,
		userProfilePic: string = senderUserId === 'Me' ? myProfilePhoto : receiverProfilePhoto,
		messageSentTime: string = message?.timestamp;

	const cardRef: React.RefObject<HTMLIonCardElement> = useRef(null),
		msgCardContentRef: React.RefObject<HTMLIonCardContentElement> = useRef(null),
		[isLoading, setIsLoading] = useState<boolean>(false),
		[showResendMessageModal, setShowResendMessageModal] = useState<boolean>(false),
		[isResendingMessage, setIsResendingMessage] = useState<boolean>(false),
		[fileUploadingProgress, setFileUploadingProgress] = useState<number>(0),
		[shouldShowReadMoreLess, setShouldShowReadMoreLess] = useState<boolean>(false),
		[showReadMore, setShowReadMore] = useState<boolean>(false),
		[longpressPopupIsEnlarge, setLongpressPopupIsEnlarge] = useState<boolean>(false),
		[recallRemainingTimeInSeconds, setRecallRemainingTimeInSeconds] = useState<number>(0),
		[translatedTxt, setTranslatedTxt] = useState<string>(''),
		[translating, setTranslating] = useState<boolean>(false),
		[actionSheetHeaderTitle, setActionSheetHeaderTitle] = useState<string>(''),
		[showActionSheet, setShowActionSheet] = useState<boolean>(false),
		[showRecallPopup, setShowRecallPopup] = useState<boolean>(false),
		[actionsheetButtons, setActionsheetButtons] = useState<any>(null),
		[showActionToast, setShowActionToast] = useState<boolean>(false),
		[toastIcon, setToastIcon] = useState<string>(''),
		[showActionToastMessage, setShowActionToastMessage] = useState<string>(''),
		[showEmojiPopover, setShowEmojiPopover] = useState<boolean>(false),
		[showQuickFunctionPopover, setShowQuickFunctionPopover] = useState<boolean>(false),
		[selectedThreadElementPosition, setSelectedThreadElementPosition] = useState<any>(null),
		[isRecall, setIsRecall] = useState<boolean>(message.recalled),
		[isDeleted, setIsDeleted] = useState<boolean>(message.recalled),
		DOUBLE_CLICK_THRESHOLD = 250,
		extractChatFromMsgbody = SharedService.extractChatFromMsgbody(message),
		chapOptionOnLongPress: any = useMemo(
			() => [
				[
					{ title: locale.dashboard.thread.forward, icon: Forward, type: 'forward' },
					{ title: locale.dashboard.thread.copy, icon: Copy, type: 'copy' },
					{ title: locale.dashboard.thread.save, icon: Heart, type: 'save' },
					{ title: locale.dashboard.thread.delete, icon: Trash, type: 'delete' },
					{ title: locale.dashboard.thread.recall, icon: Recall, type: 'recall' },
					{ title: locale.dashboard.thread.select, icon: Tick, type: 'check' },
				],
				[
					{ title: locale.dashboard.thread.expand, icon: ExpandIcon, type: 'enlarge' },
					{ title: locale.dashboard.thread.remind, icon: Remind, type: 'remind' },
					{ title: locale.dashboard.thread.notepad, icon: NotepadIcon, type: 'notepad' },
					{ title: locale.dashboard.thread.reply, icon: ReplyIcon, type: 'reply' },
					{ title: locale.dashboard.thread.translate, icon: Translate, type: 'translate' },
					// { title: '', icon: Recall, type: '', forViewAdjustment: true },
				],
			],
			[]
		),
		chapOptionOnClick: any = useMemo(
			() => [
				[
					{ icon: Heart, type: 'heart' },
					{ icon: Like, type: 'like' },
					{ icon: Dislike, type: 'dislike' },
					{ icon: Love, type: 'love' },
					{ icon: Report, type: 'questionmark' },
					{ icon: Help, type: 'help' },
				],
				[
					{ icon: EMOJILOVE, type: 'love' },
					{ icon: EMOJISCREAMING, type: 'screaming' },
					{ icon: EMOJICRY, type: 'cry' },
					{ icon: EMOJITHINK, type: 'think' },
					{ icon: EMOJIFIRE, type: 'fire' },
					{ icon: PLUS, type: 'add', size: '18px' },
				],
			],
			[]
		),
		cardClickHandler = useCallback(
			(event) => {
				const elementPos: DOMRect = event?.currentTarget?.getBoundingClientRect();

				if (elementPos) {
					setSelectedThreadElementPosition(elementPos);
				}

				if (count === 0) {
					setTimeout(() => {
						if (count === 1) {
							// eslint-disable-next-line react-hooks/exhaustive-deps
							count = 0;
							if (message?.messageType === EnumService.ChatMessageType.MEDIA) {
								actionHandler(attachmentData, message);
							}
						}
						if (count > 1) {
							count = 0;
							info('Double Tap');
							if (!sharedService.isChatScrolling) {
								setShowEmojiPopover(true);
							}
						}
					}, DOUBLE_CLICK_THRESHOLD);
				}
				count++;
			},
			[count, message]
		),
		longPressCallback = useCallback(() => {
			if (!sharedService.isChatScrolling) {
				setShowQuickFunctionPopover(true);
			}
		}, []),
		bind = useLongPress(longPressCallback, {
			onStart: (event) => {
				const elementPos: DOMRect | undefined = event?.currentTarget?.getBoundingClientRect();

				if (elementPos) {
					setSelectedThreadElementPosition(elementPos);
				}
				info('selected message: ', selectingMessages);
			},
			onFinish: () => {},

			onCancel: () => {},
			threshold: 500,
			captureEvent: true,
			detect: LongPressDetectEvents.BOTH,
		}),
		emojiClickHandler = useCallback(
			async (action: string) => {
				switch (action) {
					case 'like':
						info('Like clickedhandler');
						break;

					case 'save':
						setShowActionToastMessage('Added');
						setShowActionToast(true);
						setToastIcon(checkmarkOutline);
						break;

					case 'forward':
						info('forward clickedhandler');
						startForwardingMessages();
						break;

					case 'enlarge':
						info('enlarge clickedhandler');

						if (!sharedService.isChatScrolling) {
							setShowQuickFunctionPopover(true);
							setLongpressPopupIsEnlarge(true);
						}

						break;

					case 'copy':
						navigator.clipboard.writeText(message.hasOwnProperty('body') ? message.body : message);
						setShowActionToastMessage('Copied');
						setShowActionToast(true);
						setToastIcon(CopyDark);

						// const response = await fetch(message.body);
						// const blob = await response.blob();
						// const windowRef: any = window;
						// const ClipboardItem: any = windowRef.ClipboardItem;
						// const clipboard: any = navigator.clipboard;
						// const data = [new ClipboardItem({ [blob.type]: blob })];
						// await clipboard.write(data);
						break;

					case 'delete':
						setShowActionSheet(true);
						setActionSheetHeaderTitle(locale.dashboard.thread.recall_final_confirm);
						setActionsheetButtons([
							{
								text: 'Delete',
								role: 'destructive',
								handler: async () => {
									info(`${action} clicked`);
									setShowActionSheet(false);
									setIsLoading(true);
									await apiService.deleteMessage({ ...message, deleted: 1 }, true).then(() => {
										setIsLoading(false);
									});
								},
							},
							{
								text: 'Cancel',
								handler: () => {
									setShowActionSheet(false);
									setActionsheetButtons(null);
									setActionSheetHeaderTitle('');
								},
							},
						]);
						break;

					case 'recall':
						setShowRecallPopup(true);
						break;

					case 'check':
						info(message);
						startSelectingMessages();
						pushToSelectedMessages();
						break;

					case 'remind':
						info('Remind clickedhandler');
						break;

					case 'translate':
						setTranslating(true);

						const navigatorRef: any = navigator,
							currentLaguage: string = navigatorRef?.language || navigatorRef.userLanguage,
							currentLanguageCode: string = currentLaguage ? currentLaguage.split('-')[0] : 'en';

						await apiService.translateMessage({ messageKey: message.messageKey, from: '', to: currentLanguageCode });
						break;

					case 'reply':
						info('reply clickedhandler');

						onPublishData({
							type: EnumService.PubSubEventType.ReplyToTheConversation,
							data: {
								...message,
								senderProfileName,
								userProfilePic,
							},
						});

						break;
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			},
			[message, startSelectingMessages, pushToSelectedMessages, startForwardingMessages, onPublishData, senderProfileName, userProfilePic]
		),
		isSelected = selectingMessages && selectedMessages.some((msg: any) => msg.messageKey === message.messageKey);

	snippet = extractChatFromMsgbody.snippet;
	isEmojiStyleApplied = extractChatFromMsgbody.isEmojiStyleApplied;
	attachmentData = extractChatFromMsgbody.attachmentData;

	const stopRecallOption = useCallback(() => {
			if (showRecallPopup) {
				setShowRecallPopup(false);
			}
			if (recallRemainingTimeInSeconds > 0) {
				setRecallRemainingTimeInSeconds(0);
			}
			recallTimerIntervalRef && clearInterval(recallTimerIntervalRef.current);
		}, [showRecallPopup, recallRemainingTimeInSeconds, recallTimerIntervalRef]),
		getRemainingTime = useCallback(() => {
			const diffSecs = recallMaxTimeInMins * 60 - moment().diff(moment(messageSentTime), 'seconds');
			setRecallRemainingTimeInSeconds(diffSecs);
			if (diffSecs <= 0) {
				stopRecallOption();
			}
		}, [stopRecallOption, messageSentTime]);

	useEffect(() => {
		if (align === 'right' && messageSentTime && moment().diff(moment(messageSentTime), 'minutes') <= recallMaxTimeInMins) {
			getRemainingTime();
			recallTimerIntervalRef && clearInterval(recallTimerIntervalRef.current);

			const id: any = setInterval(() => {
				if (messageSentTime && moment().diff(moment(messageSentTime), 'seconds') <= recallMaxTimeInMins * 60) {
					getRemainingTime();
				} else {
					stopRecallOption();
				}
			}, 1000);

			recallTimerIntervalRef.current = id;
			return () => {
				clearInterval(recallTimerIntervalRef.current);
			};
		} else {
			stopRecallOption();
		}
	}, [align, messageSentTime, recallTimerIntervalRef, getRemainingTime, stopRecallOption]);

	useEffect(() => {
		const element = msgCardContentRef?.current;

		if (element && element.offsetHeight > 0) {
			if (message?.messageType !== EnumService.ChatMessageState.RESEND) {
				const offsetHeight = element.offsetHeight;
				const scrollHeight = element.scrollHeight;
				if (scrollHeight >= offsetHeight + 10) {
					setShouldShowReadMoreLess(true);
				}
				element.style.overflowY = 'hidden';
			}
		}
	}, [msgCardContentRef, msgCardContentRef?.current?.offsetHeight, message?.messageType]);

	const resendMessage = useCallback(async () => {
		setIsResendingMessage(true);
		await xmpp.sendMessage(message.to, message.body, EnumService.ChatMessageState.RESEND, message.mediaType, message.messageKey);
		setIsResendingMessage(false);
	}, [message]);

	const deleteMessage = useCallback(async () => {
		setIsResendingMessage(true);
		await apiService.deleteUnsentMessage({ id: message.messageKey }, message);
		setIsResendingMessage(false);
	}, [message]);

	useEffect(() => {
		pubsub.subscribe((msg: any) => {
			if (msg.action === 'uploadMedia' && msg.constructor === Object && message?.messageKey === msg?.messageKey) {
				setFileUploadingProgress(msg.percentComplete);
			}
		});
	}, [message]);

	// const replyToUserData = {
	// 	profilePhoto: './assets/img/mies.jpg',
	// 	// userProfileName: 'xiao_B',
	// 	userProfileName: 'CTO (aka Bug Hunter)',
	// 	messageType: EnumService.ChatMessageState.IMAGE,
	// 	// messageType: EnumService.ChatMessageState.ORIGINAL,
	// 	file: './assets/img/demo-moments-topbg.png',
	// 	// message: 'https:/www.theatlantic.com/technology/archive/2021/10/stop-s',
	// 	message: 'Great work charles looking forward to test the update!! KEEP UP THE AMAZING WORK!!!!!!!!',
	// };
	let repliedItemIndex = 0;
	const isReplied = message.messageType === EnumService.ChatMessageState.REPLY,
		repliedMessageData = self.props.chat?.history?.find((_message: any, index: number) => {
			const isFound = isReplied && _message.messageKey === message.inReplyTo;
			if (isFound) {
				repliedItemIndex = index;
			}
			return isFound;
		});

	//For UI test purpose only, remove it after UI test done
	// message.messageType = EnumService.ChatMessageState.AUDIO;
	// message.status = EnumService.MessageSendStatus.SentFailed;
	//

	const isPendingUpload = useMemo(() => message.status === EnumService.MessageSendStatus.PendingUpload, [message]),
		isPendingSent = useMemo(() => message.status === EnumService.MessageSendStatus.PendingSent, [message]),
		isSent = useMemo(() => message.status === EnumService.MessageSendStatus.Sent, [message]),
		sentFailed = message.status === EnumService.MessageSendStatus.SentFailed,
		isReceived = useMemo(() => message.status === EnumService.MessageSendStatus.Received, [message]),
		isMediaType = useMemo(() => message.messageType === EnumService.ChatMessageType.MEDIA, [message]),
		isTextType = useMemo(() => message.messageType === EnumService.ChatMessageType.TEXT, [message]);

	return (
		<>
			<IonItemSliding
				className="thread-item-sliding"
				ref={ionItemSlideRef}
				key={message.messageKey}
				onIonDrag={(event) => {
					console.log('OnIonDrag ', event);
					if (event.detail.ratio === -1) {
						emojiClickHandler('reply');
						const itemRef: any = ionItemSlideRef?.current;
						if (itemRef) {
							itemRef.close();
						}
					}
				}}
			>
				<IonItem mode="ios" lines="none" className={'thread-container ' + (align === 'left' ? 'left' : 'right')} key={'thread-container' + (message.messageKey ? message.messageKey : Math.random())} detail={false} button={true}>
					<div className="item-inner-div">
						{isReplied && repliedMessageData && (
							<ThreadRepliedForView
								self={self}
								alignClassName={align === 'left' ? 'left' : 'right'}
								userMessageData={repliedMessageData}
								onClick={() => {
									onReplyItemSelect(repliedItemIndex);
								}}
							/>
						)}

						<div className={'thread-item ' + (align === 'left' ? 'left' : 'right')}>
							<div className="mask-item">
								<IonImg src={ReplyArrowIcon} />
							</div>

							{selectingMessages && <IonCheckbox checked={isSelected} slot="start" onClick={() => pushToSelectedMessages()} />}

							{isRecall || message.recalled ? (
								<div className="recall-message-view">
									<IonText>{message.sender === 'Me' ? locale.dashboard.thread.you_recalled_a_message : `${message.sender} ${locale.dashboard.thread.recalled_a_message}`}</IonText>
									{recallRemainingTimeInSeconds > 0 && (
										<IonButton
											fill="clear"
											onClick={() => {
												onPublishData({
													type: EnumService.PubSubEventType.RecallEdit,
													data: message,
												});
											}}
										>
											{locale.global.edit}
										</IonButton>
									)}
								</div>
							) : (
								<div className={align === 'left' ? 'gp-message-other' : 'gp-message-me'}>
									{isLoading ? (
										<IonSpinner style={{ marginRight: '20px' }} name="crescent" />
									) : (
										<>
											<div className={align === 'left' ? 'avatar-group' : 'avatar-group-me'}>
												<IonThumbnail onClick={onProfilePhotoPress}>
													<img style={{ borderRadius: '3px', width: '40px', height: '40px' }} src={userProfilePic} alt="profilePicture" />
												</IonThumbnail>
											</div>
											<div className="main-area-gp-me">
												{isGroup && senderProfileName !== 'Me' ? (
													<IonCardHeader style={{ padding: '0' }}>
														<IonCardSubtitle>
															<span dangerouslySetInnerHTML={{ __html: senderProfileName?.replace('(', '(<span class="paranthesis">').replace(')', '</span>)') }}></span>
														</IonCardSubtitle>
													</IonCardHeader>
												) : null}
												<IonCard {...bind} ref={cardRef} onClick={cardClickHandler} className={align === 'left' ? 'group-message-left' : 'group-message-me'}>
													<IonCardContent
														ref={msgCardContentRef}
														style={{
															opacity: showEmojiPopover || showQuickFunctionPopover ? 0 : 1,
															maxHeight: !isTextType || showReadMore ? 'unset' : '382px',
														}}
														className={isEmojiStyleApplied ? 'only-emoji' : ''}
													>
														{align === 'left' && (
															<>
																{isMediaType && message.mediaType[0] === EnumService.ChatMediaType.AUDIO ? (
																	<div className="receiver-audio-type-msg-container">
																		{snippet}{' '}
																		<div className="audio-convert-to-text-btn">
																			<div className="red-dot"></div>
																			<IonButton fill="clear" className="convert-btn">
																				Convert to Text
																			</IonButton>
																		</div>
																	</div>
																) : (
																	<>{snippet} </>
																)}
															</>
														)}

														{align === 'right' && (
															<>
																{snippet}{' '}
																{isMediaType && isPendingUpload && (
																	<div className="media-loader-mask">
																		<CircularProgressbar
																			value={fileUploadingProgress}
																			strokeWidth={10}
																			styles={{
																				path: {
																					stroke: '#ffffff',
																					strokeLinecap: 'round',
																				},
																			}}
																		/>
																	</div>
																)}
															</>
														)}
														{isMediaType && message.mediaType[0] === EnumService.ChatMediaType.VIDEO && (isSent || isReceived) && (
															<div className="media-loader-mask">
																<IonImg className="video-play-icon" src={VideoPlayIcon} />
															</div>
														)}
													</IonCardContent>
													{shouldShowReadMoreLess && (
														<span className="readmore-less" onClick={() => setShowReadMore(!showReadMore)}>
															{showReadMore ? locale.chat.read_less : locale.chat.read_more}
														</span>
													)}

													{(!isRecall || message.recalled === 0) && (!message.linkPreview || message.linkPreview === {}) && (translatedTxt || translating || message.translated === 1) && (
														<IonCardContent className="translatedTxt-card-content">
															{translating && <IonSpinner name="crescent" />}
															{translatedTxt ? (
																<span dangerouslySetInnerHTML={{ __html: translatedTxt.replace(/&quot;/g, '"').replace(/&apos;/g, "'") }}></span>
															) : (
																message.translated === 1 &&
																message.translation && (
																	<span
																		dangerouslySetInnerHTML={{
																			__html: message.translation,
																		}}
																	></span>
																)
															)}
														</IonCardContent>
													)}
												</IonCard>
											</div>

											{align === 'right' && (
												<>
													{(isPendingSent || sentFailed) && (
														<div className="message-send-resend-view">
															{isTextType && isPendingSent && <CustomSpinner size={22} />}

															{sentFailed && (
																<IonImg
																	className="failed-icon"
																	src={FailedIcon}
																	onClick={() => {
																		setShowResendMessageModal(true);
																	}}
																/>
															)}
														</div>
													)}

													{isMediaType && message.mediaType[0] === EnumService.ChatMediaType.VIDEO && isPendingSent && (
														<div className="video-message-icon-view">
															<IonImg
																className="video-pause-icon"
																src={VideoMessageIcon}
																onClick={() => {
																	setShowResendMessageModal(true);
																}}
															/>
														</div>
													)}
												</>
											)}
										</>
									)}
								</div>
							)}
						</div>
					</div>
				</IonItem>

				<ThreadItemOptions ionItemSlideRef={ionItemSlideRef} />
			</IonItemSliding>

			<EmojiPopup
				show={showEmojiPopover || showQuickFunctionPopover}
				popupType={showEmojiPopover ? 'emoji' : showQuickFunctionPopover ? 'quickFunction' : ''}
				onHide={() => {
					setShowEmojiPopover(false);
					setLongpressPopupIsEnlarge(false);
					setShowQuickFunctionPopover(false);
				}}
				align={align}
				isEnlarge={longpressPopupIsEnlarge}
				canRecall={recallRemainingTimeInSeconds > 0}
				canTranslate={canTranslate}
				selectedThreadElementPosition={selectedThreadElementPosition}
				snippet={snippet}
				topRowItems={showEmojiPopover ? chapOptionOnClick[0] : chapOptionOnLongPress[0]}
				bottomRowItems={showEmojiPopover ? chapOptionOnClick[1] : chapOptionOnLongPress[1]}
				onItemSelect={(item: any) => {
					setShowEmojiPopover(false);
					setShowQuickFunctionPopover(false);
					emojiClickHandler(item.type);
				}}
			/>

			<ToastStylish
				show={showActionToast}
				onClose={() => {
					setShowActionToast(false);
					setToastIcon('');
					setShowActionToastMessage('');
				}}
				message={showActionToastMessage}
				svgIcon={toastIcon}
			/>

			<IonActionSheet mode="ios" header={actionSheetHeaderTitle} isOpen={showActionSheet} onDidDismiss={() => setShowActionSheet(false)} cssClass={'manage-contact-action-sheet '} buttons={actionsheetButtons}></IonActionSheet>

			<RecallPopupModal
				show={showRecallPopup}
				remainingTimeInSeconds={recallRemainingTimeInSeconds}
				onRecall={async () => {
					setShowRecallPopup(false);
					setIsLoading(true);

					await apiService.recallMessage({ ...message, recalled: 1 }).then(() => {
						setIsRecall(true);
						setIsLoading(false);
					});
				}}
				onCancel={() => {
					setShowRecallPopup(false);
				}}
			/>

			<ResendMessageModal
				show={showResendMessageModal}
				onCloseCallBack={() => {
					setShowResendMessageModal(false);
				}}
				onConfirm={() => {
					setShowResendMessageModal(false);
					resendMessage();
				}}
				onDelete={() => {
					setShowResendMessageModal(false);
					deleteMessage();
				}}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		pubsub: state.pubsub,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	onPublishData: (payload: String) => dispatch(publishData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThreadComponent);
