import React, { useMemo } from 'react';
import { blankProfilePic } from '../../../helpers/common';
import EnumService from '../../../services/enumService';
import SharedService from '../../../services/sharedService';
import './../style.scss';

const ThreadRepliedForView: React.FC<any> = ({ self, alignClassName, userMessageData, onClick }) => {
	const isTextType: Boolean = useMemo(() => userMessageData?.messageType === EnumService.ChatMessageType.TEXT, [userMessageData.messageType]);
	const isMediaType: Boolean = useMemo(() => userMessageData?.messageType === EnumService.ChatMessageType.MEDIA, [userMessageData.messageType]);
	const extractChatFromMsgbody = SharedService.extractChatFromMsgbody(userMessageData);

	let userProfileName: string = userMessageData.sender,
		userProfilePhoto = null;

	if (userMessageData.sender === 'Me') {
		userProfilePhoto = self.loggedInUser?.profilePhoto || blankProfilePic;
		userProfileName = userMessageData.sender;
	} else if (self.props.chat?.type === 'groupchat') {
		let receiverProfileDetail: any = self.conversations?.find((_contact: any) => _contact?.userId === self.props.chat?.receiver?.members?.find((_member: any) => _member.alias === userMessageData.sender)?.userId);
		userProfilePhoto = receiverProfileDetail?.profilePhoto || blankProfilePic;
		userProfileName = userMessageData.sender;
	} else {
		userProfilePhoto = self.props.chat?.receiver?.profilePhoto || blankProfilePic;
		userProfileName = self.props.chat?.receiver?.alias || self.props.chat?.receiver?.name || self.props.chat?.receiver?.username || self.props.chat?.receiver?.userId;
	}

	return (
		<div className={'replied-for-view ' + alignClassName} onClick={onClick}>
			<img className="profile-img" src={userProfilePhoto} alt="profilePicture" />
			<div className={'user-title-msg ' + (isMediaType ? 'image-type' : isTextType ? 'text-type' : '')}>
				<span className="title" dangerouslySetInnerHTML={{ __html: userProfileName?.replace('(', '(<span class="paranthesis">')?.replace(')', '</span>)') + ':' }}></span>
				&nbsp;
				{isTextType && extractChatFromMsgbody.snippet}
				{isMediaType && extractChatFromMsgbody.snippet}
			</div>
		</div>
	);
};

export default ThreadRepliedForView;
