import { apiService } from '../../services/apiService';
import { USER_LOGIN, USER_PROFILE_RESET_VALUES, USER_PROFILE_SHOW_LOADER } from '../constants/common';
import { PROFILE_UPDATE_ERROR, PROFILE_UPDATE_FAILED, PROFILE_UPDATE_SUCCESS, PROFILE_UPDATING } from '../constants/profileupdate';
import { compressSelectedFile } from '../../helpers/common';

export function resetValues() {
	return { type: USER_PROFILE_RESET_VALUES };
};

export function pageLoading(payload: any) {
	return { type: USER_PROFILE_SHOW_LOADER, payload: payload };
};

export function updateUser(payload: any) {
	const callBack: any = payload.callBack,
		updateServer: Boolean = payload.updateServer || false;
	
	if (callBack) {
		delete payload.callBack;
	}

	if (updateServer) {
		delete payload.updateServer;
	}
	
	return async function (dispatch: any) {
		dispatch({
			type: PROFILE_UPDATING,
			payload: {},
		});

		await apiService
			.updateUser(payload, updateServer)
			.then((response: any) => {
				if (response.Error) {
					dispatch({ type: PROFILE_UPDATE_FAILED, payload: response });
				} else {
					dispatch({ type: USER_LOGIN, payload: response });
					dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: response, postData: payload });
				}

				callBack && callBack(true, response);
			})
			.catch((error: any) => {
				dispatch({
					type: PROFILE_UPDATE_ERROR,
					payload: error,
				});
				callBack && callBack(false, error);
			});
	};
};

export function uploadMedia(payload: any) {
	return async function () {

		// create the thumbnail
		payload.profileThumb = await compressSelectedFile(payload.profilePhoto, { x: 40, y: 40, fit: 'contain', upscale: false });
		return await apiService.uploadMedia(payload);
	};
};
