import { getInternalStorage, setInternalStorage, info, logError } from './helpers/common';
import { AppManager } from './services/appManager';

let cookies: any = getInternalStorage();
//let upgradeNotified: boolean = false;

const isLocalhost: Boolean = Boolean(
	window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.0/8 are considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

let registration: any;

export function register(config: any) {
	if (!cookies.isApp && /*process.env.NODE_ENV === 'production' &&*/ 'serviceWorker' in navigator) {
		// The URL constructor is available in all browsers that support SW.
		const publicUrl: any = new URL(process.env.PUBLIC_URL, window.location.href);

		if (publicUrl.origin !== window.location.origin) {
			// Our service worker won't work if PUBLIC_URL is on a different origin
			// from what our page is served on. This might happen if a CDN is used to
			// serve assets; see https://github.com/facebook/create-react-app/issues/2374
			return;
		}

		window.addEventListener('load', () => {
			const swUrl: string = `${process.env.PUBLIC_URL}/service-worker.js`;
			//info(`serviceWorkerRegistration::register: swUrl is ${swUrl}`);
			if (isLocalhost) {
				// This is running on localhost. Let's check if a service worker still exists or not.
				checkValidServiceWorker(swUrl, config);

				// Add some additional logging to localhost, pointing developers to the
				// service worker/PWA documentation.
				navigator.serviceWorker.ready.then(() => {
					info('serviceWorkerRegistration::register: This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA');
				});
			} else {
				// Is not localhost. Just register service worker
				registerValidSW(swUrl, config);
			}
		});
	}
}

async function registerValidSW(swUrl: string, config: any) {
	const checkWaiting = (registration: any) => {
		if (registration && registration.waiting) {
			navigator.serviceWorker.getRegistration().catch((err) => info('Could not get registration: ', err));
		}
	};

	registration = navigator.serviceWorker
		.register(`.${swUrl}`)
		.then((registration: any) => {
			if (navigator.vendor === 'Apple Computer, Inc.') {
				checkWaiting(registration);
			}
			registration.onupdatefound = () => {
				const installingWorker = registration.installing;
				if (installingWorker === null) {
					return;
				}
				installingWorker.onstatechange = () => {
					if (installingWorker.state === 'installed') {
						if (navigator.serviceWorker.controller) {
							// Execute callback
							if (config && config.onUpdate) {
								config.onUpdate(registration);
							}

							registration.waiting.postMessage({ type: 'SKIP_WAITING' });
						} else {
							info('serviceWorkerRegistration::registerValidSW Content is cached for offline use.');

							// Execute callback
							if (config && config.onSuccess) {
								config.onSuccess(registration);
							}
						}
					}
				};
			};

			info('serviceWorkerRegistration::registerValidSW registerValidSW:registered.');
			setInternalStorage('registered', true);
			return registration;
		})
		.catch((error) => {
			logError('serviceWorkerRegistration::registerValidSW Error during service worker registration:', error);
		});

	
	await AppManager.handleVisibility({ type: 'initialisation' });
	await AppManager.askNotificationPermission();
	return registration;
}

function checkValidServiceWorker(swUrl: string, config: any) {
	// Check if the service worker can be found. If it can't reload the page.
	fetch(swUrl, {
		headers: { 'Service-Worker': 'script' },
	})
		.then((response: any) => {
			// Ensure service worker exists, and that we really are getting a JS file.
			const contentType: any = response.headers.get('content-type');
			if (response.status === 404 || (contentType != null && !contentType.includes('javascript'))) {
				// No service worker found. Probably a different app. Reload the page.
				navigator.serviceWorker.ready.then((registration) => {
					registration.unregister().then(() => {
						AppManager.reload()
					});
				});
			} else {
				// Service worker found. Proceed as normal.
				registerValidSW(swUrl, config);
			}
		})
		.catch(() => {
			logError('serviceWorkerRegistration::checkValidServiceWorker No internet connection found. App is running in offline mode.');
		});
}

export function unregister() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready
			.then((registration: any) => {
				registration.unregister();
			})
			.catch((error: any) => {
				console.error(error.message);
			});
	}
}
