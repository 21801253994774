/* eslint-disable @typescript-eslint/no-unused-expressions */
import _ from 'lodash';
import metadata from '../metadata.json';
import store from '../redux/store';
import { USER_LOGOUT } from '../redux/constants/common';
import { Config } from '../config/config';
import { apiService } from '../services/apiService';
import { xmpp } from '../services/xmpp';
//import { Storage } from '@capacitor/storage';
import { locale } from '../locales/local';
import { createDefaultImageReader, createDefaultImageWriter, PinturaEditorOptions, processImage } from 'pintura';

export const blankProfilePic = './assets/img/blank-user-profile.png';

export async function fetchProfilePic(id: any = 'placeholder', userType: string = 'user', fullImage: Boolean = false) {
	let response: any, mediaSource: any;

	if (id === 'placeholder') {
		response = blankProfilePic;
	} else if (userType === 'user') {
		mediaSource = await apiService.me();
		response = !isBlank(mediaSource) ? (fullImage ? mediaSource.profileThumb || mediaSource.profilePhoto : mediaSource.profilePhoto) : blankProfilePic;
	} else if (userType === 'contact') {
		mediaSource = await apiService.getContactByUserId(id);
		response = !isBlank(mediaSource) ? (fullImage ? mediaSource.profileThumb || mediaSource.profilePhoto : mediaSource.profilePhoto) : blankProfilePic;
	} else if (userType === 'group') {
		mediaSource = await apiService.getGroupByJid(id);
		response = !isBlank(mediaSource) ? (fullImage ? mediaSource.groupThumb || mediaSource.groupPhoto : mediaSource.groupPhoto) : blankProfilePic;
	}

	return response;
}
async function preloadImage(image: string) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = resolve;
		img.onerror = reject;
		img.src = image;
	});
}

const reload = [];
export async function prefixMedia(mediaSource: any, preloadGallery: Boolean = false) {
	if (isBlank(mediaSource)) {
		logError(`common::prefixMedia: mediaSource is empty!`);
	} else if (_.isString(mediaSource) && mediaSource.startsWith('/')) {
		mediaSource = `${Config.IMAGE_PATH}${mediaSource}`;

		if (preloadGallery) {
			await preloadImage(mediaSource)
				.then(() => true)
				.catch((error: any) => {
					logError(`common::prefixMedia: cannot retrieve mediaSource for ${mediaSource}.  queuing to retry later`);
					reload.push(mediaSource);
				});
		}
	} else {
		if (mediaSource.profilePhoto && mediaSource.profilePhoto.startsWith('/') && !mediaSource.profilePhoto.startsWith(Config.IMAGE_PATH)) {
			mediaSource.profilePhoto = `${Config.IMAGE_PATH}${mediaSource.profilePhoto}`;

			await preloadImage(mediaSource.profilePhoto).catch((error: any) => {
				logError(`common::prefixMedia: cannot retrieve profilePhoto for ${mediaSource.userId},  ${mediaSource.profilePhoto}:`, error);
				mediaSource.profilePhoto = `/assets/img/blank-user-profile.png`;
			});
		}

		if (mediaSource.profileThumb && mediaSource.profileThumb.startsWith('/') && !mediaSource.profileThumb.startsWith(Config.IMAGE_PATH)) {
			mediaSource.profileThumb = `${Config.IMAGE_PATH}${mediaSource.profileThumb}`;

			await preloadImage(mediaSource.profileThumb).catch((error: any) => {
				logError(`common::prefixMedia: cannot retrieve profilePhoto for ${mediaSource.userId},  ${mediaSource.profileThumb}:`, error);
				mediaSource.profileThumb = `/assets/img/blank-user-profile.png`;
			});
		}

		if (mediaSource.groupPhoto && mediaSource.groupPhoto.startsWith('/') && !mediaSource.groupPhoto.startsWith(Config.IMAGE_PATH)) {
			mediaSource.groupPhoto = `${Config.IMAGE_PATH}${mediaSource.groupPhoto}`;

			await preloadImage(mediaSource.groupPhoto).catch((error: any) => {
				logError(`common::prefixMedia: cannot retrieve groupPhoto for ${mediaSource.groupname}, ${mediaSource.groupPhoto}:`, error);
				mediaSource.groupPhoto = `/assets/img/blank-group-profile.png`;
			});
		}

		if (mediaSource.groupThumb && mediaSource.groupThumb.startsWith('/') && !mediaSource.groupThumb.startsWith(Config.IMAGE_PATH)) {
			mediaSource.groupThumb = `${Config.IMAGE_PATH}${mediaSource.groupThumb}`;

			await preloadImage(mediaSource.groupThumb).catch((error: any) => {
				logError(`common::prefixMedia: cannot retrieve groupPhoto for ${mediaSource.groupname}, ${mediaSource.groupThumb}:`, error);
				mediaSource.groupThumb = `/assets/img/blank-group-profile.png`;
			});
		}

		if (mediaSource.qrCode && mediaSource.qrCode.startsWith('/') && !mediaSource.qrCode.startsWith(Config.IMAGE_PATH)) {
			mediaSource.qrCode = `${Config.IMAGE_PATH}${mediaSource.qrCode}`;

			await preloadImage(mediaSource.qrCode).catch((error: any) => {
				logError(`common::prefixMedia: cannot retrieve qrCode for ${mediaSource.userId || mediaSource.groupname}, ${mediaSource.qrCode}:`, error);
				mediaSource.qrCode = `/logo.webp`;
			});
		}

		if (mediaSource.gallery && mediaSource.gallery.length > 0) {
			for (let image of mediaSource.gallery) {
				if (!isBlank(image) && !isBlank(image.url) && image.url.startsWith('/') && !image.url.startsWith(Config.IMAGE_PATH)) {
					image.url = `${Config.IMAGE_PATH}${image.url}`;

					if (preloadGallery) {
						await preloadImage(image.url).catch((error: any) => {
							logError(`common::prefixMedia: cannot retrieve gallery item for ${image.url}:`, error);
							image.url = `/logo.webp`;
						});
					}
				}
			}
		}
	}

	return mediaSource;
}
export function unprefixMedia(conversation: any) {
	if (conversation.profilePhoto) {
		conversation.profilePhoto = conversation.profilePhoto.replace(Config.IMAGE_PATH, '');
	}

	if (conversation.groupPhoto) {
		conversation.groupPhoto = conversation.groupPhoto.replace(Config.IMAGE_PATH, '');
	}

	if (conversation.qrCode) {
		conversation.qrCode = conversation.qrCode.replace(Config.IMAGE_PATH, '');
	}

	if (conversation.gallery && conversation.gallery.length > 0) {
		for (let image of conversation.gallery) {
			if (typeof image === 'object') {
				image.url = image.url?.replace(Config.IMAGE_PATH, '');
			} else {
				image = image.replace(Config.IMAGE_PATH, '');
			}
		}
	}

	return conversation;
}
export function isBlank(value: any) {
	return (_.isString(value) && _.isEmpty(value)) || (_.isArray(value) && value.length === 0) || (_.isObject(value) && Object.keys(value).length === 0) || _.isNil(value) || _.isNull(value) || _.isUndefined(value);
}

export function sleep(ms: any) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isJsonString(str: any) {
	return !isBlank(str) && str.constructor === String && str.constructor !== Object && ((str.startsWith('{') && str.endsWith('}')) || (str.startsWith('[') && str.endsWith(']')));
}

export function validateEmail(email: any) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function getUsernameFromEmail(input: String = '') {
	let output = input.match(/(.*?)@.*?/);
	return output && output[1] ? output[1] : null;
}

export function validateUsername(userId: any) {
	let re = /^[a-zA-Z0-9_]{2,}[a-zA-Z_]+[0-9_]*$/;
	return re.test(String(userId).toLowerCase());
}

export function validatePhone(phone: any) {
	let re = /^\d{10}$/;
	return re.test(String(phone).toLowerCase()) || false;
}

export function info(data: any, extra1: any = '', extra2: any = '', showStack = false) {
	if (!_.includes([''], metadata.buildTag)) {
		let getStackTrace = function () {
			let obj: any = {};
			Error.captureStackTrace(obj, getStackTrace);
			let stack = obj.stack.split('\n');
			return stack.slice(2);
		};

		if (!showStack && !getInternalStorage().Safari) {
			console.info(data, extra1, extra2);
		} else {
			console.info(data, extra1, extra2, !getInternalStorage().Safari && !getInternalStorage().mobileDevice && getStackTrace());
		}
	}

	/*let cookies = getInternalStorage(),
		user = cookies.uuid ? await apiService.me() : undefined;

	if (cookies.mobileDevice && user && user.userId === 'doncarlosone') {
		apiService.sendStatus({
			uuid: cookies.uuid,
			info: `visibility: ${document.visibilityState}, displayMode: ${cookies.displayMode}. network: ${cookies.network}`,
			cookies: _.omit(cookies, ['listeners', 'sm']),
			xmpp: _.omit(xmpp, ['client', 'user']),
			logExtra1: extra1,
			logExtra2: extra2,
		});
	}*/
}

export function logError(data: any, extra1: any = '', extra2: any = '') {
	if (metadata.buildTag !== '') {
		let getStackTrace = function () {
			let obj: any = {};
			Error.captureStackTrace(obj, getStackTrace);
			let stack = obj.stack.split('\n');
			return stack.slice(2);
		};

		console.error(data, extra1, extra2, !getInternalStorage().Safari && !getInternalStorage().mobileDevice && getStackTrace());
	}

	/*let cookies = getInternalStorage(),
		user = cookies.uuid ? await apiService.me() : undefined;

	if (cookies.mobileDevice && user && user.userId === 'doncarlosone') {
		apiService.sendStatus({
			logError: `visibility: ${document.visibilityState}, displayMode: ${cookies.displayMode}. network: ${cookies.network}`,
			cookies: _.omit(cookies, ['listeners', 'sm']),
			xmpp: _.omit(xmpp, ['client', 'user']),
			logExtra1: extra1,
			logExtra2: extra2,
		});
	}*/
}

export function isConversationOpen() {
	const currentPath: any[] = window.location.href.split('/'),
		pathLast: any = currentPath?.pop(),
		pathSecondLast: any = currentPath?.pop();

	return pathSecondLast === 'chat' || pathLast === 'personal-notepad';
}

export function getActiveConversation(user: any) {
	const currentPath: any[] = window.location.href.split('/'),
		pathLast: any = currentPath?.pop(),
		pathSecondLast: any = currentPath?.pop();

	if (isBlank(user)) {
		user = (async () => await apiService.me())();
	}

	return pathSecondLast === 'chat' ? pathLast : pathLast === 'personal-notepad' ? user?.userId : undefined;
}

export function isPersonalNotepad() {
	const currentPath: any[] = window.location.href.split('/'),
		pathLast: any = currentPath?.pop();

	return pathLast === 'personal-notepad';
}

export function isDashboardOpen() {
	const currentPath: any[] = window.location.href.split('/'),
		pathSecondLast: any = currentPath?.pop();

	return pathSecondLast === 'auth';
}

export function getInternalStorage() {
	const items: any = _.omitBy(
		{
			personalDevice: localStorage.getItem('personalDevice') === '+1',
			supportsPush: localStorage.getItem('supportsPush') === '+1',
			supportsNotifications: localStorage.getItem('supportsNotifications') === '+1',
			pushNotifictions: localStorage.getItem('pushNotifictions') === '+1',
			uuid: localStorage.getItem('uuid'),
		},
		isBlank
	);

	try {
		const exclusions: any[] = ['epr_ru', 'toResend', 'secure__ls__metadata', 'com.be-society', 'x-storagemutated-1'],
			compressed: any[] = ['sm'];
		//secureExclusions: any[] = ['up'],

		for (let _item of Object.keys(sessionStorage)) {
			if (!_.includes(exclusions, _item)) {
				items[_item] = sessionStorage[_item];

				let ascii = Buffer.from(items[_item] as string, 'base64').toString('ascii');

				if (items[_item] === null) {
					items[_item] = null;
				} else if (items[_item] === '+1' || items[_item] === 'true') {
					items[_item] = true;
				} else if (items[_item] === '-1' || items[_item] === 'false') {
					items[_item] = false;
				} else if (!isNaN(items[_item])) {
					items[_item] = parseInt(items[_item]);
				} else if (_.includes(compressed, _item)) {
					items[_item] = JSON.parse(items[_item]);
				} else if (isJsonString(items[_item]) || isJsonString(ascii)) {
					if (ascii && _item !== 'tk') {
						items[_item] = JSON.parse(ascii);
					}

					if (_.isArray(items[_item])) {
						for (let index in items[_item]) {
							if (items[_item][index].fn) {
								// potentially unsafe eval here, but acceptable as it is tightly controlled
								items[_item][index].fn = eval(`(${items[_item][index].fn})`);
							}
						}
					}
				}
				//}
			}
		}
	} catch (error) {
		deleteItemFromInternalStorage('uuid');
	}

	return items;
}

export function setInternalStorage(item: string, value: any) {
	const //secureExclusions: any[] = ['up'],
		localStorageItems = ['pushNotifications', 'personalDevice', 'uuid', 'supportsPush', 'supportsNotification'],
		compressed: any[] = ['sm'];

	if (value === 'true' || value === true) {
		value = '+1';
	} else if (value === 'false' || value === false) {
		value = '-1';
	} else if (_.isArray(value)) {
		for (let index in value) {
			if (value[index].fn) {
				value[index].fn = value[index].fn.toString();
			}
		}

		value = Buffer.from(JSON.stringify(value) as string, 'ascii').toString('base64');
	} else if (_.includes(compressed, item)) {
		value = JSON.stringify(value);
	} else if (_.isObject(value)) {
		value = Buffer.from(JSON.stringify(value) as string, 'ascii').toString('base64');
	} else if (!isNaN(value)) {
		value = parseInt(value);
	}

	if (_.includes(localStorageItems, item)) {
		localStorage[item] = value;
	} else {
		sessionStorage[item] = value;
	}
}

export function deleteItemFromInternalStorage(item: string) {
	const //secureExclusions: any[] = ['up'],
		localStorageItems = ['pushNotifications', 'personalDevice', 'uuid', 'supportsPush', 'supportsNotification'];

	if (_.includes(localStorageItems, item)) {
		delete localStorage[item];
	} else {
		delete sessionStorage[item];
	}
}

export function clearInternalStorage() {
	sessionStorage.clear();
}

export function sortByTimestamp(a: any, b: any) {
	let a1: any = a?.originalTimestamp || a?.lastMessage?.originalTimestamp,
		b1: any = b?.originalTimestamp || b?.lastMessage?.originalTimestamp,
		earlier: number = a?.originalTimestamp ? -1 : 1,
		later: number = a?.originalTimestamp ? 1 : -1,
		response: number = 0;

	if (a1) {
		response = new Date(a1).getTime() > new Date(b1).getTime() ? later : new Date(a1).getTime() < new Date(b1).getTime() ? earlier : 0;
	}

	return response;
}

export function sortByAlias(a: any, b: any) {
	let a1: any = (a?.alias || a?.userId || a?.username || a?.groupName).toLowerCase(),
		b1: any = (b?.alias || b?.userId || b?.username || b?.groupName).toLowerCase();

	return a1 > b1 ? 1 : b1 > a1 ? -1 : 0;
}

export function dataURItoBlob(dataURI: any) {
	let mimeType = '';
	try {
		mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];
	} catch (error) {}
	return new Blob([Buffer.from(dataURI.replace(/^data:image\/\w+;base64,/, ''), 'base64')], { type: mimeType });
}

function blobToDataURI(blob: Blob) {
	return new Promise((resolve, reject) => {
		const fr = new FileReader();
		fr.onload = () => {
			resolve(fr.result);
		};
		fr.onerror = reject;
		fr.readAsDataURL(blob);
	});
}

export async function compressSelectedFile(file: any, size: any = undefined, callback: any = undefined) {
	let response: any;
	let mimeType = '';
	try {
		mimeType = file.split(',')[0].split(':')[1].split(';')[0];
	} catch (error) {}

	if (!['image/jpeg', 'image/png', 'image/jpg', 'image/webp'].includes(mimeType)) {
		response = { hasError: true, errorMessage: locale.groups.invalid_file_format };
	} else {
		const options: PinturaEditorOptions = {
			locale: 'en',
			imageReader: createDefaultImageReader(),
			imageWriter: createDefaultImageWriter({ mimeType: 'image/webp' }),
			imageTargetSize: {
				width: size.x,
				height: size.y,
			},
		};

		const image: any = await processImage(file, options);
		response = await blobToDataURI(image.dest);
		if (callback) {
			callback(image);
		}
	}

	return response;
}

export async function logoutUser(forceDatabaseReset: Boolean = false) {
	let cookies: any = getInternalStorage();
	info('common::logout:: requested');

	store.dispatch({ type: USER_LOGOUT });

	if (xmpp.client && xmpp.isReady) {
		xmpp.isLoggingOut = true;
		info('common::logout:: calling xmpp.disconnect');
		await xmpp.disconnect();
	}

	if ('serviceWorker' in window.navigator && (!cookies.personalDevice || forceDatabaseReset)) {
		info('common::logout: web browser and not personal device. removing database');
		await apiService.deleteDatabase(cookies.db).catch('DatabaseClosedError', (error: any) => logError(`common::logoutUser::deleteDatabase::Error`, error));
		deleteItemFromInternalStorage('db');
	} else {
		deleteItemFromInternalStorage('sm');
		deleteItemFromInternalStorage('registered');
		deleteItemFromInternalStorage('active');
	}

	clearInternalStorage();
	deleteItemFromInternalStorage('uuid');
	info('common::logout:: reloading now');
	window.location.href = '/';
}
