import React from 'react';
import { IonToggle, IonPage, IonButton, IonIcon, IonToolbar, IonCol, IonContent, IonItem, IonList, IonRow, IonInput, IonTextarea, IonLoading, IonButtons, IonLabel, IonText } from '@ionic/react';
import './style.scss';
import { checkmark, checkmarkOutline, chevronBack, chevronForwardOutline } from 'ionicons/icons';
import { connect } from 'react-redux';
import store from '../../redux/store';
import { ADD_USER_RESET_VALUES, ADD_USER_SHOW_LOADER } from '../../redux/constants/searchUser';
import { sendContactRequest } from '../../redux/actions/addContact';
import { addContact, denyContact, confirmContact } from '../../redux/actions/addContact';
import { initChat } from '../../redux/actions/chat';
import { resetValues } from '../../redux/actions/dashboard';
import BottomNavbar from '../common/navbar/bottomNavbar';
import EnumService from '../../services/enumService';
import { DASHBOARD_LAST_MESSAGE_CLEAR } from '../../redux/constants/dashboard';
import ToastStylish from '../../components/ToastStylish';
import { locale } from '../../locales/local';
import { apiService } from '../../services/apiService';

interface iProps {
	addContacts: any;
	sendContactRequest: Function;
	isLoading: boolean;
	loggedInUser: any;
	history: any;
	dashboard: any;
	location: any;
	confirmContact: Function;
	resetValues: Function;
	initChat: Function;
}
interface iState {
	personalMessage: string;
	tags: string;
	alias: string;
	remarks: string;
	friendPrivacySettingType: any;
	loggedInUser: any;
	muteNotification?: boolean;
	stickOnTop?: boolean;
	starred?: boolean;
	showToast: boolean;
	toastMessage: string;
	toastIcon?: string;
	contact?: any;
}
class AddContacts extends React.Component<iProps, iState> {
	contactsPrivacySettings = [
		{ title: locale.add_contacts.moments.title, description: locale.add_contacts.moments.description, value: 'moments' },
		{ title: locale.add_contacts.chats_only.title, description: locale.add_contacts.chats_only.description, value: 'chats' },
	];

	friendProfileActionType: any;
	componentIsMounted: Boolean = false;
	componentIsUpdating: Boolean = false;
	visibleFields = {
		personalMessage: false,
		tags: false,
		alias: false,
		privacySetting: false,
		contactSetting: false,
	};

	constructor(props: iProps) {
		super(props);
		this.friendProfileActionType = this.props.location?.state?.friendProfileActionType;

		this.state = {
			showToast: false,
			toastMessage: '',
			toastIcon: '',
			loggedInUser: undefined,
			personalMessage: '',
			tags: '',
			alias: '',
			remarks: '',
			friendPrivacySettingType: null,
			muteNotification: false,
			stickOnTop: false,
			starred: false,
			contact: {},
		};

		this.props.resetValues();

		if (this.friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactAdd) {
			this.visibleFields.personalMessage = true;
			this.visibleFields.alias = true;
			this.visibleFields.tags = true;
			this.visibleFields.privacySetting = true;
		} else if (this.friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactRequestView) {
			this.visibleFields.alias = true;
			this.visibleFields.tags = true;
			this.visibleFields.contactSetting = true;
			this.visibleFields.privacySetting = true;
		}
	}

	async setPageData(state: any = undefined) {
		if (state) {
			this.setState((state: any, props: any) => ({
				...state,
			}));
		} else {
			let user = await apiService.me();

			this.setState((state: any, props: any) => ({
				loggedInUser: user,
				personalMessage: `${locale.add_contacts.personal_message_prefix}${user.userId}`,
				alias: this.props.location.state ? this.props.location.state.data.alias : user.alias,
				contact: this.props.location.state ? this.props.location.state.data : {},
			}));
		}

		this.componentIsMounted = true;
	}

	async componentDidMount() {
		//info('addContact::componentDidMount:start');
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
		//info('addContact::componentDidMount:end');
	}

	async componentDidUpdate() {
		if (this.componentIsMounted && !this.componentIsUpdating) {
			//info('addContact::componentDidUpdate:start');
			this.componentIsUpdating = true;
			let state: any = this.state;

			state.loggedInUser = await apiService.me();

			if (this.props.addContacts.userRequestSent) {
				state = {
					...state,
					toastIcon: checkmarkOutline,
					toastMessage: locale.toast_messages.send,
					showToast: true,
				};

				store.dispatch({ type: ADD_USER_RESET_VALUES });

				setTimeout(() => {
					this.props.history.replace('/contacts');
				}, 1000);
			} else if (this.props.dashboard.requestConfirmSuccess) {
				this.props.resetValues();

				const data = state.loggedInUser;
				data.status = 'confirmed';
				data.handlerText = locale.add_contacts.handler_text.start_chat;
				this.props.initChat(data);
				store.dispatch({ type: DASHBOARD_LAST_MESSAGE_CLEAR, payload: data });

				state = {
					...state,
					toastIcon: checkmarkOutline,
					toastMessage: locale.toast_messages.accepted,
					showToast: true,
				};

				setTimeout(() => {
					//this.props.history.replace(`/chat/${this.user.jid.split('@')[0]}`); // ........ comes up with no heading, etc
					this.props.history.replace(`/contacts`);
				}, 1000);
			}

			await this.setPageData(state);
			this.componentIsUpdating = false;
			//info('addContact::componentDidUpdate:end');
		} else {
			//info('addContact::componentDidUpdate:ignored');
		}
	}

	async sendRequest() {
		const { alias, tags, remarks } = this.state,
			tagsArr = tags ? tags.split(' ') : [];

		this.props.sendContactRequest({
			contactId: this.props?.location?.state?.data?._id,
			alias: alias,
			personalMessage: this.state.personalMessage,
			remarks: remarks,
			tags: tagsArr,
		});
	}

	async confirmContact(contact: any) {
		const { alias, tags, remarks } = this.state,
			tagsArr = tags ? tags.split(' ') : [];

		this.props.confirmContact({ ...contact, alias: alias, remarks: remarks, tags: tagsArr });
	}

	_renderHeader() {
		return (
			<IonToolbar className="toolbar-wrapper">
				<IonToolbar className="toolbar-wrapper">
					<div className="icon" onClick={() => this.props.history.goBack()}>
						<IonIcon size="large" slot="icon-only" icon={chevronBack} />
					</div>
					<IonButtons slot="end">
						{this.friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactAdd && (
							<IonButton className="send-button" disabled={!this.state.friendPrivacySettingType || !this.state.personalMessage} onClick={() => this.sendRequest()}>
								{locale.add_contacts.info.send}
							</IonButton>
						)}

						{this.friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactRequestView && (
							<IonButton className="send-button" disabled={!this.state.friendPrivacySettingType} onClick={() => this.confirmContact(this.state?.contact)}>
								{locale.add_contacts.info.done}
							</IonButton>
						)}
					</IonButtons>
				</IonToolbar>
			</IonToolbar>
		);
	}

	_renderSwitchItem(title: any, value: any, onChange: any) {
		return (
			<div className={'swtich-item'}>
				<IonToggle className="toggle-customised" mode="ios" value={value} onChange={onChange} />
				<IonLabel>{title}</IonLabel>
			</div>
		);
	}

	render() {
		const { alias, tags } = this.state,
			charLimit = 100;

		return (
			<IonPage className="add-contact-page">
				{this._renderHeader()}

				<IonContent className="auth-wrapper contact-wrap has-bottom-navbar" fullscreen>
					<IonRow className="initialRow">
						<IonCol style={{ textAlign: 'center' }}>
							<IonLabel className="title">
								{this.friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactAdd && locale.add_contacts.info.send_friend_req}
								{this.friendProfileActionType === EnumService.ProfileFriendActionTypes.ContactRequestView && locale.add_contacts.info.edit_friend_req}
							</IonLabel>
						</IonCol>
					</IonRow>

					<br />

					<IonList style={{ background: 'transparent' }}>
						{this.visibleFields.personalMessage && (
							<IonItem lines="none" className="">
								<IonLabel position="stacked">{locale.add_contacts.label.message}</IonLabel>
								<IonTextarea
									rows={3}
									cols={20}
									value={this.state.personalMessage}
									onIonChange={(e: any) => {
										if (charLimit && e.detail.value && charLimit < e.detail.value.length) {
											this.setState({ personalMessage: this.state.personalMessage! });
										} else {
											this.setState({ personalMessage: e.detail.value! });
										}
									}}
								></IonTextarea>
								<IonText className="reaminig-letter-label">{this.state.personalMessage ? charLimit - this.state.personalMessage.length : 100}</IonText>
							</IonItem>
						)}

						{this.visibleFields.alias && (
							<IonItem lines="none">
								<IonLabel position="stacked">
									{locale.add_contacts.label.set_alias} for {this.props?.location?.state?.data?.userId}
								</IonLabel>
								<IonInput disabled={false} value={alias} onIonChange={(e) => this.setState({ alias: e.detail.value! })}></IonInput>
							</IonItem>
						)}

						{this.visibleFields.tags && (
							<IonItem lines="none">
								<IonLabel position="stacked">{locale.add_contacts.label.set_tags}</IonLabel>
								<IonItem
									lines="none"
									className="tags-inner-item"
									onClick={() => {
										this.props.history.replace('/tags', { shouldSelectTag: true });
									}}
								>
									<IonInput placeholder="Classify contacts by tags" disabled={false} value={tags} onIonChange={(e) => this.setState({ tags: e.detail.value! })}></IonInput>
									<IonButton slot="end" fill="clear">
										<IonIcon icon={chevronForwardOutline}></IonIcon>
									</IonButton>
								</IonItem>
							</IonItem>
						)}

						{/* <IonItem lines="none">
								<IonLabel position="stacked">Set Remark</IonLabel>
								<IonInput disabled={false} value={remarks} onIonChange={(e) => this.setState({ remarks: e.detail.value! })}></IonInput>
							</IonItem> */}

						{this.visibleFields.contactSetting && (
							<div className="setting-toggle-container">
								{this._renderSwitchItem(locale.add_contacts.info.mute_notifications, this.state.muteNotification, () => {
									this.setState({ muteNotification: !this.state.muteNotification });
								})}
								<div className="vertical-line"></div>
								{this._renderSwitchItem(locale.add_contacts.info.stick_on_top, this.state.stickOnTop, () => {
									this.setState({ stickOnTop: !this.state.stickOnTop });
								})}
								<div className="vertical-line"></div>
								{this._renderSwitchItem(locale.add_contacts.info.starred, this.state.starred, () => {
									this.setState({ starred: !this.state.starred });
								})}
							</div>
						)}

						{this.visibleFields.privacySetting && (
							<>
								<br />
								<IonLabel className="privacy-title">{locale.add_contacts.label.privacy_settings}</IonLabel>

								{this.contactsPrivacySettings.map((item, key) => {
									const isSelected = this.state.friendPrivacySettingType === item.value;
									return (
										<IonItem
											lines="none"
											key={key}
											className={'privacy-item ' + (isSelected ? 'selected' : '')}
											onClick={() => {
												this.setState({ friendPrivacySettingType: item.value });
											}}
										>
											<IonLabel>
												<h2>{item.title}</h2>
												<p>{item.description}</p>
											</IonLabel>
											{isSelected && (
												<IonButton fill="clear">
													<IonIcon slot="icon-only" icon={checkmark}></IonIcon>
												</IonButton>
											)}
										</IonItem>
									);
								})}
							</>
						)}
					</IonList>

					{/* {this.props.auth.hasErrors ? <><IonRow><IonCol><p className="warnings">{this.props.auth.errorMessage}</p></IonCol></IonRow></> : null} */}
				</IonContent>
				<IonLoading isOpen={this.props.addContacts.isLoading} onDidDismiss={() => store.dispatch({ type: ADD_USER_SHOW_LOADER, payload: false })} message={locale.global.please_wait} duration={5000} />
				<IonLoading isOpen={this.props.dashboard.isLoading} message={this.props.dashboard.loaderMessage} />

				<BottomNavbar unreadCount={0} {...this.props} />

				<ToastStylish
					show={this.state.showToast}
					message={this.state.toastMessage}
					svgIcon={this.state.toastIcon}
					onClose={() => {
						if (this.componentIsMounted) {
							this.setState({ showToast: false, toastIcon: '', toastMessage: '' });
						}
					}}
				/>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		dashboard: state.dashboard,
		addContacts: state.addContacts,
	};
};
const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	sendContactRequest: (payload: String) => dispatch(sendContactRequest(payload)),
	confirmContact: (payload: any) => dispatch(confirmContact(payload)),
	addContact: (payload: any) => dispatch(addContact(payload)),
	denyContact: (payload: any) => dispatch(denyContact(payload)),
	initChat: (payload: any) => dispatch(initChat(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddContacts);
