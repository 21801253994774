import React from 'react';
import { IonContent, IonPage, IonAlert } from '@ionic/react';
import './AddMembers.scss';
import { RouteComponentProps } from 'react-router';
import { TopNavbar } from '../../common/header/topbar';
import { ContactsList } from '../components/ContactsList';
import { connect } from 'react-redux';
import { apiService } from '../../../services/apiService';
import { resetValues } from '../../../redux/actions/dashboard';
import { sortByAlias } from '../../../helpers/common';
import _ from 'lodash';
import EnumService from '../../../services/enumService';
import { ContactAlbhabetView } from '../components/ContactAlbhabetView';
import { sharedService } from '../../../services/sharedService';

const CloseIcon = './assets/icon/close-icon-outline.svg';
const GroupsChatIcon = './assets/icon/groups-chats-icon.svg';
const ChatsOnlyIcon = './assets/icon/chats-only-icon.svg';
const TaggedFilled = './assets/icon/tag-filled.svg';
const BlankUserProfile = './assets/img/blank-user-profile.png';

interface iProps extends RouteComponentProps<{ name: string }> {
	//addGroup: Function;
	resetValues: Function;
	location: any;
	dashboard: any;
}
interface iState {
	loggedInUser?: any;
	contacts: any;
	contactsType: any;
	selectedContactsId: any;
	selectedContacts: any;
	pageActionType: string;
	showSearchContactsModal: boolean;
	showAlert: boolean;
}

const ContactsType = {
	All: 'all',
	GroupChats: 'groups-chats',
	Tags: 'tags',
	ChatsOnly: 'chats-only',
};

class AddMembers extends React.Component<iProps, iState> {
	isComponentMounted: boolean = false;

	constructor(props: iProps) {
		super(props);

		let state: any = props.history.location.state;
		let contactsType = ContactsType.All;
		let actionType = state.actionType;
		if (state?.addContactType === 'groups') {
			contactsType = ContactsType.GroupChats;
		} else if (state?.addContactType === 'friends') {
			contactsType = ContactsType.ChatsOnly;
		}

		this.state = {
			loggedInUser: {},
			showSearchContactsModal: false,
			showAlert: false,
			contacts: [],
			selectedContactsId: [],
			selectedContacts: [],
			contactsType: contactsType,
			pageActionType: actionType,
		};
	}

	async setPageData() {
		let user = await apiService.me(),
			contacts: any = user.contacts.filter((_contact: any) => _contact.status === 'confirmed').sort(sortByAlias);
			/*contacts: any = await apiService.getContacts(false).then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});

		contacts = contacts.filter((_contact: any) => _.includes(['confirmed'], _contact?.status));*/

		if (this.isComponentMounted) {
			this.setState((state: any, props: any) => ({
				...state,
				loggedInUser: user,
				contacts: contacts,
			}));
		}
	}

	async componentDidMount() {
		this.isComponentMounted = true;
		await this.setPageData();
	}

	async componentDidUpdate() {
		await this.setPageData();
	}

	componentWillUnmount() {
		this.isComponentMounted = false;
	}

	setSearchText() {}

	_onDone = () => {};

	_onClose = () => {
		this.props.history.goBack();
	};

	async scrollToContactSection(elementId: string, ionContentId: string) {
		const element: any = document.getElementById(elementId);
		let ionContent: any = document.getElementById(ionContentId);
		if (ionContent) {
			const elem = await ionContent.getScrollElement();
			elem?.scrollTo(0, element.offsetTop);
		}
	}

	_onContactSelect = (contact: any) => {
		let tmpList: any = this.state.selectedContactsId || [];
		let contactsList: any = this.state.selectedContacts || [];
		const contactIdIndex = tmpList.indexOf(contact?._id);
		if (contactIdIndex === -1) {
			if (this.state.selectedContactsId.length >= sharedService.groupMaxMembersLimit) {
				this.setState({ showAlert: true });
			} else {
				const selectedContactsContainerElem = document.getElementById('selectedContactsContainer');
				if (selectedContactsContainerElem && selectedContactsContainerElem?.scrollWidth > 0) {
					setTimeout(() => {
						selectedContactsContainerElem?.scrollTo(selectedContactsContainerElem.scrollWidth, 0);
					}, 200);
				}
				tmpList.push(contact._id);
				contactsList.push(contact);
			}
		} else {
			tmpList.splice(contactIdIndex, 1);
			contactsList.splice(contactIdIndex, 1);
		}
		this.setState({ selectedContactsId: tmpList, selectedContacts: contactsList });
	};

	extractContactName = (contact: any) => {
		return contact?.alias || contact?.username || contact?.userId || '';
	};

	render() {
		const { contactsType, pageActionType, selectedContactsId, selectedContacts } = this.state;

		let alphabetsList = this.state.contacts.map((contact: any) => {
			const contactName = contact?.alias || contact?.username || contact?.userId || '';
			return contactName.charAt(0);
		});
		alphabetsList = _.uniq(alphabetsList).sort();

		let pageTitle = '',
			rightBtnTitle = 'Done()',
			rightButtonDisable = true,
			showSelectedContactsView = false,
			previousSelectedContactsIds;
		if (pageActionType === EnumService.AddMembersPageActionType.ChooseContactForTag) {
			if (selectedContactsId?.length > 0) {
				rightBtnTitle = 'Done (' + selectedContactsId.length + ')';
				rightButtonDisable = false;
			}

			if (contactsType === ContactsType.All) {
				pageTitle = 'Contacts';
			} else if (contactsType === ContactsType.GroupChats) {
				pageTitle = 'Groups';
			} else {
				pageTitle = 'Chats Only';
			}
		} else if (pageActionType === EnumService.AddMembersPageActionType.ChooseContactForGroup || pageActionType === EnumService.AddMembersPageActionType.ChooseContactForCurrentGroup) {
			let state: any = this.props.history.location.state;
			previousSelectedContactsIds = state?.previousSelectedContactsIds;

			pageTitle = 'Create Group';
			if (selectedContactsId?.length > 0) {
				showSelectedContactsView = true;
				if (pageActionType === EnumService.AddMembersPageActionType.ChooseContactForCurrentGroup) {
					rightBtnTitle = 'Done (' + selectedContactsId.length + ')';
					if (selectedContactsId?.length > 0) {
						rightButtonDisable = false;
					}
				} else {
					rightBtnTitle = 'Next (' + selectedContactsId.length + ')';
					if (selectedContactsId?.length > 1) {
						rightButtonDisable = false;
					}
				}
			} else {
				if (pageActionType === EnumService.AddMembersPageActionType.ChooseContactForCurrentGroup) {
					rightBtnTitle = 'Done ()';
				} else {
					rightBtnTitle = 'Next ()';
				}
			}
		}

		return (
			<>
				<IonPage className="add-members-page">
					<TopNavbar
						{...this.props}
						leftButtonIcon={CloseIcon}
						pageTitle={pageTitle}
						rightButtonText={rightBtnTitle}
						rightButtonDisabled={rightButtonDisable}
						searchTypeHandler={this.setSearchText}
						// searchHandler={this.searchAllGroups}
						onSearchBarFocus={() => {
							// this.setState({
							// 	showSearchContactsModal: true,
							// });
						}}
						onLeftButtonPress={this._onClose}
						onRightButtonPress={() => {
							if (pageActionType === EnumService.AddMembersPageActionType.ChooseContactForGroup) {
								this.props.history.push('/group-manager', {
									selectedContactIds: this.state.selectedContactsId,
									selectedContacts: this.state.selectedContacts,
								});
							} else if (pageActionType === EnumService.AddMembersPageActionType.ChooseContactForCurrentGroup) {
								const state: any = this.props.history.location.state;
								if (state?.fromPageKey) {
									sharedService.callBackData = {
										...sharedService.callBackData,
										[state?.fromPageKey]: {
											groupAllContacts: state?.previousSelectedContacts ? [...state.previousSelectedContacts, ...this.state.selectedContacts] : [...this.state.selectedContacts],
											groupAllContactIds: state?.previousSelectedContactsIds ? [...state.previousSelectedContactsIds, ...this.state.selectedContactsId] : [...this.state.selectedContactsId],
										},
									};
								}

								this.props.history.goBack();
							}
						}}
						appendAdditionalViewOnBottom={
							showSelectedContactsView ? (
								<div className="selected-contacts-container" id="selectedContactsContainer">
									{selectedContacts.map((_contact: any, key: string) => {
										const profilePhoto = _contact.hasOwnProperty('profilePhoto') && _contact.profilePhoto ? _contact.profilePhoto : BlankUserProfile;
										return (
											<div className="contact-item" key={key} onClick={() => this._onContactSelect(_contact)}>
												<img src={profilePhoto} />
												<div className="title">{this.extractContactName(_contact)}</div>
											</div>
										);
									})}
								</div>
							) : null
						}
					/>

					<IonContent id="add-members-content" className="has-topbar-with-searchbar" style={{ '--background': contactsType === ContactsType.All ? '#fff' : 'transparent' }}>
						{showSelectedContactsView && <div className="selected-contacts-space-fill"></div>}

						{this.state.contactsType === ContactsType.All && (
							<div className="chat-type-selection">
								{pageActionType === EnumService.AddMembersPageActionType.ChooseContactForTag && (
									<div className="type-item" onClick={() => this.setState({ contactsType: ContactsType.GroupChats })}>
										<img src={GroupsChatIcon} />
										<p className="title">
											Group
											<br />
											Chats
										</p>
									</div>
								)}

								{(pageActionType === EnumService.AddMembersPageActionType.ChooseContactForGroup || pageActionType === EnumService.AddMembersPageActionType.ChooseContactForCurrentGroup) && (
									<div className="type-item" onClick={() => this.setState({ contactsType: ContactsType.Tags })}>
										<img src={TaggedFilled} />
										<p className="title">Tags</p>
									</div>
								)}

								<div style={{ width: '68px' }}></div>
								<div className="type-item" onClick={() => this.setState({ contactsType: ContactsType.ChatsOnly })}>
									<img src={ChatsOnlyIcon} />
									<p className="title">
										Chats
										<br />
										Only
									</p>
								</div>
							</div>
						)}

						{this.state.contactsType === ContactsType.ChatsOnly && <div className="chats-only-msg">Chats Only friends are excluded from seeing any personal information, Profile Photos, or your moments.</div>}

						<ContactsList
							props={this.props}
							showDisableContactChecked={true}
							disabledContacts={previousSelectedContactsIds}
							starredContacts={this.state.contacts}
							contacts={this.state.contacts}
							contactListMode={EnumService.ContactsListMode.ChooseContactsList}
							selectedContacts={this.state.selectedContactsId}
							onContactSelect={this._onContactSelect}
						/>
						<ContactAlbhabetView
							alphabets={alphabetsList}
							showStarredInsideAlbhabetList={true}
							onAlphabetSelect={async (albhabet: any) => {
								this.scrollToContactSection('section_' + albhabet, 'add-members-content');
							}}
							onGoToStarredContact={async () => {
								this.scrollToContactSection('section_starred', 'add-members-content');
							}}
						/>
					</IonContent>

					<IonAlert
						mode="ios"
						isOpen={this.state.showAlert}
						onDidDismiss={() => this.setState({ showAlert: false })}
						cssClass="alert-controller-class"
						message={sharedService.groupMaxMembersLimit + ' member maximum'}
						buttons={[
							{
								text: 'OK',
								role: 'destructive',
							},
						]}
					/>
				</IonPage>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	//addGroup: (payload: any) => dispatch(addGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMembers);
