import { Config } from '../config/config';
import _ from 'lodash';
import { info, isBlank, logError, setInternalStorage, getInternalStorage, isJsonString, sleep } from '../helpers/common';
import { apiService } from './apiService';
import { createPubsub } from '../helpers/pubsub';
import axios from 'axios';
//import { setTimeout as sleep } from 'timers/promises';

const pubsub = createPubsub('com.be-society'),
	initWSS = async () => {
		api.wss = new WebSocket('wss://local.charlesassaf.com:3100');

		while (api.wss.readyState === 0) {
			await sleep(200);
		}

		api.wss.onmessage = (event: any) => {
			let data: any = isJsonString(event.data) ? JSON.parse(event.data) : event.data;
			info('serverApi::initWSS.onMessage: received: %s', data);
		};
	},
	apiFetch = async (path: string, data: any = {}, idRequired: Boolean = true, report: Boolean = false) => {
		const headers: any = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			cookies: any = getInternalStorage(),
			user = idRequired ? await apiService.me() : undefined;

		let response: any, token: string;

		// sanity check if network is considered offline
		if (window.navigator.onLine && (!cookies.network || cookies.network === 'offline')) {
			// attempt to send a ping to the api server

			await axios({
				method: 'post',
				url: `${Config.apiUrl}/ping/`,
				headers: headers,
				timeout: cookies.mode === 'LOCAL' ? 60000 : 15000,
			})
				.then((_response: any) => {
					cookies.network = 'online';
					setInternalStorage('network', 'online');
				})
				.catch((err) => {
					logError('serverApi::fetch:: API server is offline');
				});
		}

		if (window.navigator.onLine && cookies.network === 'online') {
			if (cookies.isApp) {
				info(`serverApi::fetch:: requesting: ${Config.apiUrl}/${path}/ with`, data);
			}

			// todo: need to decrypt this when encryption is activated
			token = cookies.tk;

			let options: any = {
				method: 'post',
				url: `${Config.apiUrl}/${path}/`,
				headers: _.omitBy({ ...headers, Authorization: idRequired ? `Bearer ${token}` : undefined }, isBlank),
				timeout: cookies.mode === 'LOCAL' ? 60000 : 15000,
				data: JSON.stringify(_.omitBy({ ...data, _id: idRequired ? user._id : undefined, uuid: idRequired ? cookies.uuid : data.uuid }, isBlank)),
			};

			if (report) {
				abortController = new AbortController();
				options.signal = abortController.signal;
				options.onUploadProgress = (progressEvent: ProgressEvent) => {
					if (report) {
						pubsub.send({ action: 'uploadMedia', messageKey: data.messageKey, percentComplete: Math.round((progressEvent.loaded * 100) / progressEvent.total) });
					}
				};
			}

			try {
				response = await axios(options)
					.then((_response: any) => {
						if (report) {
							abortController = null;
						}

						return _response.data;
					})
					.catch((error: any) => {
						logError(`serverApi::apiFetch::axios: Error requesting: ${Config.apiUrl}/${path}/`, error);
						response = { Error: error };
						throw response;
					});
			} catch (err) {
				let error: any = err;
				logError('serverApi::fetch::Error in try:', error.message);
				setInternalStorage('network', 'offline');
				response = { Error: err };
				throw response;
			}
		} else {
			response = { Error: 'Network is offline' };
			throw response;
		}

		return response;
	},
	cancelFetch = () => abortController?.abort(),
	api: any = {
		wss: undefined as any,

		socketStarted: false as Boolean,

		ping: async (data: any) => await apiFetch('ping', data, false),

		authenticate: async (data: any) => await apiFetch('authenticate', data, false),

		checkAvailabliity: async (data: any) => await apiFetch('checkUserId', data, false),

		registerUser: async (data: any) => await apiFetch('addUser', data, false),

		getUsers: async (data: any) => await apiFetch('getUsers', data),

		updateUser: async (data: any) => await apiFetch('updateUser', data),

		//getContacts: async () => await apiFetch('getContacts'),

		addContact: async (data: any) => await apiFetch('addContact', data),

		confirmContact: async (data: any) => await apiFetch('confirmContact', data),

		denyContact: async (data: any) => await apiFetch('denyContact', data),

		blockContact: async (data: any) => await apiFetch('denyContact', data),

		updateContact: async (data: any) => await apiFetch('updateContact', data),

		deleteContact: async (data: any) => await apiFetch('deleteContact', data),

		//getGroups: async () => await apiFetch('getGroups'),

		checkGroupname: async (data: any) => await apiFetch('Groupname', data),

		addGroup: async (data: any) => await apiFetch('addGroup', data),

		updateGroup: async (data: any) => await apiFetch('updateGroup', data),

		deleteGroup: async (data: any) => await apiFetch('deleteGroup', data),

		getMessages: async (data: any) => await apiFetch('getMessages', data),

		getSequence: async (data: any) => await apiFetch('getSequence', data),

		updateMessage: async (data: any) => await apiFetch('updateMessage', data),

		recallMessage: async (data: any) => await apiFetch('recallMessage', data),

		uploadMedia: async (data: any) => await apiFetch('uploadMedia', data),

		cancelUpload: async () => cancelFetch(),

		updateReadStatus: async (data: any) => await apiFetch('updateReadStatus', data),

		translateMessage: async (data: any) => await apiFetch('translateMessage', data),

		fetchLinkPreview: async (data: any) => await apiFetch('fetchLinkPreview', data),

		sendStatus: async (data: any) => await apiFetch('status', data, true),

		ackControl: async (data: any) => await apiFetch('ackControl', data),
	};

let abortController: any;

export const serverApi = api;
