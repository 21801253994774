export default class EnumService {
	static ProfileFriendActionTypes = {
		ContactRequestView: 'contact-request-view',
		ContactAdd: 'contact-add',
		ContactView: 'contact-view',
		ChatProfileView: 'chat-profile-view',
	};

	static ContactAddBy = {
		Id: 'Searched ID',
		QrCode: 'Qr Code',
		Group: 'Group',
	};

	static ContactsPageActionType = {
		CreateGroup: 'create-group',
		ForwardMessage: 'forward-message',
		NewChat: 'new-chat',
		ChooseContactForTag: 'choose-contact-for-tag',
	};
	static AddMembersPageActionType = {
		ChooseContactForCurrentGroup: 'choose-contact-for-current-group',
		ChooseContactForGroup: 'choose-contact-for-group',
		ChooseContactForTag: 'choose-contact-for-tag',
	};

	static ChatMessageState = {
		ORIGINAL: 'original',
		REPLY: 'reply',
		RESEND: 'resend',
	};
	static ChatMessageType = {
		TEXT: 'text',
		MEDIA: 'media',
		CONTACT: 'contact',
	};

	static ChatMediaType = {
		IMAGE: 'image',
		VIDEO: 'video',
		AUDIO: 'audio',
		DOCUMENT: 'document',
		THUMBNAIL: 'thumbnail'
	};

	static ChatContactsPageActionType = {
		ForwardMessage: 'forward-message',
		ShareContact: 'share-contact',
	};

	static ContactsListMode = {
		ChatContactsList: 'chat-contacts-list',
		NormalContactsList: 'normal-contacts-list',
		ChooseContactsList: 'choose-contacts-list',
		NewRequestContactsList: 'new-request-contacts-list',
		MostRecentContactsList: 'most-recent-contacts-list',
		RecentlyAddedContactsList: 'recently-added-contacts-list',
		RemoveContactListMode: 'remove-contacts-list-mode',
		StarredContact: 'starred-contacts',
	};

	static MessageSendStatus = {
		PendingSent: 'PendingSent',
		PendingUpload: 'PendingUpload',
		Sent: 'Sent',
		SentFailed: 'SentFailed',
		Received: 'Received',
	};

	static ManageContactActionsSheetOptions = {
		ClearChatHistory: 'clear_chat_history',
		BlockContact: 'block_contact',
		DeleteContact: 'delete_contact',
		PrivacyContact: 'privacy_contact',
		ReportContact: 'report_contact',
	};

	static PubSubEventType = {
		RecallEdit: 'RecallEdit',
		QuickAccessBtnVisibility: 'QuickAccessBtnVisibility',
		ReplyToTheConversation: 'ReplyToTheConversation',
	};
}
