import React, { createRef } from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonPage, IonToolbar, IonSlides, IonSlide, IonSegment, IonSegmentButton } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { chevronBackOutline, cameraSharp, menuOutline } from 'ionicons/icons';
import MomentsContent from './MomentsContent';
import RepostsContent from './RepostsContent';
import StatusContent from './StatusContent';
import { LongpressContainer } from '../../components/LongpressContainer';
import { info } from '../../helpers/common';

interface iProps extends RouteComponentProps<{ name: string }> {}
interface iState {
	moments: Array<any>;
	slideIndex: any;
	viewLoaded: Boolean;
	scrollPosition: any;
}

const screens = ['Reposts', 'Moments', 'Status'];

export default class MomentsPage extends React.Component<iProps, iState> {
	slidesRef: any = createRef();

	constructor(props: iProps) {
		super(props);

		this.state = {
			viewLoaded: false,
			slideIndex: 1,
			scrollPosition: { 0: 0, 1: 0, 2: 0 },
			moments: [
				{
					profile: 'dali.jpg',
					date: '2 min',
					name: 'Nels Frye',
					title: '2020 - Design Hijack Tulum 2020',
					location: 'Beijing, CN',
					imgs: ['demo-moments-topbg.png', 'demo-moments-topbg.png', 'demo-usermoments-topbg.png'],
					optionShow: '',
					liked: false,
					likes: [
						{ profile: 'brucewayane.jpg', id: 1 },
						{ profile: 'dali.jpg', id: 2 },
						{ profile: 'edward.jpg', id: 3 },
						{ profile: 'frednietz.jpg', id: 4 },
					],
					comments: [
						{ user: 'xiao_B', comment: 'Where is this?' },
						{ user: 'Nels Frye', comment: 'In Tulum.' },
						{ user: 'xiao_B', comment: 'I Love Tulum!!' },
					],
				},
				{
					profile: 'dali.jpg',
					date: '2 hr',
					name: 'xiao_B',
					title: 'Beam Me',
					imgs: ['demo-moments-topbg.png'],
					optionShow: '',
					liked: false,
					likes: [
						{ profile: 'brucewayane.jpg', id: 1 },
						{ profile: 'dali.jpg', id: 2 },
						{ profile: 'edward.jpg', id: 3 },
						{ profile: 'frednietz.jpg', id: 4 },
					],
					comments: [
						{ user: 'xiao_B', comment: 'Where is this?' },
						{ user: 'Nels Frye', comment: 'In Tulum.' },
						{ user: 'xiao_B', comment: 'I Love Tulum!!' },
					],
				},
			],
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ viewLoaded: true });
		}, 1500);
	}

	onSegmentChange = (e: any) => {
		const currentIndex = screens.includes(String(e.detail.value));
		this.setState({
			slideIndex: currentIndex,
		});

		this.slidesRef?.current?.slideTo(currentIndex);
	};

	onSlideChange = (event: any) => {
		info('onIonSlideDidChange');

		const slideRef = event.currentTarget as HTMLIonSlidesElement;
		slideRef?.getActiveIndex().then((index: number) => {
			info(index);
			this.setState({ slideIndex: index });
			// if (index != 0) {
			//   this.slidesRef?.current?.lockSwipes(false);
			// } else {
			//   this.slidesRef?.current?.lockSwipes(true);
			// }
		});
	};

	onScroll = (event: any) => {
		const { slideIndex } = this.state;
		var scrollPosition = this.state.scrollPosition;
		scrollPosition[slideIndex] = event.detail.scrollTop;
		this.setState({ scrollPosition });
	};

	render() {
		const { slideIndex, viewLoaded, scrollPosition } = this.state;
		const slideOpts = {
			initialSlide: slideIndex,
		};

		return (
			<IonPage className="moments-page">
				<IonToolbar
					className="moments-header"
					style={{
						'--background': 'rgba(255,255,255,' + parseFloat(scrollPosition[slideIndex]) / 300 + ')',
					}}
				>
					<IonSegment className={'segment ' + (slideIndex === 1 ? 'blurBackground' : '')} mode="md" value={screens[slideIndex]} onIonChange={this.onSegmentChange}>
						{screens.map((item, key) => {
							return (
								<IonSegmentButton layout="label-hide" value={item} key={key}>
									{item}
								</IonSegmentButton>
							);
						})}
					</IonSegment>

					<IonButtons slot="start">
						<IonButton className={'toolbar-btn ' + (slideIndex === 1 ? 'blurBackground' : '')} onClick={(e) => this.props.history.goBack()}>
							<IonIcon slot="icon-only" icon={chevronBackOutline} />
						</IonButton>
					</IonButtons>

					<IonButtons slot="end" style={{ width: '40px', marginRight: 16 }}>
						{slideIndex === 1 && (
							<LongpressContainer
								onLongPress={() => {
									this.props.history.replace('create-post', {
										postType: 'text',
									});
								}}
							>
								<IonButton
									className={'toolbar-btn ' + (slideIndex === 1 ? 'blurBackground' : '')}
									onClick={(e) =>
										this.props.history.replace('create-post', {
											postType: 'media',
										})
									}
								>
									<IonIcon slot="icon-only" icon={cameraSharp} />
								</IonButton>
							</LongpressContainer>
						)}

						{slideIndex === 2 && (
							<IonButton className="toolbar-btn" onClick={(e) => {}}>
								<IonIcon slot="icon-only" icon={menuOutline} />
							</IonButton>
						)}
					</IonButtons>
				</IonToolbar>

				<IonContent>
					<IonSlides options={slideOpts} onIonSlideDidChange={this.onSlideChange} ref={this.slidesRef}>
						<IonSlide>
							<IonContent scrollEvents={true} onIonScroll={this.onScroll}>
								{viewLoaded && <RepostsContent moments={this.state.moments} />}
							</IonContent>
						</IonSlide>

						<IonSlide>
							<IonContent scrollEvents={true} onIonScroll={this.onScroll}>
								<MomentsContent
									isMyMoments={false}
									moments={this.state.moments}
									onChange={(moments: any) => {
										this.setState({ moments });
									}}
								/>
							</IonContent>
						</IonSlide>

						<IonSlide>
							<IonContent scrollEvents={true} onIonScroll={this.onScroll}>
								{viewLoaded && <StatusContent moments={this.state.moments} />}
							</IonContent>
						</IonSlide>
					</IonSlides>
				</IonContent>
			</IonPage>
		);
	}
}
