import { IonModal, IonContent, IonButton, IonIcon, IonActionSheet, ActionSheetButton } from '@ionic/react';
import React, { createRef, useEffect, useState } from 'react';
import './style.scss';
import { close } from 'ionicons/icons';
import { locale } from '../locales/local';
import EnumService from '../services/enumService';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import ImageEditor, { OpenEditorType } from './ImageEditor/ImageEditor';
import ChatMediaShareOptionModal from './ChatMediaShareOptionModal';

const PhotoEditIcon = './assets/icon/photo-edit-icon.svg';
const PhotoDownloadIcon = './assets/icon/photo-downlooad-icon.svg';
const PhotoGridIcon = './assets/icon/photo-grid-icon.svg';
const PhotoMenuIcon = './assets/icon/photo-menu-icon.svg';

interface PhotoPreviewModalProps {
	props: any;
	show: boolean;
	image: any;
	selectedMediaMessageKey: any;
	onClose?: any;
	onViewAllMediaClick?: any;
	onSend?: any;
}

export const PhotoPreviewModal: React.FC<PhotoPreviewModalProps> = ({ selectedMediaMessageKey, props, show, onClose, onViewAllMediaClick }) => {
	const slideElementRef: React.RefObject<HTMLIonSlidesElement> = createRef(),
		[isDownloading, setIsDownloading] = useState(false),
		[showPhotoEditor, setShowPhotoEditor] = useState(false),
		[showChatMediaShareModal, setShowChatMediaShareModal] = useState(false),
		[actionSheetBtns, setActionSheetBtns] = useState<(string | ActionSheetButton)[]>([]),
		[showActionSheet, setShowActionSheet] = useState(false),
		[openModal, setOpenModal] = useState(false),
		mediaMessages = props.chat.history.filter((item: any) => item?.messageType === EnumService.ChatMessageType.MEDIA && (item?.mediaType === EnumService.ChatMediaType.IMAGE || item?.mediaType === EnumService.ChatMediaType.VIDEO || item?.mediaType === EnumService.ChatMediaType.THUMBNAIL)),
		slideIntialIndex = mediaMessages.findIndex((message: any) => message.messageKey === selectedMediaMessageKey);

	useEffect(() => {
		if (!show && openModal) {
			setOpenModal(false);

			const slidesElem: any = document.getElementById('slidesId');
			if (slidesElem) {
				slidesElem.style.opacity = 0;
			}
		} else {
			if (!openModal && show) {
				setOpenModal(true);

				setTimeout(() => {
					const slidesElem: any = document.getElementById('slidesId');
					if (slidesElem) {
						slidesElem.style.opacity = 1;
					}
				}, 500);
			}
		}
	}, [show, slideIntialIndex, slideElementRef, openModal, props]);

	return (
		<IonModal cssClass="photo-preview-modal" isOpen={openModal} onDidDismiss={() => onClose()} swipeToClose={false} showBackdrop={false}>
			<div className="header-btns">
				<div className="right">
					{isDownloading ? (
						<IonButton fill="clear" className="btn download-progress-btn">
							<div className="percent-text">30%</div>
							<IonButton fill="clear" onClick={() => setIsDownloading(false)}>
								<IonIcon slot="icon-only" icon={close} />
							</IonButton>
						</IonButton>
					) : (
						<IonButton fill="clear" className="btn" onClick={() => setIsDownloading(true)}>
							{locale.photo.photo_viewer_modal.full_image} (1.8M)
						</IonButton>
					)}
				</div>
			</div>

			<IonContent>
				<div className="image-container" onClick={onClose}>
					<AwesomeSlider selected={slideIntialIndex} mobileTouch={true} bullets={false} organicArrows={false} infinite={false} startup={false} buttons={false} fillParent={true}>
						{mediaMessages.map((message: any, key: any) => {
							let img = null;
							if (Array.isArray(message.mediaUrl) && message.mediaUrl.length > 0) {
								img = message.mediaUrl[0];
							} else if (Array.isArray(message.mediaThumbnail) && message.mediaThumbnail.length > 0) {
								img = message.mediaThumbnail[0];
							}

							return <div data-src={img} key={key}></div>;
						})}
					</AwesomeSlider>
				</div>
			</IonContent>

			<div className="photo-options-btns-container">
				<div className="btns">
					<IonButton fill="clear" slot="icon-only" onClick={() => setShowPhotoEditor(true)}>
						<IonIcon src={PhotoEditIcon} />
					</IonButton>
					<IonButton fill="clear" slot="icon-only">
						<IonIcon src={PhotoDownloadIcon} style={{ width: '19px', height: '24px' }} />
					</IonButton>
					<IonButton fill="clear" slot="icon-only" onClick={onViewAllMediaClick}>
						<IonIcon src={PhotoGridIcon} />
					</IonButton>
					<IonButton
						fill="clear"
						slot="icon-only"
						onClick={() => {
							// setActionSheetBtns([
							// 	{
							// 		text: 'Send to chat',
							// 		handler: () => {
							// 			setShowActionSheet(false);
							// 		},
							// 	},
							// 	{
							// 		text: 'Bookmark',
							// 		handler: () => {
							// 			setShowActionSheet(false);
							// 		},
							// 	},
							// 	{
							// 		text: 'Share',
							// 		handler: () => {
							// 			setShowActionSheet(false);
							// 		},
							// 	},
							// 	{
							// 		text: 'Cancel',
							// 		cssClass: 'action-sheet-group-cancel',
							// 		handler: () => {
							// 			setShowActionSheet(false);
							// 		},
							// 	},
							// ]);
							// setShowActionSheet(true);

							setShowChatMediaShareModal(true);
						}}
					>
						<IonIcon src={PhotoMenuIcon} />
					</IonButton>
				</div>
			</div>

			<IonActionSheet mode="md" isOpen={showActionSheet} onDidDismiss={() => setShowActionSheet(false)} cssClass={'photo-preview-menu-actionsheet'} buttons={actionSheetBtns}></IonActionSheet>

			<ImageEditor
				openEditorType={OpenEditorType.FOR_EDIT_IMAGE}
				selectedImage={showPhotoEditor ? mediaMessages[slideIntialIndex].mediaUrl || mediaMessages[slideIntialIndex].mediaThumbnail : null}
				show={showPhotoEditor}
				onClose={() => {
					setShowPhotoEditor(false);
				}}
				onSave={() => {
					setActionSheetBtns([
						{
							text: 'Send to chat',
							handler: () => {
								setShowActionSheet(false);
							},
						},
						{
							text: 'Bookmark',
							handler: () => {
								setShowActionSheet(false);
							},
						},
						{
							text: 'Send to Notepad',
							handler: () => {
								setShowActionSheet(false);
							},
						},
						{
							text: 'Cancel',
							cssClass: 'action-sheet-group-cancel',
							handler: () => {
								setShowActionSheet(false);
							},
						},
					]);
					setShowActionSheet(true);
				}}
			/>

			<ChatMediaShareOptionModal
				props={props}
				show={showChatMediaShareModal}
				onClose={() => {
					setShowChatMediaShareModal(false);
				}}
			/>
		</IonModal>
	);
};
