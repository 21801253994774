import { IonIcon } from '@ionic/react';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { publishData, resetPublishData } from '../../../../redux/actions/pubsub';
import EnumService from '../../../../services/enumService';
import './style.scss';

const CloseIconOutlineIcon = './assets/icon/close-icon-outline.svg';

interface iProps {
	messageData: any;

	onClose: any;
}

const ChatReplyView: React.FC<iProps> = ({ messageData, onClose }) => {
	let userProfilePic = null;
	let userProfileName: string = '';
	let message = '';
	userProfileName = messageData.senderProfileName || messageData.sender;
	userProfilePic = messageData.userProfilePic;
	if (messageData?.messageType === EnumService.ChatMessageState.ORIGINAL || messageData?.messageType === EnumService.ChatMessageState.REPLY) {
		message = messageData.body.replace(/&quot;/g, '');
	}
	const isTextType: Boolean = useMemo(() => messageData.messageType === EnumService.ChatMessageType.TEXT, [messageData.messageType]);
	const isMediaType: Boolean = useMemo(() => messageData.messageType === EnumService.ChatMessageType.MEDIA, [messageData.messageType]);

	return messageData ? (
		<div className="chat-reply-view">
			<img className="profile-img" src={userProfilePic} alt="profilePicture" />
			<div className={'user-title-msg ' + (isMediaType ? 'image-type' : isTextType ? 'text-type' : '')}>
				<span className="title" dangerouslySetInnerHTML={{ __html: userProfileName.replace('(', '(<span class="paranthesis">').replace(')', '</span>)') + ':' }}></span>
				&nbsp;
				{isTextType && <span dangerouslySetInnerHTML={{ __html: message }}></span>}
				{isMediaType && <img className="msg-image" src={messageData.mediaUrl} alt="media"/>}
			</div>
			<div className="close-button" onClick={() => onClose()}>
				<IonIcon src={CloseIconOutlineIcon} />
			</div>
		</div>
	) : (
		<></>
	);
};

const mapStateToProps = (state: any) => {
	return {
		pubsub: state.pubsub,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetPublishData: () => dispatch(resetPublishData()),
	publishData: (payload: any) => dispatch(publishData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatReplyView);
