import { IonModal, IonTextarea } from '@ionic/react';
import React, { useState } from 'react';
import { TopNavbar } from '../../pages/common/header/topbar';
import './GroupAboutModal.scss';

interface GroupAboutModalProps {
	show: boolean;
	props: any;
	initialValue: any;
	onCloseCallBack: any;
	onConfirm: any;
}

const GroupAboutModal: React.FC<GroupAboutModalProps> = ({ props, initialValue, show, onCloseCallBack, onConfirm }) => {
	const [value, setValue] = useState<any>(initialValue);
	return (
		<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="group-about-modal" animated={true}>
			<TopNavbar
				{...props}
				pageTitle={'About'}
				hideSearchBar={true}
				rightButtonDisabled={!value}
				showBack={true}
				onLeftButtonPress={() => {
					onCloseCallBack();
				}}
				onRightButtonPress={() => {
					onConfirm(value);
				}}
				rightButtonText="Done"
			/>
			<div className="container has-topbar">
				<IonTextarea value={value} onIonChange={(event) => setValue(event.detail.value)} placeholder="Please state the intent of your group clearly to help members understand how to best contribute and engage." />
			</div>
		</IonModal>
	);
};

export default GroupAboutModal;
