import React from 'react';
import { IonButton, IonCheckbox, IonContent, IonLabel, IonListHeader, IonLoading, IonPage, IonToggle } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { TopNavbar } from '../common/header/topbar';
import { connect } from 'react-redux';
import { apiService } from '../../services/apiService';
import { SelectedContacts } from './components/SelectedContacts';
import { addGroup, resetValues } from '../../redux/actions/dashboard';
import { sortByAlias } from '../../helpers/common';
import AddNewTagPopup from '../../modals/AddNewTagPopup';

interface iProps extends RouteComponentProps<{ name: string }> {
	//addGroup: Function;
	resetValues: Function;
	location: any;
	dashboard: any;
}
interface iState {
	loggedInUser?: any;
	contacts: any;
	hideAll: boolean;
	isLoading?: boolean;
	loadingMessage?: string;
	showAddTagPopup: boolean;
	isManagingTag: boolean;
	selectedTagIds?: any;
	shouldSelectTag?: boolean;
	tagsList?: any[];
}

class Tags extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);

		const state: any = props.history.location?.state;

		this.state = {
			shouldSelectTag: state?.shouldSelectTag || false,
			loggedInUser: undefined,
			hideAll: false,
			showAddTagPopup: state?.shouldSelectTag || false,
			selectedTagIds: [],
			contacts: undefined,
			isManagingTag: false,
			isLoading: false,
			loadingMessage: '',
			tagsList: [
				{
					id: 1,
					name: 'Family',
					contacts: ['60869c1b9540296a8b37df0f', '6086a6279540296a8b37e5d2', '608a68ad9540296a8b3a0fda', '608fe1e2ede38421e7e62811', '60916afa9540296a8b3fb28c'],
					showInChats: false,
				},
				{
					id: 2,
					name: 'Creatives',
					contacts: ['60869c1b9540296a8b37df0f', '6086a6279540296a8b37e5d2', '608a68ad9540296a8b3a0fda', '608fe1e2ede38421e7e62811', '60916afa9540296a8b3fb28c'],
					showInChats: false,
				},
				{
					id: 3,
					name: 'Tulum Contacts',
					contacts: ['60869c1b9540296a8b37df0f', '6086a6279540296a8b37e5d2', '608a68ad9540296a8b3a0fda', '608fe1e2ede38421e7e62811', '60916afa9540296a8b3fb28c'],
					showInChats: false,
				},
				{
					id: 4,
					name: 'Design Hijack',
					contacts: ['60869c1b9540296a8b37df0f', '6086a6279540296a8b37e5d2', '608a68ad9540296a8b3a0fda', '608fe1e2ede38421e7e62811', '60916afa9540296a8b3fb28c'],
					showInChats: false,
				},
			],
		};

		this.props.resetValues();
	}
	getData = async () => {
		let user = await apiService.me(),
			contacts: any = user.contacts.filter((_contact: any) => _contact.status === 'confirmed').sort(sortByAlias);
			/*contacts: any = await apiService.getContacts(false, 'confirmed').then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});*/

		return [user, contacts];
	};

	setPageData = async () => {
		let [user, contacts] = await this.getData();

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			contacts: contacts,
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	setSearchText() {}
	searchAllGroups(searchValue: string, props: any) {
		props.history.replace('/contacts/search', searchValue);
	}

	_onContactSelect = (cont: any) => {};

	_renderSwitchItem = (title: any, value: any, onChange: any) => {
		return (
			<div className={'swtich-item'} slot="end">
				<IonLabel>{title}</IonLabel>
				<IonToggle slot="end" className="toggle-customised" mode="ios" checked={value} onIonChange={onChange} />
			</div>
		);
	};

	render() {
		const { tagsList } = this.state;
		return (
			<>
				<IonPage className="tags-page">
					<TopNavbar
						{...this.props}
						isHideRightButton={tagsList?.length === 0 ? true : false}
						showBack={true}
						hideSearchBar={true}
						pageTitle={'Tags'}
						onRightButtonPress={() => {
							this.props.history.replace('/taginfo', {
								isAddNew: true,
							});
						}}
					/>

					{tagsList && tagsList.length > 0 ? (
						<IonContent className="main-content-profile has-bottom-navbar has-topbar">
							<IonList lines="none">
								{tagsList?.map((item, key) => {
									return (
										<div key={key} className="tag-item-container" style={{ opacity: item.showInChats ? 1 : 0.5 }}>
											<IonListHeader onClick={() => this.props.history.replace('/taginfo')}>
												{item.name} ({item.contacts.length})
											</IonListHeader>
											<IonItem className="contacts-container" onClick={() => this.props.history.replace('/taginfo')}>
												<div className="starred-wrapper">
													{this.state.isManagingTag && (
														<div className="select-checkbox-view" onClick={(event) => event.stopPropagation()}>
															<IonCheckbox
																mode={'ios'}
																slot={'start'}
																checked={this.state.selectedTagIds.indexOf(item.messageKey) !== -1}
																onIonChange={(event) => {
																	const isChecked = event.detail.checked;
																	const ids = this.state.selectedTagIds;
																	const tagIdIndex = ids.indexOf(item.messageKey);

																	if (isChecked) {
																		if (tagIdIndex === -1) {
																			ids.push(item.messageKey);
																		}
																	} else {
																		if (tagIdIndex !== -1) {
																			ids.splice(tagIdIndex, 1);
																		}
																	}
																	this.setState({ selectedTagIds: ids });
																}}
															/>
														</div>
													)}

													{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((item, key1) => {
														return (
															<div className="contact-item" key={key1}>
																<img src={'./assets/img/blank-user-profile.png'} alt="profilePhoto" />
																<div className="username">User Name</div>
															</div>
														);
													})}
												</div>
											</IonItem>
											<IonItem className="toggle-item">
												{this._renderSwitchItem('Show in Chats', item.showInChats, (event: any) => {
													console.log('_renderSwitchItem', event);
													const tagsListTmp = tagsList;
													tagsList[key].showInChats = event.detail.checked;
													this.setState({ tagsList: tagsListTmp });
												})}
											</IonItem>
										</div>
									);
								})}
							</IonList>
						</IonContent>
					) : (
						<div className="empty-tags-screen-message">Tags are to help users keep their chats organized.</div>
					)}

					<IonItem className="manage-toggle-item" lines="none">
						{!this.state.isManagingTag && tagsList && tagsList.length > 0 && (
							<>
								<IonButton
									fill="clear"
									className="title"
									onClick={() => {
										this.setState({ isManagingTag: true });
									}}>
									Manage
								</IonButton>

								{!this.state.shouldSelectTag &&
									this._renderSwitchItem('Hide All', this.state.hideAll, (event: any) => {
										tagsList?.forEach((item) => {
											item.showInChats = false;
										});
										this.setState({ tagsList: tagsList, hideAll: event.detail.checked });
									})}
							</>
						)}

						{(!tagsList || tagsList.length === 0) && (
							<IonButton
								fill="clear"
								slot="end"
								className="add-tag-btn"
								onClick={() => {
									this.setState({ showAddTagPopup: true });
								}}>
								Add Tag
							</IonButton>
						)}

						{this.state.isManagingTag && (
							<IonButton
								fill="clear"
								slot="end"
								className="delete-tags-btn"
								disabled={this.state.selectedTagIds.length === 0}
								onClick={() => {
									this.setState({ isManagingTag: true });
								}}>
								Delete ({this.state.selectedTagIds.length > 0 ? this.state.selectedTagIds.length : ''})
							</IonButton>
						)}

						{this.state.shouldSelectTag && !this.state.isManagingTag && (
							<IonButton fill="clear" slot="end" className="done-tags-btn" onClick={() => {}}>
								Done
							</IonButton>
						)}
					</IonItem>
					{/* <BottomNavbar {...this.props} /> */}
				</IonPage>

				<AddNewTagPopup
					show={this.state.showAddTagPopup}
					onCloseCallBack={() => {
						this.setState({ showAddTagPopup: false });
					}}
					onConfirm={(value: string) => {
						this.setState({ isLoading: true, loadingMessage: 'Creating' });
						setTimeout(() => {
							this.setState({ isLoading: false, loadingMessage: '', showAddTagPopup: false });
						}, 2000);
					}}
				/>

				<IonLoading mode="md" isOpen={this.props.dashboard.isLoading || this.state.isLoading} message={this.state.isLoading ? this.state.loadingMessage : this.props.dashboard.loaderMessage} />
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	//addGroup: (payload: any) => dispatch(addGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
