import { IonButton, IonModal, IonIcon, IonSearchbar } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import './style.scss';
import { Grid } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import CustomSpinner from '../components/CustomSpinner';
import { Config } from '../config/config';

const ArrowDownIcon = './assets/icon/arrow-down.svg';

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
const gf = new GiphyFetch(Config.GIPHY_API_KEY);

interface EmojiStickerSearchModalProps {
	show: boolean;
	onCancel?: any;
}

const EmojiStickerSearchModal: React.FC<EmojiStickerSearchModalProps> = ({ show, onCancel }) => {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [isSearching, setIsSearching] = useState<boolean>(false);

	const onCloseCallBack = useCallback(() => {
		onCancel && onCancel();
	}, [onCancel]);
	const fetchGifs = (offset: number) => (searchTerm ? gf.search(searchTerm, { offset, limit: 20 }) : gf.trending({ offset, limit: 20 }));

	return (
		<IonModal mode="ios" showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="emoji-sticker-search-modal" animated={true}>
			<div className="content">
				<div className="header">
					<IonButton fill="clear" slot="icon-only" onClick={onCloseCallBack}>
						<IonIcon src={ArrowDownIcon}></IonIcon>
					</IonButton>
				</div>

				<IonSearchbar
					mode="ios"
					placeholder="Search GIPHY"
					className="searchbar"
					value={searchTerm}
					onIonChange={(event: any) => {
						setSearchTerm(event.detail.value);
						setIsSearching(true);
					}}
				></IonSearchbar>
				{isSearching && (
					<div className="searching-view">
						<CustomSpinner size={16} />
						<div className="search-text">Searching...</div>
					</div>
				)}

				{!isSearching && <div className="content-title">Stickers</div>}
				<div className="stickers-container">
					<Grid
						width={window.innerWidth - 32}
						gutter={16}
						columns={4}
						fetchGifs={fetchGifs}
						key={searchTerm}
						noLink={true}
						onGifsFetched={() => {
							setIsSearching(false);
						}}
						onGifClick={(gif: any) => {
							console.log('Gif ', gif);
						}}
					/>
				</div>
			</div>
		</IonModal>
	);
};

export default EmojiStickerSearchModal;
