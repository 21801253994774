import { IonButton, IonIcon } from '@ionic/react';
import React, { useCallback } from 'react';
import './index.scss';
const BlankUserProfile = './assets/img/blank-user-profile.png';
const CloseCircleIcon = './assets/icon/close-circle.svg';

interface ContactItemProps {
	isDeleteMode?: boolean;
	onClick?: any;
	contact?: any;
}
const ContactItem: React.FC<ContactItemProps> = ({ contact, isDeleteMode, onClick }) => {
	const profilePhoto = contact?.hasOwnProperty('profilePhoto') && contact?.profilePhoto ? contact.profilePhoto : BlankUserProfile;
	const extractContactName = useCallback(
		(contact: any) => {
			return contact?.alias || contact?.username || contact?.userId || '';
		},
		[]
	);
	return (
		<div className="contact-item" onClick={onClick}>
			{contact && (
				<>
					{' '}
					<img src={profilePhoto} alt="profilePhoto" />
					<div className="title">{extractContactName(contact)}</div>
					{isDeleteMode && (
						<IonButton className="remove-icon" fill="clear">
							<IonIcon src={CloseCircleIcon} />
						</IonButton>
					)}
				</>
			)}
		</div>
	);
};

export default ContactItem;
