import React from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonLoading, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { chevronBackOutline } from 'ionicons/icons';
import { IonList, IonItem } from '@ionic/react';
import { blankProfilePic, unprefixMedia } from '../../helpers/common';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../redux/actions/userProfile';
import store from '../../redux/store';
import { PROFILE_EDITED } from '../../redux/constants/profileupdate';
import MultiInputListItem from '../../components/MultiInputListItem';
import PhotoInputItem from '../../components/PhotoInputItem';
import { locale } from '../../locales/local';
import { apiService } from '../../services/apiService';
import { TopNavbar } from '../common/header/topbar';

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: any;
	loggedInUser: any;
	pageLoading: Function;
	updateUser: Function;
}

interface iState {
	name?: string;
	userId?: string;
	username?: string;
	region?: string;
	status?: string;
	profession?: string;
	gender?: string;
	currentActiveEditField?: string;
	loggedInUser?: any;
	profilePhoto?: any;
	qrCode?: any;
}

class ProfileEditor extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);

		this.state = {
			currentActiveEditField: '',
			loggedInUser: undefined,
			name: undefined,
			userId: undefined,
			username: undefined,
			region: undefined,
			profession: undefined,
			status: undefined,
			gender: undefined,
			profilePhoto: undefined,
			qrCode: undefined,
		};
	}

	setPageData = async () => {
		let user: any = await apiService.me();

		this.setState((state: any, props: any) => ({
			loggedInUser: user,
			name: user?.name,
			userId: user?.userId,
			username: user?.username,
			region: user?.region || locale.global.planet_earth,
			profession: user?.profession || '',
			status: user?.quote,
			gender: user?.gender || 'Male',
			profilePhoto: user?.profilePhoto ? user.profilePhoto : undefined,
			qrCode: user?.qrCode ? user.qrCode : undefined,
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	componentWillUnmount() {}

	submitChanges() {
		// todo need to review this.  profile photo should be sent on its own and not included, no qr code needed - commenting those out for now
		this.props.updateUser({
			name: this.state.name,
			username: this.state.username,
			gender: this.state.gender,
			profession: this.state.profession,
			region: this.state.region,
			quote: this.state.status,
			//profilePhoto: this.state.profilePhoto?.startsWith('https') ? unprefixMedia(this.state.profilePhoto) : this.state.profilePhoto,
			//qrPhoto: this.state.qrCode?.startsWith('https') ? unprefixMedia(this.state.qrCode) : this.state.qrCode,
		});
	}

	render() {
		const { currentActiveEditField } = this.state,
			{ loggedInUser } = this.state;

		return (
			<IonPage className="profile-edit-page">
				<TopNavbar {...this.props} pageTitle={locale.global.my_profile} hideSearchBar={true} showBack={true} isHideRightButton={true} />

				<IonContent className="main-content-profile has-topbar has-bottom-navbar">
					<IonList lines="none">
						<PhotoInputItem
							isEdittable={true}
							fieldKey={'profilePhoto'}
							label={'Profile photo'}
							photo={loggedInUser?.profilePhoto ? loggedInUser?.profilePhoto : blankProfilePic}
							isDisabled={currentActiveEditField !== '' && currentActiveEditField !== 'profilephoto'}
							onItemClick={() => {
								this.props.history.replace('/profile/upload-photo');
							}}
						/>

						<MultiInputListItem
							key="name"
							inputType="text"
							isEdittable={true}
							shouldDisabled={currentActiveEditField !== '' && currentActiveEditField !== 'name'}
							profileValueKey={'name'}
							label="Username"
							value={this.state.name}
							isEditMode={currentActiveEditField === 'name'}
							onEditModeOn={() => {
								this.setState({ currentActiveEditField: 'name' });
							}}
							onChange={(value: any) => {
								this.setState({ name: value });
							}}
							props={this.props}
						/>
						<MultiInputListItem isEdittable={false} label="User ID" value={this.state.userId || ''} props={this.props} />
						{/* <MultiInputListItem
							inputType="dropdown"
							shouldDisabled={currentActiveEditField != '' && currentActiveEditField !== 'gender'}
							options={[{ title: 'Male', value: 'Male' }, { title: 'Female', value: 'Female' }, , { title: 'Other', value: 'Other' }]}
							isEdittable={true}
							profileValueKey={'gender'}
							label="Gender"
							value={this.state.gender || ''}
							isEditMode={currentActiveEditField === 'gender'}
							onEditModeOn={() => {
								this.setState({ currentActiveEditField: 'gender' });
							}}
							onChange={(value: any) => {
								this.setState({ gender: value });
							}}
							props={this.props}
						/> */}
						<MultiInputListItem
							inputType="text"
							shouldDisabled={currentActiveEditField !== '' && currentActiveEditField !== 'profession'}
							isEdittable={true}
							profileValueKey={'profession'}
							label="Profession"
							value={this.state.profession || ''}
							isEditMode={currentActiveEditField === 'profession'}
							onEditModeOn={() => {
								this.setState({ currentActiveEditField: 'profession' });
							}}
							onChange={(value: any) => {
								this.setState({ profession: value });
							}}
							props={this.props}
						/>
						<MultiInputListItem
							inputType="dropdown"
							shouldDisabled={currentActiveEditField !== '' && currentActiveEditField !== 'region'}
							options={[{ title: 'Planet Earth', value: 'Planet Earth' }]}
							isEdittable={true}
							profileValueKey={'region'}
							label="Region"
							value={this.state.region || ''}
							isEditMode={currentActiveEditField === 'region'}
							onEditModeOn={() => {
								this.setState({ currentActiveEditField: 'region' });
							}}
							onChange={(value: any) => {
								this.setState({ region: value });
							}}
							props={this.props}
						/>
						<MultiInputListItem
							inputType="multiline"
							isEdittable={true}
							charLimit={60}
							shouldDisabled={currentActiveEditField !== '' && currentActiveEditField !== 'quote'}
							profileValueKey={'quote'}
							label="Bio"
							value={this.state.status || ''}
							isEditMode={currentActiveEditField === 'quote'}
							onEditModeOn={() => {
								this.setState({ currentActiveEditField: 'quote' });
							}}
							onChange={(value: any) => {
								this.setState({ status: value });
							}}
							props={this.props}
						/>
					</IonList>

					{this.props.profile.hasEdited ? (
						<IonItem key={'SaveCancel'} className="save-cancel-btns" button lines="none" detail={false}>
							<IonButton
								slot="end"
								className="edit-cancel-btn"
								onClick={() => {
									this.setState({
										currentActiveEditField: '',
										name: loggedInUser?.name,
										region: loggedInUser?.region || 'Planet Earth',
										status: loggedInUser?.quote,
										gender: loggedInUser?.gender ? loggedInUser?.gender : 'Male',
										profession: loggedInUser?.profession || '',
										profilePhoto: loggedInUser?.profilePhoto,
										qrCode: loggedInUser?.qrCode,
									});
									store.dispatch({ type: PROFILE_EDITED, payload: false });
								}}
							>
								{locale.global.cancel}
							</IonButton>
							<IonButton
								className="edit-save-btn"
								slot="end"
								onClick={() => {
									this.setState({ currentActiveEditField: '' });
									this.submitChanges();
								}}
							>
								{locale.global.save}
							</IonButton>
						</IonItem>
					) : null}
				</IonContent>

				<IonLoading isOpen={this.props.profile.isLoading} onDidDismiss={() => this.props.pageLoading({ loader: false })} message="Please wait..." duration={20000} />
				<BottomNavbar unreadCount={0} {...this.props} />
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditor);
