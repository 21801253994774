import { IonPage } from '@ionic/react';
import React from 'react';
import AddContactItem from '../../../components/ContactItems/AddContactItem';
import ContactItem from '../../../components/ContactItems/ContactItem';
import RemoveContactItem from '../../../components/ContactItems/RemoveContactItem';
import EnumService from '../../../services/enumService';
import { sharedService } from '../../../services/sharedService';
import { TopNavbar } from '../../common/header/topbar';
import './GroupMembers.scss';

interface iProps {
	history: any;
}

interface iState {
	groupAllContacts: any;
	groupAllContactIds: any;
	isDeleteMode: boolean;
}

class GroupMembers extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);

		const state: any = props.history.location.state;

		this.state = {
			isDeleteMode: state?.isDeleteMode || false,
			groupAllContacts: state?.groupAllContacts,
			groupAllContactIds: state?.groupAllContactIds,
		};
	}

	componentWillUnmount() {
		sharedService.callBackData.groupMembers = null;
	}

	componentDidMount() {
		let groupAllContactIds = this.state.groupAllContactIds;
		let groupAllContacts = this.state.groupAllContacts;
		if (sharedService.callBackData.groupMembers) {
			if (sharedService.callBackData.groupMembers.groupAllContacts) {
				groupAllContacts = sharedService.callBackData.groupMembers.groupAllContacts;
			}
			if (sharedService.callBackData.groupMembers.groupAllContactIds) {
				groupAllContactIds = sharedService.callBackData.groupMembers.groupAllContactIds;
			}
		}
		this.setState({ groupAllContacts, groupAllContactIds });
	}

	render() {
		const { groupAllContacts, isDeleteMode } = this.state;
		return (
			<IonPage className="group-members-page">
				<TopNavbar
					{...this.props}
					pageTitle={'Group Members'}
					onLeftButtonPress={() => {
						sharedService.callBackData = {
							...sharedService.callBackData,
							groupManager: {
								groupAllContacts: this.state.groupAllContacts,
								groupAllContactIds: this.state.groupAllContactIds,
							},
						};
						this.props.history.goBack();
					}}
					pageTitleDescription={groupAllContacts ? groupAllContacts.length + ' Members' : ''}
					hideSearchBar={false}
					isHideRightButton={true}
					showBack={true}
					searchTypeHandler={() => {}}
				/>
				<div className="container has-topbar-with-searchbar">
					{groupAllContacts?.map((_contact: any, key: any) => {
						return (
							<ContactItem
								isDeleteMode={isDeleteMode}
								key={key}
								contact={_contact}
								onClick={() => {
									if (isDeleteMode) {
										const list = this.state.groupAllContacts;
										const listIds = this.state.groupAllContactIds;
										if (list.length > key) {
											list.splice(key, 1);
											listIds.splice(key, 1);
										}
										this.setState({ groupAllContactIds: listIds, groupAllContacts: list });
									}
								}}
							/>
						);
					})}

					<ContactItem />
					<ContactItem />
				</div>

				<div className="add-remove-btns-container">
					{groupAllContacts?.length < sharedService.groupMaxMembersLimit && (
						<AddContactItem
							onClick={() => {
								this.props.history.push('/contacts/add-members', {
									actionType: EnumService.AddMembersPageActionType.ChooseContactForCurrentGroup,
									previousSelectedContactsIds: this.state.groupAllContactIds,
									previousSelectedContacts: this.state.groupAllContacts,
									fromPageKey: 'groupMembers',
								});
							}}
						/>
					)}

					{!isDeleteMode && (
						<RemoveContactItem
							onClick={() => {
								this.setState({ isDeleteMode: true });
							}}
						/>
					)}
				</div>
			</IonPage>
		);
	}
}

export default GroupMembers;
