import React, { useRef } from 'react';
import { IonItem, IonIcon, IonLabel, IonItemSliding, IonText } from '@ionic/react';
import './style.scss';
import { notificationsOffOutline } from 'ionicons/icons';
import moment from 'moment';
import ConversationProfilePhoto from './ConversationProfilePhoto';
import ConversationItemOptions from './ConversationItemOptions';
import { locale } from '../../../locales/local';
import { isBlank } from '../../../helpers/common';
import _ from 'lodash';
import EnumService from '../../../services/enumService';

const ConversationItem: React.FC<any> = ({ index, conversation, chatClickHandler }) => {
	const ionItemSlideRef = useRef(null);
	let message: any = '',
		lastMessage = conversation?.lastMessage,
		prefix: boolean | string = false;

	if (lastMessage) {
		if (lastMessage?.body || !isBlank(lastMessage?.mediaUrl) || !isBlank(lastMessage?.mediaThumbnail)) {
			if (lastMessage?.type === 'groupchat') {
				prefix = `${lastMessage.sender}:`;
			}

			if (lastMessage?.recalled) {
				if (lastMessage?.sender === 'Me') {
					message = <span dangerouslySetInnerHTML={{ __html: `${locale.dashboard.thread.message_recalled}` }}></span>;
				} else {
					message = <span dangerouslySetInnerHTML={{ __html: `${locale.dashboard.thread.recalled_a_message}` }}></span>;
				}
			} else if (!lastMessage?.translated) {
				message = <span dangerouslySetInnerHTML={{ __html: `${message}${lastMessage.body ? lastMessage.body.replace(/&quot;/g, '"').replace(/&apos;/g, "'") : ''}` }}></span>;
			} else {
				message = <span dangerouslySetInnerHTML={{ __html: `${message}${lastMessage.translation ? lastMessage.translation.replace(/&quot;/g, '"').replace(/&apos;/g, "'") : ''}` }}></span>;
			}

			if (lastMessage?.messageType === EnumService.ChatMessageType.MEDIA) {
				message = `[${lastMessage.mediaType === EnumService.ChatMediaType.IMAGE || lastMessage.mediaType === EnumService.ChatMediaType.THUMBNAIL ? 'Photo' : _.capitalize(lastMessage.mediaType)}] `;
			} else if (lastMessage?.messageType === EnumService.ChatMessageType.CONTACT) {
				message = `[Contact] `;
			}
		}
	}

	const msgSentTime = lastMessage && lastMessage.originalTimestamp ? moment(lastMessage.originalTimestamp).calendar(null, { sameDay: 'h:mm A', lastDay: '[Yesterday]', lastWeek: 'dddd', sameElse: 'M/D/YYYY' }) : '';

	return (
		<IonItemSliding className="conversation-item-sliding" ref={ionItemSlideRef} key={conversation._id}>
			<IonItem lines="none" onClick={chatClickHandler} detail={false} button={true}>
				<ConversationProfilePhoto conversation={conversation} />
				<IonLabel>
					<div className="h-row firstline">
						<h3 className="chatname">{conversation.groupname || conversation.alias || conversation.userId}</h3>
						<div className="timestamp">{msgSentTime}</div>
					</div>
					<div className="h-row secondline">
						<div className="chatlastmessage">
							{prefix ? <IonText color="primary">{prefix}</IonText> : ''}
							{message ? message : 'No message'}
						</div>
						{index === -1 && <IonIcon className="mute-icon" slot="end" icon={notificationsOffOutline}></IonIcon>}
					</div>
				</IonLabel>
			</IonItem>
			<ConversationItemOptions ionItemSlideRef={ionItemSlideRef} />
		</IonItemSliding>
	);
};

export default ConversationItem;
