import React from 'react';
import { IonBadge, IonButton, IonButtons, IonContent, IonFooter, IonIcon, IonLabel, IonNote, IonPage, IonToolbar } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { closeSharp } from 'ionicons/icons';
import { IonList, IonItem } from '@ionic/react';
import { TopNavbar } from '../common/header/topbar';
import { ContactsList } from './components/ContactsList';
import { connect } from 'react-redux';
import { apiService } from '../../services/apiService';
import SearchContactsModal from './searchContactsModal';
import EnumService from '../../services/enumService';
import { StarredContacts } from './components/StarredContacts';
import { SelectedContacts } from './components/SelectedContacts';
import { addGroup, resetValues } from '../../redux/actions/dashboard';
import { locale } from '../../locales/local';
import { info, sortByAlias } from '../../helpers/common';
import _ from 'lodash';
import { star } from 'ionicons/icons';
import { ContactAlbhabetView } from './components/ContactAlbhabetView';

const TagsIcon = './assets/icon/tags.svg';
const FriendsIcon = './assets/icon/friends.svg';
const RecentlyAddedIcon = './assets/icon/recently-added.svg';
const OfficialAccIcon = './assets/icon/official.svg';
const WorkIcon = './assets/icon/basket.svg';
const ChatIcon = './assets/icon/tab-chat.svg';
const GroupCreateIcon = './assets/icon/group-create.svg';
const StarredIcon = './assets/icon/starred.svg';

interface iProps extends RouteComponentProps<{ name: string }> {
	//addGroup: Function;
	resetValues: Function;
	location: any;
	dashboard: any;
}
interface iState {
	newContactRequestCount: number;
	isContactSelectMode: boolean;
	loggedInUser?: any;
	contacts: any;
	selectedContacts?: any[];
	disabledContacts?: any[];
	showSearchContactsModal: boolean;
	pageActionType: string;
}

class Contacts extends React.Component<iProps, iState> {
	starredContacts = [{ title: 'Trevor W.' }, { title: 'Carlos' }];
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);

		const disabledContacts = [],
			locationState = props.location.state,
			actionType = locationState?.actionType,
			defaultGroupMember = locationState?.defaultGroupMember;

		let selectedContacts: any = locationState?.selectedContacts || [],
			isContactSelectMode = actionType === EnumService.ContactsPageActionType.CreateGroup || actionType === EnumService.ContactsPageActionType.NewChat || actionType === EnumService.ContactsPageActionType.ForwardMessage || actionType === EnumService.ContactsPageActionType.ChooseContactForTag;

		if (actionType === EnumService.ContactsPageActionType.CreateGroup && defaultGroupMember) {
			selectedContacts.push(defaultGroupMember._id);
			disabledContacts.push(defaultGroupMember._id);
		}

		this.state = {
			loggedInUser: {},
			newContactRequestCount: 0,
			selectedContacts: selectedContacts,
			disabledContacts: disabledContacts,
			isContactSelectMode: isContactSelectMode,
			showSearchContactsModal: false,
			contacts: [],
			pageActionType: actionType,
		};

		this.props.resetValues();
	}

	async setPageData() {
		this.checkNewContactRequest();

		let user = await apiService.me(),
			contacts: any = user.contacts.filter((_contact: any) => _contact.status === 'confirmed').sort(sortByAlias);
			/*contacts: any = await apiService.getContacts(false).then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});

		contacts = contacts.filter((_contact: any) => _.includes(['confirmed'], _contact?.status));*/

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			contacts: contacts,
		}));

		this.componentIsMounted = true;
	}

	async componentDidMount() {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	}

	async componentDidUpdate() {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	}

	setSearchText() {}

	searchAllGroups(searchValue: string, props: any) {
		props.history.replace('/contacts/search', searchValue);
	}

	async checkNewContactRequest() {
		let newContactRequestCount = 0,
			contacts = await apiService.getConversations();

		contacts.forEach((_contact: any, i: any) => {
			if (_contact && _contact?.status === 'pendingConfirm') {
				newContactRequestCount++;
			}
		});

		this.setState({ newContactRequestCount: newContactRequestCount });
	}

	_onContactSelect = (_contact: any) => {
		try {
			let tmpList: any = this.state.selectedContacts || [];
			const contactIdIndex = tmpList.indexOf(_contact?._id);
			if (contactIdIndex === -1) {
				tmpList.push(_contact._id);
			} else {
				tmpList.splice(contactIdIndex, 1);
			}
			this.setState({ selectedContacts: tmpList });
		} catch (error) {}
	};

	_onDone = () => {
		if (this?.state && this.state?.pageActionType === EnumService.ContactsPageActionType.ChooseContactForTag) {
			this.props.history.goBack();
		} else {
			if (this.state.selectedContacts && this.state.selectedContacts?.length > 0) {
				this.props.history.replace('/group-manager', {
					memberIds: [this.state.loggedInUser?._id, ...this.state.selectedContacts],
				});
			}
		}
	};

	async scrollToContactSection(elementId: string, ionContentId: string) {
		const element: any = document.getElementById(elementId);
		let ionContent: any = document.getElementById(ionContentId);
		if (ionContent) {
			const elem = await ionContent.getScrollElement();
			elem?.scrollTo(0, element.offsetTop);
		}
	}

	render() {
		const { isContactSelectMode, selectedContacts, disabledContacts, pageActionType } = this.state;

		let pageTitle: string = '',
			disableDoneBtnForContactSelectMode: boolean = false,
			showSelectedContacts: boolean = false,
			visibleOptions: any[] = [];

		if (pageActionType === EnumService.ContactsPageActionType.ChooseContactForTag) {
			visibleOptions = ['import_from_group'];
			pageTitle = locale.contacts.friends;
			showSelectedContacts = false;
			disableDoneBtnForContactSelectMode = !selectedContacts || selectedContacts?.length <= 0 || !disabledContacts || selectedContacts?.length <= disabledContacts.length;
		} else if (pageActionType === EnumService.ContactsPageActionType.CreateGroup || pageActionType === EnumService.ContactsPageActionType.NewChat || pageActionType === EnumService.ContactsPageActionType.ForwardMessage) {
			visibleOptions = ['work_accounts', 'starred'];
			showSelectedContacts = true;
			pageTitle = locale.contacts.friends;
			disableDoneBtnForContactSelectMode = !selectedContacts || selectedContacts?.length <= 1 || !disabledContacts || selectedContacts?.length <= disabledContacts.length;
		} else {
			visibleOptions = ['recent_friends', 'group_chats', 'tags', 'official_accounts', 'work_accounts'];
			pageTitle = locale.contacts.title;
		}

		let alphabetsList = this.state.contacts.map((contact: any) => {
			const contactName = contact?.alias || contact?.username || contact?.userId || '';
			return contactName.charAt(0);
		});
		alphabetsList = _.uniq(alphabetsList).sort();

		return (
			<>
				<IonPage className="contacts-page">
					<TopNavbar
						{...this.props}
						isHideRightButton={isContactSelectMode}
						leftButtonIcon={isContactSelectMode ? closeSharp : GroupCreateIcon}
						pageTitle={pageTitle}
						searchTypeHandler={this.setSearchText}
						searchHandler={this.searchAllGroups}
						onSearchBarFocus={() => {
							if (!isContactSelectMode) {
								this.setState({
									showSearchContactsModal: true,
								});
							}
						}}
						onLeftButtonPress={() => {
							this.props.history.push('/contacts/add-members', {
								actionType: EnumService.AddMembersPageActionType.ChooseContactForGroup,
							});
							// if (isContactSelectMode) {
							// 	if (this.state.isContactSelectMode) {
							// 		this.props.history.goBack();
							// 	} else {
							// 		this.setState({ isContactSelectMode: false });
							// 	}
							// } else {
							// 	this.setState({ isContactSelectMode: true });
							// }
						}}
					/>

					{showSelectedContacts && (
						<>
							<SelectedContacts contacts={this.state.contacts} selectedContacts={selectedContacts} props={this.props} onContactSelect={this._onContactSelect} />
						</>
					)}

					<IonContent id="contacts-content" className="main-content-profile has-bottom-navbar has-topbar-with-searchbar">
						<div className="sec-3">
							<>
								<IonList className="contact-groups-list">
									{_.includes(visibleOptions, 'recent_friends') && (
										<IonItem className="recent_friends" button onClick={() => this.props.history.replace('/contacts/recent')} lines="none" detail={false}>
											<div className="icon-div" slot="start">
												<IonIcon src={RecentlyAddedIcon} />
												{this.state.newContactRequestCount > 0 && <IonBadge>{this.state.newContactRequestCount}</IonBadge>}
											</div>
											<IonLabel>{locale.contacts.recent_friends}</IonLabel>
										</IonItem>
									)}

									{_.includes(visibleOptions, 'group_chats') && (
										<IonItem className="group_chats" button onClick={() => info('Group Chats')} lines="none" detail={false}>
											<div className="icon-div" slot="start">
												<IonIcon src="assets/icon/group.svg" />
											</div>
											<IonLabel>{locale.contacts.group_chats}</IonLabel>
										</IonItem>
									)}
									{_.includes(visibleOptions, 'tags') && (
										<IonItem className="tags" button onClick={() => this.props.history.replace('/tags')} lines="none" detail={false}>
											<div className="icon-div" slot="start">
												<IonIcon src={TagsIcon} />
											</div>
											<IonLabel>{locale.contacts.tags}</IonLabel>
										</IonItem>
									)}
									{_.includes(visibleOptions, 'official_accounts') && (
										<IonItem className="official_accounts" button onClick={() => info('Official Accounts')} lines="none" detail={false}>
											<div className="icon-div" slot="start">
												<IonIcon src={OfficialAccIcon} />
											</div>
											<IonLabel>{locale.contacts.official_accounts}</IonLabel>
										</IonItem>
									)}
									{_.includes(visibleOptions, 'work_accounts') && (
										<IonItem className="work_accounts" button onClick={() => info('Work Chats')} lines="none" detail={isContactSelectMode}>
											<div className="icon-div" slot="start">
												<IonIcon src={ChatIcon} />
											</div>
											<IonLabel>{locale.contacts.chats_only}</IonLabel>
											{isContactSelectMode && <IonNote slot="end">{locale.contacts.selected}: 2</IonNote>}
										</IonItem>
									)}
									{_.includes(visibleOptions, 'import_from_group') && (
										<IonItem button onClick={() => {}} lines="none" detail={false}>
											<div className="icon-div" slot="start">
												<IonIcon src="assets/icon/group.svg" />
											</div>
											<IonLabel>{locale.contacts.import_from_group}</IonLabel>
										</IonItem>
									)}
									{_.includes(visibleOptions, 'starred') && (
										<IonItem button onClick={() => info('Starred')} lines="none" detail={true}>
											<div className="icon-div" slot="start">
												<IonIcon src={StarredIcon} />
											</div>
											<IonLabel>{locale.contacts.starred}</IonLabel>
											{isContactSelectMode && <IonNote slot="end">{locale.contacts.selected}: 3</IonNote>}
										</IonItem>
									)}
								</IonList>
							</>
						</div>

						<ContactsList
							props={this.props}
							selectedContacts={selectedContacts}
							disabledContacts={disabledContacts}
							starredContacts={this.state.contacts}
							contacts={this.state.contacts}
							contactListMode={isContactSelectMode ? EnumService.ContactsListMode.ChooseContactsList : EnumService.ContactsListMode.NormalContactsList}
							onContactSelect={this._onContactSelect}
						/>

						{isContactSelectMode && (
							<>
								<br />
								<br />
								<br />
								<br />
								<br />
							</>
						)}

						<ContactAlbhabetView
							alphabets={alphabetsList}
							showStarredInsideAlbhabetList={true}
							onAlphabetSelect={(albhabet: any) => {
								this.scrollToContactSection('section_' + albhabet, 'contacts-content');
							}}
							onGoToStarredContact={async () => {
								this.scrollToContactSection('section_starred', 'contacts-content');
							}}
						/>
					</IonContent>
					{isContactSelectMode && (
						<IonFooter className="selected-contact-mode-footer">
							<IonToolbar className="toolbar-wrapper">
								<IonButtons slot="end">
									<IonButton className="send-button" disabled={disableDoneBtnForContactSelectMode} onClick={this._onDone}>
										{locale.global.done}
										{selectedContacts && selectedContacts.length > 0 && '(' + selectedContacts.length + ')'}
									</IonButton>
								</IonButtons>
							</IonToolbar>
						</IonFooter>
					)}

					{!isContactSelectMode && <BottomNavbar {...this.props} />}
				</IonPage>

				<SearchContactsModal
					searchTypeHandler={this.setSearchText}
					searchHandler={(event: any) => {
						this.searchAllGroups(event, this.props);
					}}
					show={this.state.showSearchContactsModal}
					onClose={() => this.setState({ showSearchContactsModal: false })}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	//addGroup: (payload: any) => dispatch(addGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
