import React, { createRef, useCallback, useEffect, useRef } from 'react';
import { IonButtons, IonIcon, IonList, IonItem, IonLabel, IonThumbnail, IonImg, IonToolbar, IonTitle, IonButton, IonAlert } from '@ionic/react';
import './style.scss';
import { atOutline } from 'ionicons/icons';
import Emoji from '../../../emoji/Emoji';
import { ChatOtherOptions } from '../ChatOtherOptions/ChatOtherOptions';
import CameraService from '../../../../services/cameraService';
import { connect } from 'react-redux';
import { publishData, resetPublishData } from '../../../../redux/actions/pubsub';
import EnumService from '../../../../services/enumService';
import GroupMembersTagModal from '../../../../modals/GroupMembersTagModal';
import { blankProfilePic, info, getInternalStorage, isBlank } from '../../../../helpers/common';
import sanitizeHtml from 'sanitize-html';
import _, { defer } from 'lodash';
import ChatReplyView from './ChatReplyView';
import { xmpp } from '../../../../services/xmpp';
import { sharedService } from '../../../../services/sharedService';

interface iProps {
	self: any;
	myRef: any;
	pubsub: any;
	allContacts: any;
	resetPublishData: Function;
	publishData: Function;
	loggedInUser: any;
	props: any;
	onMediaUpload?: Function; // accept single media file and array of media file
	onProfilePhotoPress?: Function;
	onPasteImageFromClipboard?: Function;
	onChatBoxHeightChange?: Function;
	onMessageSent?: Function;
}

let chatBoxHeight = 0;
let isMessageSending = false;

const AdditionalOptionTypes = {
		EmojiPicker: 'emoji',
		ChatOtherOptions: 'chat-other-options',
	},
	cameraService = new CameraService(),
	ChatInputBox: React.FC<iProps> = ({ self, myRef, pubsub, allContacts, loggedInUser, publishData, resetPublishData, props, onMediaUpload, onPasteImageFromClipboard, onProfilePhotoPress, onChatBoxHeightChange, onMessageSent }) => {
		const createRange = useCallback((node: any, chars: any, range: any) => {
			if (!range) {
				range = document.createRange();
				range.selectNode(node);
				range.setStart(node, 0);
			}

			if (chars.count === 0) {
				range.setEnd(node, chars.count);
			} else if (node && chars.count > 0) {
				if (node.nodeType === Node.TEXT_NODE) {
					if (node.textContent.length < chars.count) {
						chars.count -= node.textContent.length;
					} else {
						range.setEnd(node, chars.count);
						chars.count = 0;
					}
				} else {
					for (let lp in node.childNodes) {
						range = createRange(node.childNodes[lp], chars, range);

						if (chars.count === 0) {
							break;
						}
					}
				}
			}

			return range;
		}, []);

		const chatboxRef: React.RefObject<HTMLDivElement> = createRef(),
			mounted = useRef(false),
			selfRef: React.RefObject<any> = useRef({}),
			userTagPopupRef: React.RefObject<HTMLDivElement> = useRef(null),
			codeRef: React.RefObject<HTMLDivElement> = useRef(null),
			[message, setMessage] = React.useState(''),
			[showAlert, setShowAlert] = React.useState(false),
			[alertMessage, setAlertMessage] = React.useState(''),
			[showVoiceRecordModal, setShowVoiceRecordModal] = React.useState<boolean>(false),
			[showAdditionalOption, setShowAdditionalOption] = React.useState<any>(null),
			[showUserTagModal, setShowUserTagModal] = React.useState<boolean>(false),
			[showUserTagPopup, setShowUserTagPopup] = React.useState<boolean>(false),
			[recalledMessageId, setRecalledMessageId] = React.useState<any>(undefined),
			[isMobileKeyboardOpen, setIsMobileKeyboardOpen] = React.useState<boolean>(false),
			[alphabetsList, setAlphabetsList] = React.useState<any>([]),
			[sortedMembersList, setSortedMembersList] = React.useState<any>([]),
			[taggedMembers, setTaggedMembers] = React.useState<any>([]),
			[messageData, setMessageData] = React.useState<any>(null),
			[showReplyView, setShowReplyView] = React.useState<boolean>(false),
			cookies: any = getInternalStorage(),
			currentPath: any[] = window.location?.pathname?.split('/'),
			pathLast: any = currentPath?.pop(),
			personalNotepadPath: Boolean = pathLast === 'personal-notepad',
			sanitizeConf = {
				allowedTags: ['a', 'span', 'br'],
				allowedAttributes: { a: ['href', 'class'], span: ['style', 'class'] },
			},
			focusChatInput = useCallback(() => {
				const chatEditableInput = document.getElementById('chatEditableInput');
				chatEditableInput?.focus();
			}, []),
			onFocusChatInput = useCallback(() => {
				if (mounted.current) {
					setShowAdditionalOption(null);
					if (cookies.mobileDevice && mounted.current) {
						setIsMobileKeyboardOpen(true);
					}
				}
			}, [cookies.mobileDevice, mounted]),
			onBlurChatInput = useCallback(() => {
				if (cookies.mobileDevice) {
					_.delay(() => {
						if (mounted.current) {
							setIsMobileKeyboardOpen(false);
						}
					}, 100);
				}
			}, [cookies.mobileDevice, mounted]),
			setCaret = (element: any = undefined, node: any = undefined) => {
				element = (element && node) || codeRef?.current;

				if (element) {
					let selection: any = window.getSelection(),
						range: any = document.createRange();
					range = createRange(node || element, { count: element.innerText.length }, range);
					selection.removeAllRanges();
					range.selectNodeContents(element);
					range.collapse(false);
					selection.addRange(range);
				}
			},
			handleBackspace = (event: any, character: any) => {
				if (codeRef.current) {
					codeRef.current.innerHTML = codeRef.current.innerHTML.slice(0, codeRef.current.innerHTML.lastIndexOf(character));

					if (mounted.current) {
						if (!event.shouldNotFocus) {
							setCaret();
						}
						setMessage(String(codeRef.current.innerHTML));
					}
					event.preventDefault && event.preventDefault();
				}
			},
			submitMessage = async (messageType: string = EnumService.ChatMessageType.TEXT) => {
				if (message && props.chat?.receiver && !isMessageSending && mounted.current) {
					focusChatInput();
					isMessageSending = true;
					defer(() => {
						isMessageSending = false;
					}, 1000);

					let relatedMessageId = recalledMessageId;
					if (showReplyView) {
						relatedMessageId = messageData?.messageKey;
						messageType = EnumService.ChatMessageState.REPLY;
					}

					const messageText = message;
					clearInputPress();

					await xmpp.sendMessage(props.chat.receiver.jid, messageText, EnumService.ChatMessageState.ORIGINAL, messageType, relatedMessageId, taggedMembers);
					isMessageSending = false;
					onMessageSent && onMessageSent();
					setShowAdditionalOption(null);

					closeReplyView();
				}
			},
			focusChatInputBack = useCallback(() => {
				if (mounted.current) {
					focusChatInput();
					setShowAdditionalOption(null);
				}
			}, [mounted, focusChatInput]),
			emojiPickerIconPress = useCallback(() => {
				if (mounted.current) {
					publishData({ type: 'resize-chatscreen', data: {} });
					setShowAdditionalOption(AdditionalOptionTypes.EmojiPicker === showAdditionalOption ? null : AdditionalOptionTypes.EmojiPicker);
				}
			}, [showAdditionalOption, publishData, mounted]),
			plusIconPress = useCallback(() => {
				if (mounted.current) {
					publishData({ type: 'resize-chatscreen', data: {} });
					info('showAdditionalOption', showAdditionalOption);
					setShowAdditionalOption(AdditionalOptionTypes.ChatOtherOptions === showAdditionalOption ? null : AdditionalOptionTypes.ChatOtherOptions);
				}
			}, [showAdditionalOption, publishData, mounted]),
			cameraIconPress = useCallback(() => {
				if (mounted.current) {
					cameraService.capturePhotoFromCamera((photo: any) => {
						onMediaUpload && onMediaUpload(photo);
					});
					setShowAdditionalOption(null);
				}
			}, [onMediaUpload]),
			albumIconPress = useCallback(() => {
				if (mounted.current) {
					cameraService.chooseMediaFromAlbum((medias: any) => {
						if (medias.length > sharedService.maxMediaSelectLimit) {
							setAlertMessage(`Select a Maximum of ${sharedService.maxMediaSelectLimit} photos`);
							setShowAlert(true);
						} else {
							onMediaUpload && onMediaUpload(medias);
						}
					}, true);
					setShowAdditionalOption(null);
				}
			}, [onMediaUpload, mounted]),
			onShowUserTagPopup = useCallback(() => {
				if (mounted.current) {
					if (!showUserTagPopup) {
						const element: any = userTagPopupRef?.current;

						element?.classList.remove('hide');
						element?.classList.add('show');

						const codeElem = codeRef?.current;

						if (element && codeElem) {
							let offsetParent: any = codeElem?.offsetParent;
							if (offsetParent?.offsetParent) {
								offsetParent = offsetParent.offsetParent;
							}
							element.style.bottom = `${(offsetParent?.clientHeight || 0) + 2}px`;
						}
					}
					setShowUserTagPopup(true);
				}
			}, [showUserTagPopup, userTagPopupRef, mounted]),
			onHideUserTagPopup = useCallback(() => {
				if (mounted.current) {
					if (showUserTagPopup) {
						const element: any = userTagPopupRef?.current;
						element?.classList.remove('show');
						element?.classList.add('hide');
					}

					setShowUserTagPopup(false);
				}
			}, [showUserTagPopup, userTagPopupRef, mounted]),
			onShowUserTagModal = useCallback(() => {
				if (mounted.current) {
					onHideUserTagPopup();
					setShowUserTagModal(true);
				}
			}, [onHideUserTagPopup, mounted]),
			onHideUserTagModal = useCallback(() => {
				if (mounted.current) {
					onHideUserTagPopup();
					setShowUserTagModal(false);
				}
			}, [onHideUserTagPopup, mounted]),
			clearInputPress = useCallback(() => {
				if (mounted.current) {
					setMessage('');
				}

				if (codeRef && codeRef.current) {
					codeRef.current.innerHTML = '';
				}

				onHideUserTagModal();

				focusChatInput();
				const delayId = _.delay(() => {
					if (recalledMessageId && mounted.current) {
						setRecalledMessageId(undefined);
					}
				}, 200);

				setShowAdditionalOption(null);
				return () => {
					clearTimeout(delayId);
				};
			}, [codeRef, onHideUserTagModal, recalledMessageId, focusChatInput, mounted]),
			outsideClicked = useCallback(() => {
				if (mounted.current) {
					setShowAdditionalOption(null);
					onHideUserTagPopup();
				}
			}, [onHideUserTagPopup, mounted]),
			onUserTag = useCallback(
				(taggedUserIds, isGroupModal = false) => {
					const element: any = codeRef?.current,
						lastChild: any = element?.lastChild;

					if (lastChild) {
						const wholeText: any = codeRef?.current?.innerText;

						if (wholeText) {
							const attrIndex: number = wholeText.lastIndexOf('@');

							if (attrIndex >= 0) {
								const lastVal: string = wholeText.substring(attrIndex + 1).toLowerCase();

								taggedUserIds.some((alias: any) => {
									if (loggedInUser?.alias !== alias) {
										let userInfo: any = allContacts?.find((obj: any) => obj.alias === alias);

										userInfo = userInfo ? userInfo : props.chat?.receiver.members?.find((obj: any) => obj.alias === alias);

										const userProfileName: string = (userInfo?.alias || userInfo?.username || '').toLowerCase(),
											textContent = lastChild.textContent?.toLowerCase();

										if (userProfileName.includes(lastVal)) {
											if (textContent.lastIndexOf(lastVal) >= 0) {
												lastChild.textContent = lastChild.textContent.substring(0, textContent.lastIndexOf(lastVal) + 1);
											} else if (lastChild && lastChild.innerText) {
												if (!lastChild.innerText.slice(-1).toLowerCase().includes(lastVal)) {
													lastChild.innerText = lastChild.innerText.substring(0, attrIndex);
												}
											}
											return true;
										}
									}
									return false;
								});
							}
						}

						if (lastChild.textContent === '@') {
							lastChild.remove();
						} else if (lastChild && lastChild.innerText && lastChild.innerText.slice(-1) === '@') {
							lastChild.innerText = lastChild.innerText.slice(0, -1);
						}
					}

					if (element) {
						let textToAppend = '',
							tagged: any[] = [];
						if (taggedUserIds.length === props.chat?.receiver.members.length - 1) {
							textToAppend = `<span class="tagged">@All</span>&nbsp;`;
						} else {
							// this needs to use alias (not userid) to look up the group member, as that is what is displayed and
							// selected by the user.
							taggedUserIds.forEach((userId: any, key: any) => {
								let userInfo = props.chat?.receiver.members?.find((obj: any) => obj.userId === userId) || allContacts?.find((obj: any) => obj.userId === userId);
								textToAppend = `${textToAppend}<a class="tagged" href="chat/${userInfo.userId}">@${userInfo?.alias || userInfo?.username}</a>${key !== taggedUserIds.length - 1 ? ',&nbsp;' : '&nbsp;'}`;
								tagged.push(userInfo.userId);
							});
						}
						if (isGroupModal) {
							element.innerHTML = `${element.innerHTML}${textToAppend}`;
						} else {
							const startPos = element.innerHTML.lastIndexOf('@');
							element.innerHTML = `${element.innerHTML.slice(0, startPos)}${textToAppend}`;
						}

						if (mounted.current) {
							setTaggedMembers(tagged);
							setCaret();
							setMessage(String(codeRef?.current?.innerHTML));
						}
					}
					onHideUserTagModal();
				},
				[onHideUserTagModal, loggedInUser?.alias, props.chat?.receiver?.members, codeRef, allContacts, mounted]
			),
			TagIcon = ({ iconPos }: any) => (
				<IonIcon
					className={`icon-inside-chat-input ${iconPos}`}
					slot="start"
					icon={atOutline}
					onClick={() => {
						onShowUserTagModal();
					}}
				/>
			),
			ClearTextIcon = () => <IonIcon slot="start" className="clear-chat-icon" src={'./assets/icon/close-icon.svg'} onClick={clearInputPress} />,
			KeyBoardIcon = ({ slot, icon }: any) => <IonIcon className="keyboard-open-icon" slot={slot} onClick={focusChatInputBack} src={'./assets/icon/' + (icon ? icon : 'keyboard.svg')} />,
			SmileyEmojiIcon = ({ slot, icon }: any) => <IonIcon className="emoji-open-icon" slot={slot} onClick={() => emojiPickerIconPress()} src={'./assets/icon/' + (icon ? icon : 'smiley-icon.svg')} />;

		useEffect(() => {
			mounted.current = true;
			return () => {
				mounted.current = false;
			};
		}, []);

		const openReplyView = useCallback(
			(_msgData) => {
				if (mounted.current) {
					setMessageData(_msgData);
					setShowReplyView(true);
					if (selfRef && selfRef.current) {
						selfRef.current.isReplyViewOpen = true;
						selfRef.current.replyForMessageId = _msgData.messageKey;
					}
				}
			},
			[selfRef, mounted]
		);

		const closeReplyView = useCallback(() => {
			if (mounted.current) {
				setShowReplyView(false);
				setMessageData(null);
				if (selfRef && selfRef.current) {
					selfRef.current.isReplyViewOpen = false;
					selfRef.current.replyForMessageId = null;
				}
			}
		}, [selfRef, mounted]);

		if (selfRef && selfRef.current) {
			selfRef.current.closeReplyView = closeReplyView;
		}

		const isGroupChat = props.chat?.hasOwnProperty('type') && props?.chat.type === 'groupchat' && !personalNotepadPath;

		myRef && myRef(selfRef);

		useEffect(() => {
			if (pubsub?.publishedData) {
				if (pubsub?.publishType === EnumService.PubSubEventType.RecallEdit) {
					if (pubsub?.publishedData?.messageType === 'text') {
						if (mounted.current) {
							setMessage(pubsub?.publishedData?.body);
							focusChatInput();

							if (codeRef && codeRef.current) {
								codeRef.current.innerHTML = pubsub?.publishedData?.body;
								setRecalledMessageId(pubsub?.publishedData?.messageKey);
							}
						}
					}
				} else if (pubsub?.publishType === EnumService.PubSubEventType.ReplyToTheConversation) {
					openReplyView(pubsub?.publishedData);
					resetPublishData();

					focusChatInput();
				}
			}
			resetPublishData();

			return () => {};
		}, [pubsub?.publishType, pubsub?.publishedData, resetPublishData, focusChatInput, openReplyView, mounted]);

		useEffect(() => {
			const delayId = _.delay(() => {
				focusChatInput();
			}, 100);
			return () => {
				clearTimeout(delayId);
				chatBoxHeight = 0;
			};
		}, [codeRef, focusChatInput]);

		useEffect(() => {
			if (chatboxRef.current) {
				const { current } = chatboxRef;
				const boundingRect = current.getBoundingClientRect();
				const { height } = boundingRect;
				if (chatBoxHeight !== height) {
					chatBoxHeight = height;
					onChatBoxHeightChange && onChatBoxHeightChange(height);
				}
			}
		}, [chatboxRef, onChatBoxHeightChange]);

		useEffect(() => {
			const currentRef = chatboxRef?.current;
			currentRef?.parentElement?.getElementsByTagName('ion-content')[0]?.addEventListener('click', outsideClicked, { passive: true });
			return () => {
				currentRef?.parentElement?.getElementsByTagName('ion-content')[0]?.removeEventListener('click', outsideClicked);
			};
		}, [chatboxRef, outsideClicked]);

		useEffect(() => {
			if (props.chat?.receiver?.members && props.chat?.receiver?.members.length > 0) {
				let sortedList = props.chat?.receiver?.members;
				sortedList.sort((a: any, b: any) => (a.alias?.toLowerCase() < b.alias?.toLowerCase() ? -1 : a.alias?.toLowerCase() > b.alias?.toLowerCase() ? 1 : 0));

				const membersListExceptMe: any = [];
				const memberAlphabets: any = [];
				sortedList.map((item: any) => {
					if (loggedInUser?.userId !== item.userId) {
						membersListExceptMe.push(item);
						memberAlphabets.push(item.alias?.toUpperCase()?.charAt(0));
					}
					return null;
				});
				const uniqueAlphabets = memberAlphabets.filter((item: any, i: number, self: any) => self.indexOf(item) === i);

				setAlphabetsList(uniqueAlphabets);
				setSortedMembersList(membersListExceptMe);
			}
		}, [loggedInUser, props.chat?.receiver?.members]);

		const onPaste = useCallback(
			(event: any) => {
				event.preventDefault();
				// Paste image from clipboard

				for (let item of event?.clipboardData?.items) {
					// Skip content if not image
					if (item.type.startsWith('image')) {
						// Retrieve image on clipboard as blob
						let blob: any = item.getAsFile(),
							reader: any = new FileReader();

						if (mounted.current) {
							setMessage('');
						}
						// eslint-disable-next-line no-loop-func
						reader.onload = (event: any) => {
							if (mounted.current) {
								setMessage('');
								onPasteImageFromClipboard && onPasteImageFromClipboard(event.target.result);
							}
						};
						reader.readAsDataURL(blob);
						break;
					} else if (item.type.startsWith('text/plain')) {
						document.execCommand('inserthtml', false, event.clipboardData.getData('text/plain').replace(/&quot;/g, ''));
					}
				}
			},
			[onPasteImageFromClipboard, mounted]
		);

		const onKeyDownCallback = useCallback(
			(event) => {
				const element: any = codeRef?.current;
				if (mounted.current) {
					switch (event.key) {
						// handle Enter key press
						case 'Enter':
							if ((!cookies.mobileDevice && event.ctrlKey) || cookies.mobileDevice) {
								event.preventDefault();

								if (element) {
									element.innerHTML = `${element.innerHTML}<br><br>`;
									setMessage(`${element.innerHTML}<br><br>`);
									setCaret();
									focusChatInputBack();
								} else {
									setMessage(`${message}<br><br>`);
								}
							} else if (event.key === 'Enter') {
								event.preventDefault();
								setMessage(message);
								submitMessage();
							}

							break;

						case 'Space':
							// convert space to &nbsp;
							event.preventDefault();
							element.innerHTML = `${element.innerHTML.slice(0, element.innerHTML.lastIndexOf(' '))}&nbsp;`;

							break;

						case 'Tab':
							// handle Tab keypress while selecting tagged members
							if (!cookies.mobileDevice) {
								// kill the tab press in any case
								event.preventDefault();
								let current: any = codeRef?.current,
									currentInnerText: string = current?.innerText || '',
									hasTags: boolean = !isBlank(currentInnerText) && currentInnerText.includes('@');

								if (element && isGroupChat && hasTags && userTagPopupRef?.current?.children.length === 2) {
									let tag: any = Array.from(userTagPopupRef.current.children[1].childNodes).find((node: any) => node.innerText.toLowerCase().startsWith(currentInnerText.toLowerCase().substr(currentInnerText.lastIndexOf('@') + 1)));

									if (tag?.innerText) {
										onUserTag([props.chat.receiver.members.find((_member: any) => _member.alias === tag?.innerText).userId]);
									}
								}

								focusChatInput();
							}

							break;

						// handle Backspace to remove tag or html space

						//TODO: handle inline backspace when there is HTML

						case 'Backspace':
							if (element.innerHTML.endsWith('<br><br>')) {
								handleBackspace(event, '<br><br>');
							} else if (element.innerHTML.endsWith('&nbsp;')) {
								handleBackspace(event, '&nbsp;');
							} else if (element.innerHTML.endsWith('</a>')) {
								handleBackspace(event, '<a');
							} else if (element.innerHTML.endsWith('</span>')) {
								let memberStart = element.innerHTML.lastIndexOf('<span'),
									memberEnd = element.innerHTML.indexOf('</span>', memberStart),
									memberSpan = element.innerHTML.substring(memberStart, memberEnd),
									member = memberSpan.split('@')[1].split('<');

								setTaggedMembers(
									taggedMembers.splice(
										taggedMembers.findIndex((_member: any) => _member === member),
										1
									)
								);
								handleBackspace(event, '<span');
							} else {
								// const emojjis = SharedService.emojis(element.innerHTML);
								// if (emojjis.length > 0 && element.innerHTML.endsWith(emojjis.slice(-1))) {
								// 	handleBackspace(event, emojjis.slice(-1));
								// } else {
								// 	handleBackspace(event, element.innerHTML.slice(-1));
								// }
							}

							break;

						default:
							break;
					}
				}
			},
			[codeRef, userTagPopupRef, message, taggedMembers, mounted, cookies.mobileDevice, focusChatInput, focusChatInputBack, handleBackspace, isGroupChat, onUserTag, props.chat.receiver.members, submitMessage]
		);

		useEffect(() => {
			window.addEventListener('paste', onPaste, false);
			return () => {
				window.removeEventListener('paste', onPaste, false);
			};
		}, [onPaste]);

		// myRef && myRef(selfRef);

		return (
			<div
				id="chatInputBox"
				className="chat-input-box"
				ref={chatboxRef}
				onClick={(event) => event.stopPropagation()}
				style={{ paddingBottom: showAdditionalOption === AdditionalOptionTypes.EmojiPicker ? '0px' : cookies.mobileDevice && !isMobileKeyboardOpen && cookies.displayMode === 'standalone' ? '42px' : '34px' }}
			>
				{showReplyView && (
					<ChatReplyView
						messageData={messageData}
						onClose={() => {
							closeReplyView();
						}}
					/>
				)}

				{props.chat?.hasOwnProperty('type') && props?.chat.type === 'groupchat' && (
					<div className={'user-list-popup'} ref={userTagPopupRef}>
						<div className="header-toolbar">
							<IonToolbar mode="ios">
								<IonTitle className="title">Tag User</IonTitle>
								<IonButtons slot="end">
									<IonButton
										className={'all-btn'}
										onClick={() => {
											const idsList: any = [];
											sortedMembersList.forEach((item: any) => {
												if (!_.includes(idsList, item.userId)) {
													idsList.push(item.userId);
												}
											});
											onUserTag(idsList);
										}}
									>
										@ALL
									</IonButton>
								</IonButtons>
							</IonToolbar>
						</div>
						<IonList lines="none">
							{sortedMembersList?.map((item: any, key: any) => {
								const userInfo: any = self.conversations?.find((_contact: any) => _contact.userId === item?.userId) || self.props.chat?.receiver?.members?.find((_member: any) => _member.alias === item?.userId) || allContacts?.find((obj: any) => obj.userId === item.userId),
									userProfileName: any = item.alias,
									wholeText: any = codeRef?.current?.innerText;

								if (wholeText) {
									const msgsplits: any = wholeText.split('@');

									if (msgsplits && msgsplits.length > 0) {
										const lastVal: any = msgsplits.slice(-1)[0];

										if (lastVal && userProfileName && !userProfileName.toLowerCase().includes(lastVal.toLowerCase())) {
											return null;
										}
									}
								}

								return (
									<IonItem
										key={key}
										onClick={() => {
											onUserTag([item.userId]);
										}}
									>
										<IonThumbnail slot="start">
											<IonImg src={userInfo?.profilePhoto || blankProfilePic} />
										</IonThumbnail>
										<IonLabel>{userProfileName}</IonLabel>
									</IonItem>
								);
							})}
						</IonList>
					</div>
				)}

				<div className="toolbar-view">
					<IonButtons slot="start">
						{(isGroupChat || showAdditionalOption === AdditionalOptionTypes.EmojiPicker) && message ? (
							<ClearTextIcon />
						) : (
							<>
								{showAdditionalOption !== AdditionalOptionTypes.ChatOtherOptions && <IonIcon slot="start" src={'./assets/icon/add-plus-icon.svg'} onClick={plusIconPress} />}
								{showAdditionalOption === AdditionalOptionTypes.ChatOtherOptions && <KeyBoardIcon slot="start" />}
							</>
						)}
					</IonButtons>

					<div className="messeger-chat-input">
						{isGroupChat && <TagIcon iconPos="left" />}

						<div
							id="chatEditableInput"
							className="editable-input"
							contentEditable={true}
							ref={codeRef}
							onFocus={onFocusChatInput}
							onBlur={onBlurChatInput}
							onInput={(event) => {
								// Set bottom position of Popup if opened

								if (isGroupChat) {
									const element: any = codeRef?.current;
									if (showUserTagPopup && userTagPopupRef) {
										const userTagPopupElem = userTagPopupRef.current;

										if (userTagPopupElem) {
											let offsetParent: any = element?.offsetParent;
											if (offsetParent?.offsetParent) {
												offsetParent = offsetParent.offsetParent;
											}
											userTagPopupElem.style.bottom = `${(offsetParent?.clientHeight || 0) + 2}px`;
										}
									}

									const wholeText: any = codeRef?.current?.innerText,
										lastChar: any = wholeText ? wholeText.slice(-1) : '';

									let shouldShowTagPopup = false;

									if (wholeText) {
										sortedMembersList?.forEach((item: any) => {
											const userProfileName: string = item?.alias,
												msgsplits: any = wholeText.split('@');

											if (msgsplits && msgsplits.length > 1) {
												const lastVal: any = msgsplits.slice(-1)[0];
												shouldShowTagPopup = !isBlank(lastVal) && userProfileName.toLowerCase().includes(lastVal.toLowerCase());
											}
										});
									}

									if (lastChar === '@' || shouldShowTagPopup) {
										onShowUserTagPopup();
									} else if (!showUserTagPopup) {
										onHideUserTagPopup();
									}
								}

								const msg: string = String(codeRef?.current?.innerHTML);

								if (msg === '<br>') {
									clearInputPress();
								} else {
									if (mounted.current) {
										setMessage(sanitizeHtml(msg, sanitizeConf));
									}
								}
							}}
							onKeyDown={onKeyDownCallback}
						></div>

						{message && (
							<>
								{isGroupChat ? (
									<>
										{showAdditionalOption === AdditionalOptionTypes.EmojiPicker && <KeyBoardIcon icon="keyboard-light.svg" slot="end" />}
										{showAdditionalOption !== AdditionalOptionTypes.EmojiPicker && <SmileyEmojiIcon icon="smiley-icon-light.svg" slot="end" />}
									</>
								) : (
									<>
										<ClearTextIcon />
									</>
								)}
							</>
						)}
						{(!message || message.length === 0) && (
							<IonButton className="icon-inside-chat-input right" onClick={() => setShowVoiceRecordModal(true)} fill="clear">
								<IonIcon slot="end" src={'./assets/icon/mic-icon.svg'} />
							</IonButton>
						)}
					</div>
					<IonButtons slot="primary">
						{(!message || message.length === 0) && (
							<>
								{showAdditionalOption === AdditionalOptionTypes.EmojiPicker && <KeyBoardIcon slot={'end'} />}
								{showAdditionalOption !== AdditionalOptionTypes.EmojiPicker && <SmileyEmojiIcon slot="end" />}
								<IonIcon slot="end" src={'./assets/icon/chat-album-icon.svg'} onClick={albumIconPress} />
							</>
						)}

						{message && message.length > 0 && (
							<IonIcon
								slot="end"
								className="send-icon"
								src="./assets/icon/send.svg"
								onClick={(event) => {
									submitMessage();
								}}
							/>
						)}
					</IonButtons>
				</div>

				{showAdditionalOption === AdditionalOptionTypes.EmojiPicker && (
					<div className={'showEmoj'}>
						<Emoji
							message={message}
							onCancel={() => {
								onKeyDownCallback({ key: 'Backspace', shouldNotFocus: true });
							}}
							onEmojiChoose={(emojiObject) => {
								const element: any = codeRef?.current;
								element.innerHTML = `${element.innerHTML}${emojiObject.emoji}`;
								if (mounted.current) {
									setMessage(String(codeRef?.current?.innerHTML));
								}
							}}
						/>
					</div>
				)}

				{showAdditionalOption === AdditionalOptionTypes.ChatOtherOptions && (
					<ChatOtherOptions
						onTriggerFileUpload={() => {
							albumIconPress();
						}}
						onTriggerPhotoCapture={() => {
							cameraIconPress();
						}}
						props={props}
					/>
				)}

				{props.chat?.hasOwnProperty('type') && props.chat.type === 'groupchat' && (
					<GroupMembersTagModal
						props={props}
						self={self}
						alphabetsList={alphabetsList}
						sortedMembersList={sortedMembersList}
						allContacts={allContacts}
						show={showUserTagModal}
						onProfilePhotoPress={onProfilePhotoPress}
						onClose={() => {
							onHideUserTagModal();
						}}
						onDone={(userIds: any) => onUserTag(userIds, true)}
					/>
				)}

				{/* {
					<VoiceRecord
					show={showVoiceRecordModal}
					onClose={() => {
						setShowVoiceRecordModal(false);
					}}
				/>
			} */}

				<IonAlert
					mode="ios"
					isOpen={showAlert}
					onDidDismiss={() => {
						setShowAlert(false);
						setAlertMessage('');
					}}
					cssClass="alert-controller-class"
					message={alertMessage}
					buttons={[
						{
							text: 'OK',
							role: 'destructive',
						},
					]}
				/>
			</div>
		);
	};

const mapStateToProps = (state: any) => {
	return {
		pubsub: state.pubsub,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetPublishData: () => dispatch(resetPublishData()),
	publishData: (payload: any) => dispatch(publishData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatInputBox);
