import { IonModal, IonContent, IonButton, IonIcon, IonActionSheet } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './style.scss';
import EnumService from '../services/enumService';
import 'react-awesome-slider/dist/styles.css';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; // import css

interface VideoPreviewModalProps {
	props: any;
	show: boolean;
	image: any;
	selectedMediaMessageKey: any;
	onClose?: any;
	onViewAllMediaClick?: any;
	onSend?: any;
}

export const VideoPreviewModal: React.FC<VideoPreviewModalProps> = ({ selectedMediaMessageKey, props, show, image, onClose, onViewAllMediaClick, onSend }) => {
	const CloseIcon = './assets/icon/close-icon-outline.svg',
		PhotoGridIcon = './assets/icon/photo-grid-icon.svg',
		PhotoMenuIcon = './assets/icon/photo-menu-icon.svg',
		[showActionSheet, setShowActionSheet] = useState(false),
		[openModal, setOpenModal] = useState(false),
		mediaMessages = props.chat.history.filter((item: any) => item?.messageType === EnumService.ChatMessageType.MEDIA && (item?.mediaType === EnumService.ChatMediaType.IMAGE || item?.mediaType === EnumService.ChatMediaType.VIDEO)),
		slideIntialIndex = mediaMessages.findIndex((message: any) => message.messageKey === selectedMediaMessageKey);

	useEffect(() => {
		if (!show && openModal) {
			setOpenModal(false);

			const slidesElem: any = document.getElementById('slidesId');
			if (slidesElem) {
				slidesElem.style.opacity = 0;
			}
		} else {
			if (!openModal && show) {
				setOpenModal(true);

				setTimeout(() => {
					const slidesElem: any = document.getElementById('slidesId');
					if (slidesElem) {
						slidesElem.style.opacity = 1;
					}
				}, 500);
			}
		}
	}, [show, slideIntialIndex, openModal, props]);

	return (
		<IonModal cssClass="video-preview-modal" isOpen={openModal} onDidDismiss={() => onClose()} swipeToClose={false} showBackdrop={false}>
			<IonContent>
				<div className="image-container">
					{/* <IonSlides pager={true} style={{ opacity: 1 }} id="slidesId" options={{ initialSlide: slideIntialIndex, allowSlideNext: true }} ref={slideElementRef}>
						{mediaMessages.map((message: any, key: any) => {
							const img = message.mediaUrl || message.mediaThumbnail;
							return (
								<IonSlide key={key}>
									<img src={img} alt={locale.photo.photo_viewer_modal.close} />
								</IonSlide>
							);
						})}
					</IonSlides> */}

					<Player autoPlay={false}>
						<source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
					</Player>

					{/* <AwesomeSlider selected={slideIntialIndex} mobileTouch={true} bullets={false} organicArrows={false} infinite={false} startup={false} buttons={false} fillParent={true}>
						{mediaMessages.map((message: any, key: any) => {
							const img = message.mediaUrl || message.mediaThumbnail;
							return <div data-src={img} key={key}></div>;
						})}
					</AwesomeSlider> */}

					{/* <img src={image} alt={locale.photo.photo_viewer_modal.close} /> */}
				</div>
			</IonContent>

			<div className="photo-options-btns-container">
				<div className="btns">
					<IonButton fill="clear" slot="icon-only" onClick={onClose}>
						<IonIcon className="close-icon" src={CloseIcon} />
					</IonButton>

					<div style={{ flex: 1 }}></div>

					<IonButton fill="clear" slot="icon-only" onClick={onViewAllMediaClick}>
						<IonIcon src={PhotoGridIcon} />
					</IonButton>
					<IonButton
						fill="clear"
						slot="icon-only"
						onClick={() => {
							setShowActionSheet(true);
						}}
					>
						<IonIcon src={PhotoMenuIcon} />
					</IonButton>
				</div>
			</div>

			<IonActionSheet
				mode="md"
				isOpen={showActionSheet}
				onDidDismiss={() => setShowActionSheet(false)}
				cssClass={'photo-preview-menu-actionsheet'}
				buttons={[
					{
						text: 'Send to chat',
						handler: () => {
							setShowActionSheet(false);
						},
					},
					{
						text: 'Bookmark',
						handler: () => {
							setShowActionSheet(false);
						},
					},
					{
						text: 'Share',
						handler: () => {
							setShowActionSheet(false);
						},
					},
					{
						text: 'Cancel',
						cssClass: 'action-sheet-group-cancel',
						handler: () => {
							setShowActionSheet(false);
						},
					},
				]}
			></IonActionSheet>
		</IonModal>
	);
};
