import React, { useCallback, useEffect, useRef, useState } from 'react';
import { peopleOutline, personAddOutline, settingsOutline, heartOutline, listOutline, ellipsisHorizontal, addCircleOutline } from 'ionicons/icons';
import { IonToolbar, IonTitle, IonList, IonItem, IonButtons, IonButton, IonIcon, IonLabel, IonSearchbar, IonPopover } from '@ionic/react';
import './style.scss';
import EnumService from '../../../services/enumService';
import DropdownPopover from '../../../components/DropdownPopover';
import FavoriteSearchModal from '../../../modals/favoriteSearchModal';
import CustomSpinner from '../../../components/CustomSpinner';
import { locale } from '../../../locales/local';
import { logoutUser } from '../../../helpers/common';
import NotificationBadge from '../../../components/NotificationBadge';

const BackIcon = './assets/icon/back.svg';
const InfoIcon = './assets/icon/info.svg';
const SettingIcon = './assets/icon/settings.svg';
const AddPlusIcon = './assets/icon/add-icon.svg';
const AddContactIcon = './assets/icon/add-contact-icon.svg';

interface ContainerProps {
	showUnViewDotOnLeftIcon?: boolean;
	isLoading?: boolean;
	loadingText?: string;
	showBack?: boolean;
	hideSearchBar?: boolean;
	isHideLeftButton?: boolean;
	isHideRightButton?: boolean;
	leftButtonIcon?: any;
	leftButtonText?: string;
	rightButtonDisabled?: boolean;
	rightButtonText?: string;
	history: any;
	friendProfile?: any;
	pageTitle: String;
	pageTitleDescription?: String;
	unreadCount?: number;
	searchHandler?: Function;
	searchTypeHandler?: Function;
	onSearchBarFocus?: Function;
	onLeftButtonPress?: Function;
	onRightButtonPress?: Function;
	appendAdditionalViewOnBottom?: any;
}

const menuOptions: any = {
	auth: [
		{ title: locale.topbar.placeholder.new_group, svgIcon: 'group-create-icon.svg', width: '28px', height: '20px', type: 'createGroup' },
		{ title: locale.topbar.placeholder.add_contact, svgIcon: 'add-contact-filled-icon.svg', width: '33px', height: '20px', type: 'addContact' },
		{ title: locale.topbar.placeholder.favorite, svgIcon: 'favorite-filled-icon.svg', width: '20px', height: '18px', type: 'favorite' },
		{ title: locale.topbar.placeholder.settings, svgIcon: 'setting-filled-icon.svg', width: '20px', height: '20px', type: 'settings' },
	],
	profile: [
		{ title: locale.topbar.placeholder.edit_profile, svgIcon: 'pencil.svg', type: 'editProfile' },
		{ title: locale.topbar.placeholder.view_as, svgIcon: 'eye.svg', type: 'viewAs' },
		{ title: locale.topbar.placeholder.pay, svgIcon: 'pay.svg', type: 'pay' },
		{ title: locale.topbar.placeholder.privacy, svgIcon: 'privacy.svg', type: 'privacy' },
		{ title: locale.topbar.placeholder.settings, svgIcon: 'settings.svg', type: 'settings' },
		{ title: locale.topbar.placeholder.logout, svgIcon: 'signout.svg', type: 'logout' },
	],
	'profile-friend': [
		{ title: locale.topbar.placeholder.create_group, svgIcon: 'group-create.svg', width: '28.36px', height: '18.55px', type: 'createGroup' },
		{ title: locale.topbar.placeholder.manage_contact, svgIcon: 'pencil.svg', width: '24px', height: '24px', type: 'manageContacts' },
		{ title: locale.topbar.placeholder.transfer, svgIcon: 'wallet-icon.svg', width: '27.5px', height: '22px', type: 'pay' },
		{ title: locale.topbar.placeholder.share, svgIcon: 'share-icon.svg', width: '22px', height: '22px', type: 'shareContact' },
		// { title: 'Manage Contact', svgIcon: 'eye.svg', type: 'manageContacts' },
		// { title: 'Favorites', svgIcon: 'heart.svg', type: 'favorites' },
		// { title: 'Clear Chat History', svgIcon: 'pencil.svg', type: 'clearChatHistory' },
		// { title: 'Report', svgIcon: 'report.svg', type: 'report' },
	],
};

export const TopNavbar: React.FC<ContainerProps> = (props: any) => {
	const logout = useCallback(() => {
		logoutUser();
	}, []);

	let showBack = props.showBack ? props.showBack : false,
		friendProfile = props.friendProfile,
		hideSearchBar = props.hideSearchBar ? props.hideSearchBar : false;

	const routesIcon: any = {
			auth: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			contacts: <IonIcon slot="icon-only" src={AddContactIcon} />,
			'contacts/recent': <IonIcon slot="icon-only" icon={personAddOutline} />,
			profile: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			'profile-friend': <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			'profile/upload-photo': <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			'group-setting': <IonIcon slot="icon-only" icon={InfoIcon} />,
			'group-info': <IonIcon slot="icon-only" icon={SettingIcon} />,
			tags: <IonIcon slot="icon-only" src={AddPlusIcon} />,
			taginfo: <IonIcon slot="icon-only" src={AddPlusIcon} />,
		},
		[searchText, setSearchText] = useState<string>(''),
		[popoverEvent, setPopoverEvent] = useState<any>(),
		[showFavoriteSearchModal, setShowFavoriteSearchModal] = useState<boolean>(false),
		[showPopover, setShowPopover] = useState<boolean>(false),
		[showDropdownPopover, setShowDropdownPopover] = useState<boolean>(false),
		extractPath = props.location.pathname.replace(/^[/]/, ''),
		mounted = useRef(false);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	return (
		<>
			<div className="topbar-header">
				<IonToolbar mode="ios" className="toolbar-heading">
					{!props.isHideLeftButton && (
						<IonButtons slot="start" className="left-btns">
							<IonButton
								onClick={(event: any) => {
									event.persist();
									if (mounted.current) {
										if (props.onLeftButtonPress) {
											props.onLeftButtonPress();
										} else {
											!showBack ? props.history.replace('/settings') : props.history.goBack();
										}
									}
								}}
							>
								{props.leftButtonText ? props.leftButtonText : showBack ? <IonIcon style={{ width: '22px', height: '18px' }} slot="icon-only" src={BackIcon} /> : <IonIcon slot="icon-only" icon={props.leftButtonIcon || settingsOutline} />}
							</IonButton>
							{props.showUnViewDotOnLeftIcon && <NotificationBadge className="dot-circle" showOnlyCircle={true} />}
						</IonButtons>
					)}

					<IonTitle className="toolbarTitle">
						{props?.dashboard?.isLoading && props?.dashboard?.loaderMessage ? (
							<div className="loading-view">
								<CustomSpinner size={22} />
								<IonLabel>{props.dashboard.loaderMessage || 'Loading...'}</IonLabel>
							</div>
						) : (
							<>
								<div className="title">{props.pageTitle}</div>
								{props.pageTitleDescription && <div className="description">{props.pageTitleDescription}</div>}
							</>
						)}
					</IonTitle>

					<IonButtons slot="end" style={{ minWidth: 32 }}>
						{!props.isHideRightButton && (
							<IonButton
								disabled={props.rightButtonDisabled}
								onClick={(event: any) => {
									event.persist();

									if (mounted.current) {
										if (props.onRightButtonPress) {
											props.onRightButtonPress();
										} else {
											if (extractPath === 'contacts' || extractPath === 'contacts/recent') {
												props.history.replace('/contacts/search');
											} else if (extractPath === 'profile' || extractPath === 'profile-friend' || extractPath === 'auth') {
												setPopoverEvent(event);
												setShowDropdownPopover(true);
											} else {
												setShowPopover(true);
												setPopoverEvent(event);
											}
										}
									}
								}}
							>
								{props.rightButtonText ? props.rightButtonText : routesIcon[extractPath]}
							</IonButton>
						)}
					</IonButtons>

					<IonPopover
						cssClass="toggle-menu-pop"
						event={popoverEvent}
						showBackdrop={false}
						isOpen={showPopover}
						onDidDismiss={() => {
							if (mounted.current) {
								setShowPopover(false);
								setPopoverEvent(undefined);
							}
						}}
					>
						<IonList>
							<IonItem
								detail={false}
								className="custom-menu-pop"
								style={{ color: '#000000' }}
								button
								onClick={() => {
									props.history.replace('/contacts', {
										actionType: EnumService.ContactsPageActionType.CreateGroup,
										defaultGroupMember: friendProfile,
									});
								}}
							>
								<IonIcon slot="start" color="#000000" icon={peopleOutline} />
								<IonLabel style={{ textTransform: 'capitalize' }}> {locale.topbar.label.new_group} </IonLabel>
							</IonItem>
							<IonItem
								detail={false}
								className="custom-menu-pop"
								style={{ color: '#000000' }}
								button
								onClick={() => {
									// props.history.replace('/settings')
								}}
							>
								<IonIcon slot="start" color="#000000" icon={listOutline} />
								<IonLabel> To Do’s</IonLabel>
							</IonItem>
							<IonItem className="custom-menu-pop" style={{ color: '#000000' }} lines="none" detail={false} button onClick={() => logout()}>
								<IonIcon slot="start" color="#000000" icon={heartOutline} />
								<IonLabel> {locale.topbar.label.favorite} </IonLabel>
							</IonItem>
						</IonList>
					</IonPopover>
				</IonToolbar>
				{!hideSearchBar ? (
					<IonToolbar mode="ios" className="toolbar-searchbar">
						<form
							onSubmit={(e) => {
								e.preventDefault();
								props.searchHandler && props.searchHandler(searchText, props);
							}}
						>
							<IonSearchbar
								animated={true}
								mode="ios"
								className="searchbar-wrapper"
								style={{ color: '#000000' }}
								debounce={1}
								value={String(searchText)}
								onIonChange={(e) => {
									if (mounted.current) {
										setSearchText(e.detail.value!);
										props.searchTypeHandler(e.detail.value!, props);
									}
								}}
								onFocus={props.onSearchBarFocus}
								showCancelButton="never"
							></IonSearchbar>
						</form>
					</IonToolbar>
				) : null}

				{props.appendAdditionalViewOnBottom ? props.appendAdditionalViewOnBottom : null}
			</div>

			<DropdownPopover
				showPopover={showDropdownPopover}
				list={menuOptions[extractPath]}
				popoverEvent={popoverEvent}
				onDismiss={() => {
					if (mounted.current) {
						setShowDropdownPopover(false);
						setPopoverEvent(undefined);
					}
				}}
				onItemSelect={(item: any) => {
					if (mounted.current) {
						setShowDropdownPopover(false);
						setPopoverEvent(undefined);

						switch (item.type) {
							case 'settings':
								props.history.replace('/settings');
								break;
							case 'editProfile':
								props.history.replace('/profile/edit-profile');
								break;
							case 'privacy':
								props.history.replace('/privacy');
								break;
							case 'logout':
								logoutUser();
								break;
							case 'createGroup':
								props.history.push('/contacts/add-members', {
									actionType: EnumService.AddMembersPageActionType.ChooseContactForGroup,
									defaultGroupMember: friendProfile,
								});
								break;
							case 'newChat':
								props.history.replace('/contacts', {
									actionType: EnumService.ContactsPageActionType.NewChat,
								});
								break;
							case 'manageContacts':
								props.history.replace('/contacts/manage', {});
								break;
							case 'addContact':
								props.history.replace('/contacts/search');
								break;
							case 'favorite':
								setShowFavoriteSearchModal(true);
								break;
							case 'shareContact':
								props.history.replace('/contacts/chat', {
									actionType: EnumService.ChatContactsPageActionType.ShareContact,
									contactDetail: friendProfile,
								});
								break;

							default:
								break;
						}
					}
				}}
			/>

			<FavoriteSearchModal
				show={showFavoriteSearchModal}
				onClose={() => {
					if (mounted.current) {
						setShowFavoriteSearchModal(false);
					}
				}}
			/>
		</>
	);
};
