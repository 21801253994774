import React, { createRef } from 'react';
import { IonActionSheet, IonContent, IonImg, IonLoading, IonPage, IonSlide, IonSlides } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { blankProfilePic } from '../../helpers/common';
import { connect } from 'react-redux';
import { updateUser, uploadMedia, pageLoading, resetValues } from '../../redux/actions/userProfile';
import store from '../../redux/store';
import { SHOW_TOAST } from '../../redux/constants/common';
import ImageEditor, { OpenEditorType } from '../../modals/ImageEditor/ImageEditor';
import { TopNavbar } from '../common/header/topbar';
import ToastStylish from '../../components/ToastStylish';
import { checkmarkOutline } from 'ionicons/icons';
import BottomNavbar from '../common/navbar/bottomNavbar';
import CameraService from '../../services/cameraService';
import { locale } from '../../locales/local';
import { info, prefixMedia } from '../../helpers/common';
import { apiService } from '../../services/apiService';
import EnumService from '../../services/enumService';

import _ from 'lodash';

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: any;
	updateUser: Function;
	uploadMedia: Function;
	resetValues: Function;
	pageLoading: Function;
}
interface iState {
	selectedImage: any;
	showEditor: boolean;
	editedImage: any;
	loggedInUser?: any;
	openActionSheetFor: any;
	isLoading: boolean;
	loadingMessage: string;
	showToast: boolean;
	showBottomMenu: boolean;
	toastMessage: string;
	slideIndex: any;
	gallery: any;
}

class UploadPhoto extends React.Component<iProps, iState> {
	slidesRef: any = createRef();
	cameraService = new CameraService();

	componentIsMounted: Boolean = false;

	componentIsUpdated: Boolean = false;

	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: {},
			gallery: [],
			slideIndex: 0,
			showBottomMenu: false,
			openActionSheetFor: null,
			showEditor: false,
			editedImage: null,
			isLoading: false,
			loadingMessage: '',
			showToast: false,
			toastMessage: '',
			selectedImage: null,
		};
	}

	setPageData = async () => {
		let user = await apiService.me();

		const gallery = user.gallery || [];

		if (gallery) {
			// Fix gallery profile url issue
			gallery.forEach((galleryItem: any, key: any) => {
				let photoUrl: string = '';
				if (typeof galleryItem === 'object') {
					photoUrl = galleryItem.url;
				} else if (typeof galleryItem === 'string') {
					photoUrl = galleryItem;
				}

				// This is not required as gallery images are automatically prefixed - commenting out for now
				// Append baseUrl if not appended
				/*if (photoUrl.split('/').length < 3) {
					const mainProfilePhotoUrl: string = user.profilePhoto,
						directory: string = mainProfilePhotoUrl.substr(0, mainProfilePhotoUrl.lastIndexOf('/'));
					photoUrl = directory + '' + photoUrl;
				}*/

				gallery[key] = {
					url: photoUrl,
					type: 'profile',
				};
			});
		}

		this.componentIsMounted = true;
		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			gallery: gallery,
		}));
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	detectWebcam = (callback: any) => {
		let md = navigator.mediaDevices;
		if (!md || !md.enumerateDevices) return callback(false);
		md.enumerateDevices().then((devices) => {
			callback(devices.some((device) => 'videoinput' === device.kind));
		});
	};

	savePhoto = () => {
		this.setState({ isLoading: true, loadingMessage: locale.global.adding });
		_.delay(() => {
			this.setState({
				...this.state,
				isLoading: false,
				loadingMessage: '',
				showToast: true,
				toastMessage: locale.profile.upload_photo.saved_to_album,
				showBottomMenu: true,
			});
		}, 2000);
	};

	uploadPhoto = async () => {
		if (this.state.editedImage) {
			this.setState({ isLoading: true, loadingMessage: locale.profile.upload_photo.uploading });

			let user = this.state.loggedInUser,
				gallery = this.state.gallery,
				uploaded = this.props.uploadMedia({ userId: this.state.loggedInUser.userId, profilePhoto: this.state.editedImage, mediaType: 'profile' });

			// now, add it to the gallery
			gallery.unshift({ type: 'profile', url: uploaded.profileThumb });
			user.gallery = gallery;
			user = prefixMedia(user);

			// this does not go to the server, as the server has already been updated at this point
			this.props.updateUser({
				_id: this.state.loggedInUser._id,
				profilePhoto: user.profilePhoto,
				profileThumb: user.profileThumb,
				// qr code gets built here now - commenting out until ready
				//qrCode: undefined,
				gallery: gallery,
				callBack: () => {
					this.setState({
						loggedInUser: user,
						gallery: gallery,
						isLoading: false,
						loadingMessage: '',
						showToast: true,
						toastMessage: locale.profile.upload_photo.uploaded,
						showBottomMenu: true,
					});

					this.props.resetValues();

					_.delay(() => {
						this.slidesRef?.current?.slideTo(0);
						this.componentIsUpdated = false;
					}, 1000);
				},
			});

			// old method, updates photo via updateUser, but media is now in gallery, and on server
			/*this.props.updateUser({
				profilePhoto: this.state.editedImage,
				// qr code is not sent to the server - commenting out
				//qrCode: undefined,
				callBack: () => {
					this.setState({
						gallery: this.state.gallery,
						isLoading: false,
						loadingMessage: '',
						showToast: true,
						toastMessage: locale.profile.upload_photo.uploaded,
						showBottomMenu: true,
					});

					this.props.resetValues();
					_.delay(() => {
						this.slidesRef?.current?.slideTo(0);
						this.componentIsUpdated = false;
					}, 1000);
				},
			});*/
		} else {
			store.dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: locale.profile.upload_photo.select_image } });
		}
	};

	makeProfilePhoto = async (profilePhoto: any) => {
		this.setState({ isLoading: true, loadingMessage: locale.profile.upload_photo.updating });
		// adjust sequence of album items
		let gallery = this.state.gallery,
			galleryPhotoIndex: number = gallery.findIndex((_item: any) => _item.type === 'profile' && _item.url === profilePhoto),
			// remove from current gallery
			galleryPhoto: any = gallery.splice(galleryPhotoIndex, 1), // extract it to reinsert after
			galleryThumbIndex: number = gallery.findIndex((_item: any) => _item.type === EnumService.ChatMediaType.THUMBNAIL && _item.original === profilePhoto),
			galleryThumb = gallery[galleryThumbIndex];

		// put back at beginning position
		gallery.unshift(galleryPhoto);

		// this needs to update locally and on the server
		this.props.updateUser({
			_id: this.state.loggedInUser._id,
			profilePhoto: profilePhoto,
			// qr code gets built here now - commenting out until ready
			//qrCode: undefined,
			gallery: gallery,
			callBack: () => {
				this.setState({
					loggedInUser: {
						...this.state.loggedInUser,
						profilePhoto: galleryPhoto,
						profileThumb: galleryThumb,
						gallery: gallery,
					},
					gallery: gallery,
					isLoading: false,
					loadingMessage: '',
					showToast: true,
					toastMessage: locale.profile.upload_photo.updated,
					showBottomMenu: true,
				});

				this.props.resetValues();
				this.slidesRef?.current?.slideTo(0);

				_.delay(() => {
					this.componentIsUpdated = false;
				}, 1000);
			},
			updateServer: true,
		});
	};

	deleteProfilePhoto = async (profilePhotoUrl: any) => {
		// this presumes that the user has already selected a new profile photo
		let gallery = this.state.gallery,
			galleryItemIndex: number = gallery.findIndex((_gallery: any) => _gallery.url === profilePhotoUrl);

		gallery.splice(galleryItemIndex, 1);

		// this needs to update locally and on the server
		this.props.updateUser({
			_id: this.state.loggedInUser._id,
			gallery: gallery,
			galleryIndex: galleryItemIndex,
			callBack: () => {
				this.setState({
					loggedInUser: {
						...this.state.loggedInUser,
						gallery: gallery,
					},
					openActionSheetFor: null,
					showToast: true,
					toastMessage: 'Hidden',
				});
				this.props.resetValues();
				_.delay(() => {
					this.componentIsUpdated = false;
				}, 1000);
			},
			updateServer: true,
		});
	};

	hideProfilePhoto = async (profilePhotoUrl: any) => {
		// this presumes that the user has already selected a new profile photo
		let gallery = this.state.gallery,
			galleryItemIndex: number = gallery.findIndex((_gallery: any) => _gallery.url === profilePhotoUrl);

		gallery[galleryItemIndex].type = 'private';

		this.props.updateUser({
			...this.state.loggedInUser,
			gallery: gallery,
			callBack: () => {
				this.setState({
					loggedInUser: {
						...this.state.loggedInUser,
						gallery: gallery,
					},
					openActionSheetFor: null,
					showToast: true,
					toastMessage: 'Hidden',
				});
				this.props.resetValues();
				_.delay(() => {
					this.componentIsUpdated = false;
				}, 1000);
			},
			updateServer: true,
		});
	};

	unhideProfilePhoto = async (profilePhotoUrl: any) => {
		let gallery = this.state.gallery,
			galleryItemIndex: number = gallery.findIndex((_gallery: any) => _gallery.url === profilePhotoUrl);

		gallery[galleryItemIndex].type = 'profile';

		this.props.updateUser({
			_id: this.state.loggedInUser._id,
			gallery: gallery,
			callBack: () => {
				this.setState({
					loggedInUser: {
						...this.state.loggedInUser,
						gallery: gallery
					},
					openActionSheetFor: null,
					showToast: true,
					toastMessage: 'Hidden',
				});
				this.props.resetValues();

				_.delay(() => {
					this.componentIsUpdated = false;
				}, 1000);
			},
			updateServer: true,
		});
	};

	onSlideChange = (event: any) => {
		const slideRef = event.currentTarget as HTMLIonSlidesElement;
		slideRef?.getActiveIndex().then((index: number) => {
			info(index);
			this.setState({ slideIndex: index });
		});
	};

	onPinturaEditingDone = (file: any) => {
		// encode the file using the FileReader API
		const reader = new FileReader();
		reader.onloadend = async () =>
			this.setState({ showEditor: false, editedImage: reader.result }, () => {
				this.uploadPhoto();
			});
		reader.readAsDataURL(file);
	};

	_getActionsheetOptions = () => {
		const { openActionSheetFor } = this.state;
		const deletePhoto = {
			text: locale.profile.upload_photo.delete_photo,
			role: 'destructive',
			handler: () => {
				const gallery = JSON.parse(JSON.stringify(this.state.gallery));
				gallery.splice(this.state.slideIndex, 1);
				let newPhoto = null;
				if (gallery.length > 0) {
					newPhoto = gallery[0].url;
				}
				this.deleteProfilePhoto(newPhoto);
			},
		};

		const makeProfilePhoto = {
			text: locale.profile.upload_photo.make_profile_photo,
			handler: () => {
				if (this.state.gallery && this.state.gallery.length > this.state.slideIndex) {
					const imageUrl = this.state.gallery[this.state.slideIndex].url;
					this.makeProfilePhoto(imageUrl);
				}
			},
		};

		const takePhoto = {
			text: locale.profile.upload_photo.take_photo,
			handler: () => {
				this.cameraService.capturePhotoFromCamera((photo: any) => {
					this.setState({
						selectedImage: photo,
						showEditor: true,
					});
				});
			},
		};

		const chooseFromAlbum = {
			text: locale.profile.upload_photo.choose_album,
			handler: () => {
				this.cameraService.choosePhotoFromAlbum((photo: any) => {
					this.setState({
						selectedImage: photo,
						showEditor: true,
					});
				});
			},
		};

		const copyPhoto = {
			text: locale.profile.upload_photo.copy_photo,
			handler: () => {
				this.setState({ openActionSheetFor: null, showToast: true, toastMessage: 'Copied' });
			},
		};

		const savePhoto = {
			text: locale.profile.upload_photo.save_photo,
			handler: () => {
				this.savePhoto();
			},
		};

		const hidePhoto = {
			text: locale.profile.upload_photo.hide_photo,
			handler: () => {
				this.hideProfilePhoto(this.state.gallery[this.state.slideIndex].url);
			},
		};

		const cancelPhoto = {
			text: locale.global.cancel,
			role: 'cancel',
			handler: () => {
				this.setState({ openActionSheetFor: null });
				info('Cancel clicked');
			},
		};

		if (openActionSheetFor === 'current-photo') {
			return [deletePhoto, takePhoto, chooseFromAlbum, copyPhoto, savePhoto, cancelPhoto];
		} else if (openActionSheetFor === 'past-photo') {
			return [deletePhoto, makeProfilePhoto, takePhoto, chooseFromAlbum, copyPhoto, savePhoto, hidePhoto, cancelPhoto];
		} else {
			return [takePhoto, chooseFromAlbum, cancelPhoto];
		}
	};

	_showActionSheet = () => {
		if (!this.state.gallery || this.state.gallery.length === 0) {
			this.setState({ openActionSheetFor: 'no-photo' });
		} else {
			let isCurrentPhoto = false;
			const currentProfilePhoto = this.state.loggedInUser.profilePhoto;
			if (currentProfilePhoto) {
				if (this.state.gallery && this.state.gallery.length > this.state.slideIndex) {
					const galleryItem = this.state.gallery[this.state.slideIndex];
					let imageName: string = '';
					if (typeof galleryItem === 'object') {
						imageName = this.state.gallery[this.state.slideIndex].url.split('/').pop();
					} else if (typeof galleryItem === 'string') {
						imageName = galleryItem;
					}

					if (currentProfilePhoto.includes(imageName) || this.state.gallery.length === 1) {
						isCurrentPhoto = true;
					}
				}
			}

			if (isCurrentPhoto) {
				this.setState({ openActionSheetFor: 'current-photo' });
			} else {
				this.setState({ openActionSheetFor: 'past-photo' });
			}
		}
	};

	render() {
		const { slideIndex, gallery } = this.state;

		const slideOpts = {
			initialSlide: slideIndex,
		};

		return this.componentIsMounted ? (
			<IonPage className="upload-photo-page">
				<TopNavbar
					{...this.props}
					pageTitle={gallery.length > 1 ? slideIndex + 1 + ' ' + locale.global.of + ' ' + gallery.length : locale.global.photos}
					hideSearchBar={true}
					showBack={false}
					leftButtonIcon={'./assets/icon/edit-photo-back.svg'}
					isHideRightButton={false}
					onRightButtonPress={() => {
						this._showActionSheet();
					}}
					onLeftButtonPress={() => {
						this.props.history.goBack();
					}}
				/>

				<IonContent className="has-bottom-navbar has-topbar">
					{gallery && gallery.length > 0 && (
						<IonSlides key={gallery.map((photo: any) => photo.url).join('_')} options={slideOpts} onIonSlideDidChange={this.onSlideChange} ref={this.slidesRef}>
							{gallery.map((galleryItem: any, key: any) => {
								let photoUrl: string = galleryItem.url;

								return (
									<IonSlide key={key}>
										<React.Fragment>
											<div style={{ flex: 1 }}></div>
											<div className="profile-placeholder-container" onClick={this._showActionSheet}>
												<IonImg className="profile-placeholder" src={photoUrl}></IonImg>
											</div>
											<div style={{ flex: 1 }}></div>
										</React.Fragment>
									</IonSlide>
								);
							})}
						</IonSlides>
					)}

					{(!gallery || gallery.length === 0) && (
						<IonSlides options={slideOpts} onIonSlideDidChange={this.onSlideChange} ref={this.slidesRef}>
							<IonSlide>
								<React.Fragment>
									<div style={{ flex: 1 }}></div>
									<div className="profile-placeholder-container" onClick={this._showActionSheet}>
										{!this.state.editedImage && <IonImg className="profile-placeholder" src={this.state.loggedInUser.profilePhoto || blankProfilePic}></IonImg>}
										{this.state.editedImage && <IonImg className="profile-placeholder" src={this.state.editedImage}></IonImg>}
									</div>
									<div style={{ flex: 1 }}></div>
								</React.Fragment>
							</IonSlide>
						</IonSlides>
					)}
				</IonContent>

				{this.state.showBottomMenu && <BottomNavbar unreadCount={0} {...this.props} />}

				<ImageEditor
					openEditorType={OpenEditorType.FOR_PROFILE_GROUP_PHOTO}
					selectedImage={this.state.selectedImage}
					show={this.state.showEditor}
					onClose={() => {
						this.setState({ showEditor: false });
					}}
					onSave={this.onPinturaEditingDone}
				/>

				<IonLoading isOpen={this.state.isLoading} backdropDismiss={false} message={this.state.loadingMessage} />
				<ToastStylish
					show={this.state.showToast}
					message={this.state.toastMessage}
					svgIcon={checkmarkOutline}
					onClose={() => {
						this.setState({ showToast: false, toastMessage: '' });
					}}
				/>

				<IonActionSheet mode="ios" isOpen={this.state.openActionSheetFor !== null} onDidDismiss={() => this.setState({ openActionSheetFor: null })} cssClass="manage-contact-action-sheet" buttons={this._getActionsheetOptions()}></IonActionSheet>
			</IonPage>
		) : (
			<></>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	uploadMedia: (payload: String) => dispatch(uploadMedia(payload)),
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto);
