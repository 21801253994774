import { CameraDirection, CameraResultType, CameraSource, Camera } from '@capacitor/camera';

export default class CameraService {
	public capturePhotoFromCamera = (callBack: any) => {
		this.detectWebcam((hasWebcam: boolean) => {
			if (hasWebcam) {
				this.takePhotoFromCamera((photo: any) => {
					callBack && callBack(photo?.dataUrl);
				});
			} else {
				this.triggerUpload('camera', (photo: any) => {
					callBack && callBack(photo);
				});
			}
		});
	};

	public choosePhotoFromAlbum = (callBack: any) => {
		this.triggerUpload('album', (photo: any) => {
			callBack && callBack(photo, 'image');
		});
	};

	public chooseMediaFromAlbum = (callBack: any, canMultipleSelect = false) => {
		this.triggerUpload(
			'album',
			(media: any) => {
				if (canMultipleSelect) {
					callBack && callBack(media);
				} else {
					if (media.indexOf('image') !== -1) {
						callBack && callBack(media, 'image');
					} else if (media.indexOf('video') !== -1) {
						callBack && callBack(media, 'video');
					}
				}
			},
			'photovideo',
			canMultipleSelect
		);
	};

	triggerUpload(type = 'album', callBack: any = null, mediaType = 'photo', canMultipleSelect = false) {
		let input: any = document.createElement('input');
		input.type = 'file';
		if (canMultipleSelect) {
			input.multiple = true;
		}
		if (mediaType === 'photo') {
			input.accept = 'image/png,image/jpeg';
		} else if (mediaType === 'photovideo') {
			input.accept = 'image/png,image/jpeg,video/mp4,video/quicktime';
		}
		if (type === 'camera') {
			input.capture = 'environment';
			// input.capture = 'user'; //for front
		} else {
			// input.capture = 'filesystem';
		}
		input.onchange = (event: any) => {
			const fileCount = event.target.files.length;
			let base64StringArr: any = [];

			//if (canMultipleSelect && fileCount > 0) {

			for (let index = 0; index < fileCount; index++) {
				let reader = new FileReader();
				const file = event.target.files[index];
				reader.readAsDataURL(file);
				reader.onload = async () => {
					const base64String = reader.result as string;
					base64String.replace('data:', '').replace(/^.+,/, '');
					base64StringArr.push(base64String);

					if (base64StringArr.length === fileCount) {
						callBack && callBack(base64StringArr);
						input = null;
					}
				};
			}
			/*} else {
				const file = event.target.files[0];

				//Convert file object to base64 string
				reader.onloadend = async () => {
					const base64String = reader.result as string;
					base64String.replace('data:', '').replace(/^.+,/, '');
					callBack && callBack(base64String);
					input = null;
				};
				reader.readAsDataURL(file);
			}*/
		};
		setTimeout(function () {
			input.click();
		}, 200);
	}

	async takePhotoFromCamera(callBack: any = null) {
		let allowEditing = false;

		const capturedPhoto = await Camera.getPhoto({
			resultType: CameraResultType.DataUrl,
			source: CameraSource.Camera,
			quality: 100,
			allowEditing,
			height: 512,
			correctOrientation: true,
			preserveAspectRatio: true,
			webUseInput: false,
			direction: CameraDirection.Rear,
		});
		try {
			callBack && callBack(capturedPhoto);
		} catch (e) {}
	}

	detectWebcam = (callback: any) => {
		let md = navigator.mediaDevices;
		if (!md || !md.enumerateDevices) return callback(false);
		md.enumerateDevices().then((devices) => {
			callback(devices.some((device) => 'videoinput' === device.kind));
		});
	};
}


export const cameraService = new CameraService();