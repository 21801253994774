import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import Dashboard from './dashboard/Dashboard';
import Chat from './chat/chat';
import CameraPage from './camera/camera';
import Contacts from './contacts/Contacts';
import Discover from './discover/Discover';
import { IonLoading, IonRouterOutlet, IonToast } from '@ionic/react';
import store from '../redux/store';
import { SHOW_LOADER, SHOW_TOAST } from '../redux/constants/common';
import ProfilePage from './profile/profile';
import SettingsPage from './settings/settings';
import ProfileEditor from './profile/profileEditor';
import UploadPhoto from './profile/uploadPhoto';
import MyQRCode from './profile/myQRCode';
import MomentsPage from './moments/Moment';
import CreatePost from './moments/posts/CreatePost';
import AddLocation from './moments/location/AddLocation';
import ShareWith from './moments/sharewith/ShareWith';
import SearchContacts from './contacts/searchContact';
import ContactView from './contacts/contactView';
import addContact from './contacts/addContact';
import CreateGroup from './groups/createGroup';
import ProfileFriend from './profile/profileFriend';
import RecentContacts from './contacts/RecentContacts';
import ManageContact from './contacts/manageContact';
import QuickAccessPage from './quickaccess/QuickAccessPage';
import PrivacyPage from './settings/privacy';
import ChatContacts from './contacts/ChatContacts';
import GroupSetting from './groups/groupSetting';
import GroupManager from './groups/GroupManager/GroupManager';
import GroupInfo from './groups/groupInfo';
import { info } from '../helpers/common';
import Tags from './contacts/Tags';
import TagInfo from './contacts/TagInfo';
import AppUpdatePopupModal from '../modals/AppUpdatePopupModal';
import { getInternalStorage } from '../helpers/common';
import AddMembers from './contacts/AddMembers/AddMembers';
import GroupMembers from './groups/GroupMembers/GroupMembers';
import ChatMedias from './chat/Chatmedias/Chatmedias';

const AuthGuard = (props: any) => {
	const { isAuthUser } = props;

	return (
		<>
			<IonRouterOutlet id="tabs">
				<Route path="/auth" component={Dashboard} exact={true} />
				<Route path="/chat" component={Chat} exact={true} />
				<Route path="/chat/:userId" component={Chat} />
				<Route path="/personal-notepad" component={Chat} />
				<Route path="/moments" component={MomentsPage} exact={true} />
				<Route path="/create-post" component={CreatePost} exact={true} />
				<Route path="/add-location" component={AddLocation} exact={true} />
				<Route path="/share-with" component={ShareWith} exact={true} />
				<Route path="/profile/edit-profile" component={ProfileEditor} exact={true} />
				<Route path="/profile/upload-photo" component={UploadPhoto} exact={true} />
				<Route path="/contacts" component={Contacts} exact={true} />
				<Route path="/contacts/chat" component={ChatContacts} exact={true} />
				<Route path="/contacts/search" component={SearchContacts} exact={true} />
				<Route path="/contacts/view" component={ContactView} exact={true} />
				<Route path="/contacts/add" component={addContact} exact={true} />
				<Route path="/contacts/recent" component={RecentContacts} exact={true} />
				<Route path="/contacts/manage" component={ManageContact} exact={true} />
				<Route path="/contacts/add-members" component={AddMembers} exact={true} />
				<Route path="/tags" component={Tags} exact={true} />
				<Route path="/taginfo" component={TagInfo} exact={true} />
				<Route path="/discover" component={Discover} exact={true} />
				<Route path="/camera" component={CameraPage} exact={true} />
				<Route path="/profile" component={ProfilePage} exact={true} />
				<Route path="/profile-friend" component={ProfileFriend} exact={true} />
				<Route path="/settings" component={SettingsPage} exact={true} />
				<Route path="/privacy" component={PrivacyPage} exact={true} />
				<Route path="/my-qrcode" component={MyQRCode} exact={true} />
				<Route path="/new-group" component={CreateGroup} exact={true} />
				<Route path="/group-manager" component={GroupManager} exact={true} />
				<Route path="/group-setting" component={GroupSetting} exact={true} />
				<Route path="/group-info" component={GroupInfo} exact={true} />
				<Route path="/group-members" component={GroupMembers} exact={true} />
				<Route path="/quick-access" component={QuickAccessPage} exact={true} />
				<Route path="/quick-access-qrcode" component={QuickAccessPage} exact={true} />
				<Route path="/chat-medias" component={ChatMedias} exact={true} />
			</IonRouterOutlet>

			<IonLoading isOpen={isAuthUser.global.isLoading} onDidDismiss={() => store.dispatch({ type: SHOW_LOADER, payload: { isLoading: false, loaderMessage: '' } })} message={isAuthUser.global.loaderMessage} />

			<IonToast
				cssClass={'coming-soon '}
				isOpen={isAuthUser.global.showToast}
				onDidDismiss={() => {
					store.dispatch({ type: SHOW_TOAST, payload: { showToast: false, toastMessage: '' } });
				}}
				message={isAuthUser.global.toastMessage}
				position={'bottom'}
				buttons={[
					{
						text: 'Close',
						handler: () => {
							info('Cancel clicked');
							if (isAuthUser.global.toastCallBack) {
								isAuthUser.global.toastCallBack(true);
							}
						},
					},
				]}
			/>

			<AppUpdatePopupModal
				isLoading={isAuthUser.global.appUpdatePopupLoading}
				loadingMessage={isAuthUser.global.appUpdatePopupLoadingMessage}
				show={isAuthUser.global.showAppUpdatePopup}
				heading={isAuthUser.global.appUpdatePopupHeading}
				subHeading={isAuthUser.global.appUpdatePopupSubHeading}
				messageTitle={isAuthUser.global.appUpdatePopupMessageTitle}
				message={isAuthUser.global.appUpdatePopupMessage}
				actionButtonnTitle={isAuthUser.global.appUpdatePopupActionBtnTitle}
				callBack={isAuthUser.global.appUpdatePopupCallBack}
			/>
		</>
	);
};

const mapStateToProps = (isAuthUser: any) => ({ isAuthUser });

export default connect(mapStateToProps)(AuthGuard);
