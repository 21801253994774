import { IonModal, IonToolbar, IonButtons, IonButton, IonLabel, IonItem, IonThumbnail, IonImg, IonTextarea } from '@ionic/react';
import React, { useCallback, useEffect, useRef } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { isJsonString, getInternalStorage } from '../helpers/common';
import { SelectedContacts } from '../pages/contacts/components/SelectedContacts';
import SharedService from '../services/sharedService';
import { locale } from '../locales/local';
import _ from 'lodash';
interface ShareMessageModalProps {
	sharingType: 'chat' | 'contact';
	show: boolean;
	shareData: any;
	contacts?: any;
	selectedContacts?: any;
	onClose?: any;
	onSend?: any;
}

const ShareMessageModal: React.FC<ShareMessageModalProps> = ({ sharingType, show, shareData, contacts, selectedContacts, onClose, onSend, ...props }) => {
	const inputRef: React.RefObject<HTMLIonTextareaElement> = useRef(null);
	const [message, setMessage] = React.useState('');

	const maxRows = 100000;
	const approxCharsPerRow = 30;
	const [allowAutoGrow, setAllowAutoGrow] = React.useState(true);
	const handleOnChange = React.useCallback((e) => {
		const newLines = e.target.value.split('').filter((c: any) => c === '\n').length;
		setAllowAutoGrow(newLines < maxRows - 2 && e.target.value.length < approxCharsPerRow * maxRows);
		if (!e.target.value || e.target.value.length === 0) {
			e.target.style.height = 'auto';
		} else {
			e.target.style.height = 'auto';
		}
		if (e.target.clientHeight === 60) {
			e.target.style.color = '#000000';
		} else {
			e.target.style.color = '#979797';
		}
		setMessage(e.target.value);
	}, []);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				inputRef.current?.setFocus();
			}, 1000);
		}
	}, [show, inputRef]);

	const onCloseCallBack = useCallback(
		(event: any) => {
			onClose && onClose();
		},
		[onClose]
	);

	const extractContactName = useCallback((contact: any) => {
		return contact?.name || contact?.username || '';
	}, []);

	let snippet;
	if (sharingType === 'chat') {
		let msgBody = isJsonString(shareData.body) ? JSON.parse(shareData.body) : shareData.body;
		const extractChatFromMsgbody = SharedService.extractChatFromMsgbody(msgBody);
		snippet = extractChatFromMsgbody.snippet;
		// let attachmentData: any = extractChatFromMsgbody.attachmentData;
	} else if (sharingType === 'contact') {
		snippet = '[Contact Card] ' + shareData.name;
	}

	const cookies: any = async () => getInternalStorage();

	return (
		<IonModal showBackdrop={false} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={false} cssClass="share-message-modal" animated={true}>
			<div className="content-view">
				<IonLabel className="title-label">{locale.share_message.send_to}:</IonLabel>

				{selectedContacts &&
					selectedContacts.length === 1 &&
					contacts &&
					contacts.map((cont: any) => {
						if (_.includes(selectedContacts, cont._id)) {
							const contactName = extractContactName(cont);

							return (
								<IonItem className="contact-item" detail={true} lines="none">
									<IonThumbnail slot="start">
										<IonImg src={cont.hasOwnProperty('profilePhoto') && cont.profilePhoto ? cont.profilePhoto : 'assets/img/blank-user-profile.png'} />
									</IonThumbnail>
									<IonLabel>{contactName}</IonLabel>
								</IonItem>
							);
						}
						return null;
					})}

				{selectedContacts && selectedContacts.length > 1 && <SelectedContacts contacts={contacts} selectedContacts={selectedContacts} />}

				<div className="message">{snippet}</div>

				<IonItem lines="none">
					<IonTextarea
						mode={'md'}
						placeholder={locale.share_message.leave_message}
						rows={allowAutoGrow ? 1 : maxRows}
						autoGrow={allowAutoGrow}
						autofocus={true}
						debounce={1}
						value={String(message)}
						onKeyPress={(event) => {
							if (!cookies.mobileDevice) {
								if (event.ctrlKey && event.key === 'Enter') {
									event.preventDefault();
									setMessage(message + '\n');
								} else if (event.key === 'Enter') {
									event.preventDefault();
								}
							}
						}}
						onIonChange={handleOnChange}
						ref={inputRef}
						onFocus={() => {}}
					></IonTextarea>
				</IonItem>

				<IonToolbar>
					<IonButtons slot="start">
						<IonButton fill="clear" onClick={onCloseCallBack}>
							{locale.cancel}
						</IonButton>
					</IonButtons>
					<IonButtons slot="end">
						<IonButton fill="clear" onClick={() => onSend && onSend(message)}>
							{locale.send}
							{selectedContacts && selectedContacts.length > 1 ? '( ' + selectedContacts.length + ' )' : ''}
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		searchContact: state.searchContact,
	};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ShareMessageModal);
