import { LOGIN_REQUESTED, LOGIN_FAILED, REGISTER, LOGIN_SUCCESS, USER_AUTHENTICATED, LOGIN_INIT_CONTACTS } from '../constants/auth';
import { DASHBOARD_INIT } from '../constants/dashboard';
import { AUTH_SHOW_LOADER, AUTH_FORM_ERROR, AUTH_RESET_VALUES, USER_LOGIN } from '../constants/common';
import { v4 as uuidv4 } from 'uuid';
import md5 from 'md5';
import { apiService } from '../../services/apiService';
import { getInternalStorage, setInternalStorage, isBlank, prefixMedia, info, deleteItemFromInternalStorage, clearInternalStorage } from '../../helpers/common';
import store from '../store';
import _ from 'lodash';
import { xmpp } from '../../services/xmpp';
import { locale } from '../../locales/local';
export const resetValues = () => ({ type: AUTH_RESET_VALUES });

export const authenticated = async (user: any, payload: any = {}) => {
	if (!isBlank(payload)) {
		try {
			const isWebBrowser = 'serviceWorker' in window.navigator;

			let cookies = getInternalStorage();

			cookies.db = md5(`${user.userId}`);
			setInternalStorage('db', cookies.db);

			user = await prefixMedia(user);

			store.dispatch({ type: USER_LOGIN, payload: user });
			store.dispatch({ type: USER_AUTHENTICATED, payload: user });
			setInternalStorage('active', true);

			// todo: need to encrypt this
			setInternalStorage('tk', user.token);

			// is this a personalDevice?  If not, delete any databases found here
			if (!payload.personalDevice) {
				if (isWebBrowser) {
					let database = (await apiService.listDatabases()).find((_database: any) => _database.includes(cookies.db));

					if (database) {
						await apiService.deleteDatabase(database);
					}
				}
			}

			setInternalStorage('personalDevice', payload.personalDevice || false);
			xmpp.setMessagesLoaded(false);

			for (let contact of user.contacts) {
				contact = await prefixMedia(contact);
			}

			for (let group of user.groups) {
				group = await prefixMedia(group);
			}

			await apiService.addUser(await prefixMedia(user));
			info('auth::authenticated: user added.');
			//await apiService.addContacts(user.contacts);
			//info('auth::authenticated: contacts added.');
			//await apiService.addGroups(user.groups);
			//info('auth::authenticated: groups added.');

			store.dispatch({ type: LOGIN_INIT_CONTACTS });
			user = await apiService.processConversations(false, user.conversations);
			info('auth::authenticated: initial conversations seeded');
			// this initializes the dashboard
			store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });
			info('auth::authenticated: dashboard initialized.');

			// this starts the xmpp manager
			await xmpp.xmppManager();
			info('auth::authenticated: xmpp started.');

			info(`auth::authenticated: storing uuid: ${payload.uuid}`);
			setInternalStorage('uuid', payload.uuid);

			store.dispatch({ type: LOGIN_SUCCESS, payload: user });
			info('auth::authenticated: user authenticated.');
			// this forces the redirect/refresh of the auth screen, which will now display the dashboard
			payload.history.push('/auth');

		} catch (error) {
			clearInternalStorage();
			payload.Error = { message: locale.global.technical_difficulties };
			store.dispatch({ type: LOGIN_FAILED, payload: payload });
		}
	}
	else {
		clearInternalStorage();
	}
};
export const login = async (payload: any) => {
	store.dispatch({ type: LOGIN_REQUESTED, payload: {} });
	deleteItemFromInternalStorage('groups');
	let cookies = getInternalStorage();

	payload.uuid = cookies.uuid || uuidv4();
	let user: any = await apiService.authenticate(_.omit(payload, ['personalDevice', 'history']));

	if (user.Error) {
		store.dispatch({ type: LOGIN_FAILED, payload: user });
	} else {
		setInternalStorage('up', payload.password);
		await authenticated(user, payload);
	}
};

export const formHasErrors = (payload: any) => ({ type: AUTH_FORM_ERROR, payload: payload });
export const isLoading = (loader: boolean) => ({ type: AUTH_SHOW_LOADER, payload: loader });
export const register = (payload: any) => ({ type: REGISTER, payload: payload });
