import React from 'react';
import { IonButton, IonContent, IonLabel, IonNote, IonPage, IonSearchbar, IonText, IonToolbar } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../redux/actions/userProfile';
import { apiService } from '../../services/apiService';
import { TopNavbar } from '../common/header/topbar';
import { SelectedContacts } from '../contacts/components/SelectedContacts';
import PhotoInputItem from '../../components/PhotoInputItem';
import MultiInputListItem from '../../components/MultiInputListItem';
import { locale } from '../../locales/local';
import { info } from '../../helpers/common';
import _ from 'lodash';

const FieldsKey = {
	GroupPhoto: 'groupPhoto',
	GroupName: 'groupName',
	GroupQrCode: 'groupQrCode',
	GroupNotice: 'groupNotice',
	GroupMemo: 'groupMemo',
	MyAliasInGroup: 'myAliasInGroup',
};

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: any;
	loggedInUser: any;
	pageLoading: Function;
	updateUser: Function;
	location: any;
}

class GroupInfo extends React.Component<iProps, any> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);
		const locationState = props.location.state,
			groupDetail = locationState?.groupDetail,
			selectedContacts: any = [];

		if (groupDetail) {
			groupDetail.members.forEach((item: any) => {
				selectedContacts.push(item._id);
			});
		}

		this.state = {
			loggedInUser: undefined,
			isManager: undefined,
			searchText: '',
			allContacts: groupDetail?.members,
			selectedContacts: selectedContacts,
			groupDetail: groupDetail,
			currentActiveEditField: '',
			[FieldsKey.GroupPhoto]: null,
			[FieldsKey.GroupName]: 'Not Set',
			[FieldsKey.GroupQrCode]: '',
			[FieldsKey.GroupNotice]: 'This Group is Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah... ',
			[FieldsKey.GroupMemo]: 'Stage 1 needs to be finished by next week!!!',
			[FieldsKey.MyAliasInGroup]: '',
		};
		this.submitChanges = this.submitChanges.bind(this);

		/*apiService.getConversations().then((contacts: any) => {
			// this.setState({
			// 	allContacts: contacts,
			// });
		});*/
	}

	getData = async () => {
		return await apiService.me();
	};

	setPageData = async () => {

		let user = await this.getData();

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			isManager: _.includes(this.state.groupDetail?.groupAdmins, user._id),
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = () => {
		this.setPageData();
	};

	componentDidUpdate = () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			this.setPageData();
		}
	};

	submitChanges() {}

	render() {
		if (this.componentIsMounted) {
			const { currentActiveEditField, loggedInUser, groupDetail, isManager } = this.state;

			return (
				<IonPage className="group-info-page">
					<TopNavbar
						{...this.props}
						hideSearchBar={true}
						pageTitle={'Group(' + groupDetail?.members?.length + ')'}
						showBack={true}
						onRightButtonPress={() => {
							this.props.history.replace('/group-setting', {
								groupDetail: groupDetail,
							});
						}}
					/>
					<IonContent className="main-content-profile has-topbar">
						{this.state.allContacts && <SelectedContacts highlightContacts={groupDetail?.groupAdmins} showAddContactBtn={isManager} contacts={this.state.allContacts} selectedContacts={this.state.selectedContacts} />}

						<IonToolbar>
							<form
								onSubmit={(e) => {
									e.preventDefault();
									info('testing...');
								}}>
								<IonSearchbar className="searchbar-wrapper" debounce={1} value={String(this.state.searchText)} onIonChange={(e) => this.setState({ searchText: e.detail.value! })} showCancelButton="never"></IonSearchbar>
							</form>
						</IonToolbar>

						<IonList lines="none">
							<PhotoInputItem
								isEdittable={isManager}
								label={locale.global.photo}
								fieldKey={FieldsKey.GroupPhoto}
								photo={this.state[FieldsKey.GroupPhoto]}
								isDisabled={false}
								onPhotoAdded={(photo: any) => {
									this.setState({ [FieldsKey.GroupPhoto]: photo });
								}}
							/>

							<MultiInputListItem
								className="group-name"
								inputType="text"
								isEdittable={isManager}
								label={locale.global.name}
								key={FieldsKey.GroupName}
								profileValueKey={FieldsKey.GroupName}
								value={this.state[FieldsKey.GroupName]}
								isEditMode={currentActiveEditField === FieldsKey.GroupName}
								shouldDisabled={currentActiveEditField !== '' && currentActiveEditField !== FieldsKey.GroupName}
								onEditModeOn={() => {
									this.setState({ currentActiveEditField: FieldsKey.GroupName });
								}}
								onChange={(value: any) => {
									this.setState({ [FieldsKey.GroupName]: value });
								}}
							/>

							<PhotoInputItem
								className="qr-code-photo"
								isEdittable={true}
								label={locale.global.qr_code}
								fieldKey={FieldsKey.GroupQrCode}
								photo={this.state[FieldsKey.GroupQrCode]}
								isDisabled={false}
								// onPhotoAdded={(photo: any) => {
								// 	this.setState({ [FieldsKey.GroupQrCode]: photo });
								// }}
								onItemClick={() => {}}
							/>

							<IonItem detail={isManager}>
								<IonLabel>
									<h3>{locale.groups.notice}</h3>
									<p>{this.state[FieldsKey.GroupNotice]}</p>
								</IonLabel>
							</IonItem>

							<IonItem detail={isManager}>
								<IonLabel>
									<h3>{locale.groups.memo}</h3>
									<p>{this.state[FieldsKey.GroupMemo]}</p>
								</IonLabel>
							</IonItem>

							<IonItem>
								<IonLabel>{locale.groups.my_alias}</IonLabel>
								<IonNote slot="end">{loggedInUser?.username || loggedInUser?.name}</IonNote>
							</IonItem>

							<IonItem>
								<IonLabel>
									<h3>{locale.groups.encryption}</h3>
									<p>{locale.groups.info_encrypt}</p>
								</IonLabel>
							</IonItem>
						</IonList>

						<div className="bottom-btns">
							<IonButton mode="ios" fill="clear">
								{locale.global.report}
							</IonButton>
							<IonText>{locale.global.created} Apr 1, 2021</IonText>
						</div>
					</IonContent>
				</IonPage>
			);
		} else {
			return <></>;
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupInfo);
