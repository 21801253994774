import { IonButton, IonIcon, IonInput, IonModal } from '@ionic/react';
import React, { createRef, useEffect, useState } from 'react';
import './style.scss';
import { connect } from 'react-redux';

const ClearIcon = './assets/icon/close-icon.svg';

interface AddNewTagPopupProps {
	show: boolean;
	onCloseCallBack: any;
	onConfirm: any;
}

const AddNewTagPopup: React.FC<AddNewTagPopupProps> = ({ show, onCloseCallBack, onConfirm }) => {
	const [tagName, setTagName] = useState<string | null | undefined>('');
	const tagInputRef: React.RefObject<HTMLIonInputElement> = createRef();

	useEffect(() => {
		if (!show && tagName) {
			setTagName('');
		}
	}, [show, tagName]);
	return (
		<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="add-new-tag-popup" animated={true}>
			<div className="container">
				<div className="content-view">
					<div className="title">New Tag</div>
					<div className="message">Tags are to help users keep their chats organized.</div>

					<div className="tag-input-container">
						<div className="input-view">
							<IonInput ref={tagInputRef} id="tagNameInput" placeholder="Family, Creatives, Blockchain, etc" className="tag-name-input" value={tagName} onIonChange={(event) => setTagName(event.detail.value)} />
							{tagName && (
								<IonIcon
									className="clear-icon"
									src={ClearIcon}
									onClick={() => {
										setTagName('');
										const tagNameInput: any = document.getElementById('tagNameInput');
										if (tagNameInput && tagNameInput.setFocus) {
											tagNameInput.setFocus();
										}
									}}
								></IonIcon>
							)}
						</div>
					</div>
					<div className="btns-view">
						<IonButton fill="clear" className="delete-btn" onClick={onCloseCallBack}>
							Cancel
						</IonButton>
						<IonButton fill="clear" className="ok-btn" onClick={() => onConfirm(tagName)} disabled={!tagName}>
							Create Tag
						</IonButton>
					</div>
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTagPopup);
