import { REGISTER_REQUESTED, REGISTER_FAILED, USERNAME_UNAVAILABLE, USERNAME_AVAILABLE, USERNAME_ERROR, REGISTER_SUCCESS } from '../constants/register';
import { REGISTER_SHOW_LOADER, REGISTER_FORM_ERROR, REGISTER_GENERAL_ERROR, REGISTER_RESET_VALUES } from '../constants/common';
import md5 from 'md5';
import NodeRSA from 'node-rsa';
import { v4 as uuidv4 } from 'uuid';
import { authenticated } from './auth';
import { locale } from '../../locales/local';
import { apiService } from '../../services/apiService';
import { info, setInternalStorage } from '../../helpers/common';
import { delay } from 'lodash';

export function register(payload: any) {
	return async (dispatch: any) => {
		dispatch({ type: REGISTER_REQUESTED, payload: {} });

		delay(async () => {
			const key = new NodeRSA({ b: 2048 }),
				uuid = uuidv4(),
				password = md5(String(payload.password));

			key.setOptions({ environment: 'node' });
			setInternalStorage('pk', key.exportKey('private'));
			setInternalStorage('uuid', uuid);
			setInternalStorage('up', password);

			let user: any = {
				...payload,
				pubKey: key.exportKey('public'),
				password: password,
				uuid: uuid,
				ecryptMessages: true,
			};

			apiService.registerUser(user).then(async (_response: any) => {
				if (!_response.Error) {
					await authenticated(_response);
					dispatch({ type: REGISTER_SUCCESS, payload: _response });
				} else {
					dispatch({ type: REGISTER_FAILED, payload: _response });
				}
			});
		}, 100);
	};
}

export function resetValues() {
	return { type: REGISTER_RESET_VALUES };
}

export function formHasErrors(payload: any) {
	return { type: REGISTER_FORM_ERROR, payload: payload };
}

export function isLoading(loader: boolean) {
	return { type: REGISTER_SHOW_LOADER, payload: loader };
}

export function checkUserIdAvailabliity(payload: any) {
	return async (dispatch: any) => {
		dispatch({ type: REGISTER_SHOW_LOADER, payload: true });

		await apiService
			.checkUserIdAvailability(payload)
			.then((response: any) => {
				info(response, payload.username);
				if (!response.Error && response.status) {
					info(response);
					if (response.status === 'unavailable') {
						dispatch({
							type: USERNAME_UNAVAILABLE,
							// payload: `${response.authType} '${payload.userId}' ${locale.global.already_registered} ${response.authType}`,
							payload: `${locale.global.already_registered}`,
						});
					} else if (response.status === 'banned') {
						dispatch({
							type: USERNAME_UNAVAILABLE,
							payload: `${response.authType} '${payload.userId}' ${locale.global.banned} ${response.authType}`,
						});
					} else {
						dispatch({ type: USERNAME_AVAILABLE, payload: response });
					}
				} else {
					dispatch({ type: USERNAME_ERROR, payload: response });
				}
			})
			.catch((error: any) => dispatch({ type: REGISTER_GENERAL_ERROR, payload: error }));
	};
}
